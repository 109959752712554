/* eslint-disable no-useless-escape */
import React from "react";
import AppReducer from "./reducer";
import Axios from "axios";

// High-order functions in context
import DateFormatter from "../functions/NewDateFormatter";
import translate from "../lang/translator";

// Multilangual Files
import tr from "../lang/locales/tr";
import en from "../lang/locales/en";
import ru from "../lang/locales/ru";
import ar from "../lang/locales/ar";

const AppContext = React.createContext();
const CryptoJS = require("crypto-js");

export class AppProvider extends React.Component {
  constructor(props) {
    super();
    this.state = localStorage.getItem("state2")
      ? {
          ...initialState,
          ...JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem("state2"),
              "S@LON"
            ).toString(CryptoJS.enc.Utf8)
          ),

          // New Keys Persist
          locales: { tr, en, ru, ar },
        }
      : { ...initialState };

    if (this.state.user.token !== null)
      Axios.defaults.headers.Authorization = `Bearer ${this.state.token}`;
  }

  dispatch = async (action) => {
    await localStorage.removeItem("state2");
    await this.setState((state) => AppReducer(state, action));
    await localStorage.setItem(
      "state2",
      CryptoJS.AES.encrypt(JSON.stringify(this.state), "S@LON").toString()
    );
  };

  render() {
    const context = {
      // api_endpoint: "http://api.salonmanagementapp.com/api/v1",
      // eslint-disable-next-line
      api_endpoint: this.state.APPLICATION.SALON_RANDEVU.API, //current.api
      app: this.state.APPLICATION.SALON_RANDEVU, //current.application
      current_flag: this.state.APPLICATION.SALON_RANDEVU.ID, //current.flag
      expected_en_randevu: [
        "/receipts",
        "/accounting",
        "/reports",
        "/packages",
        "/products",
      ],
      expected_child_en_randevu: ["/risky"],

      t: (key, variables, config) =>
        translate(key, this.state, config, variables), // Multilangual translator function
      state: this.state,
      dispatch: this.dispatch,
      actions: { DateFormatter },
    };

    return (
      <AppContext.Provider value={context}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;
export const AppConsumer = AppContext.Consumer;

const FLAG = {
  SALON_RANDEVU: 1 << 1,
  EN_RANDEVU: 1 << 2,
};

export const initialState = {
  locales: { tr, en },
  walletLicensePanelScaffold: true,
  currency: "$",
  currencyValues: [],
  default_locale: "en",

  FLAGS: {
    SALON_RANDEVU: FLAG.SALON_RANDEVU,
    EN_RANDEVU: FLAG.EN_RANDEVU,
  },

  APPLICATION: {
    SALON_RANDEVU: {
      NAME: "Salon Management",
      ID: FLAG.SALON_RANDEVU,
      API: "https://api.salonmanagementapp.com/api/v1",
      WEB: "https://app.salonmanagementapp.com",
      EXCEL:
        "https://app.salonmanagementapp.com/excel/SalonManagement_CustomerExcel.xlsx",
      PRIVACY: "http://salonrandevu.com/gizlilik-sozlesmesi",
      LOGO: "logo_salonmanagement.png",
      INSTAGRAM: "salonmanagement",
      LANGUAGE: false,
      COLORS: {
        //for reference
        PRIMARY: "#ed1e79",
        SECONDARY: "rgb(71, 13, 108)",
      },
      SMS_SENDER_NAME: "SalonRANDVU",
    },
    EN_RANDEVU: {
      NAME: "En Randevu",
      ID: FLAG.EN_RANDEVU,
      API: "https://api.enrandevu.com/api/v1",
      WEB: "https://app.enrandevu.com",
      EXCEL:
        "https://app.salonrandevu.com/excel/Salonrandevu_MusteriExcel.xlsx",
      PRIVACY: "http://www.enrandevu.com/gizlilik-soz",
      LOGO: "logo_en.png",
      INSTAGRAM: "salonrandevu",
      LANGUAGE: false,
      COLORS: {
        //for reference
        PRIMARY: "#ffa000",
        SECONDARY: "#1e90ff",
      },
      SMS_SENDER_NAME: "Enrandevu",
    },
  },

  /*
  !! deprecated

  get CURRENT_APP (){
    return this.APPLICATION.EN_RANDEVU; //current.application
  },*/

  is_authenticated: false,
  token_expire_date: null, //Date.now() biçiminde
  token: null,

  user: {
    id: null,
    full_name: null,
    permission: null,
  },

  company_id: null,
  company_shift: {
    start_hour: null,
    end_hour: null,
    slice: null,
  },

  company_services: [],
  company_license: null,

  company_sms_settings: null,
  company_sms_settings_loaded: false,

  company_branches: [],
  company_branch_selected: false,
  company_is_branch: false, // Şube mi
  company_branch_first: {
    id: null, // İşletme ID
    name: null, // İşletme adı
    staffs: [
      {
        id: null,
        full_name: null,
        detail: {
          phone: null,
          mail: null,
        },
      },
    ],
    license: {
      end_date_text: null,
    },
  },

  company_tutorial: null,

  appointment_notification: false,

  sms_credit: null,
  remainingAppointment: null,

  limited_permission: null,

  whatsapp_permission: null,

  online_link: null,

  license_choosen: false,

  dialCode: null,

  countryCode: null,

  balance: [],
  fieldCardTwoTitle: 0,

  api_key: null,

  wp_state: null,

  wp_phone: null,

  checkedWP: true,

  sender: "",

  employeePackages: [],
  is_dealer: null,
};
