import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import AppContext from "../../context/store";
import LanguageButton from "../Buttons/LanguageButton";

class TranslateView extends Component {
	static contextType = AppContext;

	render() {
		// console.log(this.context.CURRENT_APP);

		return (
			<BoxPopContainer
				className={this.props.state ? "show" : "hide"}
				onMouseOver={this.props.stopPropagation}
				onClick={this.props.stopPropagation}
			>
				<BoxPopInner>
					<BoxPopCaption>
						{this.context.t(`scaffold.translateViewHeading`)}
					</BoxPopCaption>

					<LanguagesContainer>
						<LanguageButton heading="Türkçe" lang="tr" />
						<LanguageButton heading="English" lang="en" />
						<LanguageButton heading="Russia" lang="ru" />
						<LanguageButton heading="Arabic" lang="ar" />
					</LanguagesContainer>
				</BoxPopInner>
			</BoxPopContainer>
		);
	}
}

export default withRouter(TranslateView);

const BoxPopContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;

	position: absolute;
	z-index: 10;
	left: 0;
	top: 50px;

	width: 150px;
	max-width: 80vw;

	background: transparent;
	opacity: 0;

	&.show {
		opacity: 1;
		visibility: visible;
		transition: backdrop-filter 0s, all ease 0.25s;
	}

	&.hide {
		opacity: 0;
		visibility: hidden;
		transition: all ease 0.25s;
	}

	@media only screen and (max-width: 576px) {
		right: 20px !important;
		max-width: calc(100vw - 40px) !important;
	}
`;

const BoxPopCaption = styled.h4`
	font-weight: 600;
	letter-spacing: 0.25px;
	letter-spacing: 0.75px;
	font-size: 11px;
	color: #aaa;
	padding: 8px 0;
	margin: 0;
`;

const BoxPopInner = styled.div`
	width: 100%;
	padding: 8px 16px 16px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`;

export const LanguagesContainer = styled.div`
	display: flex;
	flex-direction: column;
	// align-items: center;
	justify-content: center;
`;
