import React from "react";
import { Column, Grid } from "../../../../theme/Grid";
import googleIcon from "../../../../assets/images/google.png";
import iosStore from "../../../../assets/images/app-store.png";
import googlePlayIcon from "../../../../assets/images/google-play.png";
import StarIcon from "@material-ui/icons/Star";
import styles from "./Header.module.css";
import headerImage1 from "../../../../assets/images/headerImage1.png";
import headerImage2 from "../../../../assets/images/headerImage2.png";
import { backgrounds } from "polished";

const Stars = () => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((_, i) => (
        <StarIcon key={i} style={{ color: "orange", width: "14px" }} />
      ))}
    </div>
  );
};

function Header() {
  return (
    <div style={{ marginBottom: "100px", marginTop: "70px" }}>
      <div style={{ marginTop: "30px" }} className="container">
        <Grid>
          <Column className="xs-12 sm-12 md-6 lg-6 xl-6 ">
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <div className={styles.smallScreenOnly}>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        /*  onClick={() =>
                           window.open(
                             `https://apps.apple.com/tr/app/salon-randevu-y%C3%B6netim-sistemi/id1413695973`,
                             "_blank"
                           )
                         } */
                        width={27}
                        src={iosStore}
                      />
                      <p style={{ color: "#788291", fontSize: "11px" }}>
                        {" "}
                        App Store
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: "#788291",
                            fontSize: "11px",

                            margin: "0px",
                          }}
                        >
                          4.8
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        /*   onClick={() =>
                            window.open(
                              `https://play.google.com/store/search?q=enrandevu&c=apps&gl=TR`,
                              "_blank"
                            )
                          } */
                        width={27}
                        src={googlePlayIcon}
                      />
                      <p style={{ color: "#788291", fontSize: "11px" }}>
                        {" "}
                        Play Store
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: "#788291",
                            fontSize: "11px",

                            margin: "0px",
                          }}
                        >
                          4.7
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        /*  onClick={() =>
                           window.open(
                             `https://www.google.com/search?sca_esv=50a3c251fb54b13c&sca_upv=1&sxsrf=ADLYWIIoS0fNJV_ZhF7bqfprjTUQnE7MAg:1715771860082&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7d-yRVfdNV6hhPVy8AF3s7HsHaeG-yxxk7OYH65ZZmHv25_XrIR346IxF0p6hFl8lhK5gexaYM9G1rVCmf_v2L4nl3n6QaY2IFP32eJnEt16uyPQ-D&si=ACC90nwO9xEzySNrBbeVuYhtSl5s84rzWVZlRreGH3L-IdoVybOPGo_7z_CvDm1yog9fpK87syTJWNOZZ_e8BDvPiHjP-PvAGOa832Zh1dmv2CztHYJEkjk%3D&q=SALON+RANDEVU+Yorumlar&sa=X&ved=2ahUKEwjc_vChxI-GAxVlQPEDHTyiDVUQ3PALegQIbRAF&biw=1920&bih=953&dpr=1`,
                             "_blank"
                           )
                         } */
                        width={27}
                        src={googleIcon}
                      />
                      <p style={{ color: "#788291", fontSize: "11px" }}>
                        Google Reviews
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: "#788291",
                            fontSize: "11px",

                            margin: "0px",
                          }}
                        >
                          5.0
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    //float: "left",
                    fontSize: "36px",
                    fontWeight: "700",
                    marginBottom: "30px",
                    marginTop: "30px",
                    textAlign: "center",
                  }}
                >
                  Beauties for Your Living Room
                  <span style={{ color: "#ff006a" }}> Here</span>
                </div>
                <p
                  style={{
                    color: "#788291",
                    width: "100%",
                    textAlign: "center",
                    fontFamily: "Noto Sans, sans-serif",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: 0,
                    marginTop: "20px",
                  }}
                >
                  Whether you are at home, on holiday, in the salon, in the car
                  or wherever you are, click on everything exactly as you want
                  with Salon Randevu APP, Turkey's most concise Salon Management
                  Platform. You can organize appointments, manage personnel,
                  control stock, and send personalized SMS to the books.
                  Everything here has been designed to help you manage your
                  living room more easily.
                </p>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <div className={styles.content}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        /* onClick={() =>
                          window.open(
                            `https://apps.apple.com/tr/app/salon-randevu-y%C3%B6netim-sistemi/id1413695973`,
                            "_blank"
                          )
                        } */
                        width={27}
                        src={iosStore}
                      />
                      <p style={{ color: "#788291", fontSize: "11px" }}>
                        {" "}
                        App Store
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: "#788291",
                            fontSize: "11px",

                            margin: "0px",
                          }}
                        >
                          4.8
                        </p>
                      </div>
                    </div>
                    <div
                      /*  onClick={() =>
                         window.open(
                           `https://play.google.com/store/apps/details?id=com.salon.plus`,
                           "_blank"
                         )
                       } */
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px",
                        cursor: "pointer",
                      }}
                    >
                      <img width={27} src={googlePlayIcon} />
                      <p style={{ color: "#788291", fontSize: "11px" }}>
                        {" "}
                        Play Store
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: "#788291",
                            fontSize: "11px",

                            margin: "0px",
                          }}
                        >
                          4.7
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        /*  onClick={() =>
                           window.open(
                             `https://www.google.com/search?sca_esv=50a3c251fb54b13c&sca_upv=1&sxsrf=ADLYWIIoS0fNJV_ZhF7bqfprjTUQnE7MAg:1715771860082&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7d-yRVfdNV6hhPVy8AF3s7HsHaeG-yxxk7OYH65ZZmHv25_XrIR346IxF0p6hFl8lhK5gexaYM9G1rVCmf_v2L4nl3n6QaY2IFP32eJnEt16uyPQ-D&si=ACC90nwO9xEzySNrBbeVuYhtSl5s84rzWVZlRreGH3L-IdoVybOPGo_7z_CvDm1yog9fpK87syTJWNOZZ_e8BDvPiHjP-PvAGOa832Zh1dmv2CztHYJEkjk%3D&q=SALON+RANDEVU+Yorumlar&sa=X&ved=2ahUKEwjc_vChxI-GAxVlQPEDHTyiDVUQ3PALegQIbRAF&biw=1920&bih=953&dpr=1`,
                             "_blank"
                           )
                         } */
                        width={27}
                        src={googleIcon}
                      />
                      <p style={{ color: "#788291", fontSize: "11px" }}>
                        Google Reviews
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <Stars />
                        <p
                          style={{
                            color: "#788291",
                            fontSize: "11px",

                            margin: "0px",
                          }}
                        >
                          5.0
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          <Column className="xs-12 sm-12 md-6 lg-6 xl-6 ">
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={styles.smallScreenOnly}>
                <Grid>
                  <Column className="xs-6 sm-6 md-6 lg-6 xl-6 mt-4">
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            width: "100%",
                            textAlign: "left",
                            color: "#ff006a",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "22px",
                            fontFamily: "Noto Sans, sans-serif",
                          }}
                        >
                          By choosing one of the packages suitable for you below
                          You can benefit from content specific to you.
                        </div>
                      </div>
                      <img style={{ width: "130%" }} src={headerImage1} />
                    </div>
                  </Column>

                  <Column className="xs-6 sm-6 md-6 lg-6 xl-6 mt-2 ">
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img style={{ width: "120%" }} src={headerImage2} />
                    </div>
                  </Column>
                </Grid>
              </div>
              <div className={styles.content}>
                <Grid style={{ display: "flex", justifyContent: "center" }}>
                  <Column className="xs-6 sm-6 md-6 lg-6 xl-6 mt-5 ">
                    <img style={{ width: "120%" }} src={headerImage2} />
                  </Column>
                </Grid>
              </div>
            </div>
          </Column>
        </Grid>
      </div>
    </div>
  );
}

export default Header;
