import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import CardSection from "../../../components/Cards/CardSection";

import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";

import styled from "styled-components";
import { Column, Grid } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import { ButtonBase, FormControlLabel, Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import Validate from "../../../functions/Validate";
import FormDataGenerator from "../../../functions/FormDataGenerator";
import CompressImage from "../../../functions/CompressImage";
import FCLabel from "../../../theme/FCLabel";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import MenuIconButton from "../../../components/Scaffold/MenuIconButton";
import { ExitToApp } from "@material-ui/icons";
import { flushSync } from "react-dom";
import TranslateView from "../../../components/Scaffold/TranslateView";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import AuthWithToken from "../../../functions/AuthWithToken";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Loader from "../../../assets/images/loader.gif";
import LaunchIcon from "@material-ui/icons/Launch";
import DialogTitle from "@material-ui/core/DialogTitle";
import EmpoolesPackages from "../../../components/EmpoolesPackages";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";

export default class ProfileIndex extends React.Component {
  static contextType = AppContext;

  state = {
    packets: [],
    selected_day: 1,
    loaded: false,

    generated_hours: BetweenTimeGenerator({
      ...this.context.state.company_shift,
    }),

    categories: [],
    company_services: [],

    category_id: 0,
    category_open: [
      {
        id: 0,
        inAcvtive: false,
      },
    ],

    employee: {
      color: "",
      id: null,
      name: null,
      surname: null,
      sex: null,
      password: null,
      send_sms: null,
      detail: {
        profile_photo: null,
        job_title: null,
        bounty: null,
        phone: null,
        dialCode: null,
        mail: null,
      },
      permission: 0,
      services: [],
      service_ids: [],
      appointments: {
        1: { active: true, hours: [] },
        2: { active: true, hours: [] },
        3: { active: true, hours: [] },
        4: { active: true, hours: [] },
        5: { active: true, hours: [] },
        6: { active: true, hours: [] },
        7: { active: true, hours: [] },
      },
      closed_appointments: {
        1: { active: true, hours: [] },
        2: { active: true, hours: [] },
        3: { active: true, hours: [] },
        4: { active: true, hours: [] },
        5: { active: true, hours: [] },
        6: { active: true, hours: [] },
        7: { active: true, hours: [] },
      },
      limited_perm: {
        id: null,
        appointment: 2,
        sales: 2,
        customer: 2,
        customer_info: 2,
        services: 2,
        sms: 2,
        income_expense: 2,
        wallet: 2,
        gallery: 2,
        system: 2,
      },
    },

    select_language: false,
    lang: this.context.state.default_locale,
    branches_data: [],
    spinner: false,
    branch_dialog: false,
    selected_again_service_modal: false,
    selected_again_service_list: [],
    last_services_ids: [],
  };

  // update_packets
  updateState = (newStateValue) => {
    this.setState({ packets: newStateValue });
  };

  getSingleStaff = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/staff/${this.context.state.user.id}`
    )
      .then(({ data }) => {
        flushSync(() => {
          this.setState({
            loaded: true,
            packets: [...data?.data?.staff_packets.map((m) => m.packet_id)],
            company_services: [
              ...data.data.company_services.map((service, i) => {
                if (
                  service.category_name &&
                  service.category_name.toLowerCase().includes("erkek")
                ) {
                  service.name += " (E)";
                } else if (
                  service.category_name &&
                  service.category_name.toLowerCase().includes("kadın")
                ) {
                  service.name += " (K)";
                }
                return service;
              }),
            ],
            employee_services: [...data.data.services],
            employee: {
              ...data.data,
              color: data.data.color,
              service_ids: [...data.data.services].map(
                (s) => s.service && s.service.id
              ),
              closed_appointments:
                data.data.closed_appointments.un_appointments &&
                data.data.closed_appointments.un_appointments !== ""
                  ? JSON.parse(data.data.closed_appointments.un_appointments)
                  : {
                      1: { active: true, hours: [] },
                      2: { active: true, hours: [] },
                      3: { active: true, hours: [] },
                      4: { active: true, hours: [] },
                      5: { active: true, hours: [] },
                      6: { active: true, hours: [] },
                      7: { active: true, hours: [] },
                    },
              services: [...data.data.services]
                .map((s) => (s.service ? s.service.id ?? undefined : undefined))
                .filter((item) => item !== undefined),
              appointments:
                data.data.closed_appointments.un_appointments &&
                data.data.closed_appointments.un_appointments !== ""
                  ? JSON.parse(data.data.closed_appointments.un_appointments)
                  : {
                      1: { active: true, hours: [] },
                      2: { active: true, hours: [] },
                      3: { active: true, hours: [] },
                      4: { active: true, hours: [] },
                      5: { active: true, hours: [] },
                      6: { active: true, hours: [] },
                      7: { active: true, hours: [] },
                    },
            },
          });
        });
        if (data.data.limited_perm === null) {
          this.setState({
            employee: {
              ...this.state.employee,
              limited_perm: {
                id: null,
                appointment: 2,
                sales: 2,
                customer: 2,
                customer_info: 2,
                services: 2,
                sms: 2,
                income_expense: 2,
                wallet: 2,
                gallery: 2,
                system: 2,
              },
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 204) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  uploadPhotoSingleStaff = async (e) => {
    Axios.post(
      `${this.context.api_endpoint}/company/staff/update/${this.context.state.user.id}/photo`,
      FormDataGenerator({
        file: await CompressImage(e.target.files[0], {
          maxSizeMB: 2,
          fileType: "image/jpg",
        }),
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`profile.photoUploadToast`));
          this.getSingleStaff();
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  deletePhotoSingleStaff = () => {
    Axios.delete(
      `${this.context.api_endpoint}/company/staff/delete/${this.context.state.user.id}/photo`
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(this.context.t(`profile.photoDeleteToast`));
          this.getSingleStaff();
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  updateSingleStaff = () => {
    this.validateStaff() &&
      Axios.put(
        `${this.context.api_endpoint}/company/staff/update/${this.context.state.user.id}`,
        {
          ...this.state.employee,
          company_services: undefined,
          detail: {
            ...this.state.employee.detail,
            phone: this.state.employee.detail.phone.slice(
              this.state.employee.detail.dialCode.length
            ),
            dialCode: this.state.employee.detail.dialCode
              ? "+" + this.state.employee.detail.dialCode
              : "",
          },
          packet_ids: this.state.packets,
        }
      )
        .then((response) => {
          if (response.status === 201) {
            this.setState({
              selected_again_service_list: response.data.data.map((m) => m),
              employee: {
                ...this.state.employee,
                service_ids: [
                  ...this.state.employee.service_ids,
                  ...response.data.data.map((m) => m.id),
                ],
              },
              selected_again_service_modal: true,
            });
          } else if (response.status === 200) {
            toast.success(this.context.t(`profile.updateToast`));
          } else {
            toast.error("Something went wrong!");
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        });
  };

  deleteSingleStaff = () => {
    Axios.delete(
      `${this.context.api_endpoint}/company/staff/delete/${this.context.state.user.id}`
    ).then((response) => {
      if (response.status === 2000) {
        toast.success("Çalışan başarıyla silindi!");
        this.props.history.push("/employees");
      }
    });
  };

  handleChange = (event, label) => {
    const { employee } = this.state;
    // console.log(this.state.employee.services);
    const { limited_perm } = this.state.employee;
    this.setState({
      employee: {
        ...employee,
        limited_perm: {
          ...limited_perm,
          [label]: parseInt(event.target.value),
        },
      },
    });
  };

  handleCategoryClick = (category) => {
    this.setState({
      category_id: category,
      category_open: [
        ...this.state.category_open.map((c) => {
          return {
            ...c,
            id: category,
            open: true,
          };
        }),
      ],
    });
  };

  tryLogout = async () => {
    await this.context.dispatch({ type: "LOGOUT_USER" });
    await this.props.history.push("/");
    localStorage.removeItem("state2");
  };

  getServices = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/services/with/category/all`
    ).then(async ({ data }) => {
      this.setState({
        categories: [...data.data.filter((m) => m.services.length !== 0)],
      });
    });
  };

  changeBranch = (id) => {
    this.setState({
      spinner: true,
    });
    Axios.get(`${this.context.api_endpoint}/company/loginbranch/${id}`)
      .then((data) => {
        AuthWithToken(data.data.data.token, this.context);
        this.props.history.push("/");
        toast.success(this.context.t(`profile.detail.branch_change_alert`));
      })
      .catch(() => {
        toast.error("operation failed");
      })
      .finally(() => {
        this.setState({
          spinner: false,
        });
      });
  };

  getBranches = () => {
    Axios.get(`${this.context.api_endpoint}/company/branches`)
      .then((response) => {
        this.setState({
          branches_data: [...response.data.data],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getSingleStaff();
    this.getServices();
    window.addEventListener("click", (e) => {
      if (e.target.id !== "languageBox") {
        this.setState({
          select_language: false,
        });
      }
    });
    this.getBranches();
  }

  render() {
    const { loaded, selected_day, employee, generated_hours, select_language } =
      this.state;

    return !this.state.spinner ? (
      <AuthContainer>
        <Grid>
          <Column
            className="xs-12 mb-3"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {loaded ? (
              <ProfilePhoto
                src={
                  employee.detail.profile_photo
                    ? `${this.context.api_endpoint.replace("api/v1", "")}${
                        this.context.state.company_id
                      }/${employee.detail.profile_photo}`
                    : require("../../../assets/images/profile_photo.svg")
                }
              />
            ) : (
              <div
                style={{ width: 160, height: 160, borderRadius: 5 }}
                className="skeleton"
              />
            )}
            <input
              id="profile_photo"
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              onChange={(e) => this.uploadPhotoSingleStaff(e)}
            />

            <Button
              className="mt-1"
              icon="add_photo_alternate"
              title={this.context.t(`profile.photo.uploadButtonTitle`)}
              textColor="blue"
              backgroundColor="blue-opacity"
              fullWidth
              onClick={() => document.getElementById("profile_photo").click()}
            />
            {employee.detail.profile_photo && (
              <Button
                className="mt-1"
                icon="delete"
                title={this.context.t(`profile.photo.deleteButtonTitle`)}
                textColor="red"
                backgroundColor="red-opacity"
                fullWidth
                onClick={() => this.deletePhotoSingleStaff()}
              />
            )}

            <LanguageSelective
              id="languageBox"
              onClick={() => {
                this.setState({ select_language: !this.state.select_language });
              }}
            >
              <Flag
                src={require(`../../../assets/images/flags/${this.state.lang}.svg`)}
              />
              {this.state.lang === "tr" && "Türkçe"}
              {this.state.lang === "en" && "English"}
              {this.state.lang === "ru" && "Russia"}
              {this.state.lang === "ar" && "Arabic"}
            </LanguageSelective>
            <TranslateView
              state={select_language}
              closeHandler={() => {
                this.setState({
                  select_language: false,
                });
              }}
              stopPropagation={(e) => e.stopPropagation()}
            />

            {!this.context.state.is_dealer &&
              this.context.state.user.permission === 1 && (
                <Button
                  className="mt-1"
                  icon="launch"
                  title={this.context.t(`profile.detail.branch_change`)}
                  textColor="blue"
                  backgroundColor="blue-opacity"
                  fullWidth
                  onClick={() => this.setState({ branch_dialog: true })}
                />
              )}
            <Dialog
              onClose={() => this.setState({ branch_dialog: false })}
              open={this.state.branch_dialog}
            >
              <DialogTitle id="simple-dialog-title">
                {this.context.t(`profile.detail.branch_list`)}
              </DialogTitle>
              <List className="branch_list">
                {this.state.branches_data.map((m) => (
                  <ListItem
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#F5F5F5",
                      margin: "8px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={m.id}
                    value={m}
                    onClick={() => {}}
                  >
                    {m.name}
                    <button
                      onClick={() => {
                        this.changeBranch(m.staffs[0].id);
                        this.setState({
                          branch_dialog: false,
                        });
                      }}
                      className="enter_to_branch_btn"
                    >
                      <LaunchIcon fontSize="small" />
                      {this.context.t(`profile.detail.branch_enter`)}
                    </button>
                  </ListItem>
                ))}
              </List>
            </Dialog>
          </Column>

          <Column className="col-auto xs-12 sm-12 md-12 lg-12 xl-12">
            <CardSection
              title={
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  {this.context.t(`profile.detail.profileTitle`)}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.tryLogout()}
                  >
                    <MenuIconButton
                      icon={ExitToApp}
                      iconHeight={24}
                      style={{ padding: "0px" }}
                      className="fc-grey"
                      onClick={(e) => {}}
                    />
                    <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                      {this.context.t("profile.logoutButtonTitle")}
                    </span>
                  </div>
                </div>
              }
            >
              <Column className="xs-12 sm-12 md-4">
                <Input
                  required
                  label={this.context.t(`profile.detail.nameInputLabel`)}
                  value={employee.name !== null ? employee.name : ""}
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        name: e.target.value,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-4">
                <Input
                  required
                  label={this.context.t(`profile.detail.surnameInputLabel`)}
                  value={employee.surname !== null ? employee.surname : ""}
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        surname: e.target.value,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-4">
                <CSwitch
                  title={this.context.t(`profile.detail.genderInputLabel`)}
                  label={this.context.t(`profile.detail.genderMale`)}
                  labelLeft={this.context.t(`profile.detail.genderFemale`)}
                  checked={!!employee.sex}
                  color="primary"
                  closeColor="purple"
                  onChange={(checked) => {
                    this.setState({
                      employee: {
                        ...employee,
                        sex: checked.target.checked === false ? 0 : 1,
                      },
                    });
                  }}
                />
                {/* <Select
                required
                label={this.context.t(`profile.detail.genderInputLabel`)}
                items={[
                  {
                    value: "0",
                    name: this.context.t(`profile.detail.genderFemale`),
                  },
                  {
                    value: "1",
                    name: this.context.t(`profile.detail.genderMale`),
                  },
                ]}
                selected={employee.sex !== null ? `${employee.sex}` : ""}
                labelKey="name"
                valueKey="value"
                handler={(sex) =>
                  this.setState({
                    employee: {
                      ...employee,
                      sex: parseInt(sex),
                    },
                  })
                }
              /> */}
              </Column>

              <Column
                className={
                  (this.context.current_flag &
                    this.context.state.FLAGS.SALON_RANDEVU &&
                    "xs-12 sm-12 md-4") ||
                  "xs-12 sm-12 md-12"
                }
              >
                <Input
                  required
                  label={this.context.t(`profile.detail.jobTitleInputLabel`)}
                  value={
                    employee.detail.job_title !== null
                      ? employee.detail.job_title
                      : ""
                  }
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        detail: {
                          ...employee.detail,
                          job_title: e.target.value,
                        },
                      },
                    })
                  }
                />
              </Column>

              {(this.context.current_flag &
                this.context.state.FLAGS.SALON_RANDEVU && (
                <Column className="xs-12 sm-12 md-4">
                  <MaskedInput
                    label={this.context.t(`profile.detail.bountyInputLabel`)}
                    mask="99"
                    maskChar=""
                    value={
                      employee.detail.bounty !== null
                        ? employee.detail.bounty
                        : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        employee: {
                          ...employee,
                          detail: {
                            ...employee.detail,
                            bounty:
                              e.target.value.length > 0
                                ? parseInt(e.target.value)
                                : null,
                          },
                        },
                      })
                    }
                  />
                </Column>
              )) ||
                null}

              <Column className="xs-12 sm-12 md-4">
                <CSwitch
                  title={this.context.t(`profile.detail.sendSmsInputLabel`)}
                  label={`${this.context.t(
                    `profile.detail.sendSmsFalse`
                  )}/${this.context.t(`profile.detail.sendSmsTrue`)}`}
                  checked={!!employee.send_sms}
                  color="primary"
                  onChange={(checked) => {
                    this.setState({
                      employee: {
                        ...employee,
                        send_sms:
                          checked.target.checked === false ? false : true,
                      },
                    });
                  }}
                />
                {/* <Select
                required
                label={this.context.t(`profile.detail.sendSmsInputLabel`)}
                items={[
                  {
                    value: true,
                    label: this.context.t(`profile.detail.sendSmsTrue`),
                  },
                  {
                    value: false,
                    label: this.context.t(`profile.detail.sendSmsFalse`),
                  },
                ]}
                selected={
                  employee.send_sms !== null ? `${employee.send_sms}` : ""
                }
                labelKey="label"
                valueKey="value"
                handler={(send_sms) =>
                  this.setState({
                    employee: {
                      ...employee,
                      send_sms: send_sms === "true",
                    },
                  })
                }
              /> */}
              </Column>
            </CardSection>

            <CardSection
              title={this.context.t(`profile.contact.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12 md-4">
                <Input
                  required
                  label={this.context.t(`profile.contact.mailInputLabel`)}
                  value={
                    employee.detail.mail !== null ? employee.detail.mail : ""
                  }
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        detail: {
                          ...employee.detail,
                          mail: e.target.value,
                        },
                      },
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-4">
                {/* asd
								<MaskedInput
									required
									label={this.context.t(`profile.contact.phoneInputLabel`)}
									placeholder="500 000 0000"
									mask="999 999 9999"
									maskChar=""
									value={
										employee.detail.phone !== null ? employee.detail.phone : ""
									}
									startAdornment="0"
									onChange={(e) =>
										this.setState({
											employee: {
												...employee,
												detail: {
													...employee.detail,
													phone:
														e.target.value &&
														e.target.value.substring(0, 1) === "5"
															? e.target.value.replace(/\s/g, "")
															: null,
												},
											},
										})
									}
								/> */}
                <PhoneInput
                  countryCodeEditable={false}
                  value={
                    employee.detail.phone !== null ? employee.detail.phone : ""
                  }
                  onChange={(value, data, event, formattedValue) => {
                    this.setState({
                      employee: {
                        ...employee,
                        detail: {
                          ...employee.detail,
                          phone: value,
                          dialCode: data.dialCode,
                        },
                      },
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-4">
                <Input
                  label={this.context.t(
                    `profile.contact.newPasswordInputLabel`
                  )}
                  autoComplete={false}
                  type="password"
                  onChange={(e) =>
                    this.setState({
                      employee: {
                        ...employee,
                        password: e.target.value,
                      },
                    })
                  }
                />
              </Column>
            </CardSection>

            <CardSection
              title={this.context.t(`profile.services.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12 mb-3">
                <Alert severity="error">
                  <b>{this.context.t(`profile.services.alertBoldText`)}</b>
                  <br />
                  {this.context.t(`profile.services.alertText`)}
                </Alert>
              </Column>
              <Column className="xs-12 sm-12">
                <Grid>
                  {loaded === true &&
                    this.state.categories.map((category, index) => {
                      return (
                        <Column
                          key={index}
                          className="col xs-12 sm-12 md-12 lg-12 xl-12"
                        >
                          <div
                            onClick={() => {
                              this.handleCategoryClick(category.id);
                            }}
                            className={
                              this.state.category_id === category.id
                                ? "mb-2 category-container active-category"
                                : "mb-2 category-container"
                            }
                          >
                            <span>{category.name}</span>
                          </div>
                          {category.services.map((service) => {
                            return (
                              <>
                                {this.state.category_open.map(
                                  (category_open, index) => {
                                    if (
                                      category_open.id === category.id &&
                                      category_open.open === true
                                    ) {
                                      return (
                                        <Column className="col xs-12 sm-12 md-6 lg-6 xl-6">
                                          <Button
                                            key={index}
                                            className="mb-2"
                                            fullWidth={true}
                                            size="sm"
                                            icon={
                                              employee.services.includes(
                                                service.id
                                              )
                                                ? "check"
                                                : "close"
                                            }
                                            title={service.name}
                                            outlined={employee.services.includes(
                                              service.id
                                            )}
                                            textColor={
                                              employee.services.includes(
                                                service.id
                                              )
                                                ? "primary"
                                                : "black"
                                            }
                                            onClick={() =>
                                              this.changeServiceStatus(
                                                service.id
                                              )
                                            }
                                          />
                                        </Column>
                                      );
                                    }
                                  }
                                )}
                              </>
                            );
                          })}
                        </Column>
                      );
                    })}
                </Grid>
              </Column>
            </CardSection>
            <CardSection
              title={this.context.t(`profile.packages.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12 mb-3">
                <Alert severity="info">
                  <b>{this.context.t(`profile.packages.alertBoldText`)}</b>
                  <br />
                  {this.context.t(`profile.packages.alertText`)}
                </Alert>
              </Column>
              <EmpoolesPackages
                packets={this.state.packets}
                updateState={this.updateState}
              />
            </CardSection>

            <CardSection
              title={this.context.t(`profile.booking.title`)}
              className="mt-5"
            >
              <Column className="xs-12 sm-12">
                <DayTabContainer>
                  {this.context
                    .t(`profile.booking.week`)
                    ?.map((item, index) => (
                      <ButtonBase key={index}>
                        <StyledDayTab
                          key={index}
                          className="ripple"
                          day={index + 1}
                          selected_day={this.state.selected_day}
                          onClick={() =>
                            this.setState({ selected_day: index + 1 })
                          }
                          children={item}
                        />
                      </ButtonBase>
                    ))}
                </DayTabContainer>
                {loaded === true ? (
                  <FormControlLabel
                    className="mb-2"
                    control={
                      <Switch
                        color="primary"
                        checked={employee.appointments[selected_day].active}
                        onChange={() => this.changeDayStatus(selected_day)}
                      />
                    }
                    label={
                      <FCLabel>
                        {employee.appointments[selected_day].active === true
                          ? this.context.t(`profile.booking.dayCloseText`)
                          : this.context.t(`profile.booking.dayOpenText`)}
                      </FCLabel>
                    }
                  />
                ) : (
                  <div
                    style={{
                      width: 283,
                      maxWidth: "100%",
                      height: 33,
                      borderRadius: 5,
                    }}
                    className="skeleton mb-2 ml-1"
                  />
                )}
              </Column>
              <Column className="xs-12 sm-12">
                {parseInt(this.context.state.company_shift.slice) > 0 ? (
                  generated_hours.map((hour, index) => (
                    <div
                      key={index}
                      className="col xs-6 sm-2 md-2 lg-2 xl-2 mb-2 p-horizontal-1"
                    >
                      <Button
                        disabled={!employee.appointments[selected_day].active}
                        key={index}
                        fullWidth={true}
                        className={
                          employee.appointments[selected_day].active &&
                          !employee.appointments[selected_day].hours.includes(
                            hour
                          )
                            ? `elevation-2`
                            : ""
                        }
                        onClick={() => this.changeHourStatus(hour)}
                        icon={
                          employee.appointments[selected_day].active &&
                          !employee.appointments[selected_day].hours.includes(
                            hour
                          )
                            ? "check"
                            : "close"
                        }
                        title={hour}
                        //outlined={employee.appointments[selected_day].active && !employee.appointments[selected_day].hours.includes(hour)}
                        backgroundColor={"white"}
                        textColor={
                          employee.appointments[selected_day].active &&
                          !employee.appointments[selected_day].hours.includes(
                            hour
                          )
                            ? "green"
                            : "red"
                        }
                      />
                    </div>
                  ))
                ) : (
                  <Alert severity="info">
                    <b>{this.context.t(`profile.booking.alertBoldText`)}</b>
                  </Alert>
                )}
              </Column>
            </CardSection>

            <Button
              className="mt-3 mb-1"
              icon="update"
              title={this.context.t(`profile.updateButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => this.updateSingleStaff()}
            />
          </Column>
          <ModalDialog
            open={this.state.selected_again_service_modal}
            closeHandler={() =>
              this.setState({
                selected_again_service_modal: false,
              })
            }
            buttons={[
              {
                title: "Hepsini Ekle",
                icon: "check",

                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  this.setState({
                    employee: {
                      ...this.state.employee,
                      service_ids: [
                        ...this.state.employee.service_ids,
                        ...this.state.selected_again_service_list.map(
                          (m) => m.id
                        ),
                      ],
                    },
                  });
                  this.updateSingleStaff();
                },
              },
              {
                title: "Vazgeç",
                icon: "close",
                textColor: "grey",
                onClick: () =>
                  this.setState({
                    employee: {
                      ...this.state.employee,
                      service_ids: this.state.last_services_ids,
                    },
                  }),
              },
            ]}
          >
            <Alert severity="warning">
              Seçtiğiniz paket içerisindeki hizmetler çalışanın sunduğu
              hizmetler arasında yok. Hizmeti çalışanın sunduğu hizmetler
              arasına eklemeden devam edemezsiniz
            </Alert>
            <div style={{ width: "100%" }}>
              {this.state.selected_again_service_list.map((m) => (
                <div style={{ width: "100%" }}>-{m.name} </div>
              ))}
            </div>
          </ModalDialog>
        </Grid>
      </AuthContainer>
    ) : (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Loader} width="100" height="100" alt="loading" />
      </div>
    );
  }

  changeHourStatus = (hour) => {
    const { selected_day } = this.state;
    !this.state.employee.appointments[selected_day].hours.includes(hour)
      ? this.setState({
          employee: {
            ...this.state.employee,
            appointments: {
              ...this.state.employee.appointments,
              [selected_day]: {
                ...this.state.employee.appointments[selected_day],
                hours: [
                  ...this.state.employee.appointments[selected_day].hours,
                  hour,
                ],
              },
            },
          },
        })
      : this.setState({
          employee: {
            ...this.state.employee,
            appointments: {
              ...this.state.employee.appointments,
              [selected_day]: {
                ...this.state.employee.appointments[selected_day],
                hours: [
                  ...this.state.employee.appointments[
                    selected_day
                  ].hours.filter((item) => item !== hour),
                ],
              },
            },
          },
        });
  };

  changeDayStatus = () => {
    const { selected_day, employee } = this.state;
    this.setState({
      employee: {
        ...employee,
        appointments: {
          ...employee.appointments,
          [selected_day]: {
            ...employee.appointments[selected_day],
            active: !employee.appointments[selected_day].active,
          },
        },
      },
    });
  };

  changeServiceStatus = (service_id) => {
    const { employee } = this.state;

    employee.services.includes(service_id)
      ? this.setState({
          employee: {
            ...employee,
            services: employee.services.filter((s_id) => service_id !== s_id),
          },
        })
      : this.setState({
          employee: {
            ...employee,
            services: [...employee.services, service_id],
          },
        });
  };

  validateStaff = () => {
    const { employee } = this.state;

    return Validate([
      {
        field: "İsim",
        value: employee.name,
        condition: /[A-Za-z]+/,
      },
      {
        field: "Soyisim",
        value: employee.surname,
        condition: /[A-Za-z]+/,
      },
      {
        field: "Cinsiyet",
        value: employee.sex,
        condition: Number,
      },

      {
        field: "E-Posta Adresi",
        value: employee.detail.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
    ]);
  };
}

const StyledDayTab = styled.div`
  width: auto;
  flex: none;
  text-align: center;
  font-size: 14px;
  padding: 16px;
  font-weight: 500 !important;
  color: ${({ day, selected_day }) =>
    day === selected_day ? "var(--primary)" : "var(--black)"};
  border-bottom: 2px solid
    ${({ day, selected_day }) =>
      day === selected_day ? "var(--primary)" : "var(--white)"};
  box-sizing: border-box;
  cursor: pointer;
`;

const DayTabContainer = styled.div`
  width: -webkit-fill-available;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden !important;
  list-style: none;
  display: inline-flex;
  margin-bottom: 16px;
  box-sizing: content-box;

  button {
    font-family: "Inter", sans-serif !important;
  }
`;

const ProfilePhoto = styled.img`
  background: #fafafa;
  width: 160px;
  height: 160px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const LanguageSelective = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
`;

const Flag = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 8px;

  & + p {
    font-size: 12px !important;
    color: #303030;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
`;
