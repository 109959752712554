import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Button from "../../../theme/Button";
import Table from "../../../theme/Table";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Validate from "../../../functions/Validate";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Select from "../../../theme/CustomMUI/Select";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import Switch from "@material-ui/core/Switch";
import { useHistory, useParams } from "react-router-dom";

const SingleService = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const { id } = useParams();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [processTimeWarn, setProcessTimeWarn] = useState(false);

  // Service Details
  const [service, setService] = useState({
    id: null,
    process_time: null,
    amount: null,
    bounty: 0,
    repeat: null,
    point: 0,
    show_at_url: null,
    description: null,
    is_campaign: 0,
    campaign_title: null,
  });

  const [serviceCategories, setServiceCategories] = useState([]);
  const [serviceNames, setServiceNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const [staffs, setStaffs] = useState({});

  // Single Service Transactions
  const [lastTransactions, setLastTransactions] = useState({
    pagination: {
      total_page: 1,
      onChange: (page) => getSingleServiceTransactions(page),
    },
    data: [],
  });

  const [moneyOrPercent, setMoneyOrPercent] = useState(null);
  const [dontWantCategory, setDontWantCategory] = useState(false);

  const validateService = () => {
    return Validate([
      /* {
        field: "Hizmet Adı",
        value: service.name,
        condition: /.+/
      }, */
      {
        field: "Hizmet Kategorisi",
        value: selectedCategory,
        condition: Object,
      },
      {
        field: "Hizmet Adı",
        value: selectedServiceName,
        condition: Object,
      },
      {
        field: "Hizmet Ücreti",
        value: service.amount,
        condition: Number,
      },
      {
        field: "Prim Yüzdesi",
        value: service.bounty,
        condition: Number,
      },
      {
        field: "İşlem Süresi",
        value: service.process_time,
        condition: Number,
      },
    ]);
  };

  useEffect(() => {
    getStaffs();
    getSingleService();
    getSingleServiceTransactions();
  }, []); // Empty dependency array means it runs once on mount

  const getStaffs = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        if (response.data.data && response.data.data.length > 0) {
          setStaffs(
            Object.assign(
              ...response.data.data.map((staff) => ({
                [staff.id]: { ...staff, active: false },
              })),
              {}
            )
          );
        }
      }
    );
  };

  const getSingleService = () => {
    Axios.get(`${context.api_endpoint}/company/service/${id}`).then(
      async ({ data }) => {
        setDontWantCategory(
          data.data.platform_services.length === 0 ? true : false
        );
        /*  this.setState({
          dontWantCategory:
            data.data.platform_services.length === 0 ? true : false,
        }); */
        setService({
          ...data.data,
          bounty: data.data.bounty ?? 0,
        });
        setSelectedServiceName(data.data.platform_services[0]);
        setMoneyOrPercent(data.data.type === 0);

        if (data.data.platform_services[0]) {
          getCategory(data.data.platform_services[0].services_categories_id);
        }
      }
    );
  };

  const getSingleServiceTransactions = (page = 1) => {
    Axios.get(
      `${context.api_endpoint}/company/service/${id}/transactions?page=${page}`
    ).then(({ data }) => {
      setLoaded(true);
      setLastTransactions((prevState) => ({
        ...prevState,
        data: data.data.records.map((item) => ({
          ...item,
          created_at: moment(item.created_at).local().format("LLL"),
        })),
        pagination: {
          ...prevState.pagination,
          total_page: data.data.records.length,
          page: data.data.page,
        },
      }));
    });
  };

  const updateSingleService = () => {
    if (validateService()) {
      Axios.put(`${context.api_endpoint}/company/service/update`, {
        ...service,
        id: service.id,
        platform_service_id: selectedServiceName
          ? selectedServiceName.id
          : null,
        type: !moneyOrPercent ? 1 : null,
        [dontWantCategory ? "name" : "platform_service_id"]: dontWantCategory
          ? service.name
          : selectedServiceName
          ? selectedServiceName.id
          : null,
      })
        .then((response) => {
          history.push("/services");
          if (response.status === 200) {
            toast.success(context.t(`['services/detail'].updateToast`));
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            if (
              e.response.data.message ===
              "3 Adetten fazla kampanya ekleyemezsiniz."
            ) {
              toast.error(e.data.message);
            }
          }
        });
    }
  };

  const deleteSingleService = () => {
    Axios.delete(`${context.api_endpoint}/company/service/delete/${id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(context.t(`['services/detail'].deleteToast`));
          history.push("/services");
        }
      }
    );
  };

  const getServiceCategories = async (key) => {
    const { data } = await Axios.get(
      `${context.api_endpoint}/company/services/category/list/all`,
      {
        params: { name: key },
      }
    );
    setServiceCategories(data.data);
    return data.data;
  };

  const getServiceNames = async (key) => {
    if (selectedCategory) {
      const { data } = await Axios.get(
        `${context.api_endpoint}/company/platform/services/list/all`,
        {
          params: { categoryid: categoryId, name: key },
        }
      );
      setServiceNames(data.data);
      return data.data;
    }
  };

  const getCategory = async (id) => {
    const arr = await getServiceCategories("");
    const selected = arr.find((item) => item.id === id);
    setSelectedCategory(selected);
  };

  return (
    <AuthContainer authorities={[1]} limited_permission="services">
      <Grid className="no-gutters-all">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Switch
            checked={dontWantCategory}
            onChange={(e) => {
              setDontWantCategory(!dontWantCategory);
            }}
          />
          <div>Continue Without Category</div>
        </div>
        <Column className="xs-12 sm-12">
          {service.name !==
            (selectedServiceName ? selectedServiceName.name : "") && (
            <Grid>
              <Column className="xs-12 sm-12 md-12">
                <Input
                  required
                  disabled={true}
                  label={context.t(`['services/detail'].nameInputLabel`)}
                  value={service.name ?? ""}
                  onChange={(e) =>
                    setService((prevService) => ({
                      ...prevService,
                      name: e.target.value,
                    }))
                  }
                />
              </Column>
            </Grid>
          )}
          <Grid>
            <Column className="xs-12 sm-12 md-6">
              <AutocompleteInput
                disabled={dontWantCategory}
                required
                label={context.t(`['services/detail'].serviceCategoryInput`)}
                hoverHelperText={context.t(
                  `['services/detail'].serviceCategoryHint`
                )}
                labelKey="name"
                valueKey="id"
                returnFullObject
                onRemove={() => {
                  setSelectedCategory(null);
                  setSelectedServiceName(null);
                }}
                selected={selectedCategory}
                selectedHandler={(selected_category) => {
                  setSelectedCategory(selected_category);
                  setCategoryId(selected_category.id);
                }}
                asyncDataService={async (keyword) =>
                  getServiceCategories(keyword)
                }
              />
            </Column>
            {!dontWantCategory ? (
              <Column className="xs-12 sm-12 md-6">
                <AutocompleteInput
                  required
                  disabled={!selectedCategory}
                  label={context.t(`['services/detail'].lookingFor`)}
                  hoverHelperText={context.t(
                    `['services/detail'].serviceNameHint`
                  )}
                  labelKey="name"
                  valueKey="id"
                  returnFullObject
                  onRemove={() => setSelectedServiceName(null)}
                  selected={selectedServiceName}
                  selectedHandler={async (selected_category_name) => {
                    setSelectedServiceName(selected_category_name);
                  }}
                  asyncDataService={async (keyword) =>
                    selectedCategory ? getServiceNames(keyword) : []
                  }
                />
              </Column>
            ) : (
              <Column className="xs-12 sm-12 md-6">
                <Input
                  required
                  label={context.t(`['services/detail'].nameInputLabel`)}
                  value={service.name !== null ? service.name : ""}
                  onChange={(e) => setService({ name: e.target.value.trim() })}
                />
              </Column>
            )}
          </Grid>
          <Grid>
            <Column className="xs-12 sm-12 md-6">
              <Input
                required
                label={context.t(`['services/detail'].amountInputLabel`)}
                hoverHelperText={context.t(
                  `['services/detail'].amountInputHint`
                )}
                type="number"
                InputProps={{
                  min: 0,
                  step: 0.1,
                }}
                value={
                  service.amount !== null
                    ? Math.round(parseFloat(service.amount))
                    : ""
                }
                onChange={(e) =>
                  setService((prevService) => ({
                    ...prevService,
                    amount:
                      e.target.value.length > 0
                        ? Math.round(parseFloat(e.target.value))
                        : null,
                  }))
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <span style={{ fontSize: "12px", opacity: "0.8" }}>
                {context.state.currency
                  ? ` (${context.state.currency})`
                  : "(₺)"}{" "}
                Money Based
              </span>
              <Switch
                checked={moneyOrPercent}
                onChange={() => {
                  setMoneyOrPercent(!moneyOrPercent);
                  setService((prevService) => ({
                    ...prevService,
                    bounty: 0,
                  }));
                }}
                color="default"
                inputProps={{
                  "aria-label": "checkbox with default color",
                }}
              />
              <span style={{ fontSize: "12px", opacity: "0.8" }}>
                (%) Bonus Percentage
              </span>
              <MaskedInput
                label={
                  moneyOrPercent
                    ? context.t(`['services/detail'].bountyInputLabel`)
                    : `Bonus Money Based ${
                        context.state.currency
                          ? ` (${context.state.currency})`
                          : "(₺)"
                      }`
                }
                hoverHelperText={context.t(
                  `['services/detail'].bountyInputHint`
                )}
                mask={moneyOrPercent ? "99" : "99999999999"}
                maskChar=""
                value={service.bounty ?? 0}
                onChange={(e) =>
                  setService((prevService) => ({
                    ...prevService,
                    bounty:
                      e.target.value.length > 0 ? parseInt(e.target.value) : 0,
                  }))
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                label={context.t(`['services/detail'].pointInputLabel`)}
                hoverHelperText={context.t(
                  `['services/detail'].pointInputHint`
                )}
                mask="99999"
                maskChar=""
                value={service.point ?? ""}
                onChange={(e) =>
                  setService((prevService) => ({
                    ...prevService,
                    point:
                      e.target.value.length > 0 ? parseInt(e.target.value) : 0,
                  }))
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                required
                label={context.t(`['services/detail'].timeInputLabel`)}
                hoverHelperText={context.t(`['services/detail'].timeInputHint`)}
                mask="999"
                maskChar=""
                type="text"
                value={service.process_time ?? ""}
                onChange={(e) => {
                  setProcessTimeWarn(true);
                  setService((prevService) => ({
                    ...prevService,
                    process_time:
                      e.target.value.length > 0 ? parseInt(e.target.value) : 0,
                  }));
                }}
              />
              {processTimeWarn && (
                <Alert className="mb-3" severity="warning">
                  <b>{context.t(`['services/detail'].timeWarnAlertText`)}</b>
                </Alert>
              )}
            </Column>
          </Grid>
          <Grid>
            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                label={context.t(`['services/detail'].repeatInputLabel`)}
                hoverHelperText={context.t(
                  `['services/detail'].repeatInputHint`
                )}
                mask="999"
                maskChar=""
                type="text"
                value={service.repeat ?? ""}
                onChange={(e) =>
                  setService((prevService) => ({
                    ...prevService,
                    repeat:
                      e.target.value.length > 0 ? parseInt(e.target.value) : 0,
                  }))
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <Select
                label={context.t(`['services/detail'].urlInputLabel`)}
                hoverHelperText={context.t(`['services/detail'].urlInputHint`)}
                items={[
                  {
                    label: context.t(`['services/detail'].urlInputTrue`),
                    value: true,
                  },
                  {
                    label: context.t(`['services/detail'].urlInputFalse`),
                    value: false,
                  },
                ]}
                labelKey="label"
                valueKey="value"
                selected={`${service.show_at_url}`}
                handler={(show_at_url) =>
                  setService((prevService) => ({
                    ...prevService,
                    show_at_url: show_at_url === "true",
                  }))
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <Input
                label={context.t(`['services/detail'].serviceDescriptionLabel`)}
                value={service.description ?? ""}
                hoverHelperText={context.t(
                  `['services/detail'].serviceDescriptionHint`
                )}
                onChange={(e) =>
                  setService((prevService) => ({
                    ...prevService,
                    description: e.target.value,
                  }))
                }
              />
            </Column>
          </Grid>
          {context.state.company_license.accounting === 1 && (
            <Grid>
              <Column className="x2-12 sm-12 md-6">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={service.is_campaign === 1}
                      onChange={(e) =>
                        setService((prevService) => ({
                          ...prevService,
                          is_campaign: e.target.checked ? 1 : 0,
                        }))
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={context.t(`['services/detail'].setCampaignTitle`)}
                />
                {service.is_campaign === 1 && (
                  <CampaignContainer>
                    <Input
                      label={context.t(`['services/detail'].campaignNameTitle`)}
                      hoverHelperText={context.t(
                        `['services/detail'].campaignHint`
                      )}
                      value={service.campaign_title ?? ""}
                      onChange={(e) =>
                        setService((prevService) => ({
                          ...prevService,
                          campaign_title: e.target.value,
                        }))
                      }
                    />
                  </CampaignContainer>
                )}
              </Column>
            </Grid>
          )}
          <Grid>
            <Column className="xs-12 sm-12">
              <Button
                icon="check"
                title={context.t(`['services/detail'].updateButtonTitle`)}
                className="mb-1"
                textColor="white"
                backgroundColor="primary"
                fullWidth={true}
                onClick={updateSingleService}
              />
              <Button
                icon="delete_forever"
                title={context.t(`['services/detail'].deleteButtonTitle`)}
                textColor="red"
                backgroundColor="red-opacity"
                fullWidth={true}
                onClick={() => setDeleteConfirm(true)}
              />

              <AlertDialog
                title={context.t(`['services/detail'].delete.title`)}
                open={deleteConfirm}
                closeHandler={() => setDeleteConfirm(false)}
                buttons={[
                  {
                    title: context.t(
                      `['services/detail'].delete.confirmButtonTitle`
                    ),
                    icon: "check",
                    textColor: "primary",
                    backgroundColor: "primary-opacity",
                    timer: 5,
                    onClick: () => deleteSingleService(),
                  },
                  {
                    title: context.t(
                      `['services/detail'].delete.discardButtonTitle`
                    ),
                    icon: "close",
                    textColor: "grey",
                    onClick: () => setDeleteConfirm(false),
                  },
                ]}
              >
                <Alert severity="warning">
                  <b>{context.t(`['services/detail'].delete.alertBoldText`)}</b>
                  <br />
                  {context.t(`['services/detail'].delete.alertText`)}
                </Alert>
              </AlertDialog>
            </Column>
          </Grid>
          <Grid className="mt-5">
            <Column className="xs-12 sm-12">
              <Table
                refreshAction={() => getSingleServiceTransactions()}
                loaded={loaded}
                headings={{
                  created_at: {
                    label: context.t(
                      `['services/detail'].lastTransactions.headings.createdAt`
                    ),
                  },
                  "staff.full_name": {
                    label: context.t(
                      `['services/detail'].lastTransactions.headings.staffFullName`
                    ),
                  },
                  amount: {
                    label: context.t(
                      `['services/detail'].lastTransactions.headings.amount`
                    ),
                    prefix: context.state.currency
                      ? context.state.currency
                      : "₺",
                  },
                  _: { label: context.t(`component.actionHeadingText`) },
                }}
                rows={lastTransactions.data}
                pagination={lastTransactions.pagination}
                buttons={[
                  {
                    icon: "launch",
                    title: context.t(
                      `['services/detail'].lastTransactions.showOnReceiptButton`
                    ),
                    onClick: (row) =>
                      history.push(`/receipts/detail/${row.receipt_id}`),
                  },
                ]}
              />
            </Column>
          </Grid>
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default SingleService;

const CampaignContainer = styled.div`
  width: 100%;
`;
