import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	receipts: {},
	receipts_records: [],
};

const receiptSlice = createSlice({
	name: "receiptSlice",
	initialState,
	reducers: {
		showReceipt: (state, action) => {
			state.receipts = action.payload;
			state.receipts_records = action.payload.receipts.records;
		},
	},
});

export const { showReceipt } = receiptSlice.actions;
export default receiptSlice.reducer;
