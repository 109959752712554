import React, { useState } from "react";
import { Grid, Icon } from "@material-ui/core";
import { IoLinkOutline } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { SlCalculator } from "react-icons/sl";
import { TbCalendarCheck } from "react-icons/tb";
import { FaMobileAlt } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { RiMessage2Fill } from "react-icons/ri";
import { FaFileContract } from "react-icons/fa";
import { IoReceipt } from "react-icons/io5";
import { HiMiniIdentification } from "react-icons/hi2";
import { IoNotifications } from "react-icons/io5";
import { TbReportSearch } from "react-icons/tb";
import styled from "styled-components";

const featuresData = [
  {
    id: 1,
    title: "Salon Appointment Page",
    Icon: <IoLinkOutline color="black" size={60} fontSize="large" />,
    context:
      "Allow your customers to book appointments online with a Salon Appointment Booking page tailored to your salon. Eliminate the time constraints of your business with the online appointment system and prevent potential customer losses.",
  },
  {
    id: 2,
    title: "Customer Management",
    Icon: <BsPeopleFill size={60} color="black" fontSize="large" />,
    context:
      "Facilitate customer tracking by creating detailed customer profiles and reporting their appointment history. Increase customer loyalty by providing personalized service to each customer.",
  },
  {
    id: 3,
    title: "Accounting Management",
    Icon: <SlCalculator size={60} color="black" fontSize="large" />,
    context:
      "Keep track of your income and expense accounting records while keeping your financial situation under control at all times. Manage daily and monthly sales, receivables, staff bonuses, inventory, and product sales with reports.",
  },
  {
    id: 4,
    title: "Appointment Management",
    Icon: <TbCalendarCheck size={60} color="black" fontSize="large" />,
    context:
      "With this automation, prevent financial losses due to missed appointments and save time. A must-have feature for those who say every minute of my time is very valuable.",
  },
  {
    id: 5,
    title: "Mobile Application",
    Icon: <FaMobileAlt size={60} color="black" fontSize="large" />,
    context:
      "Manage your salon from your phone 24/7 using the Salon Appointment APP mobile application, whether on your computer or mobile.",
  },
  {
    id: 6,
    title: "Advanced Reporting",
    Icon: <BiSolidReport size={60} color="black" fontSize="large" />,
    context:
      "Advanced Reporting allows you to view your business's performance in daily, weekly, and monthly time frames and enables you to make necessary evaluations and comparisons.",
  },
  {
    id: 7,
    title: "WhatsApp Message",
    Icon: <RiMessage2Fill size={60} color="black" fontSize="large" />,
    context:
      "It is now possible to send appointment reminders, surveys, and notifications via WhatsApp instead of SMS.",
  },
  {
    id: 8,
    title: "E-Signed Contracts",
    Icon: <FaFileContract size={60} color="black" fontSize="large" />,
    context:
      "Get rid of paper contracts and have your drafted contracts saved with all the information for each of your customers and obtain digital signatures.",
  },
  {
    id: 9,
    title: "Check System",
    Icon: <IoReceipt size={60} color="black" fontSize="large" />,
    context:
      "The detailed check management is an advanced panel that allows users to display package sales, service or product sales, and track payments on a single page.",
  },
  {
    id: 10,
    title: "Staff Reports",
    Icon: <HiMiniIdentification size={60} color="black" fontSize="large" />,
    context:
      "Time is the primary resource you sell. To maximize income, you want employees to always provide the best service. It is very important to always focus 100% and provide service to be an excellent business.",
  },
  {
    id: 11,
    title: "SMS Notifications",
    Icon: <IoNotifications size={60} color="black" fontSize="large" />,
    context:
      "Salon Appointment APP has made reminders easy and fun. Send reminders quickly and easily to ensure your customers never miss an appointment and automate them.",
  },
  {
    id: 12,
    title: "Inventory Management",
    Icon: <TbReportSearch size={60} color="black" fontSize="large" />,
    context:
      "Track product sales and inventory status with Salon Appointment App. Do not compromise on errors. Keep your data up-to-date and accurate with inventory control. Keep track of your product sales with daily and monthly reports.",
  },
];

function Index() {
  const [feature, setFeature] = useState();
  const [selectFeature, setSelecetFeature] = useState(0);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgb(247, 192, 195)",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      <div className="container">
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: 1.4,
            color: "white",
            marginTop: "30px",
          }}
        >
          Application Features
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "15px",
            fontWeight: 100,
            lineHeight: 1.1,
            color: "white",
            marginBottom: "30px",
          }}
        >
          Most used features of Salon Management App
        </p>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div>
              <Grid container>
                <Grid
                  onClick={() => setSelecetFeature(0)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 0 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {<IoLinkOutline color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: " white",
                      }}
                    >
                      {" "}
                      Salon Appointment Page
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(1)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 1 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {<BsPeopleFill color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      Customer Management
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(2)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 2 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {<SlCalculator color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Accounting Management
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(3)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 3 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {
                      <TbCalendarCheck
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Appointment Management{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(4)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 4 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {<FaMobileAlt color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Mobile Application{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(5)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 5 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {<BiSolidReport color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Advanced Reporting{" "}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(6)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 6 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {
                      <RiMessage2Fill
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      WhatsApp Message{" "}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(7)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 7 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {
                      <FaFileContract
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      E-signed Contract{" "}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(8)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 8 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {<IoReceipt color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Billing System{" "}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(9)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 9 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {
                      <HiMiniIdentification
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      Staff Reports
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(10)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 10 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {
                      <IoNotifications
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      SMS Notifications
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(11)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 11 ? "#ff006a" : "#8c0fb4"
                    }
                  >
                    {
                      <TbReportSearch
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {" "}
                      Stock management
                    </p>
                  </FavoriteBox>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                height: "450px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 5px 8px #a5a5a5ad",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {featuresData[selectFeature ? selectFeature : 0].Icon}
                      </div>
                      <h2
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                          color: "black",
                        }}
                      >
                        {featuresData[selectFeature ? selectFeature : 0].title}
                      </h2>
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "rgba(3, 29, 68, 0.5)",
                        }}
                      >
                        {
                          featuresData[selectFeature ? selectFeature : 0]
                            .context
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Index;

const FavoriteBox = styled.div`
  width: 90%;
  height: 100px;
  text-align: center;
  display: flex;
  gap: 5px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background: ${(props) => props.backgroundColor};
  box-shadow: 0 3px 3px #a5a5a5ad;

  @media only screen and (max-width: 1190px) {
    margin: 5px;
  }
`;
