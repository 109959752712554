import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Validate from "../../../functions/Validate";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import styled from "styled-components";
import { DateRangeOutlined, ThreeDRotationSharp } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

let years = [];
for (let i = 1950; i < 2025; i++) {
  years.push({ name: i, value: i });
}

class AddCustomer extends React.Component {
  static contextType = AppContext;

  state = {
    //Customer Details
    customer: {
      name: "",
      surname: "",
      phone: null,
      dialCode: null,
      secondary_phone: null,
      s_dialCode: null,
      email: null,
      sex: 0,
      description: null,
      birthday: null,

      related_company: null,
      send_sms: true,

      days: [
        {
          value: 1,
          name: 1,
        },
        {
          value: 2,
          name: 2,
        },
        {
          value: 3,
          name: 3,
        },
        {
          value: 4,
          name: 4,
        },
        {
          value: 5,
          name: 5,
        },
        {
          value: 6,
          name: 6,
        },
        {
          value: 7,
          name: 7,
        },
        {
          value: 8,
          name: 8,
        },
        {
          value: 9,
          name: 9,
        },
        {
          value: 10,
          name: 10,
        },
        {
          value: 11,
          name: 11,
        },
        {
          value: 12,
          name: 12,
        },
        {
          value: 13,
          name: 13,
        },
        {
          value: 14,
          name: 14,
        },
        {
          value: 15,
          name: 15,
        },
        {
          value: 16,
          name: 16,
        },
        {
          value: 17,
          name: 17,
        },
        {
          value: 18,
          name: 18,
        },
        {
          value: 19,
          name: 19,
        },
        {
          value: 20,
          name: 20,
        },
        {
          value: 21,
          name: 21,
        },
        {
          value: 22,
          name: 22,
        },
        {
          value: 23,
          name: 23,
        },
        {
          value: 24,
          name: 24,
        },
        {
          value: 25,
          name: 25,
        },
        {
          value: 26,
          name: 26,
        },
        {
          value: 27,
          name: 27,
        },
        {
          value: 28,
          name: 28,
        },
        {
          value: 29,
          name: 29,
        },
        {
          value: 30,
          name: 30,
        },
        {
          value: 31,
          name: 31,
        },
      ],
      selected_day: null,

      months: [
        {
          id: 0,
          value: "01",
          name: "January",
        },
        {
          id: 1,
          value: "02",
          name: "February",
        },
        {
          id: 2,
          value: "03",
          name: "March",
        },
        {
          id: 3,
          value: "04",
          name: "April",
        },
        {
          id: 4,
          value: "05",
          name: "May",
        },
        {
          id: 5,
          value: "06",
          name: "June",
        },
        {
          id: 6,
          value: "07",
          name: "July",
        },
        {
          id: 7,
          value: "08",
          name: "August",
        },
        {
          id: 8,
          value: "09",
          name: "September",
        },
        {
          id: 9,
          value: "10",
          name: "October",
        },
        {
          id: 10,
          value: "11",
          name: "November",
        },
        {
          id: 11,
          value: "12",
          name: "December",
        },
      ],
      selected_month: null,
      selected_year: null,

      //Picker için
      real_selected_birthday: null,
    },

    selected_day: moment(new Date() - 568080000000).format("DD"),
    selected_month: moment(new Date() - 568080000000).format("MM"),
    selected_year: moment(new Date() - 568080000000).format("YYYY"),

    foreing_number: false,

    showBirthdayDate: false,

    isInsertCustomerButtonClicked: false, // Control that button is checked to stop sending too request.
    selectPhoneNumberList: [
      {
        id: 1,
        name: this.context.t(`['customers/add'].firstPhoneNumber`),
        type: 0,
      },
      {
        id: 2,
        name: this.context.t(`['customers/add'].secondPhoneNumber`),
        type: 1,
      },
      {
        id: 3,
        name: this.context.t(`['customers/add'].allPhoneNumber`),
        type: 2,
      },
    ],
    selectPhoneNumber: {
      id: 1,
      name: this.context.t(`['customers/add'].firstPhoneNumber`),
      type: 0,
    },
  };

  insertCustomer = async () => {
    const {
      customer,
      selected_day,
      selected_month,
      selected_year,
      selectPhoneNumber,
    } = this.state;
    let birthday;

    try {
      if (this.state.showBirthdayDate) {
        let birthday = new Date(
          `${moment(
            `${this.state.selected_year}-${this.state.selected_month}-${this.state.selected_day}`
          ).format("YYYY-MM-DD")}`
        ).toISOString();
        if (
          this.state.customer.birthday !== null &&
          this.state.customer.birthday.length === 9
        ) {
          const birthday_arr = this.state.customer.birthday.split("/");
          birthday = new Date(
            `${birthday_arr[2]}-${birthday_arr[1]}-${birthday_arr[0]}`
          );
          birthday.setHours(0, 0, 0, 0);
          birthday = birthday.toISOString();
        }

        this.validateCustomer() &&
          (await Axios.post(
            `${this.context.api_endpoint}/company/customer/insert`,
            {
              ...customer,
              phone: customer.phone?.slice(customer?.dialCode.length),
              dialCode: customer.dialCode ? "+" + customer.dialCode : "",
              secondary_phone: customer.secondary_phone?.slice(
                customer?.s_dialCode.length
              ),
              s_dialCode: customer.secondary_phone
                ? "+" + customer.s_dialCode
                : "",
              birthday,
              selectedPhone: selectPhoneNumber && selectPhoneNumber.type,
            }
          )
            .then((response) => {
              if (response.status === 200) {
                toast.success("Müşteri kaydı başarıyla oluşturuldu!");
                this.props.history.push("/customers");
              }
              this.setState({ isInsertCustomerButtonClicked: false });
            })
            .catch((e) => {
              if (e.response.status === 401) {
                toast.error(e.response.data.message);
              } else {
                toast.warning(e.response.data.message);
              }
              this.setState({ isInsertCustomerButtonClicked: false });
            }));
      } else {
        this.validateCustomer() &&
          Axios.post(`${this.context.api_endpoint}/company/customer/insert`, {
            ...customer,
            phone: customer.phone?.slice(customer?.dialCode.length),
            dialCode: customer.dialCode ? "+" + customer.dialCode : "",
            secondary_phone: customer.secondary_phone?.slice(
              customer?.s_dialCode.length
            ),
            s_dialCode: customer.secondary_phone
              ? "+" + customer.s_dialCode
              : "",
            birthday,
            selectedPhone: selectPhoneNumber && selectPhoneNumber.type,
          })
            .then((response) => {
              if (response.status === 200) {
                toast.success("Müşteri kaydı başarıyla oluşturuldu!");
                this.props.history.push("/customers");
              }
              this.setState({ isInsertCustomerButtonClicked: false });
            })
            .catch((e) => {
              if (e.response.status === 401) {
                toast.error(e.response.data.message);
              } else {
                toast.warning(e.response.data.message);
              }
              this.setState({ isInsertCustomerButtonClicked: false });
            });
      }
    } catch (error) {
      console.log(error);
      toast.warn(
        "Hatalı Tarih Girişi Yaptınız. Lütfen 'Doğum Tarihi'ni Yeniden Seçiniz."
      );
    }
  };

  phoneNumberHandler = (value, data, event, formattedValue) => {
    this.setState({
      customer: {
        ...this.state.customer,
        phone: value,
        dialCode: data.dialCode,
      },
    });
  };

  secondaryPhoneNumberHandler = (value, data, event, formattedValue) => {
    this.setState({
      customer: {
        ...this.state.customer,
        secondary_phone: value,
        s_dialCode: data.dialCode,
      },
    });
  };

  render() {
    const { customer, selected_day, selected_month, selected_year } =
      this.state;

    return (
      <AuthContainer authorities={[0, 1]} limited_permission="customer">
        <Grid className="mb-3">
          <Column className="xs-12 sm-12 md-12 lg-4">
            <Input
              required
              label={this.context.t(`['customers/add'].nameInputLabel`)}
              placeholder={this.context.t(
                `['customers/add'].nameInputPlaceholder`
              )}
              value={customer.name !== null ? customer.name : ""}
              onChange={(e) => {
                let nameText = e.target.value;
                this.setState({
                  customer: {
                    ...this.state.customer,
                    name:
                      nameText.split(" ")[1] !== undefined
                        ? nameText.split(" ")[0] + " " + nameText.split(" ")[1]
                        : nameText.split(" ")[0] !== undefined
                        ? nameText.split(" ")[0]
                        : nameText,
                  },
                });
              }}
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-4">
            <Input
              required
              label={this.context.t(`['customers/add'].surnameInputLabel`)}
              placeholder={this.context.t(
                `['customers/add'].surnameInputLabel`
              )}
              value={customer.surname !== null ? customer.surname : ""}
              onChange={(e) => {
                let surnameText = e.target.value;
                this.setState({
                  customer: {
                    ...this.state.customer,
                    surname:
                      surnameText.split(" ")[1] !== undefined
                        ? surnameText.split(" ")[0] +
                          " " +
                          surnameText.split(" ")[1]
                        : surnameText,
                  },
                });
              }}
            />
          </Column>
        </Grid>

        <Grid className="mb-3">
          <Column className="xs-12 sm-12 md-12 lg-4">
            <PhoneInput
              countryCodeEditable={false}
              value={customer.phone !== null ? customer.phone : ""}
              onChange={this.phoneNumberHandler}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-4">
            <PhoneInput
              specialLabel={this.context.t(
                `['customers/add'].secondPhoneInputLabel`
              )}
              countryCodeEditable={false}
              value={
                customer.secondary_phone !== null
                  ? customer.secondary_phone
                  : ""
              }
              onChange={this.secondaryPhoneNumberHandler}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-4">
            <Input
              label={this.context.t(`['customers/add'].mailInputLabel`)}
              placeholder={this.context.t(
                `['customers/add'].mailInputPlaceholder`
              )}
              value={customer.email !== null ? customer.email : ""}
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...this.state.customer,
                    email: e.target.value,
                  },
                })
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-4">
            <CSwitch
              title={this.context.t(`['customers/add'].sendSmsInputLabel`)}
              label={`${this.context.t(
                `['customers/add'].sendSmsFalse`
              )}/${this.context.t(`['customers/add'].sendSmsTrue`)}`}
              checked={!!customer.send_sms}
              color="primary"
              onChange={(send_sms) =>
                this.setState({
                  customer: {
                    ...customer,
                    send_sms: send_sms.target.checked,
                  },
                })
              }
            />
          </Column>
        </Grid>

        <Grid>
          {this.state.showBirthdayDate ? (
            <Column
              className="xs-12 sm-12 md-12 lg-4"
              style={{ display: "flex", position: "relative" }}
            >
              <div style={{ position: "absolute", top: "-2vh", left: "30px" }}>
                <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                  {this.context.t(`['customers/add'].validate.birthDate`)}
                </span>
              </div>
              <CostumSelect
                className="mt-1"
                style={{ cursor: "pointer", width: "60%" }}
                value={
                  selected_day
                    ? selected_day
                    : moment(new Date() - 568080000000).format("DD")
                }
                onChange={(e) => {
                  this.setState({ selected_day: e.target.value });
                }}
              >
                {this.state.customer.days.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </CostumSelect>

              <CostumSelect
                className="m-1"
                style={{ cursor: "pointer" }}
                value={
                  selected_month
                    ? selected_month
                    : moment(new Date() - 568080000000).format("MM")
                }
                onChange={(e) => {
                  this.setState({ selected_month: e.target.value });
                }}
              >
                {this.state.customer.months.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </CostumSelect>

              <CostumSelect
                className="mt-1"
                style={{ cursor: "pointer", width: "75%" }}
                value={
                  selected_year === ""
                    ? moment(new Date() - 568080000000).format("YYYY")
                    : selected_year
                }
                onChange={async (e) => {
                  console.log(e.target.value);
                  this.setState({ selected_year: e.target.value });
                }}
              >
                {years.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </CostumSelect>
            </Column>
          ) : (
            <Column className="xs-12 sm-12 md-12 lg-4">
              <div
                style={{
                  boxShadow: "0px 2px 12px rgb(0 0 0 / 4%)",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "59px",
                }}
                onClick={() => {
                  this.setState({
                    showBirthdayDate: true,
                  });
                }}
              >
                <DateRangeOutlined
                  color="disabled"
                  style={{ padding: "10px" }}
                />
                <span>
                  {this.context.t(`['customers/add'].validate.birthDate`)}
                </span>
              </div>
            </Column>
          )}

          <Column className="xs-12 sm-12 md-12 lg-4">
            <Input
              label={this.context.t(
                `['customers/add'].relatedBusinessInputLabel`
              )}
              placeholder={this.context.t(
                `['customers/add'].relatedBusinessInputPlaceholder`
              )}
              value={
                customer.related_company !== null
                  ? customer.related_company
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...customer,
                    related_company: e.target.value,
                  },
                })
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-4">
            <Select
              label={this.context.t(
                `['customers/add'].selectPhoneNumberForMessage`
              )}
              items={this.state.selectPhoneNumberList}
              labelKey="name"
              valueKey="id"
              selected={
                this.state.selectPhoneNumber
                  ? this.state.selectPhoneNumber.id
                  : this.state.selectPhoneNumberList[0].id
              }
              returnFullObject
              handler={async (filterPicker) => {
                console.log(filterPicker);
                this.setState({
                  selectPhoneNumber: filterPicker,
                });
              }}
            />
          </Column>

          <Column className="xs-12 sm-12">
            <Input
              label={this.context.t(`['customers/add'].descriptionInputLabel`)}
              value={customer.description !== null ? customer.description : ""}
              multiline={true}
              rows={3}
              onChange={(e) =>
                this.setState({
                  customer: {
                    ...this.state.customer,
                    description: e.target.value,
                  },
                })
              }
            />
          </Column>

          <Column className="xs-12 sm-12">
            <Button
              type="submit"
              title={this.context.t(
                `['customers/add'].createNewCustomerButtonTitle`
              )}
              icon="check"
              backgroundColor="primary"
              textColor="white"
              fullWidth
              disabled={this.state.isInsertCustomerButtonClicked}
              onClick={() => {
                this.setState({ isInsertCustomerButtonClicked: true });
                setTimeout(() => {
                  this.setState({ isInsertCustomerButtonClicked: false });
                }, 1000);
                this.insertCustomer();
              }}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }

  validateCustomer = () => {
    const { customer } = this.state;

    return Validate([
      {
        field: this.context.t(`['customers/add'].validate.name`),
        value: customer.name,
        condition: /.+[a-zA-Z ._-]/,
      },
      {
        field: this.context.t(`['customers/add'].validate.surname`),
        value: customer.surname,
        condition: /.+[a-zA-Z ._-]/,
      },
    ]);
  };
}

export default AddCustomer;

const CostumSelect = styled.select`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  height: 46px;
  margin-bottom: 0px;
  margin-top: -2px;
`;
