import React, { Component } from "react";
import styled from "styled-components";

import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import FormDataGenerator from "../../../functions/FormDataGenerator";
import { Column, Grid } from "../../../theme/Grid";
import "../../../assets/style/views/Gallery.scss";
import { toast } from "react-toastify";
import Axios from "axios";
import CompressImage from "../../../functions/CompressImage";
import { Checkbox } from "@material-ui/core";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Loader from "../../../assets/images/loader.gif";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
class GalleryIndex extends Component {
  static contextType = AppContext;

  state = {
    loaded: false, // Skeleton gösterim değeri
    photos: [], // İstekten gelen fotoğraflar

    accountings: {
      1: 10, // premium photo limitation
      2: 5, // standart limitation
      3: 3, // free limitation
    },
    crop_modal: false,
    src: null,
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      width: 70,
      height: 45,
    },

    delete_modal: false, // Silme işlemi onay kutusu
    selected_photos: [], // Silme işlemi için seçilen fotoğraflar

    open_view_dialog: false, // Info dialog
  };

  /**
   * Veri kaynağı üzerindne fotoğrafları getiren fonksiyon.
   */
  getPhotos = () => {
    Axios.get(`${this.context.api_endpoint}/company/gallery`)
      .then((response) => {
        const { data } = response;
        if (response.status === 201) {
          this.setState({
            loaded: true,
            photos: [...data.data],
            selected_photos: [],
            src: null,
            crop_modal: false,
            //  modal: this.state.loaded ? false : true,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  async uploadStorePhoto(e) {
    toast.success(this.context.t(`['platform/photo'].photoUploadToast`));

    Axios.post(
      `${this.context.api_endpoint}/company/insert/gallery`,
      FormDataGenerator({
        files: await CompressImage(e, {
          maxSizeMB: 2,
          fileType: "image/jpg",
        }),
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['platform/photo'].insertToast`));
          this.getPhotos();
          this.setState({
            crop_modal: false,
            src: null,
            crop: {
              unit: "%",
              x: 0,
              y: 0,
              width: 70,
              height: 45,
            },
            croppedImageUrl: undefined,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          this.setState({
            crop_modal: false,
            src: null,
            crop: {
              unit: "%",
              x: 0,
              y: 0,
              width: 70,
              height: 45,
            },
            croppedImageUrl: undefined,
          });
        } else {
          toast.error(
            this.context.t(`['platform/photo'].photoUploadErrorToast`)
          );
          this.setState({
            crop_modal: false,
            src: null,
            crop: {
              unit: "%",
              x: 0,
              y: 0,
              width: 70,
              height: 45,
            },
            croppedImageUrl: undefined,
          });
        }
      });
  }
  // Fotoğraf crop fonksiyonları
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      e.persist();

      const { accountings } = this.state;

      let currentAccounting = this.context.state.company_license.accounting;
      const appointment_limitation =
        this.context.state.company_license.appointment_limitation;

      if (currentAccounting === 2 && appointment_limitation > 0) {
        currentAccounting = 3;
      }

      if (
        accountings[currentAccounting] <
        [...e.target.files].length + this.state.photos.length
      ) {
        toast.error("Mevcut paket için fotoğraf limitini aştınız.");
      } else {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image.target;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }

        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/jpeg");
    });
  }
  /**
   * Inputta seçimi yapılan fotoğrafları yükleyen fonksiyon.
   */
  uploadMultiPhotos = async (e) => {
    e.persist();

    const { accountings } = this.state;

    let currentAccounting = this.context.state.company_license.accounting;
    const appointment_limitation =
      this.context.state.company_license.appointment_limitation;

    if (currentAccounting === 2 && appointment_limitation > 0) {
      currentAccounting = 3;
    }

    if (
      accountings[currentAccounting] <
      [...e.target.files].length + this.state.photos.length
    ) {
      toast.error("Mevcut paket için fotoğraf limitini aştınız.");
    } else {
      const length = [...e.target.files].length;
      const Form = new FormData();

      this.setState({ loaded: false });

      length > 0 &&
        toast.info(this.context.t(`['settings/gallery'].photoUploadToast`));

      for (let i = 0; i < length; i++) {
        Form.append(
          "files",
          await CompressImage(e.target.files[i], {
            maxSizeMB: 2,
            fileType: "image/jpg",
          })
        );
      }

      length > 0 &&
        Axios.post(
          `${this.context.api_endpoint}/company/insert/gallery`,
          Form,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
          .then((response) => {
            if (response.status === 201) {
              toast.success(this.context.t(`['settings/gallery'].insertToast`));
              this.getPhotos();
            }
          })
          .catch((e) => {
            this.setState({ loaded: true });
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            } else {
              toast.warning(e.response.data.message);
            }
          });
    }
  };

  /**
   * Multi seçimin sonucunda silme işlemini yapan fonksiyondur.
   */
  deleteMultiPhotos = () => {
    Axios.post(`${this.context.api_endpoint}/company/delete/gallery`, {
      photo_id: [...this.state.selected_photos],
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['settings/gallery'].deleteToast`));
          this.getPhotos();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getPhotos();
  }

  render() {
    const GalleryPhotos = this._GalleryBuilder;
    const LoaderContainer = this._LoaderBuilder;

    const { loaded } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="gallery">
        <Grid>
          <Column className="xs-12 sm-12 mb-12 mb-3">
            <Alert severity="warning" className="mt-2">
              <b>
                {this.context.t(`['settings/gallery'].enterprise_gallery_info`)}{" "}
                <Info onClick={() => this.setState({ open_view_dialog: true })}>
                  {this.context.t(`['settings/gallery'].anexample`)}{" "}
                </Info>
              </b>
            </Alert>
            {this.state.open_view_dialog && (
              <AlertDialog
                title={""}
                open={this.state.open_view_dialog}
                fullWidth={true}
                maxWidth="lg"
                textType
                disableBackdropClick={false}
                closeHandler={() => this.setState({ open_view_dialog: false })}
                buttons={[
                  {
                    //icon: "okay",
                    title: "Kapat",
                    textColor: "white",
                    backgroundColor: "primary",
                  },
                ]}
              >
                <InfoPhoto
                  src={require("../../../assets/images/enterprise_gallery.png")}
                />
              </AlertDialog>
            )}
          </Column>
          <Column className="xs-12 sm-12 mb-3">
            <input
              id="imageUploadInput"
              type="file"
              multiple
              accept="image/*"
              style={{ display: "none" }}
              onClick={() => {
                this.setState({ crop_modal: true });
              }}
              onChange={(e) => {
                if (e.target.files.length > 1) {
                  this.uploadMultiPhotos(e);
                } else if (e.target.files.length === 1) {
                  this.onSelectFile(e);
                }
              }}
            />
            {this.state.crop_modal && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "40%" }}>
                  {this.state.src && (
                    <ReactCrop
                      className="ReactCrop__image"
                      aspect={16 / 9}
                      crop={this.state.crop}
                      ruleOfThirds
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    >
                      <img src={this.state.src} onLoad={this.onImageLoaded} />
                    </ReactCrop>
                  )}
                  {this.state.croppedImageUrl && (
                    <cimg
                      alt="Crop"
                      style={{ padding: "25px", maxWidth: "100%" }}
                      src={this.state.croppedImageUrl}
                    />
                  )}
                  {this.state.croppedImageUrl && (
                    <Button
                      className="mt-1"
                      icon="add"
                      title={this.context.t(
                        `['settings/gallery'].dialog.upload`
                      )}
                      textColor="white"
                      backgroundColor="primary"
                      fullWidth
                      onClick={() =>
                        this.uploadStorePhoto(this.state.croppedImageUrl)
                      }
                    />
                  )}
                </div>
              </div>
            )}
            {!this.state.src && (
              <ImageUploadBox htmlFor="imageUploadInput">
                <i className="material-icons">cloud_upload</i>{" "}
                {this.context.t(`['settings/gallery'].uploadButtonTitle`)}
              </ImageUploadBox>
            )}
          </Column>

          <Column className="xs-12 sm-12 mb-2">
            <Button
              className="mr-2"
              icon="check"
              title={this.context.t(`['settings/gallery'].checkAllButtonTitle`)}
              textColor="primary"
              backgroundColor="primary-opacity"
              transitionEffect
              onClick={() =>
                this.setState({
                  selected_photos: [
                    ...this.state.photos.map((item) => item.id),
                  ],
                })
              }
            />
            <Button
              icon="delete"
              title={this.context.t(
                `['settings/gallery'].deleteSelectedButtonTitle`,
                {
                  count: this.state.selected_photos.length,
                }
              )}
              disabled={!this.state.selected_photos.length > 0}
              textColor="red"
              backgroundColor="red-opacity"
              transitionEffect
              onClick={() => this.setState({ delete_modal: true })}
            />

            {this.context.state.company_license.accounting && (
              <p style={{ display: "inline-block", marginLeft: "10px" }}>
                {this.context.t(`[settings/gallery].numberPhoto`)}:{" "}
                {this.state.photos ? this.state.photos.length : 0} /{" "}
                {
                  this.state.accountings[
                    this.context.state.company_license.accounting === 2 &&
                    this.context.state.company_license.appointment_limitation >
                      0
                      ? 3
                      : this.context.state.company_license.accounting
                  ]
                }
              </p>
            )}
          </Column>

          {loaded ? <GalleryPhotos /> : <LoaderContainer />}

          <AlertDialog
            title={this.context.t(`['settings/gallery'].delete.title`)}
            maxWidth="sm"
            fullWidth
            open={this.state.delete_modal}
            closeHandler={() => this.setState({ delete_modal: false })}
            buttons={[
              {
                title: this.context.t(
                  `['settings/gallery'].delete.confirmButtonTitle`
                ),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => this.deleteMultiPhotos(),
              },
              {
                title: this.context.t(
                  `['settings/gallery'].delete.discardButtonTitle`
                ),
                icon: "close",
                textColor: "grey",
              },
            ]}
            textType
          >
            <b>{this.context.t(`['settings/gallery'].delete.alertBoldText`)}</b>
            <br />
            {this.context.t(`['settings/gallery'].delete.alertText`)}
          </AlertDialog>
        </Grid>
      </AuthContainer>
    );
  }

  _GalleryBuilder = () => {
    return (
      <>
        {this.state.photos &&
          this.state.photos.map((item, index) => (
            <Column key={index} className="xs-12 sm-12 md-6 lg-4 xl-3 mb-4">
              <PhotoContainer
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    selected_photos: this.state.selected_photos.includes(
                      item.id
                    )
                      ? this.state.selected_photos.filter(
                          (id) => id !== item.id
                        )
                      : [...this.state.selected_photos, item.id],
                  });
                }}
              >
                <GalleryPhoto
                  src={`${this.context.api_endpoint.replace("api/v1", "")}${
                    this.context.state.company_id
                  }/${item.path}`}
                />
                <PhotoProcesses>
                  <Checkbox
                    color="primary"
                    checked={this.state.selected_photos.includes(item.id)}
                  />
                </PhotoProcesses>
              </PhotoContainer>
            </Column>
          ))}
      </>
    );
  };

  _LoaderBuilder = () => (
    <LoadingContainer>
      <img src={Loader} width="100" height="100" alt="loading" />
    </LoadingContainer>
  );
}

export default GalleryIndex;

const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhotoContainer = styled.div`
  position: relative;
  height: 240px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.04);
`;

const PhotoProcesses = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  background: rgba(255, 255, 255, 1);
  z-index: 200;

  span.MuiCheckbox-root {
    padding: 0 !important;
  }
`;

const GalleryPhoto = styled.img`
  width: 100%;
  height: 190px;
  object-fit: center;
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const Info = styled.span`
  color: #ed1e79;
  cursor: pointer;
  margin-top: 15px;
`;

const InfoPhoto = styled.img`
  width: 100%;
`;
