import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import styled, { keyframes, css } from "styled-components";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import { Cast, Update, WhatsApp } from "@material-ui/icons";
import QRCode from "react-qr-code";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import { BsQrCode } from "react-icons/bs";
import { TbDeviceMobileCheck, TbHistory } from "react-icons/tb";
import ReplayIcon from "@material-ui/icons/Replay";
import Loader from "../../../assets/images/loader.gif";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import wp_tutorial_1 from "../../../assets/images/wp_tutorial_image_1.png";
import wp_tutorial_2 from "../../../assets/images/wp_tutorial_image_2.png";
import wp_tutorial_3 from "../../../assets/images/wp_tutorial_image_3.png";
import wp_tutorial_en_1 from "../../../assets/images/wp-en-1.png";
import wp_tutorial_en_2 from "../../../assets/images/wp-en-2.png";
import wp_tutorial_en_3 from "../../../assets/images/wp-en-3.png";
import { HiMiniSignal } from "react-icons/hi2";
import { HiMiniSignalSlash } from "react-icons/hi2";
import { IoIosInformationCircleOutline } from "react-icons/io";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
class SmsSettings extends React.Component {
  static contextType = AppContext;

  state = {
    // randevudan önce gönderilecek mesajların kaç saat önce gideceği belirlenir.
    reminder: 0,

    // doğum günü hatırlatması
    reminder_birthday: true,

    // müşteriye/çalışana randevu hatırlatması gitsin/gitmesin
    reminder_customer: true,
    reminder_staff: true,

    // randevu tarihine 1den fazla gün var ise bir gün öncesinden mesaj gitsin/gitmesin
    reminder_day_ago_customer: true,
    reminder_day_ago_staff: true,

    // randevu oluşturulduğu esnada sms gitsin/gitmesin
    on_appointment_customer: true,
    on_appointment_staff: true,

    // dışarıdan yeni bir randevu talebi/isteği geldiğinde sms gitsin/gitmesin
    on_request_customer: true,
    on_request_staff: true,

    // randevu isteği onaylandığında sms gitsin/gitmesin
    approve_appointment_customer: true,
    approve_appointment_staff: true,

    // randevu isteği reddedildiğinde sms gitsin/gitmesin
    decline_appointment_customer: true,
    decline_appointment_staff: true,

    // aktif bir randevu iptal edildiğinde sms gitsin /gitmesin
    cancel_appointment_customer: true,
    cancel_appointment_staff: true,

    // müşteri kaydedildiğinde randevu linki gitsin/gitmesin
    on_customer_create: true,

    // randevu sonrası gitsin/gitmesin
    after_appointment: true,

    //Randevu Güncelleme Durumu
    update_appointment_customer: true,
    update_appointment_staff: true,

    //SMS'den Randevu Katılımı İçin Link Gönderimi
    is_link_included_in_reminder_customer: true,

    // Randevuya gelemeyen müşteriye 60. günde otomatik SMS hatırlatma
    reminder_noncomer_customer: true,

    // Randevu Sürükle Ve Bırak
    drag_and_drop_appointment_customer: true,
    // Sms Kısa Kod
    sms_short_code: true,

    // Online link seçeneği
    online_link: this.context.state.online_link,

    // QRcode datas
    QRcodeInfo: {
      code: null,
      registrationId: null,
    },
    qr_code_dialog: false,

    wp_balance: null,

    timer: null,

    open_select: false,

    wp_code: null,

    wp_code_dialog: false,

    wp_loader: false,

    wp_is_connected: null,

    wp_is_connected_message: null,

    login_check_register: false,

    wp_phone: null,

    wp_send_phone: "",

    wp_phone_dialCode: null,

    code_repeat: false,

    isHovered: false,

    scalgeImage: false,

    scaleImageNumber: null,
  };

  setTimer = () => {
    let timer = 30;
    let countdownNumberEl = document.getElementById("countdown-number");

    // Check if countdownNumberEl is not null before accessing its properties
    if (countdownNumberEl) {
      countdownNumberEl.textContent = timer;

      var timerInterval = setInterval(
        function () {
          if (timer > 0) {
            timer = timer - 1;
          } else {
            this.setState({
              show_QRcode: false,
              code_repeat: true,
            });
            clearInterval(timerInterval);
          }

          // Check if countdownNumberEl is still not null before updating its content
          if (countdownNumberEl) {
            countdownNumberEl.textContent = timer;
          }
        }.bind(this),
        1000
      );

      this.setState({
        timer: timerInterval,
      });
    } else {
      console.error("Element with ID 'countdown-number' not found.");
    }
  };

  // wp kredisinin alındığı endpoint
  getBalance = async () => {
    Axios.get(`${this.context.api_endpoint}/company/balance`)
      .then((data) => {
        this.setState({
          wp_balance: data.data.data.wp_credit
            ? data.data.data.wp_credit
            : null,
        });
      })
      .catch((err) => console.log(err));
  };

  getWpMessageSettings = async () => {
    try {
      const response = await Axios.get(
        `${this.context.api_endpoint}/company/get/wp/message/settings`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  checkWpLoginStatus = async (deviceKey) => {
    try {
      const response = await Axios.post(
        `${this.context.api_endpoint}/salon/wp/login/check/active/${deviceKey}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  handleWpDetails = (response) => {
    const { data } = response;

    if (response.status === 200) {
      if (data.device_key !== "") {
        this.setState((prevState) => ({
          QRcodeInfo: {
            ...prevState.QRcodeInfo,
            registrationId: data.device_key,
          },
        }));

        this.checkWpLoginStatus(data.device_key)
          .then((response) => {
            this.setState({
              wp_is_connected: response.is_connect,
              wp_is_connected_message: response.message,
              wp_phone: response.data.device_number,
            });

            if (response.status === 200) {
              this.setState({
                wp_is_connected: response.is_connect,
                wp_is_connected_message: response.message,
                wp_phone: response.data.device_number,
              });
            }
            // toast.success(this.context.t(`sms.foundWpDevice`));
          })
          .catch((err) => {
            setTimeout(() => {
              this.setState({
                wp_is_connected: false,
                wp_is_connected_message: "",
              });
            }, 250);
            // toast.warn("Cihaz aktif değil lütfen güncelleyiniz!");
          })
          .finally(() => this.setState({ open_select: false }));
      }
    }

    if (response.status === 200) {
      const { id, company_id, ...restData } = data;
      this.setState((prevState) => ({
        ...prevState,
        ...restData,
      }));
    }
  };

  getWpDetails = async () => {
    try {
      const response = await this.getWpMessageSettings();
      this.handleWpDetails(response);
    } catch (error) {
      console.error(error);
    }
  };

  saveSmsSettings = async () => {
    await Axios.put(
      `${this.context.api_endpoint}/company/update/wp/message/settings`,
      {
        ...this.state,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(this.context.t(`['sms/wp/settings'].updateToast`));
          this.getWpDetails();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(
            "Uzun süreli randevu hatırlatma 24 saat kala sms gönderimi yapıyor, hatırlatma saatiniz güncelleyiniz veya uzun süreli randevuyu kapatınız.",
            {
              autoClose: 3000,
            }
          );
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  loginRegis = async () => {
    this.state.QRcodeInfo.registrationId !== null &&
      (await Axios.post(
        `${this.context.api_endpoint}/salon/wp/login/check/${this.state.QRcodeInfo.registrationId}`,
        {
          phone: this.context.state.company_branch_first.staffs[0].detail.phone,
        }
      )
        .then(async (res) => {
          if (res.data.is_connect) {
          }
          this.setState({
            qr_code_dialog: false,
            wp_is_connected: true,
            wp_code_dialog: false,
          });

          toast.success("device connected");
        })
        .catch((err) => {
          console.log("error:", err);
        })
        .finally(() => {
          this.setState({ open_select: false });
          this.getWpDetails();
        }));
  };

  logoutWhatsApp = () => {
    this.setState({
      wp_loader: true,
    });
    Axios.post(
      `${this.context.api_endpoint}/salon/wp/logout/${this.state.QRcodeInfo.registrationId}`
    )
      .then(() => {
        this.getWpDetails();
        toast.success("WhatsApp cihazından çıkış yapılmıştır");
        setTimeout(() => {
          this.getBalance();
        }, 250);
      })
      .catch((err) => console.log(err))
      .finally(() =>
        this.setState({
          wp_is_connected: null,
          wp_is_connected_message: null,
          wp_phone: null,
          wp_loader: false,
        })
      );
  };

  loginWhatsappWithQr = async () => {
    this.setState({
      wp_loader: true,
      open_select: false,
    });
    await Axios.post(`${this.context.api_endpoint}/salon/wp/login/qr`, {
      phone:
        (this.context.state.dialCode
          ? this.context.state.dialCode.substring(1)
          : "90") +
        this.context.state.company_branch_first.staffs[0].detail.phone,
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            QRcodeInfo: {
              code: res.data.qr,
              registrationId: res.data.regId,
            },
            qr_code_dialog: true,
            show_QRcode: true,
          });
          setTimeout(() => {
            this.setTimer();
          }, 250);
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        this.setState({
          wp_loader: false,
        })
      );
  };
  loginWhatsappWithCode = async () => {
    this.setState({
      wp_loader: true,
      open_select: false,
    });
    await Axios.post(`${this.context.api_endpoint}/salon/wp/login/code`, {
      phone: this.state.wp_send_phone,
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            wp_code: res.data.code,
            wp_code_dialog: true,
            QRcodeInfo: {
              ...this.state.QRcodeInfo,
              registrationId: res.data.regId,
            },
          });
          setTimeout(() => {
            this.setTimer();
          }, 250);
        }
      })
      .catch((err) => console.log(err))
      .finally(() =>
        this.setState({
          wp_loader: false,
        })
      );
  };

  componentDidUpdate(prevProps, prevState) {
    // Eğer QRcodeInfo'nun registrationId'si değiştiyse ve önceki değer farklıysa
    if (
      this.state.QRcodeInfo.registrationId !==
      prevState.QRcodeInfo.registrationId
    ) {
      if (this.state.login_check_register) {
        this.loginRegis();
      }
    }
  }

  componentDidMount() {
    this.getWpDetails();
    this.getBalance();
  }

  render() {
    const HOCSettingBox = this._SettingsBoxBuilder;
    const HOCSettingBoxWithNoPermission =
      this._SettingsBoxBuilderWithNoPermission;

    return (
      <AuthContainer limited_permission="sms">
        <Grid className="mb-3 pb-2" style={{ borderBottom: "1px solid #ccc" }}>
          <Column className="xs-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer
                    className={
                      this.state.wp_is_connected
                        ? "fc-grey bg-green-opacity"
                        : "fc-grey bg-red-opacity"
                    }
                  >
                    <Cast
                      style={{
                        fontSize: "3em",
                        color: this.state.wp_is_connected ? "green" : "red",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>
                      {this.context.t(`sms.deviceStatus`)}
                    </StatsHeader>
                    <StatsValue>
                      {this.state.wp_is_connected ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                            color: "green",
                          }}
                        >
                          {" "}
                          {this.context.t(`sms.deviceConnected`)}{" "}
                          <HiMiniSignal color="green" />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                            color: "red",
                          }}
                        >
                          {" "}
                          {this.context.t(`sms.deviceNotConnected`)}{" "}
                          <HiMiniSignalSlash color="red" />
                        </div>
                      )}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>

          <Column className="xs-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{this.context.t(`sms.wpPhone`)}</StatsHeader>
                    <StatsValue>
                      {this.state.wp_phone
                        ? this.state.wp_phone
                        : this.context.t(`sms.phoneNotConnected`)}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column className="xs-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{this.context.t(`sms.wpCredits`)}</StatsHeader>
                    <StatsValue>{this.state.wp_balance}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          <Column style={{ position: "relative" }} className="xs-12 md-auto">
            <ButtonBox>
              {!this.state.wp_is_connected ? (
                <StyledButton
                  open={this.state.open_select}
                  size="large"
                  onClick={() => {
                    this.setState({
                      open_select: !this.state.open_select,
                    });
                  }}
                  backgroundColor="#3189ec"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <PhonelinkSetupIcon />
                    <div style={{ marginLeft: "5px" }}>
                      {this.context.t(`sms.addDevice`)}
                    </div>
                  </div>
                </StyledButton>
              ) : (
                <StyledButton
                  open={this.state.open_select}
                  size="large"
                  onClick={() => {
                    this.logoutWhatsApp();
                  }}
                  backgroundColor="#F32323"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <PhonelinkEraseIcon />
                    <div style={{ marginLeft: "5px" }}>
                      {this.context.t(`sms.removeDevice`)}
                    </div>
                  </div>
                </StyledButton>
              )}
            </ButtonBox>

            {this.state.open_select && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <AnimatedDiv>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </div>
                  <StyledButtons textColor="white">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                      onClick={() => {
                        this.setState({
                          wp_code_dialog: true,
                          open_select: false,
                        });
                      }}
                    >
                      <TbDeviceMobileCheck size={18} />
                      <div style={{ marginLeft: "5px" }}>
                        {this.context.t(`sms.loginWithNo`)}
                      </div>
                    </div>
                  </StyledButtons>
                  <StyledButtons
                    onClick={() => {
                      this.loginWhatsappWithQr();
                      this.setState({
                        login_check_register: true,
                      });
                    }}
                    textColor="white"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <BsQrCode />
                      <div style={{ marginLeft: "5px" }}>
                        {" "}
                        {this.context.t(`sms.loginWithQr`)}{" "}
                      </div>
                    </div>
                  </StyledButtons>
                </AnimatedDiv>
              </div>
            )}
          </Column>
        </Grid>

        <Grid>
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].reminder.title`),
              description: this.context.t(
                `['sms/wp/settings'].reminder.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "reminder_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "reminder_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['sms/wp/settings'].longTermReminder.title`
              ),
              description: this.context.t(
                `['sms/wp/settings'].longTermReminder.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "reminder_day_ago_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "reminder_day_ago_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].requestInfo.title`),
              description: this.context.t(
                `['sms/wp/settings'].requestInfo.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "on_request_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "on_request_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].whenApproved.title`),
              description: this.context.t(
                `['sms/wp/settings'].whenApproved.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "approve_appointment_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "approve_appointment_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].whenRejected.title`),
              description: this.context.t(
                `['sms/wp/settings'].whenRejected.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "decline_appointment_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "decline_appointment_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].whenCreated.title`),
              description: this.context.t(
                `['sms/wp/settings'].whenCreated.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "on_appointment_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "on_appointment_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].whenCancelled.title`),
              description: this.context.t(
                `['sms/wp/settings'].whenCancelled.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "cancel_appointment_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "cancel_appointment_staff",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].whenBirthday.title`),
              description: this.context.t(
                `['sms/wp/settings'].whenBirthday.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "reminder_birthday",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['sms/wp/settings'].afterAppointment.title`
              ),
              description: this.context.t(
                `['sms/wp/settings'].afterAppointment.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "after_appointment",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].dragAndDrop.title`),
              description: this.context.t(
                `['sms/wp/settings'].dragAndDrop.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "drag_and_drop_appointment_customer",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['sms/wp/settings'].appointmentUpdate.title`
              ),
              description: this.context.t(
                `['sms/wp/settings'].appointmentUpdate.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(
                  `['sms/wp/settings'].customerSwitchTitle`
                ),
                key: "update_appointment_customer",
              },
              {
                label: this.context.t(`['sms/wp/settings'].staffSwitchTitle`),
                key: "update_appointment_staff",
              },
            ]}
          />
          {(this.context.current_flag &
            this.context.state.FLAGS.SALON_RANDEVU && (
            <HOCSettingBox
              boxProps={{
                title: this.context.t(
                  `['sms/wp/settings'].reminderNoncomerCustomer.title`
                ),
                description: this.context.t(
                  `['sms/wp/settings'].reminderNoncomerCustomer.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                  key: "reminder_noncomer_customer",
                },
              ]}
            />
          )) ||
            null}
          {this.state.online_link ? (
            <HOCSettingBox
              boxProps={{
                title: this.context.t(
                  `['sms/wp/settings'].smsAttendedApproval.title`
                ),
                description: this.context.t(
                  `['sms/wp/settings'].smsAttendedApproval.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                  key: "is_link_included_in_reminder_customer",
                },
              ]}
            />
          ) : (
            <HOCSettingBoxWithNoPermission
              boxProps={{
                title: this.context.t(
                  `['sms/wp/settings'].smsAttendedApproval.title`
                ),
                description: this.context.t(
                  `['sms/wp/settings'].smsAttendedApproval.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                  key: "is_link_included_in_reminder_customer",
                },
              ]}
            />
          )}
          <HOCSettingBox
            boxProps={{
              title: this.context.t(
                `['sms/wp/settings'].whenCustomerCreate.title`
              ),
              description: this.context.t(
                `['sms/wp/settings'].whenCustomerCreate.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "on_customer_create",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].smsShortcode.title`),
              description: this.context.t(
                `['sms/wp/settings'].smsShortcode.description`
              ),
            }}
            optionList={[
              {
                label: this.context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "sms_short_code",
              },
            ]}
          />
          <HOCSettingBox
            boxProps={{
              title: this.context.t(`['sms/wp/settings'].reminderHour.title`),
              description: this.context.t(
                `['sms/wp/settings'].reminderHour.descripton`
              ),
            }}
          >
            <Select
              className="mb-0"
              label={this.context.t(
                `['sms/wp/settings'].reminderHour.inputLabel`
              )}
              items={[
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[0]`
                  ),
                  value: "0",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[1]`
                  ),
                  value: "1",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[2]`
                  ),
                  value: "2",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[3]`
                  ),
                  value: "3",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[4]`
                  ),
                  value: "4",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[5]`
                  ),
                  value: "5",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[6]`
                  ),
                  value: "6",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[7]`
                  ),
                  value: "7",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[8]`
                  ),
                  value: "8",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[9]`
                  ),
                  value: "9",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[10]`
                  ),
                  value: "10",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[11]`
                  ),
                  value: "11",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[12]`
                  ),
                  value: "12",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[13]`
                  ),
                  value: "13",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[14]`
                  ),
                  value: "14",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[15]`
                  ),
                  value: "15",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[16]`
                  ),
                  value: "16",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[17]`
                  ),
                  value: "17",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[18]`
                  ),
                  value: "18",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[19]`
                  ),
                  value: "19",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[20]`
                  ),
                  value: "20",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[21]`
                  ),
                  value: "21",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[22]`
                  ),
                  value: "22",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[23]`
                  ),
                  value: "23",
                },
                {
                  label: this.context.t(
                    `['sms/wp/settings'].reminderHour.options[24]`
                  ),
                  value: "24",
                },
              ]}
              selected={`${this.state.reminder}`}
              labelKey="label"
              valueKey="value"
              handler={(reminder) =>
                this.setState({ reminder: parseInt(reminder) })
              }
            />
          </HOCSettingBox>
          <Column className="xs-12 sm-12">
            <Button
              iconComponent={Update}
              title={this.context.t(`['sms/wp/settings'].updateButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              fullWidth
              onClick={() => this.saveSmsSettings()}
            />
          </Column>
        </Grid>
        <this.QRCodeDialog />
        <this.CodeDialog />
        <this.ImageDialog />
        <Dialog
          open={this.state.wp_loader}
          PaperProps={{
            style: {
              backgroundColor: "transparent", // Arka plan rengini şeffaf olarak ayarlar
              boxShadow: "none", // Gölgeyi kaldırır
            },
          }}
        >
          <AlignContainer>
            <img src={Loader} width="100" height="100" alt="loading" />
          </AlignContainer>
        </Dialog>
      </AuthContainer>
    );
  }

  _SettingsBoxBuilderWithNoPermission = ({
    children,
    boxProps,
    optionList,
  }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                border: "1px solid rgba(255,193,0)",
                padding: "10px",
                backgroundColor: "rgba(255,193,0, 0.1)",
                borderRadius: "5px",
                color: "rgb(255,154,0)",
              }}
            >
              {this.context.t(`['sms/wp/settings'].padiContent`)}
            </p>
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  _SettingsBoxBuilder = ({ children, boxProps, optionList }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {optionList?.map((item) => (
              <FormControlLabel
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={<FCLabel>{item.label}</FCLabel>}
                control={
                  <Switch
                    color="primary"
                    checked={this.state[item.key]}
                    onChange={(e) =>
                      this.setState({ [item.key]: !this.state[item.key] })
                    }
                  />
                }
              />
            ))}
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  _SettingsBoxBuilderWithNoPermission = ({
    children,
    boxProps,
    optionList,
  }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                border: "1px solid rgba(255,193,0)",
                padding: "10px",
                backgroundColor: "rgba(255,193,0, 0.1)",
                borderRadius: "5px",
                color: "rgb(255,154,0)",
              }}
            >
              {this.context.t(`['sms/wp/settings'].padiContent`)}
            </p>
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  QRCodeDialog = () => {
    return (
      <Dialog
        open={this.state.qr_code_dialog}
        // open = { true}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            maxWidth: "90vw", // Maksimum genişlik: ekran genişliğinin %90'ı
            maxHeight: "90vh", // Maksimum yükseklik: ekran yüksekliğinin %90'ı
            // width: "1000px", // Genişliği otomatik olarak ayarla
            height: "580px", // Yüksekliği otomatik olarak ayarla
            padding: "10px",
            backgroundColor: "#f7f7f7",
          },
        }}
        closeHandler={() => {
          this.setState({ qr_code_dialog: false });
          clearInterval(this.state.timer);
        }}
      >
        {" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
          onClick={() => this.setState({ qr_code_dialog: false })}
        >
          <Button textColor="dark" icon={"close"} />
        </div>
        <DialogContentText style={{ height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.show_QRcode ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 256,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={this.state.QRcodeInfo.code}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                }}
              >
                <Button
                  title="Tekrar Gönder"
                  icon="replay"
                  backgroundColor="primary"
                  textColor="white"
                  onClick={() => {
                    this.loginWhatsappWithQr();
                    this.setState({
                      login_check_register: true,
                    });
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h3>{this.context.t(`sms.qrPopupTitle`)}</h3>
                <p>{this.context.t(`sms.qrPopupContext`)}</p>

                {this.state.show_QRcode && (
                  <div style={{ marginTop: "40px" }}>
                    <div id="countdown">
                      <div id="countdown-number"></div>
                      <svg id="countdown-svg">
                        <circle
                          id="countdown-circle"
                          r="18"
                          cx="20"
                          cy="20"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "50%",
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#4682B4",
                    color: "white",
                  }}
                >
                  1
                </div>
                <HoverableImage
                  onClick={() => {
                    this.setState({
                      scalgeImage: true,
                      scaleImageNumber: 1,
                    });
                  }}
                  style={{ objectFit: "cover" }}
                  src={
                    this.context.state.default_locale === "tr"
                      ? wp_tutorial_1
                      : wp_tutorial_en_1
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <p style={{ height: "40px", fontSize: "12px", color: "black" }}>
                  {" "}
                  {this.context.t(`sms.wpQrTutorial1`)}{" "}
                </p>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#4682B4",
                    color: "white",
                  }}
                >
                  2
                </div>

                <HoverableImage
                  onClick={() => {
                    this.setState({
                      scalgeImage: true,
                      scaleImageNumber: 2,
                    });
                  }}
                  style={{ objectFit: "cover" }}
                  src={
                    this.context.state.default_locale === "tr"
                      ? wp_tutorial_2
                      : wp_tutorial_en_2
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <p style={{ height: "40px", fontSize: "12px", color: "black" }}>
                  {this.context.t(`sms.wpQrTutorial2`)}
                </p>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#4682B4",
                    color: "white",
                  }}
                >
                  3
                </div>
                <HoverableImage
                  onClick={() => {
                    this.setState({
                      scalgeImage: true,
                      scaleImageNumber: 3,
                    });
                  }}
                  style={{ objectFit: "cover" }}
                  src={
                    this.context.state.default_locale === "tr"
                      ? wp_tutorial_3
                      : wp_tutorial_en_3
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <p style={{ height: "40px", fontSize: "12px", color: "black" }}>
                  {this.context.t(`sms.wpQrTutorial3`)}
                </p>
              </div>
            </div>
          </div>
        </DialogContentText>
      </Dialog>
    );
  };

  phone_handleOnChange = (value, data, event, formattedValue) => {
    this.setState({
      wp_phone_dialCode: data.dialCode,
      wp_send_phone: value,
    });
  };
  CodeDialog = () => {
    return (
      <Dialog
        open={this.state.wp_code_dialog}
        onClose={() => {
          this.setState({
            wp_code_dialog: false,
            wp_code: null,
            login_check_register: false,
          });
          clearInterval(this.state.timer);
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            maxWidth: "90vw", // Maksimum genişlik: ekran genişliğinin %90'ı
            maxHeight: "90vh", // Maksimum yükseklik: ekran yüksekliğinin %90'ı
            width: "420px",
            height: "272px",
            padding: "10px",
            position: "relative",
          },
        }}
      >
        {" "}
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
          onClick={() => {
            this.setState({
              wp_code_dialog: false,
              wp_code: null,
              login_check_register: false,
              wp_send_phone: "",
            });
            clearInterval(this.state.timer);
          }}
        >
          <Button textColor="dark" icon={"close"} />
        </div>
        <DialogContent
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          {this.state.wp_code ? (
            <div style={{ marginTop: "8px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p style={{ fontSize: "12px" }}>
                  {this.state.wp_send_phone}{" "}
                  {this.context.t(`sms.codeForThisPhone`)}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <h1
                  style={{
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.wp_code ? this.state.wp_code : ""}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {this.state.code_repeat ? (
                  <button
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: "#D3E5E1",
                      marginTop: "25px",
                    }}
                    onClick={() => {
                      this.loginWhatsappWithCode();
                      this.setState({
                        login_check_register: true,
                        code_repeat: false,
                      });
                    }}
                  >
                    <ReplayIcon />
                  </button>
                ) : (
                  <div>
                    <div id="countdown">
                      <div id="countdown-number"></div>
                      <svg id="countdown-svg">
                        <circle
                          id="countdown-circle"
                          r="18"
                          cx="20"
                          cy="20"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
              <Button
                style={{ marginTop: "45px" }}
                fullWidth
                textColor="white"
                backgroundColor="primary"
                title={this.context.t(
                  `component.staffOrderDialog.closeButtonTitle`
                )}
                onClick={() => {
                  this.setState({
                    wp_code_dialog: false,
                    wp_code: null,
                    login_check_register: false,
                    wp_send_phone: "",
                  });
                  clearInterval(this.state.timer);
                }}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "revert",
                    fontSize: "14px",
                    marginTop: "7px",
                  }}
                >
                  {this.context.t(`sms.withPhoneNumberTitle`)}
                </p>{" "}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: "15px",
                  }}
                >
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <PhoneInput
                      country={"tr"}
                      countryCodeEditable={false}
                      inputStyle={{
                        width: "100%", // Set a default width
                      }}
                      value={this.state.wp_send_phone}
                      onChange={this.phone_handleOnChange}
                      specialLabel={this.context.t(
                        `['customers/add'].phoneInputLabel`
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "5px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F0FFFF",
                      gap: "8px",
                      wordWrap: "break-word",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <IoIosInformationCircleOutline size={22} />
                      <p
                        style={{
                          color: "#696969",
                          fontSize: "13px",
                          textAlign: "center",
                          margin: "0",
                        }}
                      >
                        WhatsApp giriş kodu girilen numaraya gönderilecektir.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  fullWidth
                  icon={"send"}
                  disabled={
                    this.state.wp_send_phone.slice(
                      this.state.wp_phone_dialCode &&
                        this.state.wp_phone_dialCode.length
                    ).length === 0
                  }
                  textColor="white"
                  backgroundColor="primary"
                  title={this.context.t(`component.sms_review.sendButtonTitle`)}
                  onClick={() => {
                    this.loginWhatsappWithCode();
                    this.setState({ login_check_register: true });
                  }}
                />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    );
  };
  ImageDialog = () => {
    const { scaleImageNumber, scalgeImage } = this.state;
    return (
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "38px",
            padding: "0px",
            margin: "0px",
            backgroundColor: "transparent",
            overflow: "hidden",
          },
        }}
        open={scalgeImage}
        onClose={() => this.setState({ scalgeImage: false })}
      >
        <img
          onClick={() => this.setState({ scalgeImage: false })}
          style={{ width: "300px", height: "580px", objectFit: "cover" }}
          src={
            this.context.state.default_locale === "tr"
              ? scaleImageNumber === 1
                ? wp_tutorial_1
                : scaleImageNumber === 2
                ? wp_tutorial_2
                : wp_tutorial_3
              : scaleImageNumber === 1
              ? wp_tutorial_en_1
              : scaleImageNumber === 2
              ? wp_tutorial_en_2
              : wp_tutorial_en_3
          }
          alt="image"
        />
      </Dialog>
    );
  };
}

export default SmsSettings;

const AccountBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;

  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;

const InfoBox = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: auto;
  padding: 0;
  color: #a0a0a0;
  display: flex;
  justify-content: center;
  align-item: center;
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  /* Responsive styles */
  ${(props) =>
    props.open &&
    css`
      @media (max-width: 768px) {
        padding: 12px;
      }
    `}
`;
const AnimatedDiv = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out forwards;
  position: absolute;
  height: 200%;
  width: 70%;
  top: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  backdrop-filter: blur(5px);

  @media (max-width: 1350px) {
    top: 100px;
  }
  @media (max-width: 1000px) {
    top: 120px;
  }
  @media (max-width: 768px) {
    top: 50px;
  }
`;
const StyledButtons = styled.button`
  background-color: ${(props) =>
    props.open ? "rgb(270,230,140)" : "rgb(270,230,140)"};
  width: 100%;
  color: black;
  padding: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  &:hover {
    opacity: 0.8;
  }

  ${(props) =>
    props.open &&
    css`
      @media (max-width: 768px) {
        padding: 12px;
      }
    `}
`;
const AlignContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const HoverableImage = styled.img`
  width: 70px; /* Örneğin */
  // height: 120px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.2); /* Büyütme oranı buradan ayarlanabilir */
  }
`;
