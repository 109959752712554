import React, { useEffect } from "react";
import { Column, Grid } from "../../../../theme/Grid";
import { BiSolidQuoteRight } from "react-icons/bi";
import { MdOutlineWeb } from "react-icons/md";
import { GoStar } from "react-icons/go";
import { MdSupportAgent } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import styles from "./userMostLike.module.css";
import vildanOkan from "../../../../assets/images/vildanOkan.png";
import oktayKopru from "../../../../assets/images/oktayKopru.png";
import nagihanYuksek from "../../../../assets/images/nagihanYuksek.png";
import tugbaBeauty from "../../../../assets/images/tugbaBeauty.png";
import meltemGoz from "../../../../assets/images/meltemGoz.png";

const Index = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f2f2f2",
        paddingBottom: "100px",
      }}
    >
      <div className="container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "90%",
            }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "30px",
                fontSize: "24px",
                fontWeight: "bold",
                color: "#393e46",
              }}
            >
              <span
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  color: "#ff006a",

                  fontFamily: "Mulish, sans-serif",
                }}
              >
                Salon Management
              </span>{" "}
              features that users like most
            </p>
            <Grid>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4 mt-4">
                <Grid>
                  <Column className="xs-2 sm-4 md-4 lg-12 xl-12">
                    <p style={{ width: "100%", textAlign: "center" }}>
                      <MdOutlineWeb color="#ff006a" size={36} />
                    </p>
                  </Column>
                  <Column className="xs-10 sm-8 md-8 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#ff006a",
                        fontWeight: "bolder",

                        fontSize: "15px",
                      }}
                    >
                      Greatly designed, easy-to-use web and mobile application
                    </p>
                  </Column>
                </Grid>
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4 mt-4">
                <Grid>
                  <Column className="xs-2 sm-4 md-4 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#ff006a",
                        fontWeight: "bolder",
                        fontSize: "28px",
                      }}
                    >
                      <GoStar color="#ff006a" size={36} />
                    </p>
                  </Column>
                  <Column className="xs-10 sm-8 md-8 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#ff006a",
                        fontWeight: "bolder",

                        fontSize: "15px",
                      }}
                    >
                      Easily manage your business with rich features
                    </p>
                  </Column>
                </Grid>
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4 mt-4">
                <Grid>
                  <Column className="xs-2 sm-4 md-4 lg-12 xl-12">
                    <p style={{ width: "100%", textAlign: "center" }}>
                      <MdSupportAgent color="#ff006a" size={36} />
                    </p>
                  </Column>
                  <Column className="xs-10 sm-8 md-8 lg-12 xl-12">
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#ff006a",
                        fontWeight: "bolder",

                        fontSize: "15px",
                      }}
                    >
                      24/7 instant live support whenever you need it
                    </p>
                  </Column>
                </Grid>
              </Column>
            </Grid>
          </div>
        </div>
        <div className={styles.content}>
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6 mt-4">
              <Grid style={{ height: "450px" }}>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-4">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        color: "#788291",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "-20px",
                        }}
                      >
                        <BiSolidQuoteRight size={40} />
                      </div>
                      <p>
                        "On weekends, in the evenings, or when I'm out of my
                        headquarters Making an appointment is always a notebook
                        and pen in my bag. was to carry. Notebook and pen have
                        completely disappeared from my life 24/7. I can make an
                        appointment instantly from my phone. In my opinion,
                        perfect comfort. Also for my customers An incredible
                        image from the moment we switched to the system. My
                        change has occurred and I am told that this program is
                        very They said it was unprofessional..."
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "40px",
                          }}
                          src={tugbaBeauty}
                        />
                        <div>
                          {" "}
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              marginTop: "40px",
                              color: "#0b3052",
                            }}
                          >
                            Tuğba Karaman Beauty Stüdio
                          </p>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              marginTop: "5px",
                              color: "#0b3052",
                            }}
                          >
                            Tuğba K.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
              </Grid>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6 mt-4">
              <Grid style={{ height: "450px" }}>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-4">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      height: "100%",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        color: "#788291",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "-20px",
                        }}
                      >
                        <BiSolidQuoteRight size={40} />
                      </div>
                      <p style={{ marginTop: "15px" }}>
                        "Since our salon appointment membership started, the
                        entire team They were very caring and helpful in
                        everything. They were especially grateful to Ms. Elif
                        for her efforts and dedication. Also thank you very much
                        for your work. I do. The program has lightened our
                        workload greatly. I highly recommend it."
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            //marginTop: "20px",
                          }}
                          src={vildanOkan}
                        />
                        <div>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              color: "#0b3052",
                            }}
                          >
                            High Care Güzellik Merkezi
                          </p>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              // marginTop: "5px",
                              color: "#0b3052",
                            }}
                          >
                            Vildan Okan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-4">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      height: "100%",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        color: "#788291",
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "8px",
                          top: "-20px",
                        }}
                      >
                        <BiSolidQuoteRight size={40} />
                      </div>
                      <p style={{ marginTop: "15px" }}>
                        "An excellent program implementation is very successful:
                        Appointment for, for customer registration, accounting
                        and extensive features It works in integrity with the
                        company and says it is indispensable. "I definitely
                        recommend it"
                      </p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            width: "43px",
                            height: "40px",
                            // marginTop: "20px",
                          }}
                          src={oktayKopru}
                        />
                        <div>
                          {" "}
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              // marginTop: "40px",
                              color: "#0b3052",
                            }}
                          >
                            Erciyes Binicilik
                          </p>
                          <p
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              color: "black",
                              fontWeight: 600,
                              // marginTop: "5px",
                              color: "#0b3052",
                            }}
                          >
                            Oktay Köprü
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Column>
              </Grid>
            </Column>
          </Grid>
        </div>
        <div className={styles.smallScreenOnly}>
          {" "}
          <Grid style={{ marginTop: "50px" }}>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
              <Carousel
                additionalTransfrom={0}
                autoPlaySpeed={3000}
                // centerMode={true}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                // focusOnSelect={true}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                //   renderArrowsWhenDisabled={true}
                arrows={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      color: "#788291",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "5px",
                      }}
                    >
                      <BiSolidQuoteRight size={40} />
                    </div>
                    <p>
                      "On weekends, in the evenings, or when I'm out of my
                      headquarters Making an appointment is always a notebook
                      and pen in my bag. was to carry. Notebook and pen have
                      completely disappeared from my life 24/7. I can make an
                      appointment instantly from my phone. In my opinion,
                      perfect comfort. Also for my customers An incredible image
                      from the moment we switched to the system. My change took
                      place and I was told that this program was very
                      professional. They said it was..."
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",

                        marginTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={tugbaBeauty}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "40px",
                            color: "#0b3052",
                          }}
                        >
                          Tuğba Karaman Beauty Stüdio
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "5px",
                            color: "#0b3052",
                          }}
                        >
                          Tuğba K.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "90%",

                      color: "#788291",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "5px",
                      }}
                    >
                      <BiSolidQuoteRight size={40} />
                    </div>
                    <p
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      "Since our salon appointment membership started, the
                      entire team They were very caring and helpful in
                      everything. They were especially grateful to Ms. Elif for
                      her efforts and dedication. Also thank you very much for
                      your work. I do. The program has lightened our workload
                      greatly. I highly recommend it."
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",

                        marginTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{ width: "40px", height: "40px" }}
                          src={vildanOkan}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "40px",
                            color: "#0b3052",
                          }}
                        >
                          High Care Güzellik Merkezi
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "5px",
                            color: "#0b3052",
                          }}
                        >
                          Vildan Okan
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      color: "#788291",
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "5px",
                      }}
                    >
                      <BiSolidQuoteRight size={40} />
                    </div>
                    <p
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      "An excellent program implementation is very successful:
                      For appointments, For customer registration, accounting
                      and with extensive features It works as a whole and is
                      definitely a must. I would recommend"
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",

                        marginTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <img
                          style={{ width: "45px", height: "40px" }}
                          src={oktayKopru}
                        />
                      </div>
                      <div>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "40px",
                            color: "#0b3052",
                          }}
                        >
                          Erciyes Binicilik
                        </p>
                        <p
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            color: "black",
                            fontWeight: 600,
                            marginTop: "5px",
                            color: "#0b3052",
                          }}
                        >
                          Oktat Köprü
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </Column>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Index;

const ServicesGalleryContainer = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
