import {
  Home,
  AccountBalanceWallet,
  InsertInvitation,
  Receipt,
  LocalMall,
  Store,
  PeopleAlt,
  Layers,
  HowToReg,
  Sms,
  InsertChart,
  Settings,
  AccountBalance,
  ListAlt,
  NoteAdd,
  Storefront,
  PhotoLibrary,
  CalendarTodayOutlined,
  CalendarToday,
} from "@material-ui/icons";

const favorite_pages = [
  {
    name: "clientManagement",
    index: null,
  },
  {
    name: "clientList",
    index: 1,
    icon: PeopleAlt,
    path: "/customers",
    auth: [1, 2, 3],
  },
  {
    name: "lostClient",
    index: 2,
    icon: PeopleAlt,
    path: "/customers/passive",
    auth: [1],
  },
  {
    name: "clientComments",
    index: 3,
    icon: PeopleAlt,
    path: "/customers/rating",
    auth: [1, 2, 3],
  },
  {
    name: "createNewClient",
    index: 4,
    icon: PeopleAlt,
    path: "/customers/add",
    auth: [1, 2, 3],
  },
  {
    name: "addClientFromBulk",
    index: 5,
    icon: PeopleAlt,
    path: "/customers/add/bulk",
    auth: [1, 2, 3],
  },
  { name: "serviceManagement", index: null, auth: [1, 2, 3] },
  {
    name: "serviceList",
    index: 6,
    icon: Layers,
    path: "/services",
    auth: [1, 2, 3],
  },
  {
    name: "createNewService",
    index: 7,
    icon: Layers,
    path: "/services/add",
    auth: [1, 2, 3],
  },
  {
    name: "createNewServiceRequest",
    index: null,
    icon: Layers,
    auth: [1, 2, 3],
  },
  {
    name: "serviceRequest",
    index: 42,
    icon: Layers,
    path: "/services/requests",
    auth: [1, 2, 3],
  },
  {
    name: "createServicePrime",
    index: 43,
    icon: Layers,
    path: "/services/bounty",
    auth: [1],
  },
  { name: "packageManagement", index: null },
  {
    name: "packageList",
    index: 8,
    icon: LocalMall,
    path: "/packages/list",
    auth: [1],
  },
  {
    name: "packageSaleList",
    index: 9,
    icon: LocalMall,
    path: "/packages/sales",
    auth: [1],
  },
  {
    name: "packageWithoutAppointment",
    index: 33,
    icon: LocalMall,
    path: "/packages/unbooked",
    auth: [1],
  },
  {
    name: "createNewPackage",
    index: 10,
    icon: LocalMall,
    path: "/packages/add",
    auth: [1],
  },
  {
    name: "createNewPackageSale",
    index: 11,
    icon: LocalMall,
    path: "/packages/sales/add",
    auth: [1],
  },
  { name: "stockManagement", index: null, auth: [1] },
  {
    name: "stockList",
    index: 12,
    icon: Store,
    path: "/products",
    auth: [1],
  },
  {
    name: "createNewStock",
    index: 13,
    icon: Store,
    path: "/products/add",
    auth: [1],
  },
  { name: "accountingManagement", index: null, auth: [1] },
  {
    name: "accountingIncome",
    index: 14,
    icon: AccountBalance,
    path: "/accounting/income",
    auth: [1],
  },
  {
    name: "accountingExpense",
    index: 15,
    icon: AccountBalance,
    path: "/accounting/expense",
    auth: [1],
  },
  {
    name: "expensesTypes",
    index: 16,
    icon: AccountBalance,
    path: "/accounting/expense/types",
    auth: [1],
  },
  {
    name: "incomes",
    index: 29,
    icon: AccountBalance,
    path: "/accounting/credit",
    auth: [1],
  },
  {
    name: "expenses",
    index: 72,
    icon: AccountBalance,
    path: "/accounting/debit",
    auth: [1],
  },
  {
    name: "expenseProcess",
    index: 17,
    icon: AccountBalance,
    path: "/accounting/debt",
    auth: [1],
  },
  { name: "staffManagement", index: null, auth: [1, 2, 3] },
  {
    name: "staffList",
    index: 18,
    icon: HowToReg,
    path: "/settings/employees",
    auth: [1, 2, 3],
  },
  { name: "primProcess", index: null, auth: [1, 2, 3] },
  {
    name: "createNewPersonel",
    index: 20,
    icon: HowToReg,
    path: "/settings/employees/add",
    auth: [1, 2, 3],
  },
  { name: "advancedReports", index: null, auth: [1] },
  {
    name: "companyReports",
    index: 21,
    icon: InsertChart,
    path: "/reports/company",
    auth: [1],
  },
  {
    name: "staffReports",
    index: 22,
    icon: InsertChart,
    path: "/reports/staff",
    auth: [1],
  },
  {
    name: "clientReports",
    index: 23,
    icon: InsertChart,
    path: "/reports/customer",
    auth: [1],
  },
  {
    name: "servicesReports",
    index: 24,
    icon: InsertChart,
    path: "/reports/service",
    auth: [1],
  },
  {
    name: "productReports",
    index: 25,
    icon: InsertChart,
    path: "/reports/stock",
    auth: [1],
  },
  { name: "messageManagement", index: null, auth: [1, 2, 3] },
  {
    name: "smsReports",
    index: 28,
    icon: Sms,
    path: "/sms/reports",
    auth: [1, 2, 3],
  },
  {
    name: "selectedSMSSend",
    index: 26,
    icon: Sms,
    path: "/sms/send/selective",
    auth: [1, 2, 3],
  },
  {
    name: "filterSMSSend",
    index: 27,
    icon: Sms,
    path: "/sms/send/filter",
    auth: [1, 2, 3],
  },
  { name: "messageReports", index: null, auth: [1, 2, 3] },
  {
    name: "smsSettings",
    index: 32,
    icon: Sms,
    path: "/sms/settings",
    auth: [1, 2, 3],
  },
  { name: "systemSetting", index: null, auth: [1, 2, 3] },
  { name: "profileSettings", index: null, auth: [1, 2, 3] },
  {
    name: "compantSettings",
    index: 30,
    icon: Settings,
    path: "/settings",
    auth: [1, 2, 3],
  },
  {
    name: "platformSetting",
    index: 39,
    icon: Settings,
    path: "/settings/platform-settings",
    auth: [1, 2, 3],
  },
  {
    name: "workingHours",
    index: 31,
    icon: Settings,
    path: "/settings/hours",
    auth: [1, 2, 3],
  },
  { name: "createNewBranch", index: null, auth: [1, 2, 3] },
  {
    name: "systemLogs",
    index: 34,
    icon: Settings,
    path: "/settings/logs",
    auth: [1, 2, 3],
  },
  {
    name: "giveReferance",
    index: 38,
    icon: Storefront,
    path: "/settings/give-referance",
    auth: [1, 2, 3],
  },
  { name: "langSetting", index: null, auth: [1, 2, 3] },
  { name: "walletProcesss", index: null, auth: [1, 2, 3] },
  { name: "paymentInfo", index: null, auth: [1, 2, 3] },
  { name: "currentBalance", index: null, auth: [1, 2, 3] },
  { name: "puchase", index: null, auth: [1, 2, 3] },
  { name: "smsPackage", index: null, auth: [1, 2, 3] },
  { name: "logOut", index: null, auth: [1, 2, 3] },
  { name: "galleryManagement", index: null, auth: [1, 2, 3] },
  { name: "showcasePhotos", index: null, auth: [1, 2, 3] },
  { name: "compantGallery", index: null, auth: [1, 2, 3] },
  { name: "servicesGallery", index: null, auth: [1, 2, 3] },
  { name: "appointmentTable", index: null, auth: [1, 2, 3] },
  {
    name: "appointmentTable",
    index: 56,
    icon: CalendarToday,
    path: "/appointments",
    auth: [1, 2, 3],
  },
  {
    name: "onlineAppointmentRequests",
    index: 57,
    icon: CalendarToday,
    path: "/appointments/online",
    auth: [1, 2, 3],
  },
  {
    name: "createNewAppointment",
    index: 58,
    icon: CalendarToday,
    path: "/appointments",
    auth: [1, 2, 3],
  },
  {
    name: "filterAppointment",
    index: 59,
    icon: CalendarToday,
    path: "/appointments/filter",
    auth: [1, 2, 3],
  },
  { name: "receiptManagement", index: null, auth: [1, 2, 3] },
  {
    name: "receiptList",
    index: 60,
    icon: Receipt,
    path: "/receipts",
    auth: [1],
  },
  {
    name: "createNewReceipt",
    index: 61,
    icon: Receipt,
    path: "/receipts/add",
    auth: [1],
  },
];

export const favoritePage = (index) => {
  return favorite_pages.find((item) => item.index === parseInt(index));
};
