import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";
import { useHistory } from "react-router-dom";

import Input from "../../../theme/CustomMUI/Input";
import AuthContainer from "../../../components/AuthContainer";
import AutoComplete from "../../../theme/CustomMUI/AutoComplete";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";

import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";

import Axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";

const AddCredit = () => {
	const context = useContext(AppContext);
	const history = useHistory();

	const [customers, setCustomers] = useState([]);
	const [selected_customer, setSelectedCustomer] = useState(null);

	const [form, setForm] = useState({
		custname: null,
		date: new Date(),
		amount: null,
		explanation: null,
	});

	//TODO: Insert Credit
	const insertCredit = () => {
		selected_customer && form.date && form.explanation && form.amount
			? Axios.post(`${context.api_endpoint}/company/current/income`, {
					amount: parseInt(form.amount),
					note: form.explanation,
					customer_id: selected_customer.id,
					payment_date: moment(form.date).format("YYYY-MM-DD"),
					json_id: "json_id",
			  })
					.then((response) => {
						if (response.status === 201) {
							toast.success("Alacak Eklendi");
							history.push("/accounting/credit");
						}
					})
					.catch((e) => {
						if (e.response.status === 401) {
							toast.error(e.response.data.message);
						} else {
							toast.warning(e.response.data.message);
						}
					})
			: toast.warning(context.t(`['accounting/credit'].add.missingInfo`));
	};

	//TODO Filter Customer
	const getCustomers = (key) => {
		Axios.get(`${context.api_endpoint}/company/customers`, {
			params: { key: key ? key : " " },
		}).then(async ({ data }) => {
			await setCustomers([...data.data.records]);
		});

		return customers;
	};

	return (
		<AuthContainer
			authorities={[1]}
			makePlanUpgrade={[2]}
			limited_permission="system"
		>
			<Grid>
				<Column className="xs-12 sm-12 md-12 lg-6">
					<PlatformImage src={require("../../../assets/images/credit.jpg")} />
				</Column>
				<Column className="xs-12 sm-12 md-12 lg-6">
					<AutoComplete
						required
						label={context.t(`["accounting/credit"].add.customerSelection`)}
						labelKey="full_name"
						valueKey="id"
						returnFullObject
						onRemove={async () => {
							setSelectedCustomer(null);
						}}
						selected={selected_customer || null}
						selectedHandler={async (selected_customer) => {
							setSelectedCustomer(selected_customer);
							setForm({ ...form, custname: selected_customer.id });
						}}
						asyncDataService={async (keyword) => getCustomers(keyword)}
					/>
					<DatePickerInput
						required
						label={context.t(`["accounting/credit"].add.scheduledPaymentDate`)}
						value={form.date}
						onChange={(date) => setForm({ ...form, date: date })}
					/>
					<Input
						required
						type="number"
						label={context.t(`["accounting/credit"].add.enterAmount`) + " "}
						selected={form.amount !== null ? form.amount : ""}
						customInput={Input}
						decimalScale={2}
						suffix={context.state.currency}
						onChange={async (e) => {
							setForm({
								...form,
								amount: e.target.value,
							});
						}}
					/>
					<Input
						required
						label={context.t(`["accounting/credit"].add.explanation`)}
						value={form.explanation}
						multiline
						rows={4}
						onChange={(e) => {
							setForm({
								...form,
								explanation: e.target.value,
							});
						}}
					/>
					<Button
						title={context.t(`["accounting/credit"].add.saveReceivable`)}
						type="submit"
						size="md"
						fullWidth
						textColor="green"
						backgroundColor="green-opacity"
						onClick={() => {
							insertCredit();
						}}
					/>
				</Column>
			</Grid>
		</AuthContainer>
	);
};

export default AddCredit;

const PlatformImage = styled.img`
	width: 100%;
	max-height: 400px;
	object-fit: contain;
`;
