import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

import Button from "../../theme/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Input from "../../theme/CustomMUI/Input";
import Axios from "axios";
import { toast } from "react-toastify";
import MaskedInput from "../../theme/CustomMUI/MaskedInput";
import { Alert } from "@material-ui/lab";
import AppContext from "../../context/store";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100vh",
	},
	image: {
		backgroundImage: `url(${require("../../assets/images/unauth_image3.jpg")})`,
		backgroundRepeat: "no-repeat",
		backgroundColor:
			theme.palette.type === "light"
				? theme.palette.grey[50]
				: theme.palette.grey[900],
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: "flex",
		flexDirection: "column",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const ForgotPassIndex = (props) => {
	const context = useContext(AppContext);
	const classes = useStyles();

	// const [mailRequestForm, setMailRequestForm] = useState({
	// 	mail: null,
	// });

	const [wpRequestForm, setWpRequestForm] = useState({
		wpNumber: null,
	});

	const [newPassWithMailCodeForm, setNewPassWithMailCodeForm] = useState({
		code: null,
		password: null,
	});

	const [step, setStep] = useState(1);

	const getMailCode = () => {
		wpRequestForm.wpNumber
			? Axios.post(`${context.api_endpoint}/staff/reset/password/cd`, {
					...wpRequestForm,
			  })
					.then((response) => {
						setWpRequestForm({ wpNumber: null });
						if (response.status === 201) {
							toast.success(
								context.t(`forgotPass.verificationSuccessfullySentToast`)
							);
							setStep(2);
						} else {
							toast.error(context.t(`forgotPass.verificationErrorToast`));
						}
					})
					.catch(() => {
						toast.error(context.t(`forgotPass.verificationErrorToast`));
					})
			: toast.error(context.t(`forgotPass.validPhoneErrorToast`));
	};

	const resetPass = () => {
		Axios.post(`${context.api_endpoint}/staff/reset/password`, {
			...newPassWithMailCodeForm,
		}).then((response) => {
			if (response.status === 201) {
				toast.success(context.t(`forgotPass.passwordSuccessfullyChangedToast`));
				props.history.push("/login");
			} else {
				toast.error(context.t(`forgotPass.warningStatusToast`));
			}
		});
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={8} className={classes.image} />
			<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
				<div className={`${classes.paper} pt-5`}>
					<Typography component="h1" variant="h5" className="mb-0">
						<b>{context.app.NAME}</b>
					</Typography>
					<Typography component="h1" variant="h6" className="mb-0">
						{context.t(`forgotPass.title`)}
					</Typography>

					<Alert severity="info" fullWidth className="mt-2 mb-2">
						<b>
							{context.t(`forgotPass.stepText`)}: {step}/2
						</b>
						<br />
						{step === 1 && context.t(`forgotPass.firstStepText`)}
						{step === 2 && context.t(`forgotPass.secondStepText`)}
					</Alert>

					<form
						className={classes.form}
						noValidate
						onSubmit={(e) => e.preventDefault()}
						autoComplete="off"
					>
						{step === 1 && (
							<>
								<Input
									required
									className="mb-0"
									label={context.t(`register.phoneInputLabel`)}
									onChange={(e) => {
										setWpRequestForm({
											...wpRequestForm,
											wpNumber: e.target.value,
										});
									}}
								/>

								<Button
									className="mt-1"
									title={context.t(`forgotPass.continueButtonTitle`)}
									type="submit"
									size="lg"
									fullWidth
									textColor="white"
									backgroundColor="primary"
									onClick={() => getMailCode()}
								/>

								<Button
									className="mt-1"
									title={context.t(`forgotPass.backToHomeButtonTitle`)}
									type="submit"
									size="lg"
									fullWidth
									textColor="primary"
									backgroundColor="white"
									onClick={() => props.history.push("/login")}
								/>
							</>
						)}

						{step === 2 && (
							<>
								<Input
									required
									autoComplete="off"
									label={context.t(`forgotPass.verificationCodeInputLabel`)}
									onChange={(e) =>
										setNewPassWithMailCodeForm({
											...newPassWithMailCodeForm,
											code: e.target.value,
										})
									}
								/>

								<Input
									required
									autoComplete="off"
									label={context.t(`forgotPass.newPassInputLabel`)}
									type="password"
									onChange={(e) =>
										setNewPassWithMailCodeForm({
											...newPassWithMailCodeForm,
											password: e.target.value,
										})
									}
								/>

								<Button
									className="mt-1"
									title={context.t(`forgotPass.updatePassButtonTitle`)}
									type="submit"
									size="lg"
									fullWidth
									textColor="white"
									backgroundColor="primary"
									onClick={() => resetPass()}
								/>
							</>
						)}
					</form>
				</div>
			</Grid>
		</Grid>
	);
};

export default withRouter(ForgotPassIndex);
