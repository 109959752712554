import React, { Component } from "react";
import AuthContainer from "../../../components/AuthContainer";
import Input from "../../../theme/CustomMUI/Input";
import AppContext from "../../../context/store";
import { Grid, Column } from "../../../theme/Grid";
import styled from "styled-components";
import Axios from "axios";
import Button from "../../../theme/Button";
import FormDataGenerator from "../../../functions/FormDataGenerator";
import { toast } from "react-toastify";
import CompressImage from "../../../functions/CompressImage";
import { Alert } from "@material-ui/lab";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class Photo extends Component {
	static contextType = AppContext;

	state = {
		loaded: false,

		photo: null,

		open_dialog: false,

		upload_photo_modal: false,

		src: null,
		crop: {
			unit: "%",
			x: 0,
			y: 0,
			width: 70,
			height: 45,
		},
	};

	getSingleStorePhoto() {
		Axios.get(`${this.context.api_endpoint}/company/showcase/image/show`)
			.then(({ data }) => {
				this.setState({
					loaded: true,
					photo: data.data.path,
				});
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.warning(e.response.data.message);
				}
			});
	}

	async uploadStorePhoto(e) {
		toast.success(this.context.t(`['platform/photo'].photoUploadToast`));
		Axios.post(
			`${this.context.api_endpoint}/company/showcase/image/insert`,
			FormDataGenerator({
				files: await CompressImage(e, {
					maxSizeMB: 2,
					fileType: "image/jpg",
				}),
			}),
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		)
			.then((response) => {
				if (response.status === 201) {
					toast.success(this.context.t(`['platform/photo'].insertToast`));
					this.getSingleStorePhoto();
					this.setState({ upload_photo_modal: false });
				}
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.error(
						this.context.t(`['platform/photo'].photoUploadErrorToast`)
					);
				}
			});
	}

	deleteStorePhoto() {
		Axios.delete(`${this.context.api_endpoint}/company/showcase/image/delete`)
			.then((response) => {
				toast.success(this.context.t(`['settings/gallery'].deleteToast`));
				this.setState({
					loaded: false,
					photo: null,
				});
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.error(
						this.context.t(`['settings/gallery'].deletePhotoErrorToast`)
					);
				}
			});
	}

	// Fotoğraf crop fonksiyonları
	onSelectFile = (e) => {
		if (e.target.files && e.target.files.length === 1) {
			const reader = new FileReader();
			reader.addEventListener("load", () =>
				this.setState({ src: reader.result })
			);
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	// If you setState the crop in here you should return false.
	onImageLoaded = (image) => {
		this.imageRef = image.target;
	};

	onCropComplete = (crop) => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				"newFile.jpeg"
			);
			this.setState({ croppedImageUrl });
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty");
					return;
				}

				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(blob);
			}, "image/jpeg");
		});
	}

	componentDidMount() {
		this.getSingleStorePhoto();
	}

	render() {
		const { loaded, photo } = this.state;
		return (
			<AuthContainer authorities={[1]} limited_permission="gallery">
				<Grid>
					<Column
						className="xs-12 mb-3"
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
						}}
					>
						{
							<ProfilePhoto
								src={
									photo
										? `${this.context.api_endpoint.replace("api/v1", "")}${
												this.context.state.company_id
										  }/${photo}`
										: require("../../../assets/images/empty_store_image.jpg")
								}
							/>
						}
						<InfoDiv>
							<Alert severity="warning" className="mt-2">
								<b>{this.context.t(`['platform/photo'].photoInfo`)}</b>
								<Info onClick={() => this.setState({ open_dialog: true })}>
									{this.context.t(`['platform/photo'].anexample`)}
								</Info>
							</Alert>
						</InfoDiv>
						<AlertDialog
							title={""}
							open={this.state.open_dialog}
							fullWidth={true}
							maxWidth="lg"
							textType
							disableBackdropClick={false}
							closeHandler={() => this.setState({ open_dialog: false })}
							buttons={[
								{
									//icon: "okay",
									title: "Kapat",
									textColor: "white",
									backgroundColor: "primary",
								},
							]}
						>
							<InfoPhoto
								src={require("../../../assets/images/appointment_photo.png")}
							/>
						</AlertDialog>
					</Column>

					{/* <input
            id="store_photo"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(e) => this.uploadStorePhoto(e)}
          /> */}

					<Column>
						<Button
							className="mt-1"
							icon="add_photo_alternate"
							title={this.context.t(`profile.photo.uploadButtonTitle`)}
							textColor="primary"
							backgroundColor="primary-opacity"
							fullWidth
							disabled={photo ? true : false}
							onClick={() =>
								this.setState({
									upload_photo_modal: true,
								})
							}
						/>

						<Button
							className="mt-1"
							icon="delete"
							title={this.context.t(`profile.photo.deleteButtonTitle`)}
							textColor="primary"
							backgroundColor="primary-opacity"
							fullWidth
							disabled={photo ? false : true}
							onClick={() => this.deleteStorePhoto()}
						/>
					</Column>
				</Grid>
				<AlertDialog
					fullWidth
					open={this.state.upload_photo_modal}
					closeHandler={() => this.setState({ upload_photo_modal: false })}
					title={this.context.t(`['settings/gallery'].dialog.title`)}
					buttons={[
						{
							title: this.context.t(`['settings/gallery'].dialog.close`),
							icon: "close",
							backgroundColor: "white",
							textColor: "black",
							onClick: () => {
								this.setState({
									upload_photo_modal: false,
								});
							},
						},
					]}
				>
					{/* <UploadPhoto
            uploadPhoto={this.uploadStorePhoto}
            closeHandler={this.succesCloseHandler}
          /> */}

					<div
						style={{
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							padding: "15px",
						}}
					>
						<div>
							<input
								id="select_photo"
								style={{ display: "none" }}
								type="file"
								accept="image/*"
								onChange={this.onSelectFile}
							/>
						</div>
						<Button
							className="mb-2 mt-1"
							icon="add"
							title={this.context.t(`['settings/gallery'].dialog.selectFoto`)}
							textColor="primary"
							backgroundColor="primary-opacity"
							onClick={() => document.getElementById("select_photo").click()}
						/>
						{this.state.src && (
							<ReactCrop
								className="ReactCrop__image"
								aspect={16 / 9}
								crop={this.state.crop}
								ruleOfThirds
								onComplete={this.onCropComplete}
								onChange={this.onCropChange}
							>
								<img src={this.state.src} onLoad={this.onImageLoaded} />
							</ReactCrop>
						)}
						{this.state.croppedImageUrl && (
							<cimg
								alt="Crop"
								style={{ padding: "25px", maxWidth: "100%" }}
								src={this.state.croppedImageUrl}
							/>
						)}
						{this.state.croppedImageUrl && (
							<Button
								className="mt-1"
								icon="add"
								title={this.context.t(`['settings/gallery'].dialog.upload`)}
								textColor="primary"
								backgroundColor="primary-opacity"
								fullWidth
								onClick={() =>
									this.uploadStorePhoto(this.state.croppedImageUrl)
								}
							/>
						)}
					</div>
				</AlertDialog>
			</AuthContainer>
		);
	}
}

export default Photo;

const ProfilePhoto = styled.img`
	background: #fafafa;
	width: 480px;
	height: 270px;
	max-width: 100%;
	object-fit: cover;
	border-radius: 5px;
`;

const InfoDiv = styled.div`
	width: 100%;
`;

const Info = styled.p`
	color: #ed1e79;
	cursor: pointer;
	margin-top: 15px;
`;

const InfoPhoto = styled.img`
	width: 100%;
`;
