import React, { Component } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";

import moment from "moment";
import styled from "styled-components";
import { Grid, Column } from "../../../theme/Grid";
import { Check, TouchApp } from "@material-ui/icons";

import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Button from "../../../theme/Button";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Validate from "../../../functions/Validate";

class PaymentNotify extends Component {
	static contextType = AppContext;
	state = {
		bank_accounts: [
			{
				icon: "QNB.svg",
				title: "QNB Finansbank",
				owner: "ERSİN HACIOĞLU",
				iban: "330011100000000037321440",
			},
			{
				icon: "Garanti.svg",
				title: "Garanti Bankası",
				owner: "ERSİN HACIOĞLU",
				iban: "930006200072300006638768",
			},
			{
				icon: "Ziraat.svg",
				title: "Ziraat Bankası",
				owner: "ERSİN HACIOĞLU",
				iban: "610001001945684381925002",
			},
			{
				icon: "is-bankasi.svg",
				title: "Türkiye İş Bankası",
				owner: "ERSİN HACIOĞLU",
				iban: "910006400000112450241942",
			},
		],

		form: {
			date: moment().format("YYYY-MM-DD"),
			amount: null,
			account: null,
		},
	};

	validateForm = () => {
		const { form } = this.state;
		return Validate([
			{ field: "Yatırılan Banka", value: form.account, condition: /.+/ },
			{ field: "Yatırılan Tutar", value: form.amount, condition: Number },
		]);
	};

	insertPaymentNotify = () => {
		const { form } = this.state;
		this.validateForm() &&
			Axios.post(`${this.context.api_endpoint}/company/buy/notifier`, {
				...form,
			})
				.then((response) => {
					if (response.status === 200) {
						toast.success(response.data.data);
						setTimeout(() => {
							this.props.history.push("/wallet");
						}, 250);
					}
				})
				.catch(() => toast.warning("İs"));
	};

	render() {
		const BankAccount = this._BankAccountBuilder;
		const NotifyForm = this._NotifyFormBuilder;
		const { bank_accounts } = this.state;

		return (
			<AuthContainer>
				<Grid>
					<Column className="xs-12 sm-12 md-6">
						{bank_accounts.map((item, i) => (
							<BankAccount key={i} item={item} />
						))}
					</Column>
					<Column className="xs-12 sm-12 md-6">
						<NotifyForm />
					</Column>
				</Grid>
			</AuthContainer>
		);
	}

	_BankAccountBuilder = ({ item: { icon, title, owner, iban } }) => {
		const { form } = this.state;
		return (
			<Grid
				className={`xs-12 sm-12 md-12 mb-3 lg-${Math.ceil(
					12 / this.state.bank_accounts.length
				)}`}
			>
				<BankAccountBox
					className={form.account === title ? "active" : ""}
					onClick={() => this.setState({ form: { ...form, account: title } })}
				>
					{form.account === title && (
						<div className="checked-container">
							<Check />
						</div>
					)}
					<img
						className="icon"
						src={require(`../../../assets/images/bank/${icon}`)}
						alt={title}
					/>
					<div className="container">
						<span className="title">{title}</span>
						<p className="desc">
							<b>{owner}</b>
						</p>
						<p className="desc">
							<b>IBAN:</b> {iban}
						</p>
					</div>

					<CopyToClipboard text={iban}>
						<Button
							icon="file_copy"
							title={this.context.t(`[payment-notify].copyIban`)}
							backgroundColor="transparent"
							textColor="grey"
							size="sm"
							fullWidth
							onClick={() =>
								toast.success(this.context.t(`[payment-notify].ibanSuccess`))
							}
						/>
					</CopyToClipboard>
				</BankAccountBox>
			</Grid>
		);
	};

	_NotifyFormBuilder = () => {
		const { bank_accounts, form } = this.state;

		return (
			<Column className="xs-12 sm-12">
				<Select
					required
					label={this.context.t(`[payment-notify].depositBank`)}
					items={bank_accounts.map((item) => ({ val: item.title }))}
					selected={form.account}
					labelKey="val"
					valueKey="val"
					handler={(account) => this.setState({ form: { ...form, account } })}
				/>

				<MaskedInput
					required
					label={`${this.context.t(`[payment-notify].depositAmonut`)} `}
					maskChar=""
					mask="999999"
					onChange={(e) =>
						this.setState({
							form: {
								...form,
								amount: e.target.value ? parseInt(e.target.value) : null,
							},
						})
					}
				/>

				<DatePickerInput
					required
					label={this.context.t(`[payment-notify].depositDate`)}
					value={new Date(form.date)}
					onChange={(date) => {
						this.setState({
							form: {
								...form,
								date: moment(date).format("YYYY-MM-DD"),
							},
						});
					}}
				/>

				<Button
					iconComponent={TouchApp}
					title={this.context.t(`[payment-notify].makePaymentNotice`)}
					textColor="white"
					backgroundColor="primary"
					fullWidth
					onClick={() => this.insertPaymentNotify()}
				/>
			</Column>
		);
	};
}

export default PaymentNotify;

const BankAccountBox = styled.div`
	width: 100%;
	padding: 24px 16px 8px;
	box-sizing: border-box;
	border-radius: 8px;
	background: var(--primary-transparent);
	cursor: pointer;
	border: 2px solid transparent;
	transition: all ease 0.25s;
	position: relative;

	&.active {
		border: 2px solid var(--primary);
		box-shadow: 0 8px 16px var(--primary-transparent);
	}

	.checked-container {
		position: absolute;
		top: 8px;
		left: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 36px;
		width: 36px;
		border-radius: 100% 100%;
		background: var(--primary-transparent);
		color: var(--primary);
		font-size: 14px;
	}

	.container {
		text-align: center;
		.title {
			display: block;
			font-size: 22px;
			font-weight: 600;
		}

		.desc {
			font-size: 15px;
			font-weight: 400;
			color: #353535;
			display: block;
			margin: 0;
			padding: 0;

			b {
				font-weight: 500;
			}
		}
	}

	.icon {
		width: 100%;
		height: 40px;
		object-fit: contain;
		margin-bottom: 16px;
	}
`;
