import React from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";

import { Grid, Column } from "../../../../theme/Grid";
import Table from "../../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import FCLabel from "../../../../theme/FCLabel";
import { FormControlLabel, Switch } from "@material-ui/core";
import RoundedRow from "../../../../components/Table/RoundedRow";
import { toast } from "react-toastify";
import AutocompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import Select from "../../../../theme/CustomMUI/Select";

class PackageSalesIndex extends React.Component {
  static contextType = AppContext;

  state = {
    delete_id: null,
    delete_confirm: false,

    filter_expanded: false,
    start: new Date(),
    end: new Date(),
    filterPicker: [
      {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
      {
        id: 2,
        label: "Bu Hafta",
        offset: 3,
      },
      {
        id: 3,
        label: "Bu Ay",
        offset: 4,
      },
    ],
    selected_filter_picker: null,

    customers: [],
    selected_customer: null,

    loaded: false,
    pagination: {
      total_page: null,
      page: null,
      onChange: (page) => this.getOpenedReceipts(page),
    },
    data: [],
  };

  getOpenedReceipts = (page) => {
    const urlPage = parseInt(
      new URLSearchParams(this.props.location.search).get("page")
    );
    const { filter_expanded, start, end, selected_filter_picker } = this.state;
    Axios.get(
      `${this.context.api_endpoint}/company/receipts/packets`,
      filter_expanded
        ? selected_filter_picker === null
          ? {
              params: {
                page: page ? page : urlPage ? urlPage : 1,
                ...{
                  start: moment(start).format("YYYY-MM-DD"),
                  end: moment(end).format("YYYY-MM-DD"),
                  isbetween: true,
                  customerid: this.state.selected_customer
                    ? this.state.selected_customer.id
                    : null,
                },
              },
            }
          : {
              params: {
                page: page ? page : urlPage ? urlPage : 1,
                ...{
                  offset: selected_filter_picker.offset,
                  isbetween: false,
                  customerid: this.state.selected_customer
                    ? this.state.selected_customer.id
                    : null,
                },
              },
            }
        : {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
            },
          }
    )
      .then((response) => {
        const { data } = response;
        if (data.data.records) {
          this.setState({
            loaded: true,
            data: [
              ...data.data.records.map((record) => {
                return {
                  ...record,

                  all_amount: (
                    <RoundedRow color="orange">
                      {record.all_amount
                        ? `${Math.round(record.all_amount.toFixed(2))}.00`
                        : "0.00"}
                      {this.context.state.currency}
                    </RoundedRow>
                  ),
                  paid: (
                    <RoundedRow color="green">
                      {record.paid > 0
                        ? `${Math.round(record.paid.toFixed(2))}.00`
                        : "0.00"}
                      {this.context.state.currency}
                    </RoundedRow>
                  ),

                  debt_total: (
                    <RoundedRow color="red">
                      {(record.all_amount ?? 0) - (record.paid ?? 0) > 0
                        ? `${Math.round(
                            (
                              (record.all_amount ?? 0) - (record.paid ?? 0)
                            ).toFixed(2)
                          )}.00`
                        : "0.00"}
                      {this.context.state.currency}
                    </RoundedRow>
                  ),

                  created_at: moment(record.created_at)
                    .local()
                    .format("DD/MM/YYYY HH:mm"),
                };
              }),
            ],
            pagination: {
              ...this.state.pagination,
              page: data.data.page,
              total_page: data.data.records.length,
            },
          });
        } else {
          this.setState({
            loaded: true,
            data: [],
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getCustomers = (key) => {
    Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(async ({ data }) => {
      await this.setState({ customers: [...data.data.records] });
    });

    return this.state.customers;
  };

  componentDidMount() {
    this.getOpenedReceipts();
    // this.getCustomers();

    this.unlisten = this.props.history.listen((location, action) => {
      this.state.pagination.page !== null &&
        action === "POP" &&
        this.getOpenedReceipts();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid className="mb-2">
          <Column className={`xs-12 sm-12`}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={this.state.filter_expanded}
                  onChange={async (e) => {
                    await this.setState({
                      loaded: false,
                      filter_expanded: e.target.checked,
                    });
                    await this.getOpenedReceipts();
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{this.context.t(`receipts.filter.title`)}</span>
                </FCLabel>
              }
            />
          </Column>
          {this.state.filter_expanded && (
            <>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`receipts.filter.startInputLabel`)}
                  value={new Date(moment(this.state.start).toISOString())}
                  maxDate={this.state.end}
                  onChange={async (start) => {
                    await this.setState({
                      loaded: false,
                      start,
                      selected_filter_picker: null,
                    });
                    await this.getOpenedReceipts();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`receipts.filter.endInputLabel`)}
                  value={this.state.end}
                  minDate={new Date(moment(this.state.start).toISOString())}
                  onChange={async (end) => {
                    await this.setState({
                      loaded: false,
                      end,
                      selected_filter_picker: null,
                    });
                    await this.getOpenedReceipts();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <AutocompleteInput
                  label={this.context.t(
                    `[receipts/add].searchCustomerInputLabel`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  returnFullObject
                  onRemove={async () => {
                    await this.setState({
                      selected_customer: null,
                    });
                    this.getOpenedReceipts();
                  }}
                  selected={this.state.selected_customer || null}
                  selectedHandler={async (selected_customer) => {
                    await this.setState({
                      selected_customer: selected_customer,
                    });
                    this.getOpenedReceipts();
                  }}
                  asyncDataService={async (keyword) =>
                    await this.getCustomers(keyword)
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Select
                  label={this.context.t(`appointments.dayWeekSelectLabel`)}
                  items={[
                    {
                      id: 1,
                      label: this.context.t("component.dateRangeStats.today"),
                      offset: 1,
                    },
                    {
                      id: 2,
                      label: this.context.t("component.dateRangeStats.week"),
                      offset: 3,
                    },
                    {
                      id: 3,
                      label: this.context.t("component.dateRangeStats.month"),
                      offset: 4,
                    },
                  ]}
                  labelKey="label"
                  valueKey="id"
                  selected={
                    this.state.selected_filter_picker
                      ? `${this.state.selected_filter_picker.id}`
                      : null
                  }
                  returnFullObject
                  handler={async (filterPicker) => {
                    await this.setState({
                      selected_filter_picker: filterPicker,
                    });
                    this.getOpenedReceipts();
                  }}
                />
              </Column>
            </>
          )}
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              refreshAction={() =>
                this.getOpenedReceipts(this.state.pagination.page)
              }
              headings={{
                id: {
                  label: this.context.t(`receipts.headings.id`),
                  style: { width: 60 },
                },
                is_package: {
                  label: this.context.t(`receipts.headings.type`),
                  style: { width: 150 },
                },
                created_at: {
                  label: this.context.t(`receipts.headings.createdAt`),
                  style: { width: 200 },
                  sortable: {
                    0: [{ field: "`receipts`.`created_at`", order: "ASC" }],
                    1: [{ field: "`receipts`.`created_at`", order: "DESC" }],
                  },
                },
                full_name: {
                  label: this.context.t(`receipts.headings.customerFullName`),
                  // with_photo: "customer.profile_photo",
                  style: { width: 300 },
                  limited_line: 1,
                  sortable: {
                    0: [
                      { field: "`customers`.`name`", order: "ASC" },
                      { field: "`customers`.`surname`", order: "ASC" },
                    ],
                    1: [
                      { field: "`customers`.`name`", order: "DESC" },
                      { field: "`customers`.`surname`", order: "DESC" },
                    ],
                  },
                },
                info: {
                  label: this.context.t(`receipts.headings.info`),
                  style: { width: 400 },
                  limited_line: 2,
                },
                all_amount: {
                  label: this.context.t(`receipts.headings.allAmount`),
                  style: { width: 100 },
                  sortable: {
                    0: [{ field: "`receipts`.`all_amount`", order: "ASC" }],
                    1: [{ field: "`receipts`.`all_amount`", order: "DESC" }],
                  },
                },
                paid: {
                  label: this.context.t(`receipts.headings.paid`),
                  sortable: {
                    0: [{ field: "`receipts`.`paid`", order: "ASC" }],
                    1: [{ field: "`receipts`.`paid`", order: "DESC" }],
                  },
                  style: { width: 120 },
                },
                debt_total: {
                  label: this.context.t(`receipts.headings.debtTotal`),
                  sortable: {
                    0: [
                      { field: "`receipts`.`all_amount`", order: "ASC" },
                      { field: "`receipts`.`debt`", order: "DESC" },
                    ],
                    1: [
                      { field: "`receipts`.`all_amount`", order: "DESC" },
                      { field: "`receipts`.`debt`", order: "ASC" },
                    ],
                  },
                  style: { width: 120 },
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={this.state.data}
              loaded={this.state.loaded}
              replacements={{
                is_package: {
                  true: this.context.t(`receipts.replacements.packageSale`),
                  false: this.context.t(`receipts.replacements.standartSale`),
                },
              }}
              pagination={this.state.pagination}
              buttons={[
                {
                  title: this.context.t(`receipts.showButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  onClick: (row) =>
                    this.props.history.push(`/receipts/detail/${row.id}`),
                },
              ]}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }
}

export default PackageSalesIndex;
