import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import { Column, Grid } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Alert from "@material-ui/lab/Alert";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import moment from "moment";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

class Blacklist extends React.Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      total_page: null,
      page: null,
      onChange: (page) => this.getBlacklist(page),
    },
    blacklistNumbers: [],

    newTemplate: {
      id: null,
      title: null,
      content: "",
    },

    delete_id: null,

    open_template_dialog: false,
    open_delete_template_dialog: false,

    sumBlacklistCount: 5,

    selected_customer: null,
    blockNumbers: "",
    dialCode: null,
  };

  // GET Users that or on the blacklist
  getBlacklist = (page = 1) => {
    Axios.get(
      `${this.context.api_endpoint}/company/shortcode/list/blacklist?page${page}`
    ).then(({ data }) => {
      // console.log(data);
      this.setState({
        loaded: true,
        pagination: {
          ...this.state.pagination,
          total_page: data.data.records.length,
          page: data.page,
        },
        blacklistNumbers: [
          ...data.data.records.map((item) => {
            return {
              ...item,
              created_at: moment(item.created_at).local().format("LLL"),
              // state: item.has_paid === 1 ? "PAYBACK_COMPLETED" : item.state,
              phone:
                this.context.state.user.permission === 2 &&
                this.context.state.limited_permission.customer_info === 0
                  ? phoneNumberDisplay(item.phone)
                  : item.phone,
            };
          }),
        ],
      });
      this.setState({
        sumBlacklistCount: this.state.blacklistNumbers.length,
      });

      // this.setState(prevState => {
      //   const team = [...prevState.blacklistNumbers];
      //   team[0] = { ...team[0], created_at: "2" };
      //   return { team };
      // });
    });
  };

  //  Add new Block Numbers
  /* addNewBlockNumbers = (e) => {
    e.preventDefault();
    const errors = [];

    const numbers = this.state.blockNumbers.split(",");
    numbers.forEach((number) => {
      const numberInt = parseInt(number);
      // console.log(numberInt);
      // Validation that number is converted and is not longer
      if (numberInt === NaN || number.length !== 10) {
        // console.log(number.length);
        errors.push(1);
      }
    });

    // If There are no Errors add Numbers to the Block List
    if (errors.length === 0) {
      numbers.forEach((number) => {
        this.addBlacklist(number);
      });
    } else {
      toast.warning(this.context.t(`['sms/blacklist'].addToBlacklistError`));
    }
  }; */
  // POST / ADD User to the blacklist
  addBlacklist = () => {
    Axios.post(`${this.context.api_endpoint}/company/shortcode/add/blacklist`, {
      phone: this.state.blockNumbers.slice(this.state.dialCode.length),
      dialCode: "+" + this.state.dialCode,
      // created_at: this.state.newTemplate.content,
    })
      .then(async ({ data }) => {
        await this.setState({
          blockNumbers: "",
          open_template_dialog: false,
        });
        toast.success(this.context.t(`['sms/blacklist'].addSuccessToast`));
        this.getBlacklist();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // DELETE / REMOVE User from the blacklist
  deleteBlacklist = (id) => {
    // console.log(id);
    Axios.post(
      `${this.context.api_endpoint}/company/shortcode/delete/blacklist`,
      {
        id: id,
      }
    )
      .then(async ({ data }) => {
        await this.setState({
          delete_id: null,
          open_delete_template_dialog: false,
        });
        toast.success(this.context.t(`['sms/template'].deleteSuccessToast`));
        this.getBlacklist();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  Phone_handleOnChange = (value, data, event, formattedValue) => {
    console.log(value, data);
    this.setState({
      blockNumbers: value,
      dialCode: data.dialCode,
    });
    /*    this.setState({
         customer: {
           ...this.state.customer,
           secondary_phone: value,
           s_dialCode: data.dialCode,
         
         },
       }); */
  };

  // UseEffect / Onload
  componentDidMount() {
    this.getBlacklist();
  }

  render() {
    const { blacklistNumbers, blockNumbers } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid className="mb-2">
          {/* Input to add Number to the blacklist */}
          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            {/*     <Input
              label={this.context.t(`['sms/blacklist'].numberInputLabel`)}
              value={blockNumbers}
              onChange={(e) =>
                this.setState({
                  blockNumbers: e.target.value,
                })
              }
            /> */}

            <PhoneInput
              country={
                this.context.default_locale ? this.context.default_locale : "tr"
              }
              onChange={this.Phone_handleOnChange}
              specialLabel={this.context.t(
                `['sms/blacklist'].numberInputLabel`
              )}
            />
          </Column>
          <Column className="xs-12 sm-12 md-4 lg-auto">
            <Button
              icon="add"
              title={this.context.t(`['sms/blacklist'].addToBlacklist`)}
              className="inputBtn mt-2"
              backgroundColor="black"
              textColor="white"
              //   fullWidth={true}
              onClick={() => this.addBlacklist()}
            />
          </Column>

          {/* Download Users as Excell Buton */}
          <Column className="xs-12 sm-12 md-4 lg-auto inputBtn2">
            <ExcelButton
              route="shortcode/list/blacklist"
              requestData={{ is_excel: true }}
              className=""
            />
          </Column>
        </Grid>

        <Grid>
          {/* Sum Count Users in the Blacklist*/}
          <Column
            className="xs-12 sm-12 md-12"
            style={{
              color: "#7c7c7c",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {this.state.sumBlacklistCount > 0 &&
              this.context.t(`[sms/blacklist].totalNumber`) +
                this.state.sumBlacklistCount}
          </Column>

          {/* Table with the Users in the Blacklist listed*/}
          <Column className="xs-12 sm-12 md-12">
            <Table
              refreshAction={() =>
                this.getBlacklist(this.state.pagination.page)
              }
              loaded={this.state.loaded}
              headings={{
                phone: {
                  label: this.context.t(
                    `['sms/blacklist'].blacklistHeadings.number`
                  ),
                },
                created_at: {
                  label: this.context.t(
                    `['sms/blacklist'].blacklistHeadings.addDate`
                  ),
                  limited_line: 1,
                  style: {
                    maxWidth: "350px",
                  },
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={blacklistNumbers}
              pagination={this.state.pagination}
              showAlwaysPagination
              buttons={[
                {
                  title: this.context.t(
                    `['sms/blacklist'].deleteUserBlacklist`
                  ),
                  icon: "delete_forever",
                  transitionEffect: true,
                  textColor: "red",
                  onClick: (row) => {
                    this.setState({
                      delete_id: row.id,
                      open_delete_template_dialog: true,
                    });
                  },
                },
              ]}
            />
          </Column>

          {/* Alertbox Modal for Deleting User from Blacklist*/}
          <AlertDialog
            title={this.context.t(`['sms/blacklist'].deleteConfirm.title`)}
            open={this.state.open_delete_template_dialog}
            disableBackdropClick={false}
            buttons={[
              {
                title: this.context.t(
                  `['sms/template'].deleteConfirm.confirmButtonTitle`
                ),
                disabledHandleClose: true,
                onClick: () => this.deleteBlacklist(this.state.delete_id),
              },
              {
                title: this.context.t(
                  `['sms/template'].deleteConfirm.discardButtonTitle`
                ),
              },
            ]}
            closeHandler={() => {
              this.setState({
                delete_id: null,
                open_delete_template_dialog: false,
              });
            }}
          >
            <Alert severity="warning">
              <b>
                {this.context.t(`['sms/template'].deleteConfirm.alertBoldText`)}
              </b>
              <br />
              {this.context.t(`['sms/template'].deleteConfirm.alertText`)}
            </Alert>
          </AlertDialog>
        </Grid>
      </AuthContainer>
    );
  }
}

export default Blacklist;
