import React, { Component } from "react";
import AppContext from "../../../context/store";
import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Dialog from "@material-ui/core/Dialog";
import { Button as Btn } from "@material-ui/core";
import styled from "styled-components";
import Axios from "axios";
import {
  Typography,
  Grid as Gr,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import StatsCard from "../../../components/Cards/StatsCard";
import CalculateLicense from "../../../functions/CalculateLicense";
import SinglePlan from "../../../components/SinglePlan";

import * as MUI from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  ShoppingCart,
  Send,
  CreditCard,
  AccountBalance,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AuthContainer from "../../../components/AuthContainer";
import { indexOf } from "lodash";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SmsIcon from "@material-ui/icons/Sms";
import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import WalletTable from "../../../components/WalletTable";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CloseIcon from "@material-ui/icons/Close";
import { AiOutlineBarcode } from "react-icons/ai";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip } from "@material-ui/core";

import { GrStatusInfo } from "react-icons/gr";
class WalletIndex extends Component {
  static contextType = AppContext;

  state = {
    balance_modal: {
      active: false,
      url: null,
      data: null,
    },

    company_license: { ...this.context.state.company_license },

    balance: 0,

    sms_packages: [],
    wp_packages: [],
    extra_licence: [],
    license_packages: [],
    license: null,

    timer: 10,

    router: 1,

    payment_modal: {
      active: false,
      type: null, // SMS || LICENSE
      data: {},
    },

    remaining_appointment: null,

    open_payment_type_dialog: false,

    select_data: {},
    modal_open: false,
    isDropdownPlatiniumOpen: false,
    isDropdownDiamondOpen: false,
    defaultColorGold: true,
    defaultColorPlatinium: true,
    defaultColorDiamond: true,
    tooltipVisible: true,
    check_accounting: 0,
  };
  getExtraLicence = () => {
    Axios.get(`${this.context.api_endpoint}/company/show/license/extras`, {})
      .then((data) => this.setState({ extra_licence: data.data.data }))
      .catch((error) => console.log(error));
  };
  componentDidMount = () => {
    this.loadData();
    this.getExtraLicence();
    this.setState({
      router: this.props.location.data ? this.props.location.data : 1,
    });
  };
  toggleDropdownPlatinium = () => {
    this.setState((prevState) => ({
      isDropdownPlatiniumOpen: !prevState.isDropdownPlatiniumOpen,
    }));
  };
  toggleDropdownDiamond = () => {
    this.setState((prevState) => ({
      isDropdownDiamondOpen: !prevState.isDropdownDiamondOpen,
    }));
  };

  render() {
    const {
      balance,
      wp_packages,
      sms_packages,
      license_packages,
      payment_modal,
      balance_modal,
      timer,
      remaining_appointment,
      license,
      check_accounting,
    } = this.state;
    return (
      <AuthContainer limited_permission="wallet">
        <Grid>
          <Column className="xs-12 sm-12 md-12 xl-12">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <div
                  className="sms_btn_hover"
                  onClick={() =>
                    this.setState({
                      router: 1,
                    })
                  }
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                    borderBottom:
                      this.state.router === 1 ? "1px solid #3189ec" : "",
                    opacity: this.state.router === 1 ? "1" : "0.8",
                    backgroundColor: this.state.router === 1 ? "#F0F6FB" : "",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                >
                  {this.context.t(`wallet.licenses`)}
                </div>
                <div
                  className="sms_btn_hover"
                  onClick={() =>
                    this.setState({
                      router: 2,
                    })
                  }
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                    marginLeft: "12px",
                    borderBottom:
                      this.state.router === 2 ? "1px solid #3189ec" : "",
                    opacity: this.state.router === 2 ? "1" : "0.8",
                    backgroundColor: this.state.router === 2 ? "#F0F6FB" : "",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                >
                  {this.context.t(`wallet.message_packages`)}
                </div>
                <div
                  className="sms_btn_hover"
                  onClick={() =>
                    this.setState({
                      router: 3,
                    })
                  }
                  style={{
                    cursor: "pointer",
                    padding: "8px",
                    marginLeft: "12px",
                    borderBottom:
                      this.state.router === 3 ? "1px solid #3189ec" : "",
                    opacity: this.state.router === 3 ? "1" : "0.8",
                    backgroundColor: this.state.router === 3 ? "#F0F6FB" : "",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                >
                  {this.context.t(`wallet.extra_license`)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "25px",
                }}
              >
                {(this.context.current_flag &
                  this.context.state.FLAGS.SALON_RANDEVU && (
                  <div
                    style={{ display: "flex", gap: "2px", marginTop: "5px" }}
                  >
                    <AssignmentIcon style={{ marginTop: "3px" }} />
                    <p
                      style={{
                        opacity: "0.8",
                        fontSize: "16px",
                        marginTop: "3px",
                      }}
                    >
                      {this.context.t(`wallet.licenseDetail`)}
                    </p>
                    :
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginTop: "3px",
                      }}
                    >
                      {this.context.state.company_license.accounting === 2 &&
                      this.context.state.company_license
                        .appointment_limitation > 0
                        ? "Silver Paket"
                        : PACKAGE_LIST[
                            this.context.state.company_license.accounting
                          ]}
                    </p>
                  </div>
                )) ||
                  null}

                {this.context.current_flag &
                  this.context.state.FLAGS.SALON_RANDEVU &&
                  this.context.state.company_license.accounting === 2 &&
                  this.context.state.company_license.appointment_limitation >
                    0 && (
                    <div
                      style={{ display: "flex", gap: "2px", marginTop: "5px" }}
                    >
                      <EventNoteIcon style={{ marginTop: "3px" }} />
                      <p
                        style={{
                          opacity: "0.8",
                          fontSize: "16px",
                          marginTop: "3px",
                        }}
                      >
                        {this.context.t(`wallet.appointmentRemaining`)}
                      </p>
                      <p style={{ fontSize: "16px", marginTop: "3px" }}>
                        {`${
                          remaining_appointment ? remaining_appointment : "0"
                        } adet`}
                      </p>
                    </div>
                  )}
                <div style={{ display: "flex", gap: "2px", marginTop: "5px" }}>
                  <AccountBalanceWalletIcon style={{ marginTop: "3px" }} />
                  <p
                    style={{
                      opacity: "0.8",
                      fontSize: "16px",
                      marginTop: "3px",
                    }}
                  >
                    {" "}
                    {this.context.t(`wallet.cardTitle`)}
                  </p>{" "}
                  :
                  <p
                    style={{
                      fontSize: "bold",
                      fontSize: "17px",
                      marginTop: "3px",
                    }}
                  >
                    {balance.toFixed(2)}
                    {this.context.state.currency}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="sms_btn_hover"
                    style={{
                      display: "flex",
                      minWidth: "150px",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      borderRadius: "50px",
                      color: "#04113B",
                      backgroundColor: "#98ADE8",
                      border: "none",
                      gap: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({
                        modal_open: true,
                      })
                    }
                  >
                    <div>{this.context.state.currency}</div>
                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                      {this.context.t(`wallet.load_balance`)}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </Column>
          <Column className="xs-12 sm-12 md-12 xl-12">
            {this.state.router === 1 ? (
              <PackageSection style={{ marginTop: "40px" }}>
                <Container>
                  <Gr container spacing={2}>
                    <Gr item xs={12} sm={6} md={3}>
                      <Paper elevation={3}>
                        <Typography
                          variant="h5"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          align="center"
                        >
                          Silver
                        </Typography>
                        <List>
                          {this.context
                            .t(`licenseDetails.silver`)
                            .map((m, index) => (
                              <ListItem key={index} dense>
                                <CheckIcon color="action" fontSize="small" />

                                <ListItemText
                                  style={{
                                    marginLeft: "6px",
                                  }}
                                  primary={m}
                                  dense
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Paper>
                      {license &&
                        license.accounting === 2 &&
                        license.appointment_limitation > 0 && (
                          <Paper style={{ marginTop: "10px" }}>
                            <div>
                              {license_packages.length > 0 && (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "20px",
                                    height: "150px",
                                  }}
                                >
                                  <h3
                                    style={{ textAlign: "center" }}
                                  >{`${license_packages[0].name.replace(
                                    "Paket ",
                                    ""
                                  )}`}</h3>

                                  <Btn
                                    className="platinium-diamond-button"
                                    style={{
                                      backgroundColor: "white",
                                      borderRadius: "50px",
                                      width: "200px",
                                      padding: "8px 30px",
                                      color: "rgb(82, 150, 233)",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      boxShadow:
                                        "0px 4px 8px rgba(0, 0, 0, 0.5)",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        timer: 10,
                                        payment_modal: {
                                          active: true,
                                          type: "license",
                                          data: license_packages[0],
                                        },
                                      });
                                    }}
                                  >
                                    <ShoppingCart
                                      style={{ marginRight: "5px" }}
                                      color="white"
                                    />{" "}
                                    {this.context.t(`wallet.buy`)}
                                  </Btn>
                                </div>
                              )}
                            </div>
                          </Paper>
                        )}
                    </Gr>
                    <Gr item xs={12} sm={6} md={3} style={{ width: "100%" }}>
                      <Paper
                        style={{ backgroundColor: "#ffbf00" }}
                        elevation={3}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                          align="center"
                        >
                          <div>
                            <div>Gold</div>
                            {this.context.state.company_license.accounting ===
                              2 && (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#2F4F4F",
                                    position: "absolute",
                                    top: "54px",
                                  }}
                                >
                                  {this.context.t(
                                    "licenseDetails.currentPackage"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </Typography>

                        <Typography
                          style={{
                            marginTop: "10px",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "80%",
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                            }}
                          >
                            <Btn
                              style={{
                                width: "50%",
                                height: "120px",
                                border: "none",

                                backgroundColor:
                                  this.state.select_data.id == 4
                                    ? " white"
                                    : null,
                                opacity:
                                  this.state.select_data.id == 4 ? "1" : "0.5",
                              }}
                              value={4}
                              onClick={() => {
                                this.setState({
                                  select_data:
                                    license_packages[check_accounting + 0],
                                  defaultColorGold: false,
                                });
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.context.t(`wallet.monthly_payment`)}
                                </p>
                                <h2
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#3189ec",
                                  }}
                                >
                                  {
                                    license_packages[check_accounting + 0]
                                      ?.amount
                                  }
                                  $
                                </h2>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  / {this.context.t(`wallet.monthly`)}
                                </p>
                              </div>
                            </Btn>
                            <Btn
                              style={{
                                width: "50%",
                                height: "120px",
                                backgroundColor:
                                  this.state.select_data.id == 3 ||
                                  this.state.defaultColorGold
                                    ? " white"
                                    : this.state.select_data.id == 4
                                    ? null
                                    : " white",
                                opacity:
                                  this.state.select_data.id == 3 ||
                                  this.state.defaultColorGold
                                    ? "1"
                                    : this.state.select_data.id == 4
                                    ? "0.5"
                                    : " 1",
                              }}
                              value={3}
                              onClick={() => {
                                this.setState({
                                  select_data:
                                    license_packages[check_accounting + 3],
                                  defaultColorGold: false,
                                });
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.context.t(`wallet.yearly_payment`)}
                                </p>
                                <h2
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#3189ec",
                                  }}
                                >
                                  {(license_packages[3]?.amount / 12).toFixed(
                                    2
                                  )}
                                  $
                                </h2>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  / {this.context.t(`wallet.monthly`)}
                                </p>
                              </div>
                            </Btn>
                          </div>
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "32px",
                          }}
                        >
                          <Btn
                            className="platinium-diamond-button"
                            style={{
                              backgroundColor: "white",
                              borderRadius: "50px",
                              width: "200px",
                              padding: "8px 30px",
                              color: "#ffbf00",
                              fontSize: "12px",
                              fontWeight: "bold",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={() => {
                              this.setState({
                                timer: 10,
                                payment_modal: {
                                  active: true,
                                  type: "license",
                                  data:
                                    this.state.select_data ===
                                      license_packages[check_accounting + 3] ||
                                    this.state.select_data ===
                                      license_packages[check_accounting + 0]
                                      ? this.state.select_data
                                      : license_packages[check_accounting + 3],
                                },
                                select_data:
                                  this.state.select_data ===
                                    license_packages[check_accounting + 3] ||
                                  this.state.select_data ===
                                    license_packages[check_accounting + 0]
                                    ? this.state.select_data
                                    : license_packages[check_accounting + 3],
                              });
                            }}
                          >
                            <ShoppingCart
                              style={{ marginRight: "5px" }}
                              color="white"
                            />{" "}
                            {this.context.t(`wallet.buy`)}
                          </Btn>
                        </Typography>

                        <List style={{ marginTop: "30px", height: "269px" }}>
                          {this.context
                            .t(`licenseDetails.gold`)
                            .map((m, index) => (
                              <ListItem key={index} dense>
                                <CheckIcon color="action" fontSize="small" />

                                <ListItemText
                                  primary={
                                    <span
                                      style={{
                                        marginLeft: "6px",
                                      }}
                                    >
                                      {m}
                                    </span>
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Paper>
                    </Gr>
                    <Gr item xs={12} sm={6} md={3}>
                      <Paper
                        style={{ backgroundColor: "#E6F2FD" }}
                        elevation={3}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                          align="center"
                        >
                          <div>
                            <div>Platinium</div>
                            {this.context.state.company_license.accounting ===
                              1 && (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#2F4F4F",
                                    position: "absolute",
                                    top: "54px",
                                  }}
                                >
                                  {this.context.t(
                                    "licenseDetails.currentPackage"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </Typography>
                        <Typography
                          style={{
                            marginTop: "10px",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "80%",
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                            }}
                          >
                            <Btn
                              style={{
                                width: "50%",
                                height: "120px",
                                border: "none",

                                backgroundColor:
                                  this.state.select_data.id == 2
                                    ? " #F8F7F7"
                                    : null,
                                opacity:
                                  this.state.select_data.id == 2 ? "1" : "0.4",
                              }}
                              value={2}
                              onClick={() => {
                                this.setState({
                                  select_data:
                                    license_packages[check_accounting + 1],
                                  defaultColorPlatinium: false,
                                });
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.context.t(`wallet.monthly_payment`)}
                                </p>
                                <h2
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#3189ec",
                                  }}
                                >
                                  {
                                    license_packages[check_accounting + 1]
                                      ?.amount
                                  }
                                  $
                                </h2>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  / {this.context.t(`wallet.monthly`)}
                                </p>
                              </div>
                            </Btn>
                            <Btn
                              style={{
                                width: "50%",
                                height: "120px",
                                backgroundColor:
                                  this.state.select_data.id == 1 ||
                                  this.state.defaultColorPlatinium
                                    ? " #F8F7F7"
                                    : this.state.select_data.id == 2
                                    ? null
                                    : " #F8F7F7",
                                opacity:
                                  this.state.select_data.id == 1 ||
                                  this.state.defaultColorPlatinium
                                    ? "1"
                                    : this.state.select_data.id == 2
                                    ? "0.4"
                                    : "1",
                                border: "none",
                              }}
                              value={1}
                              onClick={() => {
                                this.setState({
                                  select_data:
                                    license_packages[check_accounting + 4],
                                  defaultColorPlatinium: false,
                                });
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.context.t(`wallet.yearly_payment`)}
                                </p>
                                <h2
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#3189ec",
                                  }}
                                >
                                  {(license_packages[4]?.amount / 12).toFixed(
                                    2
                                  )}
                                  $
                                </h2>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  / {this.context.t(`wallet.monthly`)}
                                </p>
                              </div>
                            </Btn>
                          </div>
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "32px",
                          }}
                        >
                          <Btn
                            className="platinium-diamond-button"
                            style={{
                              backgroundColor: "white",
                              borderRadius: "50px",
                              width: "200px",
                              padding: "8px 30px",
                              color: "#5296e9",
                              fontSize: "12px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={() => {
                              this.setState({
                                timer: 10,
                                payment_modal: {
                                  active: true,
                                  type: "license",
                                  data:
                                    this.state.select_data ===
                                      license_packages[check_accounting + 4] ||
                                    this.state.select_data ===
                                      license_packages[check_accounting + 1]
                                      ? this.state.select_data
                                      : license_packages[check_accounting + 4],
                                },
                                select_data:
                                  this.state.select_data ===
                                    license_packages[check_accounting + 4] ||
                                  this.state.select_data ===
                                    license_packages[check_accounting + 1]
                                    ? this.state.select_data
                                    : license_packages[check_accounting + 4],
                              });
                            }}
                          >
                            <ShoppingCart
                              style={{ marginRight: "5px" }}
                              color="white"
                            />{" "}
                            {this.context.t(`wallet.buy`)}
                          </Btn>
                        </Typography>
                        <div>
                          <div
                            className={`list-container ${
                              this.state.isDropdownPlatiniumOpen ? "open" : ""
                            }`}
                          >
                            <List style={{ marginTop: "30px" }}>
                              {this.context
                                .t(`licenseDetails.platinum`)
                                .map((m, index) => (
                                  <ListItem key={index} dense>
                                    <CheckIcon
                                      color="action"
                                      fontSize="small"
                                    />

                                    <ListItemText
                                      primary={
                                        <span
                                          style={{
                                            marginLeft: "6px",
                                          }}
                                        >
                                          {m}
                                        </span>
                                      }
                                    />
                                  </ListItem>
                                ))}
                            </List>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {this.state.isDropdownPlatiniumOpen ? (
                              <button
                                className="sms_btn_hover"
                                style={{
                                  border: "none",
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#E6F2FD",
                                }}
                                onClick={this.toggleDropdownPlatinium}
                              >
                                <div></div>
                                <p style={{ fontSize: "13px" }}>
                                  {this.context.t(`wallet.see_less`)}
                                </p>
                                <div>
                                  <ExpandLessIcon />
                                </div>
                              </button>
                            ) : (
                              <button
                                className="sms_btn_hover"
                                style={{
                                  border: "none",
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#E6F2FD",
                                }}
                                onClick={this.toggleDropdownPlatinium}
                              >
                                <p style={{ fontSize: "13px" }}>
                                  {" "}
                                  {this.context.t(`wallet.see_more`)}
                                </p>
                                <div>
                                  {" "}
                                  <ExpandMoreIcon />
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </Paper>
                    </Gr>
                    <Gr item xs={12} sm={6} md={3}>
                      <Paper
                        style={{ backgroundColor: "#b8d8e7" }}
                        elevation={3}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                          align="center"
                        >
                          <div>
                            <div>Diamond</div>
                            {this.context.state.company_license.accounting ===
                              3 && (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#2F4F4F",
                                    position: "absolute",
                                    top: "54px",
                                  }}
                                >
                                  {this.context.t(
                                    "licenseDetails.currentPackage"
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </Typography>

                        <Typography
                          style={{
                            marginTop: "10px",

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "80%",
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                            }}
                          >
                            <Btn
                              style={{
                                width: "50%",
                                height: "120px",
                                border: "none",

                                backgroundColor:
                                  this.state.select_data.id == 8
                                    ? "white"
                                    : null,
                                opacity:
                                  this.state.select_data.id == 8 ? "1" : "0.5",
                              }}
                              value={2}
                              onClick={() => {
                                this.setState({
                                  select_data:
                                    license_packages[check_accounting + 2],
                                  defaultColorDiamond: false,
                                });
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.context.t(`wallet.monthly_payment`)}
                                </p>
                                <h2
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#3189ec",
                                  }}
                                >
                                  {
                                    license_packages[check_accounting + 2]
                                      ?.amount
                                  }
                                  $
                                </h2>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  / {this.context.t(`wallet.monthly`)}
                                </p>
                              </div>
                            </Btn>
                            <Btn
                              style={{
                                width: "50%",
                                height: "120px",
                                backgroundColor:
                                  this.state.select_data.id == 7 ||
                                  this.state.defaultColorDiamond
                                    ? "white"
                                    : this.state.select_data.id == 8
                                    ? null
                                    : " white",
                                opacity:
                                  this.state.select_data.id == 7 ||
                                  this.state.defaultColorDiamond
                                    ? "1"
                                    : this.state.select_data.id == 8
                                    ? "0.5"
                                    : "1",
                                border: "none",
                              }}
                              value={1}
                              onClick={() => {
                                this.setState({
                                  select_data:
                                    license_packages[check_accounting + 5],
                                  defaultColorDiamond: false,
                                });
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {this.context.t(`wallet.yearly_payment`)}
                                </p>
                                <h2
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#3189ec",
                                  }}
                                >
                                  {(license_packages[5]?.amount / 12).toFixed(
                                    2
                                  )}
                                  $
                                </h2>
                                <p
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    fontSize: "11px",
                                  }}
                                >
                                  / {this.context.t(`wallet.monthly`)}
                                </p>
                              </div>
                            </Btn>
                          </div>
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "32px",
                          }}
                        >
                          <Btn
                            className="platinium-diamond-button"
                            style={{
                              backgroundColor: "white",
                              borderRadius: "50px",
                              width: "200px",
                              padding: "8px 30px",
                              color: "#5296e9",
                              fontSize: "12px",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={async () => {
                              await this.setState({
                                timer: 10,
                                payment_modal: {
                                  active: true,
                                  type: "license",
                                  data:
                                    this.state.select_data ===
                                      license_packages[check_accounting + 5] ||
                                    this.state.select_data ===
                                      license_packages[check_accounting + 2]
                                      ? this.state.select_data
                                      : license_packages[check_accounting + 5],
                                },
                                select_data:
                                  this.state.select_data ===
                                    license_packages[check_accounting + 5] ||
                                  this.state.select_data ===
                                    license_packages[check_accounting + 2]
                                    ? this.state.select_data
                                    : license_packages[check_accounting + 5],
                              });
                            }}
                          >
                            <ShoppingCart
                              style={{ marginRight: "5px" }}
                              color="white"
                            />{" "}
                            {this.context.t(`wallet.buy`)}
                          </Btn>
                        </Typography>
                        <div>
                          <div
                            className={`list-container ${
                              this.state.isDropdownDiamondOpen ? "open" : ""
                            }`}
                          >
                            <List style={{ marginTop: "30px" }}>
                              {this.context
                                .t(`licenseDetails.diamond`)
                                .map((m, index) => (
                                  <ListItem key={index} dense>
                                    <CheckIcon
                                      color="action"
                                      fontSize="small"
                                    />

                                    <ListItemText
                                      primary={
                                        <span
                                          style={{
                                            marginLeft: "6px",
                                          }}
                                        >
                                          {m}
                                        </span>
                                      }
                                    />
                                  </ListItem>
                                ))}
                            </List>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {this.state.isDropdownDiamondOpen ? (
                              <button
                                className="sms_btn_hover"
                                style={{
                                  border: "none",
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#b8d8e7",
                                }}
                                onClick={this.toggleDropdownDiamond}
                              >
                                <div></div>
                                <p style={{ fontSize: "13px" }}>
                                  {this.context.t(`wallet.see_less`)}
                                </p>
                                <div>
                                  <ExpandLessIcon />
                                </div>
                              </button>
                            ) : (
                              <button
                                className="sms_btn_hover"
                                style={{
                                  border: "none",
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#b8d8e7",
                                }}
                                onClick={this.toggleDropdownDiamond}
                              >
                                <p style={{ fontSize: "13px" }}>
                                  {" "}
                                  {this.context.t(`wallet.see_more`)}
                                </p>
                                <div>
                                  {" "}
                                  <ExpandMoreIcon />
                                </div>
                              </button>
                            )}
                          </div>
                        </div>
                      </Paper>
                    </Gr>
                  </Gr>
                </Container>
                <Column className="xs-12 sm-12 md-12 xl-12">
                  <Container
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "3px",
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      {" "}
                      {this.context.t(`wallet.package_prices`)}{" "}
                    </p>

                    {/* <p>
											<u>{this.context.t(`wallet.kdv`)}.</u>
										</p> */}
                  </Container>
                </Column>
                <Column className="xs-12 sm-12 md-12 xl-12">
                  <Container style={{ width: "80%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                        marginBottom: "50px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {this.context.t("wallet.allLicenceFeatures")}
                    </div>
                    <WalletTable />
                  </Container>
                </Column>
              </PackageSection>
            ) : this.state.router === 2 ? (
              <Container>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "40px",
                  }}
                >
                  {/* <div
										style={{
											flex: "1",
											padding: "20px",
											borderRadius: "10px",
											border: "1px solid #b8d8e7",
											height: "450px",
											width: "100%",
											boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
										}}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<h3 style={{ fontSize: "23px" }}>
												{" "}
												{this.context.t(`wallet.sms_package`)}
											</h3>
											<MailOutlineIcon
												style={{
													fontSize: 25,
													marginRight: "10px",
												}}
											/>
										</div>
										<PackageSection style={{ marginTop: "20px" }}>
											{sms_packages?.map((_package) => (
												<div
													className="sms_btn_hover"
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														width: "320px",
													}}
												>
													<div
														style={{
															marginBottom: "17px",
															fontSize: "16px",
															color: "#434746",
														}}
													>{`${_package.quantity} SMS`}</div>
													<div style={{ marginBottom: "17px" }}>
														<button
															className="sms_btn_hover"
															style={{
																width: "110px",
																padding: "4px",
																borderRadius: "50px",
																backgroundColor: "#00d38b",
																color: "white",
																border: "none",
																cursor: "pointer",
															}}
															onClick={() => {
																this.setState({
																	timer: 0,
																	payment_modal: {
																		active: true,
																		type: "sms",
																		data: _package,
																	},
																});
															}}
														>
															<div
																style={{ fontWeight: "bold", padding: "5px" }}
															>
																{_package.amount}₺
															</div>
														</button>
													</div>
												</div>
											))}
										</PackageSection>
									</div> */}
                  <div style={{ flex: "1" }}></div>

                  <div
                    style={{
                      flex: "1",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "20px",
                      border: "1px solid #b8d8e7",
                      height: "400px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ fontSize: "23px" }}>
                        {" "}
                        {this.context.t(`wallet.wp_package`)}
                      </h3>
                      <WhatsAppIcon
                        style={{
                          fontSize: 25,
                          marginRight: "10px",
                        }}
                      />
                    </div>
                    <PackageSection style={{ marginTop: "20px" }}>
                      {wp_packages?.map((_package) => (
                        <div
                          className="sms_btn_hover"
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "332px",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "17px",
                              fontSize: "16px",
                              color: "#434746",
                            }}
                          >{`${_package.quantity} WhatsApp`}</div>
                          <div style={{ marginBottom: "17px" }}>
                            <button
                              className="sms_btn_hover"
                              style={{
                                width: "110px",
                                padding: "4px",
                                borderRadius: "50px",
                                backgroundColor: "#00d38b",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({
                                  timer: 0,
                                  payment_modal: {
                                    active: true,
                                    type: "wp",
                                    data: _package,
                                  },
                                });
                              }}
                            >
                              <div
                                style={{ fontWeight: "bold", padding: "5px" }}
                              >
                                {_package.amount}₺
                              </div>
                            </button>
                          </div>
                        </div>
                      ))}
                    </PackageSection>
                  </div>
                  <div style={{ flex: "1" }}></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "3px",
                    marginTop: "10px",
                  }}
                >
                  <p style={{ fontWeight: "bold", marginTop: "25px" }}>
                    {this.context.t(`wallet.sms_price`)}{" "}
                  </p>

                  {/* <p style={{ marginTop: "15px" }}>
										<u>{this.context.t(`wallet.kdv`)}</u>
									</p> */}
                </div>
              </Container>
            ) : (
              <Container>
                {this.state.extra_licence.map((_package, index) => (
                  <div
                    style={{
                      marginTop: "40px",
                      maxWidth: "400px",
                      padding: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #DCDCDC",
                      borderRadius: "20px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {_package.name === "Whatsapp" && (
                        <WhatsAppIcon
                          style={{
                            fontSize: "23px",
                            color: "#25D366",
                            marginTop: "1px",
                          }}
                        />
                      )}
                      {_package.name === "Barkod" && (
                        <AiOutlineBarcode
                          style={{
                            fontSize: "23px",
                            marginTop: "1px",
                            color: "rgb(49, 137, 236)",
                          }}
                        />
                      )}
                      {_package.name === "OnlineLink" && (
                        <FaExternalLinkAlt
                          style={{
                            fontSize: "20px",
                            color: "#2F13BA",
                          }}
                        />
                      )}
                      <p>
                        {_package.name === "Whatsapp"
                          ? this.context.t(`wallet.whatsapp_extra_package`)
                          : _package.name === "OnlineLink"
                          ? this.context.t(`wallet.online_link_extra_package`)
                          : this.context.t(`wallet.bardcode_extra_package`)}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        key={index}
                        title={
                          _package.name === "OnlineLink" ? (
                            <h3>
                              {this.context.t(
                                `wallet.online_link_extra_package_tooltip`
                              )}
                            </h3>
                          ) : _package.name === "Barkod" ? (
                            <h3>
                              {this.context.t(
                                `wallet.bardcode_extra_package_tooltip`
                              )}
                            </h3>
                          ) : (
                            <h3>
                              {this.context.t(
                                `wallet.whatsapp_extra_package_tooltip`
                              )}
                            </h3>
                          )
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={4000}
                        placement="top-end"
                      >
                        <Typography
                          onMouseEnter={() =>
                            this.setState({
                              tooltipVisible: true,
                            })
                          }
                          onMouseLeave={() =>
                            this.setState({
                              tooltipVisible: false,
                            })
                          }
                        >
                          <GrStatusInfo style={{ cursor: "pointer" }} />
                        </Typography>
                      </Tooltip>

                      <buuton
                        className="sms_btn_hover"
                        style={{
                          padding: "8px",
                          borderRadius: "20px",
                          backgroundColor:
                            _package.name === "Whatsapp"
                              ? "#25D366"
                              : _package.name === "Barkod"
                              ? "rgb(49, 137, 236)"
                              : "#2F13BA",
                          width: "70px",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            timer: 0,
                            payment_modal: {
                              active: true,
                              type: "extralicense",
                              data: _package,
                            },
                          });
                        }}
                      >
                        {_package.credit_card_amount}$
                      </buuton>
                    </div>
                  </div>
                ))}
              </Container>
            )}
          </Column>
          <AlertDialog
            title={this.context.t(`wallet.check.title`)}
            open={payment_modal.active}
            textType
            fullWidth
            disableBackdropClick={false}
            closeHandler={() =>
              this.setState({
                payment_modal: { ...payment_modal, active: false },
              })
            }
            buttons={[
              {
                icon: "check",
                title: this.context.t(`wallet.check.confirmButtonTitle`),
                backgroundColor: "primary",
                textColor: "white",
                transitionEffect: true,
                timer: timer,
                onClick: () => this.buySingleItem(),
              },
              {
                icon: "close",
                title: this.context.t(`wallet.check.discardButtonTitle`),
                textColor: "grey",
              },
            ]}
          >
            {this.context.t(`wallet.check.alertText`, {
              credit_card_amount: payment_modal.data?.credit_card_amount,
              package_name:
                payment_modal.type === "sms"
                  ? `${payment_modal.data.quantity} SMS`
                  : payment_modal.type === "wp"
                  ? `${payment_modal.data.quantity} WhatsApp`
                  : payment_modal.data.name,
            })}
            {this.packageChangeAlertBuilder()}
          </AlertDialog>
          <Dialog
            open={this.state.modal_open}
            onClose={() =>
              this.setState({
                modal_open: false,
              })
            }
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ modal_open: false })}
              >
                <CloseIcon />
              </div>
              <Hero TLContainer>
                <HeroInner>
                  <span className="caption">
                    {this.context.t(`wallet.cardTitle`)}
                  </span>
                  <span className="wallet-value">
                    {balance.toFixed(2)}
                    {this.context.state.currency}
                  </span>

                  <div className="amount-input mb-2">
                    <MaskedInput
                      className="mb-0"
                      label={this.context.t(`wallet.amountInputLabel`)}
                      maskChar=""
                      mask="999999"
                      autoFocus
                      value={balance_modal.data}
                      onChange={(e) =>
                        this.setState({
                          balance_modal: {
                            ...balance_modal,
                            data: e.target.value ? parseInt(e.target.value) : 0,
                          },
                        })
                      }
                    />
                  </div>

                  <Button
                    iconComponent={Send}
                    title={this.context.t(`wallet.amountButtonTitle`)}
                    textColor="blue"
                    backgroundColor="white"
                    fullWidth
                    onClick={() => {
                      if (
                        balance_modal.data === null ||
                        balance_modal.data === 0
                      ) {
                        return toast.error(
                          this.context.t("wallet.balanceErrorToast")
                        );
                      }
                      this.setState({
                        open_payment_type_dialog: true,
                      });
                    }}
                  />
                </HeroInner>
              </Hero>
              {/* <div style={{ width: "80%" }}>
								<Link to="/payment-notify">
									<button
										className="sms_btn_hover"
										style={{
											width: "300px",
											padding: "8px",
											borderRadius: "50px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											margin: "10px",
											border: "none",
											cursor: "pointer",
											backgroundColor: "white",
										}}
									>
										<NotificationsIcon /> {this.context.t(`wallet.paymentForm`)}
									</button>
								</Link>
							</div> */}
            </div>
          </Dialog>
          <AlertDialog
            open={balance_modal.active}
            fullWidth
            maxWidth="md"
            disableBackdropClick={false}
            scroll="body"
            containerStyle={{ padding: 0 }}
            closeHandler={() =>
              this.setState({
                balance_modal: { ...balance_modal, active: false },
              })
            }
          >
            <div style={{ display: "flex", overflow: "hidden", width: "100%" }}>
              <BalanceIFrame src={balance_modal.url} />
            </div>
          </AlertDialog>
          <AlertDialog
            title={this.context.t(`wallet.paymentType.title`)}
            open={this.state.open_payment_type_dialog}
            disableBackdropClick={false}
            scroll="body"
            containerStyle={{ padding: 0 }}
            closeHandler={() =>
              this.setState({
                open_payment_type_dialog: false,
              })
            }
          >
            <Grid className="mt-1">
              <Column className="xs-12 sm-12 md-12 mb-2">
                <Button
                  iconComponent={CreditCard}
                  title={this.context.t(`wallet.paymentType.creditCard`)}
                  textColor="primary"
                  backgroundColor="primary-opacity"
                  fullWidth
                  onClick={() => {
                    this.setState({
                      open_payment_type_dialog: false,
                    });
                    this.balanceAdd();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12">
                <Button
                  iconComponent={AccountBalance}
                  title={this.context.t(`wallet.paymentType.eft`)}
                  textColor="secondary"
                  backgroundColor="secondary-opacity"
                  fullWidth
                  onClick={() => this.props.history.push("/payment-notify")}
                />
              </Column>
            </Grid>
          </AlertDialog>
        </Grid>
      </AuthContainer>
    );
  }

  packageChangeAlertBuilder = () => {
    const { payment_modal } = this.state;
    if (
      payment_modal.data?.subscription_id &&
      payment_modal.data?.subscription_id !==
        this.context.state.company_license.accounting
    ) {
      const remaining_day = CalculateLicense(
        this.context.state.company_license
      ).getRemainingDaysFromNow();

      switch (payment_modal.data.subscription_id) {
        // Platinium Confirm Alert
        case 1:
          return (
            <Alert severity="info" className="mt-2">
              <b>
                {this.context.t(`wallet.check.packageChangeAlert`, {
                  active_package:
                    this.context.state.company_license.accounting === 1
                      ? "Platinium"
                      : this.context.state.company_license.accounting === 2
                      ? "Gold"
                      : this.context.state.company_license.accounting === 3
                      ? "Diamond"
                      : "Silver",
                  new_package: this.state.select_data.name,
                  remaining_day,
                  formula: Math.round((remaining_day * 1.3) / 2.63),
                })}
              </b>
            </Alert>
          );
        // Gold Confirm Alert
        case 2:
          return (
            <Alert severity="info" className="mt-2">
              <b>
                {this.context.t(`wallet.check.packageChangeAlert`, {
                  active_package:
                    this.context.state.company_license.accounting === 1
                      ? "Platinium"
                      : this.context.state.company_license.accounting === 2
                      ? "Gold"
                      : this.context.state.company_license.accounting === 3
                      ? "Diamond"
                      : "Silver",
                  new_package: this.state.select_data.name,
                  remaining_day,
                  formula: Math.round((remaining_day * 2.63) / 1.3),
                })}
              </b>
            </Alert>
          );

        default:
          break;
      }
    }

    return false;
  };

  balanceAdd = () => {
    const { balance_modal } = this.state;
    Axios.post(`${this.context.api_endpoint}/company/balance/request`, {
      amount: balance_modal.data,
    })
      .then(({ data }) => {
        if (data.status === 200) {
          this.setState({
            balance_modal: {
              ...balance_modal,
              active: false, // Olur da iframe açarsak diye true'ya çekilecek
              url: data.message,
            },
          });

          window.open(
            `${data.message}`,
            "",
            "width=560,height=768,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes"
          );
        } else {
          toast.warning("İşlem gerçekleştirilemedi!");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  buySingleItem = () => {
    const { payment_modal } = this.state;

    if (payment_modal.data?.credit_card_amount)
      Axios.post(
        `${this.context.api_endpoint}/company/buy/${payment_modal.type}`,
        payment_modal.type === "license"
          ? {
              license_packet_id: payment_modal.data.id,
              accounting: payment_modal.data.subscription_id,
            }
          : payment_modal.type === "extralicense"
          ? { extra_license_packet_id: payment_modal.data?.id, months: 1 }
          : { id: payment_modal.data?.id }
      )
        .then(({ status, data: { data } }) => {
          status === 201 && toast.success(data);
          this.loadData();
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  loadData = () => {
    this.getBalance();

    this.getLicensePackages();
    this.getSmsPackages();
    this.getWhatsAppPackages();

    if (this.context.state.company_license.appointment_limitation > 0) {
      this.getRemainigAppoinment();
    }
  };

  // SMS Kredisi Bilgisi
  getBalance = () => {
    Axios.get(`${this.context.api_endpoint}/company/balance`).then(
      ({
        data: {
          data: { balance, license },
        },
      }) => {
        this.setState({ balance, license });

        this.context.dispatch({
          type: "SET_COMPANY_LICENSE",
          payload: { company_license: license },
        });
      }
    );
  };

  getRemainigAppoinment = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/remaining/appointment`
    ).then(({ data }) => {
      this.setState({ remaining_appointment: data.data.remaining_appointment });
    });
  };

  // Lisans Paketleri
  getLicensePackages = () => {
    Axios.get(`${this.context.api_endpoint}/company/show/license/packets`).then(
      ({ data: { data } }) => {
        this.setState({
          license_packages: [...data]?.sort((a, b) =>
            a.credit_card_amount > b.credit_card_amount ? 1 : -1
          ),
          check_accounting: data.length === 7 ? 1 : 0,
        });
      }
    );
  };

  // SMS Paketleri
  getSmsPackages = () => {
    Axios.get(`${this.context.api_endpoint}/company/show/sms/packets`).then(
      ({ data: { data } }) => {
        this.setState({ sms_packages: [...data] });
      }
    );
  };

  // WhatsApp Paketleri
  getWhatsAppPackages = () => {
    Axios.get(`${this.context.api_endpoint}/company/show/wp/packets`).then(
      ({ data: { data } }) => {
        this.setState({ wp_packages: [...data] });
      }
    );
  };
}

export default WalletIndex;

const Hero = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px;

  box-sizing: border-box;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);

  ${(props) =>
    props.TLContainer &&
    `
  background: rgb(77,111,149);
  background: linear-gradient(40deg, rgba(77,111,149,1) 0%, rgba(131,189,222,1) 100%);
  color: #FFF;
  `}

  ${(props) =>
    props.SMSContainer &&
    `
    box-shadow: none;
    background: #FAFAFA;
    color: #505050;
  `}
`;

const HeroInner = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  span.caption {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
  }

  span.wallet-value {
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 40px;
    letter-spacing: -0.25px;
    margin-bottom: 16px;
  }

  div.amount-input {
    width: 100%;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(20px);

    * {
      color: #fff;
    }
  }
`;

const PackageSection = styled.div`
  margin-bottom: 56px;

  h1 {
    font-size: 20px;
  }
`;

const BalanceIFrame = styled.iframe`
  flex: 1;
  border: none;
  height: -webkit-fill-available;
`;

const PACKAGE_LIST = {
  1: "Platinium",
  2: "Gold",
  3: "Diamond",
};
