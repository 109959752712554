import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import { useHistory } from "react-router-dom";

import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import AuthContainer from "../../../components/AuthContainer";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";

import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";

import Axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";

const AddDebit = () => {
	const context = useContext(AppContext);
	const history = useHistory();

	const [form, setForm] = useState({
		custname: null,
		date: new Date(),
		amount: null,
		explanation: null,
		json_id: null,
	});

	const [expense, setExpense] = useState([]);
	const [types_loaded, setTypesLoaded] = useState(false);

	//TODO: Insert Debit
	const insertDebit = () => {
		Axios.post(`${context.api_endpoint}/company/current/expense`, {
			amount: parseInt(form.amount),
			current_company: form.custname,
			current_name: form.custname,
			note: form.explanation,
			payment_date: moment(form.date).format("YYYY-MM-DD"),
			json_id: form.json_id ? form.json_id : null,
		})
			.then((response) => {
				if (response.status === 201) {
					history.push("/accounting/debit");
					toast.success(context.t(`['accounting/debit'].add.debitAdded`));
				}
			})
			.catch((e) => {
				if (e.response.status === 500) {
					toast.error(context.t(`['accounting/debit'].add.missingInfo`));
				} else if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.error(context.t(`['accounting/debit'].add.errorAccur`));
				}
			});
	};

	// TODO: Get Expense Types
	const getExpenseTypes = () => {
		Axios.get(`${context.api_endpoint}/company/expense/categories`)
			.then(async ({ data }) => {
				const types = JSON.parse(data.data.name);
				await setTypesLoaded(true);
				await setExpense([
					...Object.keys(types)
						.map((key) => {
							if (types[key] !== null && types[key] !== "") {
								return { json_id: key, label: types[key] };
							} else {
								return undefined;
							}
						})
						.filter((item) => item !== undefined),
				]);
			})
			.catch((e) => {
				if (e.response.status === 401) {
					toast.error(e.response.data.message);
				} else {
					toast.warning(e.response.data.message);
				}
			});
	};

	useEffect(() => {
		getExpenseTypes();
	}, []);

	return (
		<AuthContainer
			authorities={[1]}
			makePlanUpgrade={[2]}
			limited_permission="system"
		>
			<Grid>
				<Column className="xs-12 sm-12 md-12 lg-6">
					<PlatformImage src={require("../../../assets/images/expense.png")} />
				</Column>
				<Column className="xs-12 sm-12 md-12 lg-6">
					<Input
						required
						label={context.t(`["accounting/credit"].add.customerSelection`)}
						name="custname"
						value={form.custname}
						onChange={(e) => setForm({ ...form, custname: e.target.value })}
					/>
					<DatePickerInput
						required
						label={context.t(`["accounting/debit"].add.scheduledPaymentDate`)}
						value={form.date}
						onChange={(date) => setForm({ ...form, date: date })}
					/>
					<Input
						required
						type="number"
						label={context.t(`["accounting/debit"].add.enterAmount`) + " "}
						selected={form.amount !== null ? form.amount : ""}
						customInput={Input}
						decimalScale={2}
						suffix={context.state.currency}
						onChange={(e) => setForm({ ...form, amount: e.target.value })}
					/>
					<Select
						label={context.t(`['accounting/expense'].add.typeInputLabel`)}
						items={types_loaded === true ? expense : null}
						labelKey="label"
						valueKey="json_id"
						selected={form.json_id !== null ? form.json_id : ""}
						handler={(json_id) => setForm({ ...form, json_id: json_id })}
					/>
					<Input
						required
						label={context.t(`["accounting/debit"].add.explanation`)}
						value={form.explanation}
						multiline
						rows={4}
						onChange={(e) => {
							setForm({
								...form,
								explanation: e.target.value,
							});
						}}
					/>

					<Button
						title={context.t(`["accounting/debit"].add.saveReceivable`)}
						type="submit"
						size="md"
						fullWidth
						textColor="green"
						backgroundColor="green-opacity"
						onClick={() => {
							insertDebit();
						}}
					/>
				</Column>
			</Grid>
		</AuthContainer>
	);
};

export default AddDebit;

const PlatformImage = styled.img`
	width: 100%;
	max-height: 400px;
	object-fit: contain;
`;
