import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import Validate from "../../../functions/Validate";

import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import Axios from "axios";
import { toast } from "react-toastify";
import Input from "../../../theme/CustomMUI/Input";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
// import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";

const CostumerAdd = () => {
	const context = useContext(AppContext);
	const history = useHistory();

	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [receipt_note, setReceiptNote] = useState(null);
	const [foreing_number, setForeingNumber] = useState(false);
	// const [customer_add_modal, setCustomerAddModal] = useState(false);
	const [insertButtonClick, setInsertButtonClick] = useState(false);
	const [customer, setCustomer] = useState({
		name: "",
		surname: "",
		phone: null,
		dialCode: null,
		// secondary_phone: null,
		// s_dialCode: null,
		email: null,
		sex: null,
		description: null,
		birthday: null,
		marital_status: null,
		working_status: null,
		related_company: null,
		send_sms: true,

		//Picker için
		real_selected_birthday: null,
	});

	const searchCustomerByKey = async (key) => {
		let arr = [];
		await Axios.get(`${context.api_endpoint}/company/customers`, {
			params: { key },
		}).then(({ data }) => (arr = [...data.data.records]));
	};

	const validateCustomer = () => {
		let validate_for_phone_number;

		if (customer.secondary_phone === null) {
			validate_for_phone_number = {
				field: context.t(`['customers/add'].validate.phoneNumber`),
				value: customer.phone,
				condition: /\S{10}/,
			};
		} else {
			validate_for_phone_number = {
				field: context.t(`['customers/add'].validate.phoneNumber`),
				value: customer.phone,
				condition: /^\s*|\S{10}/,
			};
		}

		return Validate([
			{
				field: context.t(`['customers/add'].validate.name`),
				value: customer.name,
				condition: /.+[a-zA-Z ._-]/,
			},
			{
				field: context.t(`['customers/add'].validate.surname`),
				value: customer.surname,
				condition: /.+[a-zA-Z ._-]/,
			},
			validate_for_phone_number,
		]);
	};

	const insertCustomer = () => {
		let birthday =
			customer.real_selected_birthday !== null
				? customer.real_selected_birthday.toISOString()
				: null;
		if (customer.birthday !== null && customer.birthday.length === 9) {
			const birthday_arr = customer.birthday.split("/");
			birthday = new Date(
				`${birthday_arr[2]}-${birthday_arr[1]}-${birthday_arr[0]}`
			);
			birthday.setHours(0, 0, 0, 0);
			birthday = birthday.toISOString();
		}
		validateCustomer() &&
			Axios.post(`${context.api_endpoint}/company/customer/insert`, {
				...customer,
				phone: customer.phone?.slice(customer.dialCode.length),
				dialCode: customer.dialCode,
				secondary_phone: customer.secondary_phone?.slice(
					customer.s_dialCode.length
				),
				s_dialCode: customer.s_dialCode,
				birthday,
			})
				.then((response) => {
					if (response.status === 200) {
						toast.success(context.t(`component.CADialog.insertToast`));
						console.log(response.data.data.id);
						setSelectedCustomer(response.data.data.id);
						// props.createdCustomerHandler(response.data.data);
					}
				})
				.catch((e) => {
					toast.error(e.response.data.message);
				});
	};

	// const searchCustomerByKey = async (key) => {
	//   let arr = [];
	//   await Axios.get(`${context.api_endpoint}/company/customer/search`, {
	//     params: { key },
	//   }).then(({ data }) => (arr = [...data.data.records]));

	//   return arr.map((item) => {
	//     return {
	//       id: item.id,
	//       full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
	//       send_sms: item.send_sms,
	//       description: item.description,
	//     };
	//   });
	// };

	const addReceiptBySelectedCustomer = () => {
		console.log(selectedCustomer);
		Axios.post(`${context.api_endpoint}/company/receipt/insert`, {
			customer_id: selectedCustomer,
			note: receipt_note,
		})
			.then((response) => {
				if (response.status === 201) {
					setInsertButtonClick(true);
					toast.success(context.t(`['receipts/add'].insertToast`));
					history.push(`/receipts/detail/${response.data.data.id}`);
				}
			})
			.catch((e) => {
				toast.error(e.response.data.message);
				setInsertButtonClick(false);
			});
	};

	// Insert Random Customer
	// const insertRandomCustomer = () => {
	//   Axios.post(`${context.api_endpoint}/company/customer/insert/walkin`)
	//     .then((response) => {
	//       // console.log(response)
	//       if (response.status === 201) {
	//         // console.log(response.data.data.id)
	//         setSelectedCustomer(response.data.data.id);
	//       }
	//     })
	//     .catch((e) => {
	//       toast.warning(e.response.data.message);
	//     });
	// };

	useEffect(() => {
		setCustomer({
			name: "",
			surname: "",
			phone: null,
			secondary_phone: null,
			email: null,
			sex: null,
			description: null,
			birthday: null,
			marital_status: null,
			working_status: null,
			related_company: null,
			send_sms: true,

			//Picker için
			real_selected_birthday: null,
		});
	}, []);

	useEffect(() => {
		if (selectedCustomer !== null) {
			addReceiptBySelectedCustomer();
		}
	}, [selectedCustomer]);

	return (
		<AuthContainer
			makePlanUpgrade={[2]}
			authorities={[0, 1]}
			limited_permission="sales"
		>
			<Grid>
				<Column className="xs-12 sm-12 md-12 lg-12 xl-2"></Column>
				<Column className="xs-12 sm-12 md-12 lg-12 xl-8">
					<ReceiptTypeContainer>
						<img
							src={require("../../../assets/images/manypixels/profiling.svg")}
							alt="Müşteri Seç"
						/>
						<Grid>
							<Column className="xs-12 sm-12 md-12 lg-12 xl-12">
								<Input
									required
									label={context.t(`component.CADialog.nameInputLabel`)}
									placeholder={context.t(
										`component.CADialog.nameInputPlaceholder`
									)}
									value={customer.name !== null ? customer.name : ""}
									onChange={(e) =>
										setCustomer({
											...customer,
											name: e.target.value,
										})
									}
								/>
							</Column>
							<Column className="xs-12 sm-12 md-12 lg-12 xl-12">
								<Input
									required
									label={context.t(`component.CADialog.surnameInputLabel`)}
									placeholder={context.t(
										`component.CADialog.surnameInputPlaceholder`
									)}
									value={customer.surname !== null ? customer.surname : ""}
									onChange={(e) =>
										setCustomer({
											...customer,
											surname: e.target.value,
										})
									}
								/>
							</Column>
							<Column className="xs-12 sm-12 md-12 lg-12 xl-12">
								<PhoneInput
									countryCodeEditable={false}
									value={customer.phone !== null ? customer.phone : ""}
									onChange={(value, data, event, formattedValue) => {
										setCustomer({
											...customer,
											phone: value,
											dialCode: data.dialCode,
										});
									}}
								/>
							</Column>
							<Column className="xs-12 sm-12 md-12 lg-12 xl-12">
								<Column className="xs-12 sm-12 md-12 lg-6 xl-6">
									<CSwitch
										label={context.t(`['customers/add'].genderMale`)}
										labelLeft={context.t(`['customers/add'].genderFemale`)}
										checked={!!customer.sex}
										color="primary"
										closeColor="purple"
										onChange={(checked) => {
											setCustomer({
												...customer,
												working_status: null,
												sex: checked.target.checked === true ? 1 : 0,
											});
										}}
									/>
								</Column>
							</Column>
							<Column className="xs-12 sm-12 md-12 lg-12 xl-12">
								<Input
									className="mb-1"
									label={context.t(`['receipts/add'].receiptNoteInputLabel`)}
									multiline
									rows={3}
									onChange={(e) => setReceiptNote(e.target.value)}
								/>
							</Column>
							<Column className="xs-12 sm-12 md-12 lg-12 xl-12">
								<Button
									icon="how_to_reg"
									title={context.t(
										`['receipts/add'].openReceiptWithCreatedNewCustomerButtonTitle`
									)}
									size="md"
									backgroundColor="primary-opacity"
									textColor="primary"
									fullWidth
									disabled={insertButtonClick}
									onClick={() => {
										insertCustomer();
										// validateCustomer();
									}}
								/>
							</Column>
						</Grid>
					</ReceiptTypeContainer>
				</Column>

				<Column className="xs-12 sm-12 md-12 lg-12 xl-2"></Column>
			</Grid>

			{/* <CustomerAddDialog
        open={customer_add_modal}
        createdCustomerHandler={async (data) => {
          addReceiptBySelectedCustomer();
          setSelectedCustomer(data.id);
        }}
        closeHandler={() => setCustomerAddModal(false)}
      /> */}
		</AuthContainer>
	);
};

export default CostumerAdd;

const ReceiptTypeContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: ${window.screen.width < 576 ? "column" : "row"};
	align-items: center;
	justify-content: center;
	margin: 32px 0;

	img {
		height: 180px;
		object-fit: contain;
		margin: 0 32px 32px;
	}

	div.form {
		flex: 1;
	}
`;
