import React, { useEffect, useState } from "react";
import { Button, Input, makeStyles } from "@material-ui/core";
import { TfiInfoAlt } from "react-icons/tfi";
import { Tooltip, Typography } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import { MdDriveFolderUpload } from "react-icons/md";
import Dialog from "@material-ui/core/Dialog";
import styled, { keyframes } from "styled-components";

const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/jpeg",
  "image/jpg",
  "image/png",
];

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const UploadInput = ({ fileHandlerProp }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    if (selectedFile) {
      fileHandlerProp(selectedFile);
    }
  }, [selectedFile]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip
        title={
          <p style={{ fontSize: "14px" }}>
            You can select and send files in your WhatsApp transmissions.
          </p>
        }
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={4000}
        placement="left"
      >
        <Typography
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          <TfiInfoAlt
            size={15}
            style={{
              textAlign: "center",
              marginRight: "8px",
              cursor: "pointer",
            }}
          />
        </Typography>
      </Tooltip>
      <div>
        <input
          accept={ALLOWED_FILE_TYPES.join(",")}
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            style={{ backgroundColor: "#2196f3", fontSize: "13px" }}
          >
            <MdDriveFolderUpload size={17} style={{ marginRight: "5px" }} />{" "}
            Select File
          </Button>
        </label>
        <Input
          placeholder="File Name"
          value={selectedFile ? selectedFile.name : ""}
          readOnly
        />
      </div>
      {selectedFile &&
        (selectedFile.type === "image/jpeg" ||
          selectedFile.type === "image/jpg" ||
          selectedFile.type === "image/png") && (
          <img
            onClick={() => setDialogOpen(true)}
            src={URL.createObjectURL(selectedFile)}
            style={{
              cursor: "pointer",
              objectFit: "contain",
              width: "50px",
              height: "50px",
            }}
          />
        )}
      {selectedFile && (
        <VscClose
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedFile(null)}
          size={17}
        />
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <AlignContainer>
          {selectedFile &&
            (selectedFile.type === "image/jpeg" ||
              selectedFile.type === "image/jpg" ||
              selectedFile.type === "image/png") && (
              <img
                onClick={() => setDialogOpen(false)}
                src={URL.createObjectURL(selectedFile)}
                style={{
                  objectFit: "contain",
                  width: "600px",
                  height: "600px",
                }}
              />
            )}
        </AlignContainer>
      </Dialog>
    </div>
  );
};

export default UploadInput;

const AlignContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
