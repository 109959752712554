import React from "react";
import { Column, Grid } from "../../../../theme/Grid";
import { MdSupportAgent } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { VscWorkspaceTrusted } from "react-icons/vsc";

function Statisctic() {
  return (
    <div
      style={{
        padding: "50px",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
      }}
      className="container"
    >
      <Grid style={{ marginTop: "50px" }} item xs={12} sm={12} md={12}>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: 1.4,
            color: "#0b3052",
            height: "80px",
          }}
        >
          The Best <span style={{ color: "#ff006a" }}>Salon</span> Software
        </p>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <MdSupportAgent size={60} />
            </div>
          </div>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Instant Live Support
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            Our team is ready to support you whenever you need! You can reach us
            24/7 via our contact information.
          </p>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <FaMobileAlt size={60} />
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Easy to Use
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            You can access your salon via web panel or mobile application. Enjoy
            the convenience of being able to check all information about your
            device at any time.
          </p>
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-auto mt-4">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                width: "90px",
                height: "90px",
                backgroundColor: "white",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              }}
            >
              <VscWorkspaceTrusted size={60} />
            </div>
          </div>
          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "5px",
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            100% Trust
          </p>

          <p
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "15px",
              color: "#696969",
            }}
          >
            All your data is safe with us! Everything about you and your salon
            We store and protect information safely.
          </p>
        </Column>
      </Grid>
    </div>
  );
}

export default Statisctic;
