/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import HoverHelperText from "../HoverHelperText";

const StaffSelect = (props) => {
  useEffect(() => {});
  return (
    <div className={props.className ? props.className : "mb-2"}>
      {props.hoverHelperText && (
        <HoverHelperText hoverHelperText={props.hoverHelperText} />
      )}
      <TextField
        select
        disabled={props.disabled}
        label={props.label}
        value={props.selected ? props.selected : ""}
        onChange={
          props.handler
            ? (e) =>
                props.returnFullObject === true
                  ? props.handler(
                      props.items.filter(
                        (item) => item[props.valueKey] == e.target.value
                      )[0]
                    )
                  : props.handler(`${e.target.value}`)
            : () => console.log("handler is not defined!")
        }
        helperText={props.helperText}
        required={props.required}
        fullWidth
        size={props.size}
        variant={"outlined"}
      >
        {props.items &&
          props.items.map((option, index) => {
            if (option.deleted_at !== null && option.id != props.selected) {
              return null;
            } else {
              return (
                <MenuItem key={index} value={`${option[props.valueKey]}`}>
                  {option.deleted_at === null ? (
                    <span>{option[props.labelKey]}</span>
                  ) : (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {option[props.labelKey]} (Silinmiş)
                    </span>
                  )}
                </MenuItem>
              );
            }
          })}
      </TextField>
    </div>
  );
};

export default React.memo(StaffSelect);
