import React from "react";
import AppContext from "../../../context/store";
import styled from "styled-components";

import { Column, Grid } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";

import {
  getCities,
  getDistrictsByCityID,
} from "../../../functions/CityDistrict";
import { getCurrencies } from "../../../functions/Currency";
import Button from "../../../theme/Button";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import moment from "moment";
import TimeSelect from "../../../theme/TimeSelect";
import QRCode from "react-qr-code";
import { Alert } from "@material-ui/lab";
import { getCountryCode } from "../../../functions/CountryCode";

class SettingsIndex extends React.Component {
  static contextType = AppContext;

  state = {
    business: {
      id: null,
      name: null,
      mail: null,
      phone: null,
      province: null,
      district: null,
      address: null,
      website: null,
      map: null,
      instagram: null,
      facebook: null,
      active_sms_package: null,
      password: null,
      gallery: null,
      staffs: null,
      customers: null,
      services: null,
      receipts: null,
      message_settings: null,
      company_settings: null,
      hours: null,

      // show_amount_on_appointment: null,
      // show_customer_comments_at_url: null,

      about: null,
    },

    hours: {
      start_hour: null,
      end_hour: null,
      slice: null,
    },

    hours_for_send: {
      start_hour: null,
      end_hour: null,
      slice: null,
    },

    hours_change_approval_modal: false,
    is_change: false,

    company_categories: [],
    selected_company_category: null,

    active_steps: 0,
    show_how_to_dialog: false,

    logo_src: null,

    qr_code_dialog: false,

    currency: this.context.state.currency,

    currency_code: this.context.state.currencyCode,

    country_code: this.context.state.countryCode,

    dial_code: this.context.state.dialCode,
  };

  componentDidMount() {
    this.getCompanyDetails();
    this.getCompanyCategories();
    this.getLogoSrc();
  }

  getCompanyDetails = () => {
    Axios.get(`${this.context.api_endpoint}/company/itself`).then(
      (response) => {
        const {
          data: { data },
        } = response;
        this.setState({
          business: {
            ...this.state.business,
            ...data,
            company_settings: {
              ...this.state.company_settings,
              category_id: data.category_id,
            },
          },
          hours: { ...data.hours },
          hours_for_send: { ...data.hours },
        });
      }
    );
  };

  updateCompanyDetails = () => {
    const {
      business,
      selected_company_category,
      currency,
      dial_code,
      currency_code,
    } = this.state;
    if (!this.validateHours()) {
      return toast.warning(
        this.context.t(`['settings/hours'].compareErrorToast`)
      );
    }
    this.validateCompany() &&
      Axios.put(`${this.context.api_endpoint}/company/update`, {
        ...business,
        province: parseInt(business.province),
        category_id: parseInt(
          selected_company_category
            ? selected_company_category
            : business.company_settings.category_id
        ),
        hour: this.state.hours_for_send,
        any_hour_changed: this.state.is_change,
        currency: currency,
        dialCode: dial_code,
        currencyCode: currency_code,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(this.context.t(`settings.updateToast`));
            this.context.dispatch({
              type: "SET_COMPANY_SHIFT",
              payload: { ...this.state.hours },
            });
            this.setState({
              hours: { ...this.state.hours_for_send },
              is_change: false,
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401 || e.response.status === 400) {
            toast.error(e.response.data.message);
          }
        });
  };

  checkForHours = () => {
    this.setState({
      hours_change_approval_modal: true,
    });
  };

  getCompanyCategories = () => {
    Axios.get(`${this.context.api_endpoint}/company/category/all`).then(
      ({ data }) => {
        this.setState({
          company_categories: [...data.data],
        });
      }
    );
  };

  updateWorkHours = () => {
    const { start_hour, end_hour } = this.state.hours;

    if (this.validateHours())
      if (
        moment(`1970-01-01 ${end_hour}:00`).isAfter(
          moment(`1970-01-01 ${start_hour}:00`)
        )
      )
        Axios.put(`${this.context.api_endpoint}/company/update/hours`, {
          ...this.state.hours,
        })
          .then((response) => {
            if (response.status === 201) {
              this.context.dispatch({
                type: "SET_COMPANY_SHIFT",
                payload: { ...this.state.hours },
              });
              toast.success(this.context.t(`['settings/hours'].updateToast`));
              this.getCompanyDetails();
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            }
          });
      else
        toast.warning(this.context.t(`['settings/hours'].compareErrorToast`));
  };

  uploadPhoto = async (file) => {
    const Form = new FormData();
    toast.warning("Logo hazırlanıyor...");
    await Form.append("files", file.target.files[0]);
    Axios.post(
      `${this.context.api_endpoint}/company/service/gallery/logo/insert`,
      Form,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
      .then((data) => {
        if (data.status === 201) {
          toast.success("Logo başarıyla yüklendi.");
          this.setState({
            logo_src: data.data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getLogoSrc = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`)
      .then(({ data }) => {
        this.setState({
          logo_src: data.data.settings.logo_src,
        });
      })
      .catch((e) => console.log(e));
  };

  validateHours = () => {
    const { hours } = this.state;
    return Validate([
      {
        value: hours.start_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: this.context.t(`['settings/hours'].startInputLabel`),
      },
      {
        value: hours.end_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: this.context.t(`['settings/hours'].endInputLabel`),
      },
      {
        value: hours.slice,
        condition: /^.+$/,
        field: this.context.t(`['settings/hours'].periodBetweenInputLabel`),
      },
    ]);
  };

  render() {
    const {
      business,
      company_categories,
      selected_company_category,
      hours_for_send,
      hours,
    } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="system">
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            <Input
              label={this.context.t(`settings.urlInputLabel`)}
              readOnly={true}
              disabled={true}
              value={
                business.id !== null
                  ? `${this.context.app.WEB}/isletme/${business.id}`
                  : ""
              }
              helperText={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    icon="public"
                    title={this.context.t(`settings.openOnBrowserButtonTitle`)}
                    onClick={() =>
                      window.open(
                        `${this.context.app.WEB}/isletme/${business.id}`,
                        "_blank"
                      )
                    }
                  />
                  <CopyToClipboard
                    text={`${this.context.app.WEB}/isletme/${business.id}`}
                    onCopy={(copiedValue) => {
                      toast.success(this.context.t(`settings.copyLinkToast`));
                    }}
                  >
                    <Button
                      icon="file_copy"
                      title={this.context.t(`settings.copyLinkButtonTitle`)}
                      textColor="grey"
                    />
                  </CopyToClipboard>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      icon="crop_free"
                      title="QR Kodu Gör"
                      onClick={() => this.setState({ qr_code_dialog: true })}
                    />
                  </div>
                </div>
              }
            />
            <Input
              label={this.context.t(`settings.nameInputLabel`)}
              value={business.name !== null ? business.name : ""}
              onChange={(e) =>
                this.setState({
                  business: { ...business, name: e.target.value },
                })
              }
            />
            <MaskedInput
              required
              mask="999 999 9999"
              maskChar=""
              label={this.context.t(`settings.phoneInputLabel`)}
              startAdornment="0"
              value={business.phone !== null ? business.phone : ""}
              placeholder="500 000 0000"
              onChange={(e) =>
                this.setState({
                  business: {
                    ...this.state.business,
                    phone: e.target.value.replace(/\s/g, ""),
                  },
                })
              }
            />
            <Input
              label={this.context.t(`settings.mailInputLabel`)}
              value={business.mail !== null ? business.mail : ""}
              onChange={(e) =>
                this.setState({
                  business: { ...business, mail: e.target.value },
                })
              }
            />
            <Grid className="no-gutters-all">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                <TimeSelect
                  label={this.context.t(`['settings/hours'].startInputLabel`)}
                  type="all"
                  value={
                    hours_for_send.start_hour !== null
                      ? hours_for_send.start_hour
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={5}
                  handler={(time) => {
                    this.setState({
                      hours_for_send: { ...hours_for_send, start_hour: time },
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                <TimeSelect
                  label={this.context.t(`['settings/hours'].endInputLabel`)}
                  type="all"
                  value={
                    hours_for_send.end_hour !== null
                      ? hours_for_send.end_hour
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={5}
                  handler={(time) => {
                    this.setState({
                      hours_for_send: { ...hours_for_send, end_hour: time },
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                <Select
                  label={this.context.t(
                    `['settings/hours'].periodBetweenInputLabel`
                  )}
                  items={[...new Array(47).keys()].map((i) => ({
                    value: (i + 2) * 5,
                  }))}
                  selected={
                    hours_for_send.slice !== null ? hours_for_send.slice : 10
                  }
                  labelKey="value"
                  valueKey="value"
                  handler={(e) => {
                    this.setState({
                      hours_for_send: {
                        ...hours_for_send,
                        slice: e.substring(0, 1) !== "0" ? e : null,
                      },
                    });
                  }}
                />
              </Column>
            </Grid>

            <Select
              label={this.context.t(`[settings/hours].businessCategory`)}
              items={company_categories}
              selected={
                selected_company_category
                  ? selected_company_category
                  : business.company_settings
                  ? business.company_settings.category_id
                  : ""
              }
              labelKey="name"
              valueKey="id"
              handler={(category) =>
                this.setState({
                  selected_company_category: category,
                })
              }
            />
            <Input
              label={this.context.t(`settings.aboutCompanyLabel`)}
              value={business.about !== null ? business.about : ""}
              rows={6}
              multiline={true}
              onChange={(e) =>
                this.setState({
                  business: { ...business, about: e.target.value ?? null },
                })
              }
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <Select
                disabled
                label="Country Code"
                items={getCountryCode()}
                selected={
                  getCountryCode().filter(
                    (curr) => curr?.code === this.state.country_code
                  )[0]?.code
                }
                labelKey="name"
                valueKey="code"
                handler={(item) => {}}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <Select
                dialCode={true}
                label="Dial Code"
                items={getCountryCode()}
                selected={
                  getCountryCode().filter(
                    (curr) => curr?.dial_code === this.state.dial_code
                  )[0]?.dial_code
                }
                labelKey="dial_code"
                valueKey="dial_code"
                handler={(item) => {
                  this.setState({
                    dial_code: getCountryCode().filter(
                      (curr) => curr.dial_code === item
                    )[0]?.dial_code,
                  });
                  this.context.dispatch({
                    type: "SET_DIAL_CODE",
                    payload: {
                      dialCode: getCountryCode().filter(
                        (curr) => curr.dial_code === item
                      )[0]?.dial_code,
                    },
                  });
                }}
              />
            </Column>
            <Select
              currency={true}
              label="Currency"
              items={getCurrencies()}
              selected={
                getCurrencies().filter(
                  (curr) => curr.code === this.state.currency_code
                )[0]?.code
              }
              labelKey="name"
              valueKey="code"
              handler={(item) => {
                this.setState({
                  currency: getCurrencies().filter(
                    (curr) => curr.code === item
                  )[0].symbol,
                  currency_code: getCurrencies().filter(
                    (curr) => curr.code === item
                  )[0].code,
                });
                this.context.dispatch({
                  type: "SET_CURRENCY",
                  payload: {
                    currency: getCurrencies().filter(
                      (curr) => curr.code === item
                    )[0].symbol,
                  },
                });
                this.context.dispatch({
                  type: "SET_CURRENCY_CODE",
                  payload: {
                    currencyCode: getCurrencies().filter(
                      (curr) => curr.code === item
                    )[0].code,
                  },
                });
              }}
            />
            <Select
              label={this.context.t(`settings.cityInputLabel`)}
              items={getCities()}
              selected={business.province !== null ? business.province : ""}
              labelKey="name"
              valueKey="id"
              handler={(province) =>
                this.setState({
                  business: { ...business, province, district: null },
                })
              }
            />

            <Select
              label={this.context.t(`settings.districtInputLabel`)}
              items={getDistrictsByCityID(business.province)}
              selected={business.district !== null ? business.district : ""}
              labelKey="name"
              valueKey="name"
              handler={(district) =>
                this.setState({
                  business: { ...business, district },
                })
              }
            />

            <Input
              required
              label={this.context.t(`settings.addressInputLabel`)}
              value={business.address !== null ? business.address : ""}
              rows={6}
              multiline={true}
              onChange={(e) =>
                this.setState({
                  business: { ...business, address: e.target.value ?? null },
                })
              }
            />

            <Input
              label={this.context.t(`settings.mapsInputLabel`)}
              placeholder={this.context.t(`settings.mapsInputHint`)}
              value={business.map !== null ? business.map : ""}
              helperText={
                <div>
                  <Button
                    icon="my_location"
                    title={this.context.t(
                      `settings.currentLocationButtonTitle`
                    )}
                    textColor="primary"
                    onClick={async () => {
                      if (!navigator.geolocation) {
                        toast.warning(
                          this.context.t(`settings.geolocationErrorToast`)
                        );
                      } else {
                        navigator.geolocation.getCurrentPosition(
                          (position) => {
                            const lat = position.coords.latitude;
                            const lng = position.coords.longitude;

                            this.setState({
                              business: {
                                ...business,
                                map: `${lat}, ${lng}`,
                              },
                            });
                          },
                          () =>
                            toast.warning(
                              this.context.t(`settings.locationErrorToast`)
                            ),
                          { enableHighAccuracy: true, maximumAge: 10000 }
                        );
                      }
                    }}
                  />
                  <Button
                    icon="help"
                    title={this.context.t(`[platform/categories].howto.title`)}
                    textColor="primary"
                    onClick={async () =>
                      this.setState({ show_how_to_dialog: true })
                    }
                  />
                </div>
              }
              onChange={(e) =>
                this.setState({
                  business: { ...business, map: e.target.value },
                })
              }
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            <Input
              label={this.context.t(`settings.instagramInputLabel`)}
              helperText={this.context.t(`settings.withoutAtHint`)}
              value={business.instagram !== null ? business.instagram : ""}
              onChange={(e) =>
                this.setState({
                  business: { ...business, instagram: e.target.value },
                })
              }
            />
            <Input
              label={this.context.t(`settings.facebookInputLabel`)}
              helperText={this.context.t(`settings.withoutAtHint`)}
              value={business.facebook !== null ? business.facebook : ""}
              onChange={(e) =>
                this.setState({
                  business: { ...business, facebook: e.target.value },
                })
              }
            />
            <Input
              label={this.context.t(`settings.websiteInputLabel`)}
              value={business.website !== null ? business.website : ""}
              onChange={(e) =>
                this.setState({
                  business: { ...business, website: e.target.value },
                })
              }
            />
            <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
              {this.state.logo_src ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80%",
                      height: "15vh",
                      overflow: "hidden",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      style={{ width: "70%", objectFit: "cover" }}
                      src={`${this.context.api_endpoint}/${this.context.state.company_id}/${this.state.logo_src}`}
                    />
                  </div>
                </div>
              ) : (
                <p style={{ textAlign: "center" }} className="mb-2">
                  {this.context.t(`settings.img.imgNotFound`)}
                </p>
              )}

              <input
                id="LogoUploadInput"
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={(e) => {
                  this.uploadPhoto(e);
                }}
              />
              <ImageUploadBox htmlFor="LogoUploadInput">
                <i className="material-icons">cloud_upload</i>{" "}
                {`${
                  this.state.logo_src
                    ? this.context.t(`settings.img.uploadPhoto`)
                    : this.context.t(`settings.img.clickToUpload`)
                }`}
              </ImageUploadBox>
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <Button
              icon="update"
              title={this.context.t(`settings.updateButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => {
                if (
                  !(
                    hours_for_send.end_hour !== hours.end_hour ||
                    hours_for_send.start_hour !== hours.start_hour ||
                    hours_for_send.slice !== hours.slice
                  )
                ) {
                  this.updateCompanyDetails();
                } else {
                  this.checkForHours();
                }
              }}
            />
          </Column>
          <this.HowToDialog />
        </Grid>
        <AlertDialog
          title={this.context.t(`settings.img.changeStaffHours`)}
          open={this.state.hours_change_approval_modal}
          disableBackdropClick={false}
          closeHandler={() =>
            this.setState({ hours_change_approval_modal: false })
          }
          buttons={[
            {
              icon: "check",
              title: this.context.t(`settings.img.approve`),
              textColor: "white",
              backgroundColor: "primary",
              timer: 5,
              onClick: async () => {
                await this.setState({
                  is_change: true,
                });
                await this.updateCompanyDetails();
              },
            },
            {
              icon: "clear",
              title: this.context.t(`settings.img.clear`),
              textColor: "black",
              outlined: true,
            },
          ]}
        >
          <Alert severity="warning" className="mb-3">
            {this.context.t(`settings.img.expHours`)}
          </Alert>
        </AlertDialog>
        <this.QRCodeDialog />
      </AuthContainer>
    );
  }

  HowToDialog = () => {
    const steps = ["1. adım", "2. adım", "3. adım", "4. adım"];
    return (
      <AlertDialog
        title={""}
        open={this.state.show_how_to_dialog}
        fullWidth
        textType
        disableBackdropClick={false}
        closeHandler={() => this.setState({ show_how_to_dialog: false })}
        buttons={[
          {
            //icon: "okay",
            title: "Kapat",
            textColor: "white",
            backgroundColor: "primary",
          },
        ]}
      >
        <Grid>
          <Grid>
            <Stepper
              activeStep={this.state.active_steps}
              alternativeLabel
              style={{ width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <div style={{ width: "100%", height: "400px" }}>
            <img
              src={require(`../../../assets/images/step_${
                this.state.active_steps + 1
              }.png`)}
              alt=""
              style={{ width: "100%", height: "400px", objectFit: "fill" }}
            />
          </div>
          <Grid>
            <Button
              disabled={this.state.active_steps === 0}
              title={"Back"}
              backgroundColor="blue"
              textColor="white"
              onClick={() =>
                this.setState((prev) => ({
                  active_steps: prev.active_steps - 1,
                }))
              }
            />
            <Column className="xs-auto sm-auto md-auto lg-auto xl-auto"></Column>
            <Button
              disabled={this.state.active_steps === steps.length - 1}
              title={"Next"}
              backgroundColor="blue"
              textColor="white"
              onClick={() =>
                this.setState((prev) => ({
                  active_steps: prev.active_steps + 1,
                }))
              }
            />
          </Grid>
        </Grid>
      </AlertDialog>
    );
  };

  QRCodeDialog = () => {
    return (
      <AlertDialog
        open={this.state.qr_code_dialog}
        disableBackdropClick={false}
        closeHandler={() => this.setState({ qr_code_dialog: false })}
        buttons={[
          {
            icon: "close",
            title: "Kapat",
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <div
          style={{
            height: "auto",
            margin: "50px 40px",
            maxWidth: 256,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${this.context.app.WEB}/isletme/${this.state.business.id}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </AlertDialog>
    );
  };

  validateCompany = () => {
    const { business } = this.state;
    return Validate([
      {
        field: this.context.t("settings.validation.companyName"),
        value: business.name,
        condition: /[a-zA-Z0-9]+/,
      },
      {
        field: this.context.t("settings.validation.eMail"),
        value: business.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
      {
        field: this.context.t("settings.validation.phoneNumber"),
        value: business.phone,
        condition: /[0-9]{10}/,
      },
      {
        field: this.context.t("settings.validation.city"),
        value: business.phone,
        condition: /\d*/,
      },
      {
        field: this.context.t("settings.validation.district"),
        value: business.district,
        condition: /\w*/,
      },
      {
        field: this.context.t("settings.validation.companyAddress"),
        value: business.address,
        condition: /[a-zA-Z0-9]+/,
      },
    ]);
  };
}

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

export default SettingsIndex;
