const langEN = {
  langText: "English",

  /**
   * İlk açılan pop-up içeriği
   */

  definePopup: {
    title: "We define the best features for you.",
    description:
      "In addition to the appointment calendar in your business, would you like to use our admission and accounting feature?",
    buttons: {
      approve: "Yes",
      cancel: "No",
    },
    succesMessage:
      "Your preference has been successfully saved. Please login again.",
  },

  /**
   * Mesaj Karakter sayısı hesaplayan fonksiyon
   */

  charMessage: {
    char: "Character",
    excededChar: "You have exceeded the character limit",
    left: "Remaining",
    min: "min",
  },

  /**
   * Giriş sayfasının bulunduğu key.
   * https://app.salonrandevu.com/login
   */
  login: {
    title: "Log in",
    mailPhoneInputLabel: "E-mail / Phone number",
    passInputLabel: "Password",
    loginButtonTitle: "Log in",
    registerButtonTitle: "Haven’t you got an account yet? Register now!",
    forgotPassButtonTitle: "Forgot password / Reset password",
    rememberMe: "Remember Me",

    license: {
      // Modal
      title: "Your licence has expired!",
      description:
        "Your panel usage period is over. By purchasing the most suitable package for your business, you may continue to use your panel.   ",
      buttonTitleUK: " Call us now to buy package - UK: [number]",
      buttonTitleUSA: " Call us now to buy package - USA: [number]",

      standart: {
        type: "1 USER",
        title: "SOLO",
        items: [
          "Client Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },

      essentials: {
        type: "5 USER",
        title: "Essentials",
        items: [
          "Client Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },

      deluxe: {
        type: "9 USER",
        title: "Deluxe",
        items: [
          "Client Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },

      premium: {
        type: "UNLIMITED",
        title: "PREMIUM",
        items: [
          "Client Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },
    },

    downloadApp: "Download the app now by scanning the QR code.",
  },

  /**
   * Kayıt sayfasının bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  register: {
    title: "Register",
    trialText: " All features are FREE OF CHARGE for 7days!",
    noPaymentText: "No payment, No commitment, No cancellation fees.",
    privacyPolicyText: "I have read and accepted privacy policy",

    continueButtonTitle: "Continue",
    completeButtonTitle: "Complete registration",
    alreadyUserButtonTitle: "Already a member? Sign in!",

    companyNameInputLabel: "Company name",
    phoneInputLabel: "Phone number",
    campaingCodeInputLabel: " If you have a campaign code, enter please",
    staffNameInputLabel: "Official’s Name",
    staffSurnameInputLabel: "Official’s surname",
    staffMailInputLabel: "E-mail address",
    passwordInputLabel: "Password",
  },

  /**
   * Şifremi unuttum sayfasının bulunduğu key.
   * https://app.salonrandevu.com/forgot-pass
   */
  forgotPass: {
    title: "Forgot password & Reset",

    stepText: "STEP",
    firstStepText:
      "In this step, your WhatsApp number linked to your employee profile should be entered in the field below. You will receive a message with verification code, by entering this code you can create your new password at the next step",
    secondStepText:
      "We have sent a message including verification code to the whatsApp you entered. In the fields below, you can type your new password and update it by entering verification code sent to you.",

    continueButtonTitle: "Continue",
    backToHomeButtonTitle: "Return to Log in page",
    updatePassButtonTitle: "Update password",

    phoneInputLabel: "Phone Number",
    verificationCodeInputLabel: "Verification Code",
    newPassInputLabel: "New Password",

    verificationSuccessfullySentToast:
      "Verification code is successfully sent.",
    verificationErrorToast: "Verification code could not be sent.",
    validPhoneErrorToast: "Enter your WhatsApp number",
    passwordSuccessfullyChangedToast:
      "Your password has been changed successfully.",
    warningStatusToast: "Incorrect, your password could not be changed.",
  },

  /**
   * Panel içerisindeki sol menü ve alt elemanların bulunduğu key.
   */
  menuItems: {
    dashboard: { title: "Dashboard" },
    profile: { title: "Profile Management" },
    wallet: { title: "Plans&Prices" },
    todos: { title: "To Do List" },
    notify: { title: "Payment Notification" },
    appointment: {
      title: "Calendar",
      children: {
        index: "Appointment Calendar",
        filter: "Filter Appointments",
        online: "Online Appointment Requests",
        add: "+ Create a New Appointment",
      },
    },
    receipt: {
      title: "Tickets",
      children: {
        index: "View Tickets",
        recordAdd: "Create a Ticket for an Existing Client",
        newAdd: " Create a Ticket for a New Client",
      },
    },
    packages: {
      title: "Packages",
      children: {
        sales: "List Sales",
        salesAdd: "+ Add New Sale",
        list: "List Packages",
        add: "+ Create Package",
        unbookedPackages: "Unbooked Packages",
      },
    },
    products: {
      title: "Products",
      children: {
        index: "List Products",
        add: "+ Create New Product",
      },
    },
    customers: {
      title: "Clients",
      children: {
        active: "Active Clients",
        passive: "Inactive Clients",
        risky: "List of Risk",
        rating: "Evaluation Survey",
        add: "+ Create New Client",
        addBulk: "+ Create Bulk Clients with Excel",
      },
    },
    services: {
      title: "Services",
      children: {
        index: "List Services",
        add: "+ Create New Service",
        requests: "Service Name Requests",
        bounty: "Create Custom Bounty",
      },
    },
    employees: {
      title: "Staff Management",
      children: {
        index: "List Staff",
        add: "+ Create New Staff",
      },
    },
    sms: {
      title: "Messages",
      children: {
        index: "Message Reports",
        sendSelective: "Sent Selective Message",
        sendFilter: "Send Filtered Message",
        sendGroup: "Send Group Message",
        settings: "Sending Settings",
        blacklist: "Message Blacklist",
        template: "Template Settings",
        wpSettings: "WhatsApp Settings",
      },
    },
    accounting: {
      title: "Sales",
      children: {
        income: "Income Transaction",
        debt: "Debt Transaction",
        bounty: "Bonus Transaction",
        expense: "Expense Transaction",
        expenseTypes: "Expense Items",
        credit: "Credit Transaction",
        debit: "Debit Transaction",
      },
    },
    reports: {
      title: "Reports",
      children: {
        company: "Enterprise Reports",
        service: "Service Reports",
        stock: "Product Reports",
        staff: "Staff Reports",
        customer: "Client Reports",
        appointment: "Appointment Reports",
      },
    },
    settings: {
      title: "Settings",
      children: {
        index: "Enterprise Settings",
        hours: "Working Hours",
        gallery: "Enterprise Gallery",
        logs: "Transaction Records",
        branchAdd: "Create New Branch",
        giveAReferans: "Refer a Friend",
      },
    },
    platform: {
      title: "Platform Management",
      children: {
        photo: "Showcase Photo",
        settings: "Platform Settings",
        category_settings: "Category Settings",
      },
    },
    gallery: {
      title: "Gallery",
      children: {
        platform_photo: "Showcase Photo",
        enterprise_gallery: "Enterprise Gallery",
        service_gallery: "Service Gallery",
      },
    },
    agreement: {
      settings: "Contract Settings",
    },
    support: {
      title: "Live Support",
    },
  },

  /**
   * Panel içerisindeki üst menü ve açılan popuplarının bulunduğu key.
   */
  scaffold: {
    managementNo: "ID NO",
    translateViewHeading: "SYSTEM LANGUAGE",
    logsViewHeading: "SYSTEM LOGS",
    notificationViewHeading: "NOTIFICATIONS",
    quickActionsViewHeading: "QUICK TRANSACTIONS",

    searchCustomer: "Search for Clients",

    notificationButtons: {
      clearAll: "Clear All",
      cancel: "Cancel",
      delete: "Delete",
      new: "New",
      approved: "Approved",
      canceled: "Canceled",
    },

    logsViewAllButtonTitle: "View All",

    notificationFound: "New Notification!",
    notFoundNotification: "No notification yet!",

    QAItems: {
      createCustomer: "Create New Client",
      createAppointment: "Create New Appointment",
      createReceipt: "Create New Ticket",
      createPackage: "Create New Package",
      createPackageSale: "New Package Sale",
      createService: "Create New Service",
      createEmployee: "Create New Staff",
      showIncomes: "View Income",
      showExpenses: " View Expenses",
      showDebts: " View Debt",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/branches
   */
  branches: {
    heroHeadingText: "Salon Appointment Multiple Branch Management",
    greetingText: "Welcome",
    greetingBottomText:
      "In the field blow, branches listed under your management.",
    greetingBottomText2:
      "You can either update log in information of any branch or log into relevant branch by using the buttons on the right-hand side of table.",

    licenseExpiredToast: "License Expired User",
    logoutButtonTitle: "Sign out",
    loginBranchButtonTitle: "Log in to Branch",
    editStaffButtonTitle: "Edit Information",
    completeEditingButtonTitle: "Complete Editing",
    discardEditingButtonTitle: "Cancel",
    createBranchButtonTitle: "Create Branch",

    tabBranchListTitle: "List Branches",
    tabBranchReportsTitle: " List Branches",
    tabBranchAddTitle: "+ Create New Branch",

    headings: {
      listTable: {
        id: "Enterprice Code",
        companyName: "Enterprise/Branch Name",
        staffFullName: "Official’s Full Name",
        staffPhoneNumber: " Official’s Phone Number",
        staffMail: "Official’s E-mail",
        licenseEndDate: "License Expiry Date",
        refresh: "Refresh",
      },
      reportsTable: {
        companyName: "Enterprise/Branch Name",
        staffCount: "Number of Staff",
        appointmentCount: "Number of Appointments",
        appointmentStatus: "Arrived / Expecting / Not Arrived",
        receiptCount: "Number of Cheques",
        serviceIncome: "Service Income",
        saleIncome: "Product Sale Income",
        staffBounty: "Bonus Paid",
        totalIncome: "Total Income",
        totalExpense: "Total Expense",
        totalCount: "Net Income",
      },
    },

    createBranch: {
      companyNameInputLabel: "Enterprise Name",
      staffNameInputLabel: "Official’s Name",
      staffSurnameInputLabel: "Official’s Surname",
      commonMailInputLabel: "E-Mail Address",
      commonPhoneInputLabel: "Phone Number",
      passInputLabel: "Password",
    },

    licenseExpiredLoginToast:
      " The license period of account you want to log in has expired.",
    licenseExpiredEditToast:
      "The license period of account you want to edit has expired.",
    staffUpdateToast: "Staff is successfully updated.",
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/give-reference
   */
  referance: {
    createReferanceButtonTitle: "Give a Referance",
    createReferance: {
      companyNameInputLabel: "Enterprise Name",
      staffNameInputLabel: "Official’s Name and Surname",
      commonMailInputLabel: "E-Mail Address",
      commonPhoneInputLabel: "Phone Number",
    },
  },
  platformSettings: {
    approve: "Update Settings",
    appointmentApprovelCode: {
      title: "Appointment Verification Code",
      detail:
        "This is the setting for verification code for appointments received from the platform. When this setting is enabled, a verification code will be sent to the client when an appointment is received from the platform.",
    },
    appointmentApprovelCodeType: {
      title: "Appointment Verification Code Delivery Type",
      detail:
        "This setting is for delivery of verification codes for appointments received from the platform. When this setting is enabled, it indicates which delivery type will be used to send the verification code to the client when an appointment is received from the platform.",
    },
  },

  /**
   * Genel Bakış sayfasının bulunduğu key.
   * https://app.salonrandevu.com/dashboard
   */
  dashboard: {
    favoriteBox: {
      customerManagement: "Client Management",
      customerList: "Client List",
      lostCustomer: "Lost Clients",
      customerComments: "Client Comments",
      createNewCustomer: "Create New Client",
      addCustomerFromBulk: "Add Clients from Excel",
      serviceManagement: "Service Management",
      serviceList: "Service List",
      createNewService: "Create New Service",
      createNewServiceRequest: "Create New Service Request",
      serviceRequest: "Service Requests",
      createServicePrime: "Create Service Prime",
      packageManagement: "Package Management",
      packageList: "Package List",
      packageSaleList: "Package Sales List",
      packageWithoutAppointment: "Packages without Appointments",
      createNewPackage: "Create New Package",
      createNewPackageSale: "Create New Package Sale",
      stockManagement: "Stock Management",
      stockList: "Product List",
      createNewStock: "Create New Product",
      accountingManagement: "Accounting Management",
      accountingIncome: "Income Transactions",
      accountingExpense: "Expense Transactions",
      expensesTypes: "Expense Types",
      incomes: "Incomes",
      expenses: "Expenses",
      expenseProcess: "Expense Transactions",
      staffManagement: "Staff Management",
      staffList: "Staff List",
      primProcess: "Prim Transactions",
      createNewPersonnel: "Create New Staff",
      advancedReports: "Advanced Reports",
      companyReports: "Company Report",
      staffReports: "Staff Report",
      customerReports: "Client Report",
      servicesReports: "Service Report",
      productReports: "Product Report",
      messageManagement: "Message Management",
      smsReports: "SMS Reports",
      selectedSMSSend: "Selective SMS Sending",
      filterSMSSend: "Filtered SMS Sending",
      messageReports: "Message Reports",
      smsSettings: "SMS Settings",
      systemSetting: "System Settings",
      profileSettings: "Profile Settings",
      companySettings: "Company Settings",
      platformSettings: "Platform Settings",
      workingHours: "Working Hours",
      createNewBranch: "Create New Branch",
      systemLogs: "System Logs",
      giveReference: "Give Reference",
      languageSettings: "Language Settings",
      walletProcesses: "Balance Transactions",
      paymentInfo: "Payment Notification",
      currentBalance: "Current Balance",
      purchase: "Purchase",
      smsPackage: "SMS Package",
      logOut: "Log Out",
      galleryManagement: "Gallery Management",
      showcasePhotos: "Showcase Photos",
      companyGallery: "Company Gallery",
      servicesGallery: "Service Gallery",
      appointmentTable: "Appointment Calendar",
      onlineAppointmentRequests: "Online Appointment Requests",
      createNewAppointment: "Create New Appointment",
      filterAppointments: "Filter Appointments",
      receiptManagement: "Receipt Management",
      receiptList: "Receipts List",
      createNewReceipt: "Create New Receipt",
    },
    noWait: "You have no pending appointment requests.",
    PlatformLink: {
      btnText: "Appointment Reservation Page",
      title: "Appointment Reservation Page",
      description:
        "From this area, you can access the link required for your clients to create an appointment request for your business. From this link, your clients can create an appointment request by entering employee, service, day and time information.",
      copyLink: "Copy Link",
      goToLink: "Go to Page",
      linkCopied: "Link Copied.",
      Qr: "See QR Code",
    },
    accountInstallCard: {
      heading: "You can follow your account setup from the steps below.!",
      description:
        "By clicking on relevant step, you can go to transaction page.",

      setShift: "Set up your working hours",
      createService: "Create your services",
      setServicePicked: " Choose the services provided by the staff",
      createCustomer: "Create your clients",
    },

    tutorialModal: {
      startHeading: "Start Account Setup",
      startDescription:
        "It is possible to complete your account setup in three basic steps by completing system requirements ",

      startTutorialButton: "Start Setup",
      dontShowAgainButton: "Do not show again",
    },

    enterpriseHead: "Enterprise Info",
    enterpriseDesc:
      "View your enterprise address with your and heck your remaining usage below.",

    businessURLCard: {
      heading:
        "By sharing your enterprise address with your clients, it is easy to manage your appointments!",
      inputLabel: "URL for Enterprise Appointment",
      openBrowserButton: "Open in Browser",
      copyLinkButton: "Copy the Link",
      copySuccessToast: "The link has been successfully copied!",
    },

    usageCard: {
      heading: "You can easily check your remaining usage below.",

      licenseDetail: "License Details",
      packageText: "PACKAGE",
      licenseRemaining: "days left",

      smsDetail: "Remaining Email credit",
    },

    stats: {
      appointment_info: {
        heading: "Appointment Info",
      },
      appointment: {
        heading: "Appointment Statistics",
        createdAppointments: "Already Booked Appointments",
        successAppointments: "Completed Appointments",
        closedAppointments: " Incomplete Appointments",
      },

      customer: {
        heading: "Client Statistics",
        newCustomers: "New Cliens",
        oldCustomers: "Existing Cliens",
        appointmentCustomers: "Clients with Appointment",
      },

      accounting: {
        heading: "Cash Statistics",
        income: "Total Income",
        expense: "Total Expense",
        debt: "Total Dept",

        licenseUpgradeText: "Upgrade Your Account!",
      },

      meta: {
        total: "Total Appointmens",
        arrived: "Arrived",
        notArrived: "Not Arrived",
        waiting: "Pending",
        canceled: "Canceled",
      },
    },

    waitingAppointments: {
      sectionHeading: "Onlıne Appoıntment Requests",
      headings: {
        appointmentStartDate: "Appointment Time",
        customerFullName: "Client Name",
        staffFullName: "Staff Name",
        serviceName: "Service Name",
      },

      buttons: {
        openReceiptTitle: "Open Ticket",
      },
    },

    cardTitle: {
      receipt_income_card_title: "Cash Statistics",
      appointment_calender_card_title: "Appointment Calendar",
      appointments_card_title: "Appointment Details",
      customers_card_title: "Client Statistics",
    },

    cardDescription: {
      customers_description: "Client Statistics",
      favorites_description:
        "You can create your own shortcuts by starring the tabs you use most often. You can also change the places of the cards with drag and drop.",
      receipt_income_card_description:
        "You can follow your daily, 7-day or 30-day cash stats on the income graph.",
      appointment_calender_card_description:
        "Click on the day you want to view the appointment details.",
      appointment_statistic_card_description:
        "You can report the number of appointments created on the platform, business link and panel through statistical data at daily, 7-day or 30-day intervals.",
      appointments_card_description:
        "You can track the number of appointments your business makes daily, 7 days or 30 days apart and whether your clients are coming or not.",
      online_appointment_request_card_description:
        "You can approve or reject the appointment by following the online appointment requests from your clients via your business link.",
    },

    report: {
      company: {
        typeCASH: "Cash",
        typeCARD: "Card",
        typeEFT: "Wire Transfer/EFT",
        totalIncome: "Total Revenue",
        noDataText:
          "The graph cannot be displayed because your Cash, Credit/Debit Card and Money Order/EFT incomes are not available.",
      },
    },

    appointmentAnalys: {
      platform: "SalonManagement.com",
      company: "Company Website",
      panel: "Created By Business",
    },

    waitingAppointments2: {
      sectionHeading: "Online Appointment Requests",
      headings: {
        appointmentStartDate: "Appointment Time",
        customerFullName: "Client",
        staffFullName: "Staff",
        serviceName: "Service to be taken",
      },

      buttons: {
        openReceiptTitle: "Open Ticket",
      },
    },

    onlineAppointments: {
      headings: {
        appointmentStartDate: "Date - Hour",
        customerFullName: "Client",
        customerPhone: "Phone",
        staffFullName: "Staff",
        serviceName: "Service",
        note: "Note",
        customerType: "Client Type",
        statistics: "Statistics",
      },

      approveButtonTitle: "Approve",
      rejectButtonTitle: "Reject",

      rejectConfirm: {
        title: "Do you confirm the appointment cancellation?",
        confirmButtonTitle: "Confirm",
        discardButtonTitle: "Discard",

        alertBoldText: "This action cannot be undone!",
        alertText:
          "If you confirm this request, you will no longer be able to access this appointment request.",
      },

      approveToast: "Appointment request successfully approved!",
      rejectToast: "Appointment request successfully rejected!",
    },

    openAppointments: {
      headings: {
        date: "Date - Hour",
        customerFullName: "Client",
        products: "Products",
        serviceName: "Service",
        totalPayment: "Total Payment",
      },
      title: "Open Tıckets",
      approveButtonTitle: "Approve",
      rejectButtonTitle: "Reject",

      rejectConfirm: {
        title: "Do you confirm the appointment cancellation?",
        confirmButtonTitle: "Confirm",
        discardButtonTitle: "Discard",

        alertBoldText: "This action cannot be undone!",
        alertText:
          "If you confirm this request, you will no longer be able to access this appointment request.",
      },

      approveToast: "Appointment request successfully approved!",
      rejectToast: "Appointment request successfully rejected!",
    },
    creditors: {
      headings: {
        customerFullName: "Client",
        type: "Type",
        paymentDate: "Scheduled Payment Date",
        totalPayment: "Total Payment",
      },
      title: "Credıtors",
      approveButtonTitle: "Approve",
      rejectButtonTitle: "Reject",

      rejectConfirm: {
        title: "Do you confirm the appointment cancellation?",
        confirmButtonTitle: "Confirm",
        discardButtonTitle: "Discard",

        alertBoldText: "This action cannot be undone!",
        alertText:
          "If you confirm this request, you will no longer be able to access this appointment request.",
      },

      approveToast: "Appointment request successfully approved!",
      rejectToast: "Appointment request successfully rejected!",
    },
    birthDay: {
      headings: {
        customerFullName: "Client",
        customerPhone: "Phone Number",
        birthDate: "Birth Date",
      },
      title: "Upcomıng Bırthdays",
    },
    openReceiptOnSuccessToast:
      "Income belonging to appointment has been successfully created!",
  },

  /**
   * Randevu tablosun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments
   */
  appointments: {
    customerAppointment: {
      title: "Are you sure you want to confirm the appointment cancellation?",
      yes: "Yes",
      close: "Cancel",
      expTitle: "This action is irreversible!",
      expDetail:
        "The appointment will be removed from the calendar. Are you sure?",
    },

    appointmentsComp: {
      green: "Green indicates available appointment times.",
      red: "Red represents previously scheduled appointment times.",
      purple:
        "Purple may indicate potential conflicts with existing appointments or closed hours when making an appointment.",
      gray: "Gray signifies employee details or hours closed on the appointment calendar.",
    },

    comunicateWithWhatsApp: "Contact via Whatsapp",
    tooltipExplanations: {
      selectStaff:
        "Here you can select the staff you want to see on the calendar",
      staffCount:
        "Here you can edit the number of people you want to appear on the page",
      appointmentRequest: "Your Online Appointment Requests",
      appointmentList: "Your Appointment List",
      appointmentTypes:
        "You can change the appointment calendar here to view daily, weekly or monthly",
    },
    staffSelect: {
      all: "All",
    },
    staffViewCount: {
      person3: "3 person view",
      person5: "5 person view",
      person7: "7 person view",
      person9: "9 person view",
      all: "All",
    },
    appointmentTableTypes: {
      day: "Today",
      week: "Weekly",
      month: "Monthly",
    },
    dayInputLabel: "Date of Appointments",
    beforeDayButtonTitle: "Previous Day",
    afterDayButtonTitle: "Next Day",
    today: "Today",
    allStaff: "All Staff",
    dayWeekSelectLabel: "Day-Week-Monthly",

    definitionGreenText: " Represents appointments with opened ticket.",
    definitionRedText:
      "Represents overdue appointments and unopened appointments ticket.",
    definitionYellowText:
      "The appointments whose date and time have not been passed are waiting to be opened.",
    definitionLightText: "Represents incoming and open tickets.",
    definitionPurpleText: "Arrived and processing is in progress.",

    create: {
      title: "Quickly Create Appointment for Selected Time",
      completeButtonTitle: "Complete",
      discardButtonTitle: "Cancel",

      selectedTimeInputLabel: "Appointment Date and Time",
      selectedStaffInputLabel: "Selection of Staff",
      selectedServiceInputLabel: "Service Provided by Staff",
      selectedPackageInputLabel: "Packages Provided by Staff",
      selectedCustomerInputLabel: "Clients to be given appointment",
      selectedCustomerInputPlaceholder: "Type client’s name in this field.",
      createNewCustomerButtonTitle: "Create New Client",
      createNewWalkinCustomerButtonTitle: "Create Unregistered Client",

      selecting_service: "Service Selection",
      selecting_package: "Package Selection",

      closeAllDayTitle: "Close All Day",

      // Add unregistered customer
      sendSmsInputLabel: "Notification Email Sending",
      sendSmsTrue: "Send",
      sendSmsFalse: "Not Send",

      groupAppointment: "Bulk Appointment Creation",
      clockOff: "Add blocked time",
      addEmployeeButtonTitle: "Add Employee + Service",

      noteInputLabel: "Note for Appointment (Optional)",

      onWarningToast: "Enter client and service selection fields completely!",
      noDataText:
        "To create a Bulk Appointment, you must select at least 2 employees and services.",
    },

    detail: {
      title: "Appointment Details",
      deleteButtonTitle: "Delete Appointment",
      closeButtonTitle: "Close",

      deleteButton: "Delete",
      editButton: "Edit",
      clockOffDetail: "Clock Shutdown Detail",
      clockOffDateInputLabel: "Closing Date/Time",
      clockOffStaffInputLabel: "Relevant Personnel",
      clockOffNoteInputLabel: "Explanation",

      appointmentTime: "Appointment Time (min)",
      appointmentTimeExtension: "Extend Appointment Time",
      appointmentalAlertMessage:
        "Your appointment time has been extended. You can view the current appointment time from the Appointment Calendar.",
      appointmentalWarningMessage: "Please enter the appointment time.",

      dateInputLabel: "Appointment Date/Time",
      serviceInputLabel: "Service to be Taken",
      packageInputLabel: "Package to Apply",
      staffInputLabel: "Staff",
      customerInputLabel: "Client's full name",
      customerPhoneInputLabel: "Client's Phone Number",
      noteInputLabel: "Note for Appointment",

      appointmentStatus: {
        title: "Appointment Status",
        arrived: "Arrived",
        notArrived: "Not Arrived",
        waiting: "Waiting",

        infoText: "The sessions in the package are organized as follows :",

        changeStatusSuccessToast:
          "The appointment status has been successfully changed.",
      },

      types: {
        come: "Arrived",
        notCome: "Didn't arrive",
        inProcess: "In Process",
        new: "New",
        approved: "Approved",
      },

      createReceipt: "Open a Receipt",
      goToReceipt: "Go To Receipt",
    },

    deleteConfirm: {
      title: "Appointment Cancellation Confirmation",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertBoldText: "This action is irreversible!",
      alertText:
        "In case of Email sending settings active and having remaining balance, a notification will be sent to client about cancellation of appointment.",
    },

    pastCreateErrorToast:
      "You tried to create an appointment to a past date-time.",
    onMouseOverCardToast:
      "You can click on the card you want to see the details of the appointment.",
    insertToast: "The appointment was created successfully.",
    deleteToast: "The appointment was successfully deleted.",

    weeklyAppointmentsStaffErrorToast:
      "Please select an employee for weekly appointments.",
    weeklyAppointmentsDisplayErrorToast:
      "To create an appointment, view the daily appointments.",

    checkButton: "Understood",
    firstLoginAppointmentsTitle: "TO INFORM",
    firstLoginAppointmentsDescription:
      "Sample personnel information and appointment card have been created for you. You can update your personnel information and appointments by clicking on the available tabs.",

    updateAppointment: {
      title: "Do you want to change the appointment worker and/or time?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you update the current appointment, the employee and/or time information will change.",
      confirmButtonTitle: "Confirm",
      discardButtonTitle: "Discard",

      updateAppointmentSuccessToast:
        "The appointment has been successfully updated.",
    },

    orderStaffsButtonTitle: "Order Staff",

    rightButtonTooltip: "Move the calender to the right",
    leftButtonTooltip: "Move the calender to the left",

    createAppointmentButtonTitle: "Create Appointment",
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/filter
   */
  "appointments/filter": {
    alertBoldText:
      "By using the fields below you can filter your appointments.",
    alertText:
      "In case of making any changes over the fields, this will be applied automatically.",

    startInputLabel: "Starting Date",
    endInputLabel: "Due to Date",
    staffInputLabel: "Staff Selection",
    allStaffsText: "All Staff",
    serviceInputLabel: "Service Name",
    customerInputLabel: "Client's Full Name",

    tabMenuLeft: {
      customerBill: "Client Bills",
      customerAppointment: "Client Appointments",
      customerDetails: "Client Details",
      customerPhotos: "Client Photos",
      customerContract: "Agreements",
      updateCustomerDetailsTrue: "Client Details successfully updated!",
      updateCustomerDetailsFalse: "Client Detail Update Failed!",
    },

    statusInputLabel: "Appointment Status Selection",
    statusInputItems: [
      "All Appointments",
      "Upcoming Appointments",
      "Missed Appointments",
      "Pending Appointments",
      "Cancelled Appointments",
    ],

    filterTitle: "Date Filter",

    canceled: "Cancelled",
    situations: "Situations",

    headings: {
      appointmentStartDate: "Appointment Date",
      customerFullName: "Client",
      serviceName: "Service",
      appointmentNote: "Appointment Note",
      staffFullName: "Staff",
      customerPhone: "Client Phone Number",
      createdAt: "Appointment Booking Date",
    },
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/online
   */
  "appointments/online": {
    alertText:
      "In the field below if approved, online appointment requests are listed. Those appointments can only be confirmed/rejected by authorized person.",
    headings: {
      appointmentStartDate: "Date - Time",
      customerFullName: "Related Client",
      customerPhone: "Client Phone Number",
      staffFullName: "Staff",
      serviceName: "Service",
      note: "Client's Appointment Note",
      customerType: "Client Type",
    },

    approveButtonTitle: "Confirm",
    rejectButtonTitle: "Reject",

    rejectConfirm: {
      title: "Do you confirm appointment cancellation?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "This action irreversible!",
      alertText: "You can no more access this appointment request if rejected.",
    },

    approveToast: "The appointment request has been successfully confirmed!",
    rejectToast: "The appointment request has been successfully rejected!",
    blackListWarning:
      "After being blacklisted, appointment requests cannot be made with this number.",
    addToNumberBlackList: "Do you want to add this number to the blacklist?",
  },

  /**
   * Randevu Oluştur sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/add
   */
  "appointments/add": {
    staffInputLabel: "Staff Selection",
    serviceInputLabel: "Services Offered by the Staff",
    customerInputLabel: "Client to Be Given Appointment",
    customerInputPlaceholder: "Type Client's Name in This Field",
    sendSmsInputLabel: "Sending Notification Email",
    sendSmsTrue: "Send",
    sendSmsFalse: "Do not Send",
    noteInputLabel: "Appointment Note (Optional)",

    availableHoursButtonTitle: "Upload Available Appointment Hours",
    confirmButtonTitle: "Confirm Appointment Selection",
    confirmButtonHoursTitle: "Confirm Appointment Hours",
    createNewCustomerButtonTitle: "Create New Client",
    createNewWalkinCustomerButtonTitle: "Create Unregisterd Client",
    unregisteredCustomerCreated: "Unregistered Client is created",

    insertToast: "The Appointment has been successfully booked.",
  },

  /**
   * Adisyonların listeletildiği sayfaların bulunduğu key.
   * https://app.salonrandevu.com/receipts
   * https://app.salonrandevu.com/packages/sales
   */
  receipts: {
    allDelete: "Receipts and payments deleted successfully!",
    filter: {
      title: "Based on Date Filtration",
      startInputLabel: "Starting Date",
      endInputLabel: "Due to Date",
      typeOfSales: "Type of Sales",
      openTitle: "All",
      open: "Open Sales",
      close: "Close Sales",
    },

    previous: "Previous",
    next: "Next",

    headings: {
      id: "Ticket No",
      type: "Ticket Type",
      billType: "Bill Type",
      createdAt: "Creation Date",
      customerFullName: "Client Name",
      info: "Ticket Content",
      allAmount: "Total",
      paid: "Paid",
      debtTotal: "Remaining",
      processDate: "Process Date",
    },

    replacements: {
      undefinedCustomer: "Undefined Client",
      allSales: "All Sales",
      packageSale: "Package Sale",
      standartSale: "Standard Sale",
    },

    showButtonTitle: "View",

    detailButtons: {
      confirmButton: "Approve",
      closeButton: "Close",
    },
    serviceTransactions: {
      // Section
      print: "Print",
      soldTitle: "New Service Sale",
      title: "Service Sales",
      dateInputLabel: "Date",
      packageTitle: "Package Sales",
      packageDateInputLabel: "Package Date",
      nameInputLabel: "Name",
      staffInputLabel: "Staff",
      transactionStatus: "Status",
      amountInputLabel: "Amount",
      totalAmount: "Total Service Sale Amount",
      noDataText: "No service sale has been added to ticket yet!",
      packageTotalAmount: "Total Package Sale Amount",
    },
    alert_receipt_installment:
      "There are overdue installments in the receipt with past due dates.",
  },

  /**
   * Adisyonların oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/receipts/add
   */
  "receipts/add": {
    searchCustomerInputLabel: "Search Client",
    searchCustomerInputPlaceholder: "Type Client Full Name in This Field",
    receiptNoteInputLabel: "Ticket Note (Optional)",

    openReceiptBySelectedButtonTitle: "Create Ticket Over Selected Client",
    openReceiptByNewCustomerButtonTitle: "Create Ticket Over New Client",
    openReceiptByRandomCustomerButtonTitle:
      "Create Ticket on Unregistered Client",
    openReceiptWithCreatedNewCustomerButtonTitle: `Create a New Client and a Ticket`,

    insertToast: "The Ticket has been successfully created!",
  },

  /**
   * Tekli adisyonun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/receipts/detail/:id
   */
  "receipt/detail/id": {
    installmentPayment: "Installment Payments",
    addPaymentTitle: "Cash Payment",
    packageEdit: {
      dateEditTitle: "Package Date Update",
      dateEdit:
        "The dates of all sessions in the package will be updated according to the date range. Do you approve?",
      dateEditNote: "Note: Don't forget to save the changes after this update.",
      approve: "Approve",
      abort: "Abort",
      delete: "Delete Package",
      deleteInfo:
        "The package and its associated services will be deleted. Do you approve?",
      deleteNote: "Note: Don't forget to save the changes after this update.",
    },
    paymentStatus: {
      paid: "Paid",
      noPaid: "Not Paid",
      waiting: "Waiting",
    },

    agreementPaper: {
      buttons: {
        close: "Close",
        cancel: "Cancel",
        preview: "Preview",
        approve: "Approve",
        abort: "Abort",
        save: "Save",
      },
      selectAggrementTemplate: "Select Agreement Template",
      createNewAgreement: "Create New Agreement",
      uploadAgreement: "Upload Agreement",
      savedAgreement: "Saved Agreements",
      personalInfo: "Personal Information",
      nameSurname: "Name Surname",
      phoneNumber: "Phone Number",
      idNo: "ID Number",
      taxNo: "Tax Number",
      address: "Address",
      staffName: "Staff Name",
      service: "Service",
      netAmount: "Net Amount",
      yes: "Yes",
      no: "No",
      print: "Print",
      sendToSigniture: "Send for Signature",
      approvalTitle:
        "Do you approve sending the edited agreement for mobile signature?",
      aggExp:
        "You can view, edit, and sign the sent agreement from the Lists Agreement List section of your mobile application.",
      uploadExp: "Click here to upload the agreement for this receipt.",
      uploadLabel: "Name Surname",
      aggrementName: "Agreement Name",
      warningInfo: "Please fill in the missing fields: Agreement Name",
      savedAggrement: "Saved Agreements",
      table: {
        date: "Date",
        name: "Name Surname",
        aggName: "Agreement Name",
        size: "Size",
        actions: "Actions",
      },
      sendAggrement: {
        smsTitle: "Do you want to send the saved agreement via SMS?",
        wpTitle: "Do you want to send the saved agreement via WhatsApp?",
        send: "Send",
        cancel: "Cancel",
        approval: "Are you sure?",
      },
      deleteAggrement: {
        title: "Do you want to confirm deleting the agreement?",
        approval: "Approve and Delete",
        abort: "Abort",
        warningExp: "The agreement will be permanently deleted.",
        warningDetailExp:
          "This action is irreversible. The agreement will be permanently deleted.",
      },
    },

    printPaper: {
      receiptNo: "Receipt Number",
      services: "Services",
      staff: "Staff",
      service: "Service",
      price: "Price",
      products: "Products",
      product: "Product",
      amount: "Amount",
      receiptSummary: "Receipt Summary",
      discount: "Discount",
      netSum: "Net Total",
      remainingAmount: "Remaining Amount",
    },
    saveYourChanges: "Please save the changes you have made.",
    customerPoint: "Client Accumulated Points",
    saveChangesButtonTitle: "Save Changes",
    print: "Print",
    contract: "Agreement",
    installSingle: "Installment",
    installmentSingle: "Installments",
    paymentAmount: "Payment Amount",
    packageSessionUpdate: "Package Session Update",
    getAvailableServices: "Get Available Services",
    selectCustomerPayment: "Staff Selection (Optional)",

    applyDiscountButtonTitle: "Apply Discount",
    addPackageSaleButtonTitle: "Add Package Sale",
    addServiceTransactionButtonTitle: "Add Service Sale",
    addStockSaleButtonTitle: "Add New Product Sale",
    addPaymentButtonTitle: "Add New Payment",

    installmentButtonTitle: "Add Installment Payment",
    installmentCountInputLabel: "Installment Count",
    installmentProcess: "Installment Process",

    deleteReceiptButtonTitle: "Delete Ticket",

    alertText:
      "The message has been saved, but you must first save the ticket for the message to be forwarded.",

    sms: {
      sendButtonTitle: "Save SMS",
      discardButtonTitle: "Discard",
      addSmsButtonTitle: "Send Collection Message",
      smsButtonTitle: "Send Debt Information / Collection Message",
    },

    creationdate: "Creation Date",
    point: "point",

    packageInner: {
      title: "Package Content",
      headings: {
        // Table Headings
        name: "Service Name",
        count: "Total Sessions",
        completed: "Completed Sessions",
        pending: "Remaining Sessions",
      },
    },

    serviceTransactions: {
      // Section
      print: "Print",
      soldTitle: "New Service Sale",
      title: "Service Sales",
      dateInputLabel: "Date",
      packageTitle: "Package Sales",
      packageDateInputLabel: "Package Date",
      nameInputLabel: "Name",
      staffInputLabel: "Staff",
      transactionStatus: "Status",
      amountInputLabel: "Amount",
      totalAmount: "Total Service Sale Amount",
      noDataText: "No service sale has been added to ticket yet!",
    },

    stockSales: {
      // Section
      title: "Product Sales",
      nameInputLabel: "Name",
      deletedName: "Deleted Product",
      staffInputLabel: "Sold by",
      amountInputLabel: "Unit Price",
      countInputLabel: "Quantity",
      totalAmount: "Total Product Sale Amount",
      noDataText: "No product sale has been added to ticket yet",
      selectWithBarcode: "Select Product With Barcode",
      selectStock: "Select Product",
      barcodeCheckAlert:
        "Paid content is not active. Please contact the representative for activation.",
    },
    barcodeAlert: {
      pleaseEnterACode: "Please Enter a Product Code",
    },

    productTransactions: {
      productSaleTitle: "New Product Sales",
      successAdd: "Product Added Successfully",
    },

    payments: {
      // Section
      title: "Payments",
      dateInputLabel: "Payment Date",
      typeInputLabel: "Payment Type",
      amountInputLabel: "Amount",
      pointInputLabel: "Points to be Used",

      typeCASH: "Cash",
      typeCARD: "Credit / Bank Card",
      typeEFT: "Bank Transfer",
      typePOINT: "Client Points",

      totalAmount: "Total Ticket",
      totalPaidAmount: "Amount Paid",
      totalDebtAmount: "Debt Amount",

      noDataText: "No payment method has been added to ticket!",
    },

    installment: {
      title: "Installment payments",
    },

    notes: {
      // Section
      title: "Notes",
      receipt: "Ticket Note",
      customer: "Client Note",
      appointment: "Appointment Note",
    },

    applyDiscount: {
      // Modal
      title: "Apply Discount",
      typeInputLabel: "Discount Type",
      typeCASH: "Discount",
      typePERCENT: "Discount as Percentage",
      amountInputLabel: "Discount Amount",
      discardButtonTitle: "Cancel",
    },

    packageSale: {
      // Modal
      title: "New Package Sale",
      completeButtonTitle: "Complete Sale",

      packageNameInputLabel: "Package Selection",
      serviceInputLabel: "Service Selection",
      staffInputLabel: "Staff Selection",
      startInputLabel: "Starting Date",
      periodInputLabel: "Session Quantity",
      periodBetweenInputLabel: "Session Interval",
      periodAmountInputLabel: "Session Price",

      noDataText: "No service session has been added to package sale!",
      totalAmount: "Package Total",

      servicePickToast: "Complete your service selections totally!",
      customerPickToast: "Complete your client selections totally!",

      packageSaleSuccess:
        "The package has been successfully added to the receipt.",
    },

    serviceTransaction: {
      // Modal
      title: "New Service Sale",
      addButtonTitle: "Add",
      clearButton: "Clear",
      discardButtonTitle: "Cancel",

      staffInputLabel: "Staff Selection",
      serviceInputLabel: "Service Selection",
      successAdd: "Service Added Successfully",
    },

    stockSale: {
      // Modal
      title: "New Product Sale",
      addButtonTitle: "Add",
      discardButtonTitle: "Cancel",

      staffInputLabel: "Selling Staff",
      stockInputLabel: "Product Selection",
    },

    delete: {
      // Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertText:
        "This action is irreversible! In case of deleting this cheque will also delete transaction in it. It may affect your accounting records!",
    },

    discountBeforePaymentToast:
      "In order to apply discount, you must remove payments.",
    saveChangesToast: "Changes have been successfully saved.",
  },

  "packages/sales": {
    filter: {
      title: "Date Based Filtering",
    },
  },

  /**
   * Paket satışının yapıldığı sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/sales/add
   */
  "packages/sales/add": {
    searchPackegeInputLabel: "Search Package",
    customerInputLabel: "Client to Sell",
    customerInputPlaceholder: "Enter Client Name in this Field",

    importPackageButtonTitle: "Import Package",
    addServiceButtonTitle: "Add Service to Package",
    completeButtonTitle: "Complete Sale",

    enterPackageName: "Enter Package Name",

    serviceInputLabel: "Service Selection",
    staffInputLabel: "Staff Selection",
    startInputLabel: "Starting Date",
    periodInputLabel: "Session Quantity",
    periodBetweenInputLabel: "Session Interval",
    periodAmountInputLabel: "Session Price",
    totalInputLabel: "Package in Total",

    noDataText: "No service – session has been added to package sale yet!",

    modal: {
      title: "Import Prepared Package",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      packageInputLabel: "Package Selection",
    },
  },

  /**
   * Paketlerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/list
   */
  "packages/list": {
    headings: {
      name: "Package Name",
      amount: "Package Sum",
    },
  },

  /**
   * Paketlerin oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/add
   */
  "packages/add": {
    name: "Package Name",
    completeButtonTitle: "Create Package",
    addServiceButtonTitle: "Add New Service to Package",
    pickCostumerAndPackageSale: "Select Clients and Sell Packages",
    serviceInputLabel: "Service Selection",
    periodInputLabel: "Number of Session",
    periodAmountInputLabel: "Sessions Unit Price",
    totalInputLabel: "Package Price in Total",

    noDataText: "No service – session has been added to package yet!",

    validateServiceToast: "Complete your service selections fully!",
    validateCustomersToast: "Complete your clients selections fully!",
    insertToast: "Package has been successfully created.",
  },

  /**
   * Paket detayı sayfasının bulunduğu key.
   * https://app.salonrandevu.com/packages/detail/:id
   */
  "packages/detail/id": {
    updatePackageButtonTitle: "Update Package",
    deletePackageButtonTitle: "Delete Package",
    addServiceButtonTitle: "Add New Service to Package",

    nameInputLabel: "Package Name",
    serviceInputLabel: "Service Selection",
    countInputLabel: "Number of Session",
    periodAmountInputLabel: "Session Unit Price",

    totalInputLabel: "Package Price in Total",
    noDataText: "No service – session has been added to package yet!",

    delete: {
      title: "Confirm to Delete Package",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Cancel",
      alertText:
        "Do you confirm to delete package? Previous selling, cheques and accounting records will not be affected",
    },

    validateServiceToast: "Full complete your service selections!",
    deleteToast: "Package has been successfully deleted.",
    updateToast: " Package has been successfully updated",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  products: {
    deleteButtonTitle: "Delete Product",

    headings: {
      name: "Product Name",
      amount: "Product Price",
      limit: "Stock Quantity",
      bounty: "Percentage of Bonus",
    },
    delete: {
      // Modal
      title: "Confirmation of Product Deletion",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "This action irreversible!",
      alertText: "If you delete, you can no more make sales over the product!",
    },

    deleteToast: "Product has been successfully deleted.",
    stockName: "Product Stock",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  "products/add": {
    searchProductInputLabel: "Search Product",
    searchProductInputPlaceholder: "Please enter product name",

    createButtonTitle: "Create Product",

    nameInputLabel: "Product Name",
    nameInputPlaceholder:
      "Enter product name that will be listed in your management application.",
    amountInputLabel: "Product Price",
    amountInputHint:
      "The amount you set is your default amount. You can change the amount while adding to cheque .",
    bountyInputLabel: "Percentage of Bonus (%)",
    bountyInputHint:
      "If your staffs fixed bonus percentage is undefined, they will earn bonus according to percentage will be defined.",
    pointInputLabel: "Points to be Earnt",
    pointInputHint: " 100 client point corresponds to 1.",
    limitInputLabel: "Stock Quantity",
    limitInputHint:
      "Enter product stock quantity. You can change the value later.",

    insertToast: "Product has been successfully created.",
    addBarcode: "Add a Product with Barcode Code",
    addBarcodeExplain: "Enter the code to add a barcode for the product.",
    barcodeCheckTitle:
      "Paid content is not active. Please contact the representative for activation.",
  },

  /**
   * Ürün detay sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products/detail/:id
   */
  "products/detail/id": {
    updateButtonTitle: "Update Product",

    nameInputLabel: "Product Name",
    nameInputPlaceholder:
      "Select product name that will be listed in your management application.",
    amountInputLabel: "Product Price",
    amountInputHint:
      "The amount you set is default amount. You can change the price while adding to cheque.",
    bountyInputLabel: "Percentage of Bonus (%)",
    bountyInputHint:
      "If your staffs fixed bonus percentage is undefined, they will earn bonus according to percentage will be defined.",
    pointInputLabel: "Point to be Earnt",
    pointInputHint: "100 client point corresponds to 1.",
    limitInputLabel: "Stock Quantity",
    limitInputHint: "Enter stock quantity. You can change the value later.",

    updateToast: "Product has been successfully updated.",
  },

  /**
   * Aktif müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers
   */
  customers: {
    phone: { placeHolder: "Enter a Phone Number" },
    authMessage: {
      noAuth:
        "You do not have the necessary permissions to make changes to client information.",
      updateAuth:
        "Editing client information and deleting clients can only be done by business administrators.",
    },
    detail: {
      goToAppointment: "Go To Appoitment",
      cancel: "Cancel",
      canceled: "Canceled",
      birthday: "Birthday",
      sPhone: "Secondary Phone Number",
      contactViaWP: "Contact via Whatsapp",
    },
    alertTExt:
      "It is on/off switch setting for sms sending for your clients on your client list. When you perform this operation, the added All your Clients' sms sending settings will be updated.",
    filter: {
      title: "Client Search filter",
      inputLabel: "Name, Surname or Phone Number",
    },
    amount: {
      openSmsSendingStatus: "Open SMS Sending Status",
      closeSmsSendingStatus: "Close SMS Sending Status",
      title: "View Amounts",
      updateSmsStatus: "Update E-mail Status",
      allCustomerSmsUpdate: "All Clients' SMS Delivery Status Will Change!",
    },
    headings: {
      code: "Client Code",
      fullName: "Name Surname",
      phone: "Phone Number",
      sex: "Gender",
      incomeAll: "Amount Earnt",
      debtAll: "Amount of Debt",
    },
    replacements: {
      totalCustomerNumber: "Total Client Number",
      sex: {
        undef: "Unspecified",
        male: "Male",
        female: "Female",
      },
    },

    passiveAll: {
      passiveAllButtonTitle: "Make All Pasive",
      title: "Do you approve to make passive all of your clients?",
      alertText:
        "If you make all clients passive, you will not be able to see clients in areas such as appointments / bills again. You can access the information of clients from the Passive Clients tab whenever you want.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Cancel",

      successToast: "All clients have been successfully gone passive.",
    },
    passiveSelected: {
      passiveSelectedButtonTitle: "Make Selected Pasive",
      title: "Do you approve to make passive chosen clients?",
      alertText:
        "If you make chosen clients passive, you will not be able to see clients in areas such as appointments / bills again. You can access the information of clients from the Passive Clients tab whenever you want.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Cancel",

      successToast: "Chosen clients have been successfully gone passive.",
    },

    totalCustomers: "Total Clients:",

    // customers/detail/:id
    // customers/detail/:id
    single: {
      toast: {
        validateCustomer: {
          success: "Client information has been successfully updated!",
          warn: "Client information could not be updated!",
        },
        makePassiveSingleCustomer: {
          success: "Client has been successfully set to passive!",
        },
        makeActiveSingleCustomer: {
          success: "Client has been successfully set to active!",
        },
        deleteSingleCustomer: {
          success: "Client successfully deleted!",
        },
      },
      tabs: ["Client Information", "Client Cheques", "Client Appointments"],
      form: {
        tab1: {
          // customer info
          info: [
            "You cannot change the client information in accordance with your authorization!",
            "Only business officials can edit client information and delete clients.",
          ],
          clientName: [
            //0: label, 1: placeholder
            "Client Name",
            "Enter client name",
          ],
          clientSurname: [
            //0: label, 1: placeholder
            "Client Surname",
            "Enter client surname",
          ],
          clientGender: ["Gender", "Female", "Male"],
          birthday: [
            "Date of birth",
            "Please log in the desired format. Day/Month/Year",
          ],
          maritalStatus: ["Marital Status", "Married", "Single"],
          phone: [
            "Mobile Number",
            "Second Mobile Number",
            "Enter second mobile number if available",
          ],
          email: [
            "Enter Your E-mail",
            "If available enter your e-mail",
            "If available enter client's e-mail",
            "Enter Client Email",
          ],
          additionalDescription: ["Additional Notes"],
          clientPoint: ["Client Rating"],
          workStatus: {
            label: "Working Status",
            woman: [
              "Employed",
              "Unemployed",
              "Employer",
              "Student",
              "Housewife",
            ],
            man: ["Employed", "Unemployed", "Employer", "Student"],
          },
          relatedInstitution: [
            "Relevant Institution",
            "Specify the instituiton which the client comes from",
          ],
          smsSendStatus: ["Email Sending Status", "Send", "Do not send"],
          checkers: ["Yes", "No"],
          buttons: [
            "Update Client",
            "Activate Client",
            "Deactivate Client",
            "Do you approve to delete?",
            "Do you approve passive adjustment?",
          ],
          modals: {
            deleteConfirm: [
              "This process cannot be reversed!",
              "By deleting this client you may not be able to access their information again.",
            ],
            passiveConfirm: [
              "If you make this client pasif you will not be able to see the client in areas such as appointmentcheque.",
              "You can always access client information from the passive client tab.",
            ],
          },
        },
        tab2: {
          tableHeadings: [
            "Cheque No",
            "Type of Cheque",
            "Opening Date",
            "Bill Content",
            "Total",
            "Paid",
            "Remaining",
          ],
          tableButtons: ["View"],
          unknownInfo: [
            "Unidentified Client",
            "Package Sales",
            "Standard Sales",
          ],
          noData: ["Data Not Found"],
        },
        tab3: {
          customerFullName: "Client Fullname",
        },
      },
    },
  },

  "logs/index": {
    filter: {
      title: "Advanced Search Filter",
      form: {
        description: "Process Detail / Client Name / Personal Name",
        startDate: "Start Date",
        endDate: "End Date",
        allDate: "All Time",
        findCustomer: {
          label: "Client Name",
          placeholder: "Search specific client name",
          helper: "Only specific client filtering",
        },
      },
    },
  },

  /**
   * Riskli müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/risky
   */
  "customers/risky": {
    offsetInputLabel: "Day(s) Selection For",
    offsetItem15: "Those which have been inactive for more than 15 days",
    offsetItem30: "Those which have been inactive for more than 30 days",
    offsetItem45: "Those which have been inactive for more than 45 days",
    offsetItem60: "Those which have been inactive for more than 60 days",

    headings: {
      code: "Client Code",
      fullName: "Name Surname",
      phone: "Phone Number",
      sex: "Gender",
      incomeAll: "Amount Earnt",
      debtAll: "Amount of Debt",
      lastTransaction: "The time passed over the last transaction",
    },

    warning: {
      allCustomersDeletedSuccess: "All clients deleted successfully.",
      selectedCustomersDeletedSuccess: "Selected clients deleted successfully.",
    },

    sendSMSButtonTitle: "Send Campaign SMS",
    selectedAllButtonTitle: "Select All",
    cancelAllButtonTitle: "Remove All",
    deleteSelectedCustomers: "Delete Selected Client",
    deleteAllCustomer: "Delete All Client",
  },

  /**
   * Değerlendirme anketlerinin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/rating
   */
  "customers/rating": {
    headings: {
      createdAt: "Evaluation Date",
      staffFullName: "Staff",
      serviceName: "Service Applied",
      companyStar: "Enterprise Point",
      staffStar: "Staff Point",
      description: "Explanation",
    },

    deleteButtonTitle: "Delete Evaluation",
    replyToComment: "Reply",
    viewToComment: "View",
    delete: {
      title: "Do you confirm to delete?",
      alertText:
        "This action is irreversible! You cannot access it anymore if you delete the evaluation survey.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
    },

    deleteToast: "Specified evaluation has been successfully deleted!",
    deleteBeforePlanUpgradeToast:
      "You must upgrade to premium package in order to delete customer comments.",
  },

  /**
   * Müşterilerin eklendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/add
   */
  "customers/add": {
    validate: {
      phoneNumber: "Phone Number",
      name: "Name",
      surname: "Surname",
      birthDate: "Birth Date",
    },
    nameInputLabel: "Client Name",
    nameInputPlaceholder: "Enter Client Name",

    surnameInputLabel: "Client Surname",
    surnameInputPlaceholder: "Enter Client Surname",

    phoneInputLabel: "Phone Number",

    genderInputLabel: "Gender",
    genderMale: "Male",
    genderFemale: "Female",

    birthdayInputLabel: "Date of Birth",
    birthdayInputInvalidMessage:
      "Enter your date of birth as required Day/Month/Year",

    maritalStatusInputLabel: "Marital Status",
    maritalStatusMarried: "Married",
    maritalStatusSingle: "Single",

    secondPhoneInputLabel: "Additional Phone Number",
    secondPhoneInputPlaceholder: "Enter second phone number if exists.",

    mailInputLabel: "E-Mail Address",
    mailInputPlaceholder: "Enter e-mail address if exists",

    descriptionInputLabel: "Additional Explanations",

    workStatusInputLabel: "Employment Status",
    workStatusWorking: "Employed",
    workStatusUnemployed: "Unemployed",
    workStatusEmployer: "Employer",
    workStatusStudent: "Student",
    workStatusHouseWife: "Housewife",

    relatedBusinessInputLabel: "Related Foundation",
    relatedBusinessInputPlaceholder:
      "Please indicate the foundation that client has come from if known.",

    sendSmsInputLabel: "Email Sending Status",
    sendSmsTrue: "Send",
    sendSmsFalse: "Do not Send",

    selectPhoneNumberForMessage: "Select the number to send messages to",
    firstPhoneNumber: "First phone number",
    secondPhoneNumber: "Second phone number",
    allPhoneNumber: "Both",

    createNewCustomerButtonTitle: "Create Client",
  },

  "customers/passive": {
    makeActiveButtons: {
      selectedButtonTitle: "Activate Selected",
      allButtonTitle: "Activate All",
    },

    selectedCustomersSuccessToast:
      "Selected clients have been successfully activated!",
    allCustomersSuccessToast: "All clients have been successfully activated!",
  },

  /**
   * Toplu müşteri ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/customers/add/bulk
   */
  "customers/add/bulk": {
    uploadAreaText: "Please click here to upload the excel file",
    stepsHeading: "Uploading Bulk Client Steps with Excel",
    steps: [
      "In order to add your client into the system over excel, you must upload the excel data created for you.",
      "By clicking the button on next step, download excel template.",
      "Download Excel Format",
      "Convert your client data to previously downloaded excel format and upload them into system.",
    ],
    boldText:
      "IMPORTANT: For lossless and faultless data transfer, locations and names of columns must not be changed on excel template!",

    preview: {
      heading: "Preview of Excel Uploading",
      completeButtonTitle: "Complete Bulk Clients Registration",

      nameInputLabel: "Name",
      surnameInputLabel: "Surname",
      phoneInputLabel: "Phone Number",
      sendSmsInputLabel: "Sending Email",
      sendSmsTrue: "Open",
      sendSmsFalse: "Close",

      genderInputLabel: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      mailInputLabel: "E-mail Address",
      birthdayInputLabel: "Date of Birth",

      maritalStatusInputLabel: "Marital Status",
      maritalStatusMarried: "Married",
      maritalStatusSingle: "Single",
    },

    duplicateRecords: "%s duplicate records were deleted.",

    insertToast:
      " Transaction of bulk upload has been successfully queued. You are being redirected to homepage.",
  },

  /**
   * Hizmet listesinin bulunduğu key.
   * https://app.salonrandevu.com/services
   */
  services: {
    validations: {
      servicesCategory: "Service Category",
      servicesName: "Service Name",
      servicesAmount: "Service Amount",
      servicesBounty: "Bounty Percentage",
      servicesTime: "Processing Time",
    },
    buttons: {
      allServices: "All Services",
      categories: "Categories",
      selectAll: "Select All",
      selectedAll: "All Selected",
      removeAll: "Remove All",
    },
    filter: {
      title: "Service Search Filter",
      inputLabel: "Service Name",
    },

    headings: {
      name: "Service Name",
      processTime: "Processing Time",
      bounty: "Bonus Percentage",
      bountyMoney: "Bounty Amount",
      amount: "Service Fee",
      showAtUrl: "Online Appointment Settings",
    },

    replacements: {
      showAtUrlTrue: "Open",
      showAtUrlFalse: "Close",
    },

    deleteButtonTitle: "Delete Service",
    delete: {
      title: "Do you confirm to delete service?",
      alertBoldText: "This action is irreversible!",
      alertText:
        "If you delete this service, you cannot control cheque management or book/complete appointments any more over this service. Appointments relating to this service are reserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Discard",
    },

    dataNotFound: "Data Not Found!",

    deleteToast: "Service has been successfully deleted.",

    addAllService: {
      warningTitle: "WARNING!",
      addButtonTitle: "Add Bulk Service",
      informationDialog: {
        addServiceDescription:
          "The duration and price information of the services you have transferred are automatically assigned by the system. Please update the service time and price information you have provided in your business.",
        confirmButtonTitle: "Confirm",
      },
    },

    deleteAll: {
      title: "Do you confirm deletion of services?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you delete all services, you will not be able to perform transactions in the ticket management, and you will not be able to make an appointment through these services while making/defining an appointment. Your existing appointments for these services will be preserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Discard",
      deleteAllServicesToast: "Services deleted successfully",
      deleteAllButtonTitle: "Delete All",
    },

    deleteSelectedServices: {
      title: "Do you confirm deletion of selected services?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you delete the selected services, you will not be able to perform transactions in the ticket management, and you will not be able to make an appointment through these services while making/defining an appointment. Your existing appointments for these services will be preserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Discard",
      deleteSelectedButtonTitle: "Delete Selected Services",
    },

    deleteToast: "The service was successfully deleted.",
  },

  /**
   * Hizmet detay/ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/services/add
   * https://app.salonrandevu.com/services/detail/id
   */
  "services/detail": {
    placeholder: {
      search: "Enter the service you're looking for",
    },
    validate: {
      serviceCategory: "Service Category",
      servicesName: "Service Name",
      servicePrice: "Service Price",
      servicesProcessTime: "Service Process Time",
    },
    selcetAllEmployees: "Select All Employees",

    serviceName: "That you are looking for ",
    lookingFor: "Service Name",
    ourList: "is not on our list?",
    createRequest: "Create Request",
    createNewRequest: "Create New Request",
    serviceCategoryInput: "Service Category",
    serviceCategoryHint:
      "The service category and service name you choose will be used on the Salon Appointment platform.",

    nameInputLabel: "Service Name",
    nameInputHint:
      "Enter the service name that will be listed on management application and appointment page.",

    amountInputLabel: "Service Fee",
    amountInputHint:
      "The amount you set is default amount. While adding to cheque you can change transaction fee.",

    bountyInputLabel: "Bonus Percentage (%)",
    bountyMoneyBased: "Bonus Money Based",

    bountySelectMoney: "Money Based ",
    bountySelectPer: "Bonus Percentage",

    bountyInputHint:
      " If your staffs fixed bonus percentage is undefined, they will earn bonus according to percentage will be defined.",

    pointInputLabel: "Points to Earn",
    pointInputHint: "100 client point corresponds to 1.",

    timeInputLabel: "Average Waiting Time (min)",
    timeInputHint:
      "Transaction time is an information that will be displayed while the client is booking an appointment. Staff who produce this service cannot accept a new appointment during the period of it .",

    repeatInputLabel: "Repetition Time (Day)",
    repeatInputHint:
      "If this service requires repetition of transaction, it allows you to send E-mail notification to client when the required time repetition comes.",

    urlInputLabel: "Online Appointment Settings",
    urlInputHint:
      "This is the setting if transaction will be shown or not for the online appointments booked from enterprise’s URL.",

    urlInputTrue: "Open",
    urlInputFalse: "Close",

    staffSectionTitle: "Service Application Staff",
    staffSelectAll: "Apply For All Staff",
    staffDeSelectAll: "Remove All",
    timeWarnAlertText:
      "The changes you make will be valid from creating new appointment onwards, previously created appointments will not be affected.",

    createButtonTitle: "Create Service",
    updateButtonTitle: "Update Service",
    deleteButtonTitle: "Delete Service",

    delete: {
      title: "Do you confirm to delete this service?",
      alertBoldText: "This action is irreversible!",
      alertText:
        "If you delete this service, you cannot control cheque management or book/complete appointments any more over this service. Appointments relating to this service are reserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
    },

    lastTransactions: {
      headings: {
        createdAt: "Transaction Date",
        staffFullName: "Staff",
        amount: "Transaction Fee",
      },
      showOnReceiptButton: "Shown in Cheque",
    },

    deleteToast: "Service has been successfully deleted.",
    insertToast: " Service has been successfully created.",
    updateToast: " Service has been successfully updated.",

    newServiceText: "Is the Service Name you are looking for not on our list?",
    newServiceBtn: " Create Request",

    newServiceInputTitle: "New Service Name",
    newServiceRequestToast:
      "Your new Service request has been successfully created.",
    newServiceRequestErrorToast: "New Service Name cannot be blank.",
    newServiceRequestDialogTitle: "New Service Request",
    newServiceCategoryHint:
      "The Service Category to which the new service will depend.",
    createNewServiceRequest: "Create Request",
    cancelNewServiceRequest: "Cancel",

    setCampaignTitle: "Set as Campaign",
    campaignNameTitle: "Campaign Name",
    campaignHint:
      "Products marked as Campaigns will appear in the Campaign field on your business page.",

    serviceDescriptionLabel: "Service Description",
    serviceDescriptionHint:
      "The service description allows your clients to get to know the service you offer better.",
  },

  "payment-notify": {
    copyIban: "Copy IBAN",
    ibanSuccess: "IBAN copied successfully.",
    depositBank: "Deposit Bank",
    depositAmonut: "Deposit Amount",
    depositDate: "Deposit Date",
    makePaymentNotice: "Make Payment Notice",
  },

  "services/requests": {
    headings: {
      time: "Transaction date",
      name: "Service Name",
      category: "Category",
      state: "Status",
      actionHeadingText: "Actions",
      stateCompleted: "Request Approved",
      stateIncompleted: "Request Not Approved",
      stateWaiting: "Awaiting Request Approval",
    },

    deletedRequestToast: "Request has been Deleted",

    cancel: {
      cancelButton: "Cancel",
      cancelTitle: "Do You Want to Delete New Service Request?",
      cancelConfirmButtonTitle: "Confirm",
      cancelDiscardButtonTitle: "Discard",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you cancel this request, we will not be able to perform the transaction and no new service check will be made.",
    },
  },

  "services/bounty": {
    addBountyTitle: "Add Bounty",

    bountyDescription:
      "You can add a special premium here for your services and employees that do not have a fixed premium percentage, with the option to define special premiums.",

    staffInputLabel: "Employee to be added bonus",
    categoryInputLabel: "Service Category",
    serviceInputLabel: "Service Name",
    bountyAmountInputLabel: "Bounty Percentage",
    bountyAdd: "Add",

    headings: {
      bounty: "Bounty Percentage(%)",
      serviceName: "Service Name",
      staffName: "Employee Name",
      deleteBountyButtonTitle: "Delete Bounty",
    },

    deleteBountyDialog: {
      title: "Are you sure you want to delete the bounty?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you delete this premium, your employee will not have a premium account linked to this service.",
      confirmButtonTitle: "Confirm",
      discardButtonTitle: "Discard",
    },

    addBountySuccessToast:
      "The service-specific employee bonus has been successfully added.",
    deleteBountySuccessToast:
      "The employee bonus for the service has been successfully deleted.",
  },

  noDataFound: "No Data Found",

  /**
   * Çalışanların listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees
   */
  employees: {
    editButtonTitle: "Edit Staff",
    createNewEmployee: "Create Staff",
    orderStaffs: "Order Staff",
  },

  /**
   * Çalışan profil sayfalarının listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees/detail/id
   * https://app.salonrandevu.com/employees/add
   * https://app.salonrandevu.com/profile
   */
  profile: {
    inputPhone: "Phone *",
    detail: {
      //Section
      title: "Staff Profile",

      nameInputLabel: "Name",
      surnameInputLabel: "Surname",

      genderInputLabel: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      jobTitleInputLabel: "Title",
      bountyInputLabel: "Percentage of Fixed Bonus",

      permissionInputLabel: "Panel Authorization Status",
      permissionOwner: "Authorized Person",
      permissionStaff: "Staff",
      permissionLimited: "Limited Authority",

      sendSmsInputLabel: "Email Sending",
      sendSmsTrue: "Send",
      sendSmsFalse: "Do not Send",

      urlInputLabel: "Display in Enterprise URL",
      showAtUrlTrue: "Display",
      showAtUrlFalse: "Do not Display",

      branch_list: "Branch List",
      branch_change: "Change Branch",
      branch_enter: "Enter Branch",
      branch_change_alert: "Branch change has been made.",
    },
    logoutButtonTitle: "Logout",

    contact: {
      //Section
      title: "Contact Information",

      mailInputLabel: "Email",
      phoneInputLabel: "Phone Number",
      passwordInputLabel: "Panel Password",
      newPasswordInputLabel: "New Panel Password",
    },

    services: {
      //Section
      title: "Offered Services",

      alertBoldText:
        "Services offered by the employee below must be selected by clicking on it!",
      alertText:
        "Otherwise services provided by staff at enterprise’s appointment address will be appeared as empty field.",
    },
    packages: {
      title: "Offered Packages",

      alertBoldText:
        "Packages offered by the employee can be selected by clicking on the boxes below!",
      alertText:
        "Otherwise, the packages offered by the employee will appear empty at the business appointment address.",
    },

    booking: {
      //Section
      title: "Appointment Hours",
      alertBoldText: "Manage Your Appointment Interval!",
      week: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      dayOpenText: "Open the day for appointment selection",
      dayCloseText: "Close the day for appointment selection",
    },

    photo: {
      //Section
      uploadButtonTitle: "Upload Photos",
      deleteButtonTitle: "Remove Photo",
    },

    createButtonTitle: "Create Staff",
    updateButtonTitle: "Save Changes",
    deleteButtonTitle: "Delete Staff",

    delete: {
      //Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertBoldText: "This action is irreversible!",
      alertText:
        "If you delete this staff, you might not be able to access staff profile and other information anymore.",
    },

    photoUploadToast: "Photo has been successfully uploaded.",
    photoDeleteToast: " Photo has been successfully removed.",
    insertToast: " Staff has been successfully created.",
    updateToast: " Staff has been successfully updated.",
    deleteToast: " Staff has been successfully deleted.",

    limited_permission_label: {
      no_permission: "No Access",
      display: "Only View",
      full_permission: "Full Permision",
    },
  },

  /**
   * SMS Yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms
   */
  sms: {
    smsCode: {
      name: "Name",
      surname: "Surname",
    },
    wpSettings: {
      addDevice: "Add Device",
      removeDevice: "Remove Device",
      whatsappNumber: "WhatsApp Number",
      whatsappCredit: "WhatsApp Credit",
      status: {
        title: "Status",
        active: "Active",
        passive: "Passive",
        quited: "Quitted",
      },
    },
    qRCode: {
      searchCode: "Scan QR Code",
      close: "Close",
      again: "Send Again",
    },
    index: {
      whatsAppMessage: "WhatsApp Message",
    },
    foundWpDevice: "WhatsApp device registered to your account has been found.",
    noFoundWpDevice:
      "No whatsapp devices registered to your account were found.",

    creditSMSLabel: "Usable/Remaining SMS Credit",
    creditWPLabel: "Usable/Remaining WP Credit",
    senderLabel: "Sender Name",

    sendType: {
      label: "Message Sending method",
      sendSMS: "Send SMS",
      sendWP: "Send Whatsapp Message",
      both: "Send on both",
    },
    credit: {
      sms: "Sms Credit",
      wp: "WhatsApp Credit",
    },
    loginWithQr: "Scan QR Code",
    loginWithNo: "Enter Phone Number",

    addDevice: "Add Device",
    removeDevice: "Remove Device",

    deviceStatus: "Status",
    wpPhone: "WhatsApp Number",
    wpCredits: "WhatsApp Credits",

    phoneNotConnected: "Your Number is Not Connected",

    deviceConnected: "Device Connected",
    deviceNotConnected: "Device Not Connected",

    wpQrTutorial1: "Open the WhatsApp application, then tap on the ︙.",
    wpQrTutorial2: "Tap on the 'Connect Device' button.",
    wpQrTutorial3: "Scan your QR code with your camera.",

    qrPopupTitle: "Scan QR Code",
    qrPopupContext:
      "Scan the QR code using your device's camera to connect to the application.",

    withPhoneNumberTitle:
      "Enter the Number for Which You Want to Receive WhatsApp Code.",
    codeForThisPhone: "WhatsApp verification code for this number",

    alertBoldText: "Your Email sender information is being taken...",
    alertText: "It may take for a while please wait.",

    undefinedAlerts: {
      wp_heading:
        "No device found for sending WhatsApp messages in your account",
      wp_content:
        "There is no WhatsApp thread that you can send with your business name. If you want to be able to send special WhatsApp messages to your clients other than the fixed information messages of the platform",
      wp_span: " define device to your account.",
      sms_heading: "SMS header is not defined for customized deliveries!",
      sms_content:
        "  There is no SMS header that you can send with your business name. If you want to be able to send special SMS messages to your clients other than the fixed information messages of the platform, you need to purchase an SMS service.",
    },

    sendButtonText: "View",
    dialogTitle: "Sms viewing",
    buttonApprove: "Approve",
    buttonCancel: "Cancel",

    groupSMSReports: "Group SMS Reports",
    singleSMSReports: "Automatic SMS Reports",
    groupWPReports: "Group WP Reports",
    singleWPReports: "Automatic WP Reports",

    lastSendingsTitle: "Last Email Sendings",
    creditPaybackButtonTitle: "Get Refund Credits",

    headings: {
      date: "Date",
      sender: "Sender Name",
      phone: "Phone Number",
      status: "Sending Status",
      content: "Message Content",
    },
    table_titles: {
      date: "Date",
      total: "Total",
      successful: "Successful",
      unsuccessful: "Unsuccessful",
      post_postCredit: "Before Post-Credit",
      content: "Content",
    },
    bottom_table_titles: {
      phone: "Phone Number",
      status: "Status",
      message_content: "Message Content",
      details: "Details",
      successful: "Successful",
      unsuccessful: "Unsuccessful",
      data_not_found: "Data Not Found!",
    },
    replacements: {
      status: ["Sent", "Rejected", "Invalid Number", "Return", "In queue"],
    },

    send: {
      // Section
      messageInputLabel: "Message Content",
      chartType: "Character Type",
      charTR: "Turkish",
      charNormal: "Normal",
      buttonTitle: "Send Message",
      discardButtonTitle: "Discard",
      sendDate: "Send Date",
      futureDate: "Advanced SMS",

      autoText: {
        nameSurname: "Name Surname",
        amountPaid: "Amount Paid",
        remainingDebt: "Remaining Debt",
        businessUrl: "Business Url",
        shortCode: "Short Code",
        forSmsSelection: "For SMS Selection",
        writeIt: "write it to 4607.",
      },

      check: {
        // Modal
        title: "Do you confirm this action?",
        confirmButtonTitle: "Yes",
        discardButtonTitle: "No",
        alertText:
          "If your credit is enough, sending process will be started afterwards your confirmation. This action is irreversible!",
      },

      template: "Template Selection",

      nameSurnameShortcut: "Name Surname",
      successToast: "Email sending has been successfully started!",
    },
  },

  /**
   * Seçimli SMS Gönder sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/selective
   */
  "sms/selective": {
    continueButtonTitle: "Continue",
    backToSelectButtonTitle: " Return to Selections",

    warningText: "The Send List cannot be the same as the Exclude List!",

    customer: {
      // Section
      title: "Client List",
      headings: {
        fullName: "Name Surname",
        phone: "Phone Number",
      },

      searchInputLabel: "Search for Client",
      selectAll: "Select All Client",
      unselectAll: "Remove All Selection",
    },

    approved: {
      // Section
      title: "List to be Sent",
      notFound: {
        alertBoldText: "The selection has not been made among clients to send!",
        alertText:
          "You can start to select your costumer by clicking the tik sign in client list.",
      },

      alertBoldText: "Your all client have been successfully selected!",
    },

    excepted: {
      // Section
      title: "List to be Excluded",
      notFound: {
        alertBoldText:
          "Clients who will be excluded from sending have not been selected!",
        alertText:
          "You can exclude client by clicking block button in table if there are exceptional clients who will be excluded from sending in others.",
      },
    },
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/filter": {
    applyFilterButtonTitle: "Apply Filter",
    continueButtonTitle: "Continue",
    backToFilterButtonTitle: "Return to Filter",

    tabs: {
      // Section
      debt: "Status of Dept",
      debtTrue: "Indebted",
      debtFalse: "Debtless",

      risk: "Status of Risk",
      risk15: " Those who did not have a transaction in the last 15 days",
      risk30: " Those who did not have a transaction in the last 30 days",
      risk45: " Those who did not have a transaction in the last 45 days",
      risk60: " Those who did not have a transaction in the last 60 days",

      work: "Status of Employment",
      workWorking: "Employed",
      workUnemployed: "Unemployed",
      workEmployer: "Employer",
      workStudent: "Student",
      workHouseWife: "Housewife",

      gender: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      marital: "Marital Status",
      maritalMarried: "Married",
      maritalSingle: "Single",

      service: "Service Taken",
      staff: " Staff Who Proceeded Transaction",
      age: "Age Group",
    },

    customer: {
      // Section
      title: "Client List",
      headings: {
        fullName: "Name Surname",
        phone: "Phone Number",
        gender: "Gender",
      },
      replacements: {
        genderNull: "Unspecified",
        genderFemale: "Female",
        genderMale: "Male",
      },
    },

    except: {
      title: "Those who will be excluded form Filtration List",
      alertBoldText:
        "Clients who will be excluded from sending have not been selected!",
      alertText:
        "You can exclude client by clicking block button in table after filtration if there are exceptional clients who will be excluded from sending in others.",
    },
  },

  "sms/group": {
    continueButtonTitle: "Continue",
    backToSelectButtonTitle: "Return to Selections",

    newGroupButtonTitle: "Create New Group",

    group: {
      title: "Group List",

      headings: {
        groupName: "Group Name",
        customerCount: "Client Count",
      },

      searchInputLabel: "Search for Group",
      searchInputPlaceholder: "Search for Group ",
      selectAll: "Select All Group",
      unselectAll: "Remove All Selection",
    },

    approved: {
      title: "List to be Sent",
      notFound: {
        alertBoldText: "The selection has not been made among groups to send!",
        alertText:
          "You can start to select your group by clicking the tik sign in group list.",
      },

      alertBoldText: "Your all groups have been successfully selected!",
    },

    excepted: {
      title: "List to be Excluded",
      notFound: {
        alertBoldText:
          "Groups who will be excluded from sending have not been selected!",
        alertText:
          "You can exclude group by clicking block button in table if there are exceptional groups who will be excluded from sending in others.",
      },
    },

    newGroup: {
      title: "Create New Group",

      groupName: "Group Name",
      groupEditButtonTitle: "Edit Group",

      customersTitle: "Client List",
      selectedCustomersTitle: "Selected Clients",

      searchCustomer: "Search for Client",
      searchCustomerDescription: "Write the client name and number",
      selectionDescription:
        "You can select client by clicking the tik sign in client list.",
      notFoundCustomer: "The client(s) you are looking for were not found.",

      selectAllButtonTitle: "Select All",
      unselectAllButtonTitle: "Remove All Selection",
      deleteAllButtonTitle: "Delete All",

      alertBoldText: "Your all clients have been successfully selected!",

      createButtonTitle: "Create Group",
      cancelButtonTitle: "Cancel",

      successToast: "Group has been successfully created!",
      emptyGroupName: "Group name can not be empty!",

      edit: {
        editGroupTitle: "Update Group",
        editButtonTitle: "Update",
        deleteGroupButtonTitle: "Delete Group",

        updateSuccessToast: "Group has been successfully updated!",
        deleteSuccessToast: "Group has been successfully deleted!",
      },
    },

    successToast: "Group has been successfully created!",
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/settings": {
    updateButtonTitle: "Update Settings",
    customerSwitchTitle: "Client",
    staffSwitchTitle: "Staff",
    onOffSwitchTitle: "Open/Close",

    padiContent:
      "Paid content is not active. Please contact your client representative.",

    reminder: {
      title: "Appointment Reminder",
      description:
        "This is the setting of sending or not sending Email relating to appointment reminder.",
    },

    longTermReminder: {
      title: "Appointment Reminder for Long Period",
      description:
        "This is the setting of sending or not sending Email day before the appointment if there is more than one day to appointment date",
    },

    requestInfo: {
      title: "Appointment Request Notification",
      description:
        "This is the setting of sending /not sending Email if there is a new online appointment request.",
    },

    whenApproved: {
      title: "When Appointment Request Confirmed",
      description:
        " This is the setting of sending /not sending Email when online appointment request has been confirmed.",
    },

    whenRejected: {
      title: "When Appointment Request Rejected",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenCreated: {
      title: "When Appointment Created",
      description:
        "This is the setting of sending /not sending Email at the time of appointment being created.",
    },

    whenCancelled: {
      title: "Active Appointment Cancellation",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenBirthday: {
      title: "Birthday Message",
      description:
        "This is the setting of sending/not sending congratulation Email to client who has got birthday. This setting can only work on if you have got a special sender name for your enterprise/yourself.",
    },

    whenCustomerCreate: {
      title: "When Client Added",
      description:
        " This is the setting of sending/not sending Email notification to client who has been added to your client list after client registration.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Reminding the Client Who Does Not Appointment",
      description: [
        "Automatic reminders to clients who have not visited your business for 60 days",
        "Email is sent.",
        "Sample Message Content: Dear Figen Çelik, we missed you so much!",
        "We noticed that you have not been trading for 60 days. Hope to see you again, We wish you happy days ..",
        "To make an appointment: https://app.salonmanagementapp.com/company/2037",
      ],
    },

    dragAndDrop: {
      title: "Drag and Drop Appointment",
      description:
        "It is the setting to send/not send WhatsApp to client when appointment is drag and drop.",
    },

    smsShortcode: {
      title: "SMS Short Code",
      description:
        "It is the setting for WhatsApp to go/not go when the client number is added to the blacklist.",
    },

    appointmentUpdate: {
      title: "Appointment Update",
      description:
        "It is the setting to send/send the updated appointment time and date via SMS.",
    },

    smsAttendedApproval: {
      title: "Sending Link for Appointment Attendance via SMS",
      description:
        "A link is sent to find out whether or not to attend the appointment created by the client. This link is a setting for the client to choose whether to attend the appointment positively or negatively.",
    },

    afterAppointment: {
      title: "Evaluation After Appointment",
      description:
        "This is the setting of sending/not sending Email for evaluation after appointment.",
    },

    reminderHour: {
      title: "Hours for Sending Appointment Reminder",
      description:
        "This is the setting of time arrangement for Email that will be sent before appointment.",

      inputLabel: "How many hours before to send?",
      options: [
        "Close",
        "1 hour",
        "2 hours",
        "3 hours",
        "4 hours",
        "5 hours",
        "6 hours",
        "7 hours",
        "8 hours",
        "9 hours",
        "10 hours",
        "11 hours",
        "12 hours",
        "13 hours",
        "14 hours",
        "15 hours",
        "16 hours",
        "17 hours",
        "18 hours",
        "19 hours",
        "20 hours",
        "21 hours",
        "22 hours",
        "23 hours",
        "24 hours",
      ],
    },

    updateToast: "Email settings have been successfully updated.",
  },

  "sms/wp/settings": {
    updateButtonTitle: "Update Settings",
    customerSwitchTitle: "Client",
    staffSwitchTitle: "Staff",
    onOffSwitchTitle: "Open/Close",

    padiContent:
      "Paid content is not active. Please contact your client representative.",

    reminder: {
      title: "Appointment Reminder",
      description:
        "This is the setting of sending or not sending Email relating to appointment reminder.",
    },

    longTermReminder: {
      title: "Appointment Reminder for Long Period",
      description:
        "This is the setting of sending or not sending Email day before the appointment if there is more than one day to appointment date",
    },

    requestInfo: {
      title: "Appointment Request Notification",
      description:
        "This is the setting of sending /not sending Email if there is a new online appointment request.",
    },

    whenApproved: {
      title: "When Appointment Request Confirmed",
      description:
        " This is the setting of sending /not sending Email when online appointment request has been confirmed.",
    },

    whenRejected: {
      title: "When Appointment Request Rejected",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenCreated: {
      title: "When Appointment Created",
      description:
        "This is the setting of sending /not sending Email at the time of appointment being created.",
    },

    whenCancelled: {
      title: "Active Appointment Cancellation",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenBirthday: {
      title: "Birthday Message",
      description:
        "This is the setting of sending/not sending congratulation Email to client who has got birthday. This setting can only work on if you have got a special sender name for your enterprise/yourself.",
    },

    whenCustomerCreate: {
      title: "When Client Added",
      description:
        " This is the setting of sending/not sending Email notification to client who has been added to your client list after client registration.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Reminding the Client Who Does Not Appointment",
      description: [
        "Automatic reminders to clients who have not visited your business for 60 days",
        "Email is sent.",
        "Sample Message Content: Dear Figen Çelik, we missed you so much!",
        "We noticed that you have not been trading for 60 days. Hope to see you again, We wish you happy days ..",
        "To make an appointment: https://app.salonmanagementapp.com/company/2037",
      ],
    },

    dragAndDrop: {
      title: "Drag and Drop Appointment",
      description:
        "It is the setting to send/not send WP to client when appointment is drag and drop.",
    },

    smsShortcode: {
      title: "WhatsApp short code",
      description:
        "It is the setting for WhatsApp to go/not go when the client number is added to the blacklist.",
    },

    appointmentUpdate: {
      title: "Appointment Update",
      description:
        "It is the setting to send/send the updated appointment time and date via Email.",
    },

    smsAttendedApproval: {
      title: "Sending Link for Appointment Attendance via WhatsApp",
      description:
        "A link is sent to find out whether or not to attend the appointment created by the client. This link is a setting for the client to choose whether to attend the appointment positively or negatively.",
    },

    afterAppointment: {
      title: "Evaluation After Appointment",
      description:
        "This is the setting of sending/not sending Email for evaluation after appointment.",
    },

    reminderHour: {
      title: "Hours for Sending Appointment Reminder",
      description:
        "This is the setting of time arrangement for Email that will be sent before appointment.",

      inputLabel: "How many hours before to send?",
      options: [
        "Close",
        "1 hour",
        "2 hours",
        "3 hours",
        "4 hours",
        "5 hours",
        "6 hours",
        "7 hours",
        "8 hours",
        "9 hours",
        "10 hours",
        "11 hours",
        "12 hours",
        "13 hours",
        "14 hours",
        "15 hours",
        "16 hours",
        "17 hours",
        "18 hours",
        "19 hours",
        "20 hours",
        "21 hours",
        "22 hours",
        "23 hours",
        "24 hours",
      ],
    },

    updateToast: "Email settings have been successfully updated.",
  },

  "sms/template": {
    notEmpty: "Template cannot be empty",
    createTemplateButtonTitle: "Create New Template",

    fillAll: "Please fill in all required fields",

    templatesHeadings: {
      name: "Name",
      content: "Content",
    },

    deleteButtonTitle: "Delete Template",

    templateDialog: {
      addTitle: "New Template",
      updateTitle: "Template Detail/Edit",

      templateTitleInputLabel: "Tamplate Name",
      templateTitleInputPlaceholder: "Enter template name",

      templateContentInputLabel: "Tamplate Content",

      createButtonTitle: "Create",
      updateButtonTitle: "Update",
      discardButtonTitle: "Discard",
    },

    deleteConfirm: {
      title: "Do you approve to delete template?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertBoldText: "This process can not be reverted !",
      alertText:
        "If you remove this phone number from black list, Bulk SMS will be able to send this number SMSs.",
    },

    addSuccessToast: "Successfully template created.",
    updateSuccessToast: "Successfully template updated",
    deleteSuccessToast: "Successfully template deleted",
  },

  "sms/blacklist": {
    totalNumber: "Total Number count: ",
    blacklistHeadings: {
      number: "Number",
      addDate: "Add Date",
    },
    deleteUserBlacklist: "Remove number from the list",
    numberInputLabel: "Enter the numbers that you want to block.",
    addToBlacklist: "Add to Black List",
    addToBlacklistError:
      "Please enter a valid number, use ',' to add multiple numbers.",
    addSuccessToast: "Succesfully number added.",

    deleteConfirm: {
      title: "Do you approve to remove number from the list?",
      // alertText:"If you delete this number you will not be able to reach it again.",
    },
  },

  /**
   * Muhasebe Yönetimindeki ortak kullanımda olan key.
   * https://app.salonrandevu.com/accounting/income
   * https://app.salonrandevu.com/accounting/debt
   */
  accounting: {
    showOnReceipt: "Show in Cheque",

    startInputLabel: "Starting Date",
    endInputLabel: "Ending Date",
    typeInputLabel: "Payment Method",

    typeALL: "All Method",
    typeCASH: "Cash",
    typeCARD: "Credit/Bank Card",
    typeEFT: "Transfer",

    totalIncome: "Total Income",
    totalDebt: "Total Debt",

    alertBoldText: "By using the field below, you can make changes.",
    alertText:
      "If you make changes on the fields, this will be applied automatically.",

    today: "Today",
    thisWeek: "This Week",
    thisMonth: "This Month",

    incomeMethods: "Revenue transactions",

    filterArea: {
      header: "Select Time Range",
      yesterday: "Yesterday",
      lastSevenDays: "Last 7 Days",
      lastFifteenDays: "Last 15 Days",
      lastThirtyDays: "Last 30 Days",
      approve: "Approve",
      cancel: "Cancel",
    },

    noRecords: "The data is equal to zero in the date range you select.",
  },

  /**
   * Gelir işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/income
   */
  "accounting/income": {
    headings: {
      createdAt: "Payment Date Specified",
      customerFullName: "Client Name",
      amount: "Transaction Fee",
      type: "Payment Method",
    },
  },

  /**
   * Borç işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debt
   */
  "accounting/debt": {
    headings: {
      createdAt: "Transaction Date",
      customerFullName: "Client Name",
      amount: "Total",
      paid: "Paid",
      remaining: "Remaining",
    },
  },

  /**
   * Prim işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/bounty
   */
  "accounting/bounty": {
    payButtonTitle: "Pay Premium",
    allPayButtonTitle: "Pay All",

    headings: {
      staffFullName: "Staff Full Name",
      bounty: "Bonus Amount",
    },

    check: {
      title: "Bonus Payment",
      confirmButtonTitle: "Make Payment",
      discardButtonTitle: "Cancel",
      alertBoldText:
        "There is {bounty} bonus payment for {selected_employee} staff.  You can fill process date and payment type for bonus payment.",
    },
  },

  /**
   * Gider işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense
   */
  "accounting/expense": {
    newFields: {
      expenseType: "Expense Type",
      expenseAmount: "Expense Amount",
      deletingReversable: "This operation cannot be undone",
      approveDeleting: "The expense will be deleted from the table",
      selectPaymentType: "Please select the payment type.",
    },
    add: {
      head: "New Expense",
      close: "Cancel",
      title: "Add New Expense",
      typeInputLabel: "Expense Item",
      amountInputLabel: "Amount",
      amountInputPlaceholder: "Enter this field related expense amount.",
      descriptionInputLabel: "Explanation",
      descriptionInputHint:
        "In this field you can make notes about related transaction that you would like to add.",

      buttonTitle: "Add Expense",

      paymentType: "Payment Type",
      processDate: "Process Date",

      spending: "Spending",
      spendingPlaceholder: "Enter your spend.",
    },

    table: {
      title: "Expense Transaction",
      headings: {
        createdAt: "Created Date",
        transactionDate: "Transaction Date",
        type: "Expense Item",
        description: "Expense Explanation",
        amount: "Amount",
      },
      deleteButtonTitle: "Delete Expense",
    },

    totalExpenses: "Total Expense",

    deleteToast: "Expense record has been successfully deleted.",
    insertToast: "Expense has been successfully created.",

    today: "Today",
    thisWeek: "This Week",
    thisMonth: "This Month",

    incomeMethods: "Revenue transactions",

    filterArea: {
      header: "Select Time Range",
      yesterday: "Yesterday",
      lastSevenDays: "Last 7 Days",
      lastFifteenDays: "Last 15 Days",
      lastThirtyDays: "Last 30 Days",
      approve: "Approve",
      cancel: "Cancel",
    },

    cash: "Cash",
    creditBankCard: "Credit/Bank Card",
    transfer: "Transfer",
    customerPoint: "Client Point",

    deleteConfirm: {
      title: "",
      explanationTop: "",
      explanationBottom: "",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense/types
   */
  "accounting/expense/types": {
    alertBoldText: "About Expense Item",
    alertText:
      "By defining your specific transaction expense items in this field, you can make transactions over those items created by you.",

    saveChangesButtonTitle: "Save Changes",
    typeInputLabel: "{i}. Special Expense Item",

    insertToast: "Transaction has been successfully done.",

    moreButtonTitle: "More",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/credit
   */
  "accounting/credit": {
    filter: {
      title: "Based on Date Filtration",
      satrtDate: "Start Date",
      endDate: "End Date",
      name: "Client Name",
    },

    add: {
      customerSelection: "Select Client",
      scheduledPaymentDate: "Scheduled Payment Date",
      enterAmount: "Enter Amount",
      explanation: "Explanation",
      saveReceivable: "Save Receivable",
      updateReceivable: "Update Receivable",
      collectPayment: "Collect Payment",
      paymentDialogTitle: "Payment",
      savePaymnet: "Save Payment",
      closePayment: "Close Payment",

      date: "Date",
      amount: "Amount",
      paymentType: "Payment Type",
      actions: "Actions",
      delete: "Delete",
      update: "Update",

      start_amount: "Start Amount",
      end_amount: "End Amount",

      missingInfo: "You entered missing information",
    },

    payments: {
      typeInputLabel: "Payment Type",
      typeInputPlaceholder: "Select Payment Type",

      amountInputLabel: "Enter Amount",

      typeCASH: "Cash",
      typeCARD: "Credit / Bank Card",
      typeEFT: "Bank Transfer",
      typePOINT: "Client Points",
    },

    addCredit: "Add Credit",

    paid: "Paid",
    notPaid: "Not Paid",

    headings: {
      createdAt: "Transaction Date",
      scheduledPaymentDate: "Scheduled Payment Date",
      nameSurname: "Name-Surname",
      all_amount: "All Amount",
      paid: "Paid",
      debt_total: "Debt Total",
      view: "Detail & View",
      delete: "Delete",
      transactions: "Transactions",
      is_paid: "Payment Status",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debit
   */
  "accounting/debit": {
    filter: {
      title: "Based on Date Filtration",
      satrtDate: "Start Date",
      endDate: "End Date",
      name: "Client Name",
    },

    add: {
      customerSelection: "Client Name",
      scheduledPaymentDate: "Scheduled Payment Date",
      enterAmount: "Enter Amount",
      explanation: "Explanation",
      saveReceivable: "Save Debt",
      uppdateReceivable: "Update Debt",
      collectPayment: "Collect Payment",
      paymentDialogTitle: "Payment",
      savePaymnet: "Save Payment",
      closePayment: "Close Payment",

      date: "Date",
      amount: "Amount",
      paymentType: "Payment Type",
      actions: "Actions",
      delete: "Delete",
      update: "Update",

      start_amount: "Start Amount",
      end_amount: "End Amount",

      debitAdded: "Debt Added",
      missingInfo: "You entered missing information",
      errorAccur: "An error occurred",
    },

    payments: {
      typeInputLabel: "Payment Type",
      typeInputPlaceholder: "Select Payment Type",

      typeCASH: "Cash",
      typeCARD: "Credit / Bank Card",
      typeEFT: "Bank Transfer",
      typePOINT: "Client Points",
    },

    addCredit: "Add Debit",

    paid: "Paid",
    notPaid: "Not Paid",

    headings: {
      createdAt: "Transaction Date",
      scheduledPaymentDate: "Scheduled Payment Date",
      nameSurname: "Name-Surname",
      all_amount: "All Amount",
      paid: "Paid",
      debt_total: "Debt Total",
      view: "Detail & View",
      delete: "Delete",
      transactions: "Transactions",
      is_paid: "Payment Status",
    },
  },

  /**
   * Raporlar modülünün genel kullanımlarının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   * https://app.salonrandevu.com/reports/services
   * https://app.salonrandevu.com/reports/stock
   * https://app.salonrandevu.com/reports/staff
   * https://app.salonrandevu.com/reports/customer
   */
  reports: {
    startInputLabel: "Starting Date",
    endInputLabel: "Ending Date",

    headings: {
      stockName: "Product Name",
      serviceName: " Product Name",
      transactionCount: "Number of Transaction",
      soldCount: "Sales Number",
      income: "Income Earnt",
    },

    selectedStaffInputLabel: "Employee Selection",
    selectedPageCountInputLabel: "Page Count",
  },

  /**
   * İşletme raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   */
  "reports/company": {
    soldItem: "Sold Product",
    soldService: "Service Applied",

    serviceIncome: "Service Income",
    itemIncome: "Product Income",

    generalIncome: "Paid Income",
    maxExpense: "Total Expense",
    netIncome: "Net Income",
    totalIncome: "Total Income",

    typeCASH: "Cash",
    typeCARD: "Credit/Bank Card",
    typeEFT: "Transfer",
    _maxAppointment: "Total Number of Appointment",
    _maxReceipt: "Total Number of Cheque",
    _serviceIncome: "Income Earnt from Services",
    _itemIncome: "Income Earnt from Product",
    _paidBounty: "Bonus Paid to Staff",

    alertText:
      "The values specified as service income and product income are calculated over fully paid and debt-free cheques.",

    incomeReports: "Income Reports",
    expensesReports: "Expense Reports",
    summary: "Summary",

    goToDetail: "Go To Details",
  },

  /**
   * Çalışan raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/staff
   */
  "reports/staff": {
    name: "Staff Name",
    serviceCount: "Number of Services Provided",
    soldItemCount: "Number of Product Sold",

    onlyService: "Services Provided",
    bountyService: "Bonus Services",
    productSum: "Product Turnover",
    bountyProduct: "Product Premium",
    packageSum: "Package Turnover",
    bountyPackage: "Package Premium",

    endorsement: "Total Turnover",
    bounty: "Total Premium",

    survey: "Evaluation",
  },

  /**
   * Müşteri raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/customer
   */
  "reports/customer": {
    name: "Client Name",
    appointmentCount: "Number of Appointment",
    transactionCount: "Number of Transaction Performed",
    productCount: "Number of Product Sold",
    totalEarned: "Earnings in Total",
  },

  /**
   * Yapılacaklar listesi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/todos
   */
  todos: {
    toastSuccess: "Operation has been successfully done.",
    toastWarningMessage: "Oparation content can't be empty.",
    toastWarningProcess: "Operation adding process has been failed.",

    addButtonTitle: "Create Task",

    addTodoTitle: "Add Task",
    todoInputLabel: "Task Content",
    todoInputPlaceholder:
      " In this field you can enter your notes and tasks relating to your to do list.",

    dateTitle: "Dated Task",
    notFoundText: "There is no saved task on {date}.",

    statusWaiting: "PENDING",
    statusCompleted: "COMPLETED",

    updateToast: "Task status has been successfully updated.",
    insertToast: "Task has been successfully added.",
    deleteToast: " Task has been successfully deleted.",
    deleteToastDescription: "Task will be deleted.",

    deleteTaskQuestion: "Do you want to delete the task?",
    deleteTaskDescription: "The task will be removed from your task list.",

    emptyWarning: "You cannot create empty tasks.",
  },

  /**
   * Cüzdan sayfasının bulunduğu key.
   * https://app.salonrandevu.com/wallet
   */
  wallet: {
    cardTitle: "Total Balance",
    amountInputLabel: "Amount to Top up ($)",
    amountButtonTitle: "Top up Balance",

    remainingSMS: "Remaining Email Credit",
    licenseDetail: "Package Used",
    licenseRemaining: "Remaining to License Expiry",
    appointmentRemaining: "Remaining Appointment Credit",
    paymentForm: "Bank Accounts / Payment Notification Form",

    licenseHeading: "Buy License",
    smsHeading: "Buy Email Package",
    buy: "Buy",
    package_prices: "Package Prices",
    kdv: "Excluding VAT",

    load_balance: "Load Balance",

    withEFT: "TO TRANSFER",
    buyButtonTitle: "Buy",
    includesAll: "Included in All Licenses",

    see_more: "Show More",
    see_less: "Show Less",

    licenses: "Licenses",
    message_packages: "Message Packages",
    sms_price: "WhatsApp Package Prices",
    sms_package: "SMS Packages",
    wp_package: "WhatsApp Packages",
    extra_license: "Additional Packages",

    bardcode_extra_package: "Barcode Scanner",
    whatsapp_extra_package: "WhatsApp Instant Messaging",
    online_link_extra_package: "Appointment Booking Page",

    bardcode_extra_package_tooltip:
      "While tracking inventory, you can add products by scanning their barcodes. With our application, you can easily scan barcodes using your mobile phone camera and make sales.",
    whatsapp_extra_package_tooltip:
      "Instant connection is established with your WhatsApp account. You can send messages by clicking on the WhatsApp icon if you are using the web version or quickly communicate through the WhatsApp application if you are using the mobile version.",
    online_link_extra_package_tooltip:
      "This section activates the necessary link for your clients to create appointment requests for your business. Through this link, your clients can create appointment requests 24/7, specifying the date, time, and type of service.",

    freePackage: "Free",
    monthly_payment: "Monthly Payment",
    yearly_payment: "Pay Annually",
    monthly: "Monthly",

    check: {
      title: "Do you confirm to buy?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertText:
        "Do you confirm to buy the {package_name} that costs {credit_card_amount}$ with your current balance",
      packageChangeAlert:
        "Your remaining {remaining_day} days from your {active_package} package will be calculated based on the unit price and added to your {new_package} package as {formula} days.",
    },

    paymentType: {
      title: "Please Choose a Payment Method",
      creditCard: "Credit Card",
      eft: "Remittance/EFT",
    },
  },

  "platform/photo": {
    photoUploadToast: "Photo uploading has been started.",
    insertToast: "Photo uploading has been successfully finished.",
    deleteToast: "Photo successfully removed.",
    anexample: "An Example",

    photoUploadErrorToast: "Adding photo process has been failed.",
    deletePhotoErrorToast: "Failed to remove photo.",

    photoInfo:
      "Represents the photo to be displayed on the search and listing screens.",
  },

  "platform/settings": {
    offOn: "OFF / ON",
    chooseColor: "Choose Color",
    platformVisibleTitle: "Enterprise Visibility",
    platformVisibleDescription:
      "It is the setting for your business to appear/not appear in client searches on the Salon Appointment Platform. When this setting is turned off, your business will not appear in searches on the Salon Appointment Platform.",

    platformPriceTitle: "Service Fees Visibility",
    platformPriceDescription:
      "It is the setting to hide your service fees in the Salon Appointment Platform. When this setting is turned off, the fee for your services will not appear on the Salon Appointment Platform.",

    platformCommentTitle: "Client Reviews Visibility",
    platformCommentDescription:
      "It is the setting to hide the reviews made for your business on the Salon Appointment Platform. When this setting is turned off, no client comments about your business will appear on the Salon Appointment Platform.",

    platfromAppointmentTitle: "Automatic Appointment Confirmation",
    platfromAppointmentDescription:
      "It is the automatic confirmation setting of your appointment requests. When this setting is turned on, your appointment requests are automatically approved.",

    platformColorSelectionTitle: "Platform Color Adjustment",
    platformColorSelectionDescription:
      "According to the color selected from this setting, the color setting you have chosen for your page in the Salon Appointment Platform will be used.",

    platformVideoURLTitle: "Platform Video URL",
    platformVideoURLDescription:
      "The video URL you entered will be displayed to users on your platform page.",
    platformVideoURLInputLabel: "Video URL",
    platformVideoURLInputPlaceholder: "Enter Video URL",

    platformSettingsUpdated:
      "Platform settings have been successfully updated.",

    colorDialogTitle: "Color Selection",
    colorDialogCancelTitle: "Cancel Color",
    colorDialogSelectTitle: "OK",
    settingOnOff: "On/Off",
  },

  "platform/categories": {
    howto: {
      title: "How to Do it?",
      content: [
        "On the left are the services you have created (your existing services), and on the right are the services that will appear on the platform.",
        "Select your services that you want to add to the platform.",
        "Expand the category under which your selected services will appear on the Platform.",
        "If the services you have chosen match the services under the category you have expanded, select that service and click the Transfer button.",
      ],
    },
    knowrequired: {
      title: "What You Need to Know",
      content: [
        "You can select and move multiple services at the same time.",
        "Your services that you migrated to the platform will no longer appear in the Your Existing Services tab.",
        "You cannot map a service to more than one category-service name.",
        "If the services you selected match the services under the category you have expanded, select that service and click the Transfer button.",
        "You can use the cross next to the service name to remove the services you mismatched to the Your Existing Services tab.",
        "When adding a new service, the category-service selection for the platform has also been added to the 'Add New Service' page.",
      ],
    },
  },

  /**
   * İşletme Ayarları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings
   */
  settings: {
    employees: {
      name: "Name",
      surName: "Surname",
      sex: "Gender",
      color: "Color",
      phoneNumber: "Mobile Phone",
      email: "Email Address",
      password: "Password",
    },
    platformSettingSuccess: "Platform settings updated successfully.",
    validation: {
      companyName: "Company Name",
      eMail: "Email Address",
      phoneNumber: "Phone Number",
      city: "City",
      district: "District",
      companyAddress: "Company Address",
      ownerName: "Official's Name",
      ownerSurname: "Official's Surname",
    },
    createStaffSuccess: "Staff created successfully!",
    img: {
      imgNotFound: "Logo for this business not found.",
      uploadPhoto: "Update Logo.",
      clickToUpload: "Click to upload logo for this business.",
      changeStaffHours: "Change Staff Hours",
      approve: "Approve",
      clear: "Cancel",
      expHours:
        "You are about to make changes to the appointment time / start / end / recurrence!! All closed hours in the profiles of all your staff will be reset to default, and you will need to rearrange them. Are you sure?",
    },

    idInputLabel: "Salon Code",
    nameInputLabel: "Salon Name",
    mailInputLabel: "Salon E-mail Address",
    phoneInputLabel: "Salon Phone Number",
    currencyInputLabel: "Select Your Currency",
    aboutCompanyLabel: "About Company",

    cityInputLabel: "Select Province",
    districtInputLabel: "Select District",
    addressInputLabel: "Full Address",
    mapsInputLabel: "Google Map Enterprise Coordination",
    mapsInputHint: "For example: 41.009336, 28.660129",
    currentLocationButtonTitle: "Use Current Location",

    instagramInputLabel: "Instagram User Name",
    facebookInputLabel: "Facebook Page User Name",
    websiteInputLabel: "Web Page Url",
    withoutAtHint: "Enter without @ marks",

    activeInstagramLabel: "Login to Instagram",

    priceInputLabel: "Price Info Settings",
    priceInputHint:
      "This setting will determine whether the transaction fee sum will be shown or not to clients who will request an appointment over your enterprise’s URL.",

    commentsInputLabel: "Client Comments Settings",
    commentsInputHint:
      "This setting will determine whether the clients comments made in survey will be shown or not in your enterprise’s URL.",

    inputOn: "Open",
    inputOff: "Close",

    urlInputLabel: "Enterprise’s Appointment URL",
    openOnBrowserButtonTitle: "Open in Browser",
    copyLinkButtonTitle: "Copy Link",
    updateButtonTitle: "Update Information",

    geolocationErrorToast: "Your browser does not support Geolocation.",
    locationErrorToast: "Your location is failed please try again later.",
    updateToast: "Information has been successfully updated.",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/agreement/index
   */

  "settings/agreements": {
    agreementTemplates: "Agreement Templates",
    createNewAgreement: "Create New Agreement",
    editAgreement: "Edit Agreement",
    agreementClauses: "Agreement Clauses",
    agreements: "Agreements",

    delete: "Delete",
    edit: "Edit",
    close: "Close",

    yesOrNo: "Yes / No",

    createTamplateArea: {
      personalInformation: "Personal Information",
      paymentInformation: "Payment Information",
      clausesInformation: "Clauses Information",
      label: "New Template",
      placeHolder: "New Agreement Template",
      saveTamplate: "Save Template",
      editTamplate: "Edit Template",
      cancel: "Cancel",
      placeHolders: "Business Information",
    },

    personal_info: {
      name_and_surname: "Name and Surname",
      phone: "Phone Number",
      birth_date: "Birth Date",
      job: "Job",
      id_number: "ID Number",
      tax_number: "Tax Number",
      address: "Address",
    },
    payment_info: {
      staff_name: "Staff Name",
      service: "Service",
      total_amount: "Total Amount",
    },

    createClausesArea: {
      createNewClauses: "Create New Clause",
      title: "Clause Title",
      context: "Clause Content",
      yesOrNo: "Add Yes / No Query",
      update: "Update",
      save: "Save",
      cancel: "Cancel",
      fillAreaComplatelty: "Please fill all fields completely.",
    },

    createPdf: {
      companyNamePlaceHolder: "Company Name",
      agreementTitle: "Agreement Title",
    },

    agreementsList: {
      date: "Date",
      nameSurname: "Name and Surname",
      agreementName: "Agreement Name",
      size: "Size",
      actions: "Actions",
      startDate: "Start Date",
      endDate: "End Date",
      searchCustomer: "Search Client",
    },

    agreementRequstFail:
      "An unexpected error occurred. Please contact your client representative.",

    agreementClausesSavedSucces: "Agreement clause saved successfully.",
    agreementClausesEditSucces: "Agreement clause edited successfully.",
    agreementClausesDeleteSucces: "Agreement clause deleted successfully.",

    agreementSaveSucces: "Agreement template saved successfully.",
    agreementEditSucces: "Agreement template edited successfully.",
    agreementDeleteSucces: "Agreement template deleted successfully.",

    deleteClausesModal: {
      title: "Are you sure you want to delete this agreement clause?",
      delete: "Delete",
      cancel: "Cancel",
      expTitle: "This action cannot be undone!",
      expDetail: "The clause will be permanently deleted.",
    },

    deleteAgreementModal: {
      title: "Are you sure you want to delete this agreement template?",
      delete: "Delete",
      cancel: "Cancel",
      expTitle: "This action cannot be undone!",
      expDetail: "The agreement template will be permanently deleted.",
    },

    agreement: {
      yes: "Yes",
      no: "No",
      signiture: "Signature",
    },

    smsAproove: {
      title: "Do you want to send the registered agreement via SMS?",
      send: "Send",
      cancel: "Cancel",
      textFirst: "Are you sure you want to send the agreement to",
      textSecond: "via SMS?",
    },

    wpAproove: {
      title: "Do you want to send the registered agreement via WhatsApp?",
      send: "Send",
      cancel: "Cancel",
      textFirst: "Are you sure you want to send the agreement to",
      textSecond: "via WhatsApp?",
    },

    deleteAgreement: {
      title: "Are you sure you want to delete this agreement?",
      aproove: "Confirm and Delete",
      cancel: "Cancel",
      infoTitle: "The agreement will be permanently deleted.",
      infoContext:
        "This action cannot be undone. The agreement will be permanently deleted.",
    },
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/hours
   */
  "settings/hours": {
    businessCategory: "Business Category",
    startInputLabel: "Starting Time",
    endInputLabel: "Finishing Time",
    periodBetweenInputLabel: "Appointment Interval (In Minutes)",

    startInputLabelHour: "Starting Hour",
    startInputLabelMinute: "Starting Minute",

    endInputLabelHour: "Finishing Hour",
    endInputLabelMinute: "Finishing Minute",

    updateButtonTitle: "Update Information",

    updateToast: "Information has been successfully updated.",
    compareErrorToast:
      "The area of finishing time cannot be smaller than starting time area.",
  },

  /**
   * İşletme Galerisi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/gallery
   */
  "settings/gallery": {
    numberPhoto: "Total photo count you can upload",
    uploadButtonTitle: "Click here to upload photos.",
    anexample: "An example",
    nextPhoto: "Next Photo",

    checkAllButtonTitle: "Select All Photos",
    deleteSelectedButtonTitle: "Delete Photos Selected ({count})",

    delete: {
      // Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "Do you confirm this action?",
      alertText:
        " All deleted photos will be removed from relating pages if you continue.",

      selectAll: "Select All",
      before: "Before",
      after: "After",
    },

    photoUploadToast: "Delete selected photo(s)",
    insertToast: " Photo uploading has been successfully completed.",
    deleteToast: "Photo has been successfully deleted.",

    photoMorePictureError: "8 fotoğraftan fazla yükleme yapılamaz.",

    enterprise_gallery_info:
      "You can add venue photos of your business from this area. These images will be displayed on the Salon Management Platform and your business page.",
    exampleView: "View Example",
    totalAllowedUpload: "Total Number of Photos You Can Upload:",

    dialog: {
      title: "Upload Showcase Photo",
      close: "Close",
      selectFoto: "Select Photo",
      upload: "Upload",
    },
  },
  "settings/service-gallery": {
    numberPhoto: "Total photo count you can upload",
    uploadButtonTitle: "Click here to upload photos.",
    anexample: "An example",

    photoDelete: "Select the Photo to be Deleted",

    checkAllButtonTitle: "Select All Photos",
    deleteSelectedButtonTitle: "Delete Photos Selected ({count})",

    delete: {
      // Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "Do you confirm this action?",
      alertText:
        " All deleted photos will be removed from relating pages if you continue.",
    },

    photoUploadToast: "Delete selected photo(s)",
    insertToast: " Photo uploading has been successfully completed.",
    deleteToast: "Photo has been successfully deleted.",

    photoMoreUploadError: "8 fotoğraftan fazla yükleme yapılamaz.",

    service_photo_info:
      "You can add images related to the services you provide in this area. These images will be displayed on the Salon Management Platform and your business page.",
  },

  /**
   * Sistem Logları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/logs
   */
  "settings/logs": {
    headings: {
      createdAt: "Transaction Date",
      message: "Transaction Explanation",
      detail: "Transaction Details",
      ip: "IP Address",
    },
  },

  /**
   * 404 sayfasının bulunduğu key.
   * https://app.salonrandevu.com/404
   */
  notFound: {
    heroHeadingText: "Sorry, not found!",
    boldText:
      "Unfortunately we could not find the page you wanted to search or open.",
    text: "It might be deleted, removed or not created.",
  },

  /**
   * İşletme URL'si sayfasının bulunduğu key.
   * https://app.salonrandevu.com/isletme/22
   */
  businessURL: {
    callButtonTitle: "Call on now (0{phone})",
    instagramHeading: "Follow in Instagram!",
    instagramText: "In Instagram @{username}",

    facebookHeading: "Follow in Facebook!",
    facebookText: "In Facebook@{username}",

    aboutHeading: "Contact",
    phoneText: "Phone",
    mailText: "E-Mail",
    addressText: "Address",

    appointment: {
      // Tab
      tab: "Create Appointment",
      title: "Create Appointment Request",

      nameInputLabel: "Name",
      surnameInputLabel: "Surname",
      phoneInputLabel: "Phone Number",
      serviceInputLabel: "Service Selection",
      serviceInputHint: "Select among services",
      staffInputLabel: "Staff Selection",
      staffInputHint:
        "Select the staff who will take care of you during your appointment.",
      dateInputLabel: "Appointment Date",
      dateInputHint:
        "You can either select the date by clicking on calendar icon or do it manually.",

      availableHoursButtonTitle: "Search for Available Hours",

      check: {
        // Modal
        title: "Send Appointment Request",
        confirmButtonTitle: "Yes",
        discardButtonTitle: "No",

        alertBoldText: "Dear [name] [surname]",
        alertText:
          "Do you confirm to create your appointment on [date] at [time]?",

        priceText:
          "Over all fee of your selected transaction is determined as [amount] by enterprise.",
        priceBoldText:
          "This fee is given for informative purposes and does not constitute exact transaction amount.",

        noteInputLabel: "Appointment Note (Optional)",
        noteInputHint: "%s/ 255 characters",
      },

      insertToast: "Your appointment request has been successfully created.",
    },

    services: {
      // Tab
      tab: "List of Service",
      title: "List of Service",
      processTime: "Transaction time: {time} minutes",
    },

    staffs: {
      //Tab
      tab: "Staff List",
      title: "Staff List",
    },

    comments: {
      // Tab
      tab: "Reviews",
      title: "Reviews",
    },
  },

  // Support Live Chat

  "support/live_support": {
    liveSupportRequestTitle: "Live Support Request",
    appointmentDate: "Appointment Date and Time",
    appointmentRequestTitle: "Support Request",
    createAppointmentButtonTitle: "Get Appointment",

    nameInputLabel: "Name",
    nameInputHint: "Please enter your name",

    surnameInputLabel: "Surname",
    surnameInputHint: "Please enter your last name",

    name: "Name",
    surname: "Surname",
    supportrequestsubject: "Support Request Subject",
    supportrequestdetail: "Support Request Detail",
    hours: "Hours",

    phoneNumberInputLabel: "Phone Number",

    supportRequestAboutLabel: "Support Request About",
    supportRequestAboutHint: "Please select the service you want to request",

    dateInputLabel: "Appointment Date",
    noAvailableHours:
      "There are no available hours between hours that you selected.",

    supportRequestDetailLabel: "Support Request Detail",
    supportRequestDetailHint:
      "Please provide a detailed explanation about the issue you are requesting support for.",
    supportRequestDetailHelperText:
      "Please explain in detail the related operations and conflicts that you have encountered. The details you provide will enable us to assist you in the fastest and most accurate way.",

    appointmentCancelDescription:
      "Important: If you solved your problem before the meeting time, please cancel your appointment.",

    headings: {
      appointmentDate: "Appointment Date",
      appointmentAbout: "About",
      appointmentDetail: "Detail",
      name: "Name and Surname",
      phoneNumber: "Phone Number",
      appointmentStatus: "Appointment Status",
      cancelButtonTitle: "Cancel",

      replacements: {
        status: {
          waiting: "Waiting",
          done: "Done",
          cancel: "Cancel",
        },
      },
    },

    alertInfo: {
      text1:
        "At the time you choose, we will call you by 0212100510 number of ours. Please download the",
      text2: "ALPEMIX remote connection program and make it work",
      text3: "in case the need for remote connection.",
      text4: "You can reach their client information from",
      text5: "Passive Clients section anytime you want.",
    },

    createLiveSupportRequestToast:
      "Your appointment request has been successfully created.",
    cancelLiveSupportRequestToast:
      "Your appointment request has been successfully cancelled.",
  },

  /**
   * Birden fazla yerde kullanılan sabit tanımlar bu alanda yazılmaktadır.
   */
  component: {
    actionHeadingText: "Transaction",
    actionHeadingTextRating: "Comment Transactions",
    detailEditButtonTitle: "Details & Edit",
    validationErrorText: "Edit the fields according to necessary format",
    loadingText: "Loading...",

    smartBanner: {
      // Loginde mobil uygulama yayında banner'ı
      onAirText: "Download our app!",
    },

    timerButton: {
      waitText: "Wait",
    },

    dayWeekButton: {
      day: "Day",
      week: "Week",
    },

    semiCirclePie: {
      noDisplayChartDescription:
        "The graph is not displayed because the values ​​are equal to zero.",
    },

    excelButton: {
      title: "Download as Excel",
    },

    datePickerInput: {
      // Datepicker Input
      cancelLabel: "CANCEL",
      clearLabel: "DELETE",
      okLabel: "COMPLETE",
    },

    dateRangeStats: {
      // Dashboard'daki istatistik kartları
      yesterday: "Yesterday",
      today: "Today",
      week: "This Week",
      month: "This Month",
      lastWeek: "Last 7 Days",
      last_15_days: "Last 15 Days",
      lastMonth: "Last 30 Days",
    },

    NADDialog: {
      createAppointment: "Create an Appointment",
    },

    CADialog: {
      // Customer Add Dialog
      title: "Create Quick Client",
      createButtonTitle: "Create Client",
      discardButtonTitle: "No",

      nameInputLabel: "Client Name",
      nameInputPlaceholder: "Type Client Name",

      surnameInputLabel: "Client Surname",
      surnameInputPlaceholder: "Type Client Surname",

      phoneInputLabel: "Phone Number",

      updateCustomerDetails: "Update Cutomer",
      changeCutomertoActive: "Change Client to Active",
      changeCutomertoPassive: "Change Client to Passive",

      genderInputLabel: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      delete: {
        // Modal
        title: "Do you confirm to delete?",
        confirmButtonTitle: "Yes",
        discardButtonTitle: "No",
        alertText: "This action is irreversible!",
        alertTitleText:
          "In case of deleting this cheque will also delete transaction in it. It may affect your accounting records!",
      },

      checkingForeingNumber: "Check as a foreign Number",

      confirmChangeCustomerTitle:
        "Are you confirming to change active to passive?",
      confirmCustomerTitle:
        "If you change active state to passive state of this client, you will be not able to see the client at Appointment/Bill sections.",
      confirmTitle1: "You can reach their client information from",
      confirmTitle2: "Passive Clients section",
      confirmTitle3: "anytime you want.",

      birthdayInputLabel: "Date of Birth",
      birthdayInputInvalidMessage: "Pleas enter as indicated Day/Month/Year",

      maritalStatusInputLabel: "Marital Status",
      maritalStatusMarried: "Married",
      maritalStatusSingle: "Single",

      secondPhoneInputLabel: "Second Phone Number",
      secondPhoneInputPlaceholder:
        "Please enter second phone number if exists.",

      mailInputLabel: "E-Mail Address",
      mailInputPlaceholder: "Enter e-mail address if exists.",

      descriptionInputLabel: "Extra Explanations",

      workStatusInputLabel: "Employment Status",
      workStatusWorking: "Employed",
      workStatusUnemployed: "Unemployed",
      workStatusEmployer: "Employer",
      workStatusStudent: "Student",
      workStatusHouseWife: "Housewife",

      relatedBusinessInputLabel: "Related Foundation",
      relatedBusinessInputPlaceholder:
        "Please specify the foundation that client came over please",

      sendSmsInputLabel: "Email Sending Status",
      sendSmsTrue: "Send",
      sendSmsFalse: "Not Send",

      advancedOptions: "Advance Options",
      advancedOptionsHide: "Hide",
      advancedOptionsShow: "Show",

      insertToast: "Client registration has been successfully created!",

      appointment_notification: {
        description: "You have a new appointment. Have a great day!",
        closeButtonTitle: "OK",
        appointmentsButtonTitle: "Appointments",
      },
    },

    OARDialog: {
      title: "Online Appointment Requests",
    },

    filterAppointmentDialog: {
      title: "Filter Appointments",
    },

    sms_review: {
      smsReviewTitle: "Message Preview",
      totalNumberTitle: "Total Number of Persons to Send:",
      totalWpCreditTitle: "Total WhatsApp Credit:",
      totalSMSCreditTitle: "Total SMS Credit:",
      smsContentTitle: "Message Content:",

      inUseSMSCredit: "Total SMS Credits to Use:",
      inUseWPCredit: "Total WhatsApp Credits to Use:",

      sendButtonTitle: "Send",
      discardButtonTitle: "Cancel",
    },

    allServicesDialog: {
      serviceCategoryTitle: "Service Categories",
      selectedServicesTitle: "Selected Services",
      loading: "Loading...",
      noSelectedService: "No selected services.",
      notFoundService: "Service not found.",
      transferButtonTitle: "Transfer Selected Services",

      successToast: "Your services have been successfully transferred.",
    },
  },
  panel: {
    licenseRemaining: "days remaining",
    sms_credit: "SMS",
    wp_credit: "WhatsApp",
    remainingAppointment: "appointments remaining",
    changeLanguage: "Change Language",
  },

  panelTabs: {
    moreFavoriteErrorToast: "You can only add up to 7 favourites.",
  },

  favorites: {
    customerManagement: "Client Management",
    customersList: "Clients List",
    customersPassive: "Passive Clients",
    customersRating: "Clients Ratings",
    customersAdd: "Create New Client",
    addBulk: "Add Client from Directory",
    serviceManagement: "Service Management",
    serviceList: "Service List",
    servicesAdd: "Create New Service",
    serviceCreateRequest: "Create Service Name Request",
    servicesRequests: "Service Name Requests",
    servicesBounty: "+ Create Service Bonus",
    packageManagement: "Package Management",
    packageList: "Package List",
    packagesSales: "Package Sales List",
    packagesAdd: "Create New Package",
    salesAdd: "Create New Package Sale",
    stockManagement: "Stock Management",
    productsList: "Products List",
    newProduct: "Create New Product",
    accountingManagement: "Accounting Management",
    accountingIncome: "Income Transactions",
    accountingExpense: "Expense Transactions",
    expenseTypes: "Expense Items",
    accountingDebt: "Debt Transactions",
    personalManagement: "Staff Management",
    settingsEmployees: "List Staff",
    bonusTransaction: "Bonus Transactions",
    employeeAdd: "Create New Employee",
    advancedReports: "Advanced Reports",
    companyReports: "Company Reports",
    staffReports: "Staff Reports",
    customerReports: "Client Reports",
    serviceReports: "Service Reports",
    stockReports: "Products Reports",
    messageManagement: "Message Management",
    smsManagement: "SMS Management",
    sendSelective: "Send Select Message",
    sendFilter: "Send Filtered Message",
    messageReports: "Message Reports",
    smsSettings: "SMS Settings",
    systemSettings: "System Settings",
    profileSettings: "Profile Settings",
    settings: "Enterprise Settings",
    platformSettings: "Platform Settings",
    workingHours: "Working Hours",
    newBranch: "Create New Branch",
    systemLogs: "System Logs",
    languageSettings: "Language Settings",
    walletManagement: "Wallet Management",
    paymentNotify: "Payment Notify",
    currentBalance: "Current Balance",
    purchase: "Purchase",
    smsPackage: "SMS Package",
    logout: "Logout",
    galleryManagement: "Gallery Management",
    showcaseGallery: "Showcase Gallery",
    enterpriseGallery: "Enterprise Gallery",
    serviceGallery: "Service Gallery",
    appointmentsManagement: "Appointment Management",
    appointments: "Appointment Calender",
    appointmentsOnline: "Online Appointment Requests",
    appointmentsAdd: "Create New Appointment",
    appointmentsFilter: "Filter Appointments",
    receipsManagement: "Tickets Management",
    receipts: "List Tickets",
    receiptsAdd: "Create New Ticket",
  },

  noAccessHead: "You are not authorized to access!",
  noAccessTextOne: "You are not authorized to access this page.",
  noAccessTextTwo:
    "If you think there is a problem, you can reach us through our communication channels.",
  licenseDetails: {
    currentPackage: "(current package)",
    features: "Features",
    silver: [
      "Up to 3 Employees",
      'Special "50 Appointment Gift" for New Members',
      'Special "100 WhatsApp Gift" for New Members',
      "100 New Appointments for Only 3.99$",
    ],
    gold: [
      "Up to 5 Employees",
      "Unlimited Appointments",
      "Multi-Branch Management",
      "Dedicated Client Representative",
      'Special "250 WhatsApp Gift" for New Members',
    ],
    platinum: [
      "Up to 7 Employees",
      "Ticket Transactions",
      "Package Management",
      "Inventory Management",
      "Bonus Transactions",
      'Special "500 WhatsApp Gift" for New Members',
      "Advanced Reports",
      "Unlimited Appointments",
      "Online Appointment Website",
      "Multi-Branch Management",
      "Dedicated Client Representative",
    ],
    diamond: [
      "Up to 10 Employees",
      "E-Signature Add-on",
      "Contract Management",
      "Ticket Transactions",
      "Package Management",
      'Special "1000 WhatsApp Gift" for New Members',
      "Inventory Management",
      "Bonus Transactions",
      "Advanced Reports",
      "Unlimited Appointments",
      "Online Appointment Website",
      "Multi-Branch Management",
      "Dedicated Client Representative",
    ],
    includesAllLicence: [
      "Business Management",
      "Client Management",
      "Employee Management",
      "WP Sending",
      "Appointment Reminder",
      "Appointment Management",
      "Business Gallery",
      "To-Do List",
    ],
  },
};

export default langEN;
