const langTR = {
  langText: "Türkçe",

  /**
   * İlk açılan pop-up içeriği
   */

  definePopup: {
    title: "Sizin İçin En Uygun Özellikleri Tanımlıyoruz.",
    description:
      "İşletmenizde Randevu Takvimine Ek Olarak, Adisyon ve Muhasebe Özelliğimizi de Kullanmak İster Misiniz?",
    buttons: {
      approve: "Evet",
      cancel: "Hayır",
    },

    succesMessage:
      "Tercihiniz başarıyla kaydedilmiştir. Lütfen tekrar giriş yapınız.",
  },

  /**
   * Mesaj Karakter sayısı hesaplayan fonksiyon
   */

  charMessage: {
    char: "Karakter",
    excededChar: "Karakter Sınırını Aştınız.",
    left: "Kalan",
    min: "dk",
  },

  /**
   * Giriş sayfasının bulunduğu key.
   * https://app.salonrandevu.com/login
   */
  login: {
    title: "Giriş Yap",
    mailPhoneInputLabel: "E-Posta / Telefon Numarası",
    passInputLabel: "Şifre",
    loginButtonTitle: "Giriş Yap",
    registerButtonTitle: "Hesabınız yok mu? Hemen kayıt olun!",
    forgotPassButtonTitle: "Şifremi Unuttum",
    rememberMe: "Beni Hatırla",

    license: {
      // Modal
      title: "Lisans Kullanım Süreniz Bitti!",
      description:
        "Sayın {company}, panel kullanım süreniz sona ermiştir. İşletmeniz için uygun olacağını düşündüğünüz paketi satın alarak paneli kullanmaya devam edebilirsiniz.",
      buttonTitle: "Paket Satın Almak İçin Bizi Arayın - 0(212) 706 0510",

      standart: {
        type: "KÜÇÜK ÖLÇEKLİ İŞLETMELER",
        title: "STANDART",
        items: [
          "Sınırsız Randevu",
          "Size Özel Randevu Sayfası",
          "Randevu Yönetim Paneli",
          "Firma Profili",
          "Çalışan Profili",
        ],
      },

      premium: {
        type: "ORTA VE BÜYÜK ÖLÇEKLİ İŞLETMELER",
        title: "PREMIUM",
        items: [
          "Standart Paketin Tüm Özellikleri",
          "Adisyon Yönetimi",
          "Gelir - Gider Takibi",
          "Müşteri Puan Sistemi",
          "Müşteri Memnuniyet Analizi",
        ],
      },
    },

    downloadApp: "QR Kodu Okutarak Hemen Uygulamayı İndir.",
  },

  /**
   * Kayıt sayfasının bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  register: {
    title: "Kayıt Ol",
    trialText: "7 Gün tüm özellikler tamamen ÜCRETSİZ!",
    noPaymentText: "Ödeme yok, Taahhüt yok, İptal ücreti yok.",
    privacyPolicyText: "Gizlilik Sözleşmesini okudum ve kabul ediyorum.",

    continueButtonTitle: "Devam Et",
    completeButtonTitle: "Kaydı Tamamla",
    alreadyUserButtonTitle: "Zaten üye misiniz? Giriş yapın!",

    companyNameInputLabel: "İşletme Adı",
    phoneInputLabel: "Telefon Numarası",
    campaingCodeInputLabel: "Kampanya kodunuz varsa giriniz",
    staffNameInputLabel: "Yetkili Adı",
    staffSurnameInputLabel: "Yetkili Soyadı",
    staffMailInputLabel: "E-Posta Adresi",
    passwordInputLabel: "Şifre",
  },

  /**
   * Şifremi unuttum sayfasının bulunduğu key.
   * https://app.salonrandevu.com/forgot-pass
   */
  forgotPass: {
    title: "Şifremi Unuttum & Sıfırla",

    stepText: "ADIM",
    firstStepText:
      "Bu adımda çalışan profilinize bağlı olan telefon numaranız aşağıdaki alana girilmelidir. Telefonunuza göndereceğimiz SMS'teki doğrulama kodunu sonraki aşamada girerek yeni şifrenizi oluşturabilirsiniz.",
    secondStepText:
      "Girmiş olduğunuz telefon numarasına doğrulama kodunu SMS olarak gönderdik. Aşağıdaki alanlara yeni şifrenizi ve size gönderilen doğrulama kodunu girerek şifrenizi güncelleyebilirsiniz.",

    continueButtonTitle: "Devam Et",
    backToHomeButtonTitle: "Giriş Sayfasına Dön",
    updatePassButtonTitle: "Şifreyi Güncelle",

    phoneInputLabel: "Telefon Numarası",
    verificationCodeInputLabel: "Doğrulama Kodu",
    newPassInputLabel: "Yeni Şifreniz",

    verificationSuccessfullySentToast: "Doğrulama kodu başarıyla gönderildi.",
    verificationErrorToast: "Doğrulama kodu gönderilemedi.",
    validPhoneErrorToast: "Telefon numaranızı eksiksiz giriniz.",
    passwordSuccessfullyChangedToast: "Şifreniz başarıyla değiştirildi.",
    warningStatusToast: "Hatalı durum, şifreniz değiştirilemedi.",
  },

  /**
   * Panel içerisindeki sol menü ve alt elemanların bulunduğu key.
   */
  menuItems: {
    dashboard: { title: "Genel Bakış" },
    profile: { title: "Profil Yönetimi" },
    wallet: { title: "Bakiye İşlemleri" },
    notify: { title: "Ödeme Bildirimi" },
    todos: { title: "Yapılacaklar Listesi" },

    appointment: {
      title: "Randevu Takvimi",
      children: {
        index: "Randevu Takvimi",
        filter: "Randevuları Filtrele",
        online: "Online Randevu Talepleri",
        add: "+ Yeni Randevu Oluştur",
      },
    },
    receipt: {
      title: "Adisyon Yönetimi",
      children: {
        index: "Adisyonları Listele",
        recordAdd: "Kayıtlı Müşteri İçin Adisyon Oluştur",
        newAdd: "Yeni Müşteri İçin Adisyon Oluştur",
      },
    },
    packages: {
      title: "Paket Yönetimi",
      children: {
        sales: "Paket Satışları Listesi",
        salesAdd: "Paket Satışı Yap",
        list: "Paket Listesi",
        add: "+ Yeni Paket Oluştur",
        unbookedPackages: "Randevusu Oluşturulmamış Paketler",
      },
    },
    products: {
      title: "Stok Yönetimi",
      children: {
        index: "Ürün Listesi",
        add: "+ Yeni Ürün Oluştur",
      },
    },
    customers: {
      title: "Müşteri İşlemleri",
      children: {
        active: "Müşteri Listesi",
        passive: "Pasif Müşteriler",
        risky: "Kayıp Müşteriler",
        rating: "Müşteri Yorumları",
        add: "+ Yeni Müşteri Oluştur",
        addBulk: "+ Excel ile Toplu Müşteri Oluştur",
      },
    },
    services: {
      title: "Hizmet Yönetimi",
      children: {
        index: "Hizmet Listesi",
        add: "+ Yeni Hizmet Oluştur",
        requests: "Hizmet Adı Talepleri",
        bounty: "Özel Prim Oluştur",
      },
    },
    employees: {
      title: "Çalışan Yönetimi",
      children: {
        index: "Çalışanları Listele",
        add: "+ Yeni Çalışan Oluştur",
      },
    },
    sms: {
      title: "Mesaj Yönetimi",
      children: {
        index: "Mesaj Raporları",
        sendSelective: "Toplu Mesaj Gönder",
        sendFilter: "Filtreli Mesaj Gönder",
        sendGroup: "Gruplara Mesaj Gönder",
        settings: "SMS Ayarları",
        blacklist: "Mesaj Kara Liste",
        template: "Şablon Ayarları",
        wpSettings: "WhatsApp Ayarları",
      },
    },
    accounting: {
      title: "Muhasebe Yönetimi",
      children: {
        income: "Gelir İşlemleri",
        debt: "Borç İşlemleri",
        bounty: "Prim İşlemleri",
        expense: "Gider İşlemleri",
        expenseTypes: "Gider Kalemleri",
        credit: "Alacaklandırma",
        debit: "Borçlandırma",
      },
    },
    reports: {
      title: "Gelişmiş Raporlar",
      children: {
        company: "İşletme Raporları",
        service: "Hizmet Raporları",
        stock: "Ürün Raporları",
        staff: "Çalışan Raporları",
        customer: "Müşteri Raporları",
        appointment: "Randevu Raporları",
      },
    },
    platform: {
      title: "Platform Yönetimi",
      children: {
        photo: "Vitrin Fotoğrafı",
        settings: "Platform Ayarları",
        category_settings: "Kategori Ayarları",
      },
    },
    settings: {
      title: "Sistem Ayarları",
      children: {
        index: "İşletme Ayarları",
        hours: "Çalışma Saatleri",
        gallery: "İşletme Galerisi",
        service_gallery: "Hizmet Galerisi",
        logs: "İşlem Kayıtları",
        branchAdd: "Yeni Şube Oluştur",
        giveAReferans: "Referans Ol",
      },
    },
    gallery: {
      title: "Galeri Yönetimi",
      children: {
        platform_photo: "Vitrin Fotoğrafı",
        enterprise_gallery: "İşletme Galerisi",
        service_gallery: "Hizmet Galerisi",
      },
    },
    agreement: {
      settings: "Sözleşme Ayarları",
    },
    support: {
      title: "Canlı Destek",
    },
  },

  /**
   * Panel içerisindeki üst menü ve açılan popuplarının bulunduğu key.
   */
  scaffold: {
    managementNo: "İşletme No",
    translateViewHeading: "SİSTEM DİLİ",
    logsViewHeading: "SİSTEM LOGLARI",
    notificationViewHeading: "BİLDİRİMLER",
    quickActionsViewHeading: "HIZLI İŞLEMLER",

    searchCustomer: "Müşteri ara",

    logsViewAllButtonTitle: "Tümünü Gör",

    notificationButtons: {
      clearAll: "Hepsini Sil",
      cancel: "İptal",
      delete: "Sil",
      new: "Yeni",
      approved: "Onaylandı",
      canceled: "İptal Edildi",
    },

    notificationFound: "Yeni bildirimleriniz var!",
    notFoundNotification: "Henüz bir bildiriminiz bulunmuyor!",

    QAItems: {
      createCustomer: "Yeni Müşteri Oluştur",
      createAppointment: "Yeni Randevu Oluştur",
      createReceipt: "Yeni Adisyon Oluştur",
      createPackage: "Yeni Paket Oluştur",
      createPackageSale: "Yeni Paket Satışı",
      createService: "Yeni Hizmet Oluştur",
      createEmployee: "Yeni Çalışan Oluştur",
      showIncomes: "Gelirleri Görüntüle",
      showExpenses: "Giderleri Görüntüle",
      showDebts: "Borçları Görüntüle",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/branches
   */
  branches: {
    heroHeadingText: "Salonrandevu Çoklu Şube Yönetimi",
    greetingText: "Hoşgeldin",
    greetingBottomText:
      "Aşağıdaki alanda yönetiminizde olan şubeleriniz listelenmektedir.",
    greetingBottomText2:
      "Tablonun sağ tarafında bulunan buttonlar ile herhangi bir şubenin giriş bilgilerini güncelleyebilir ya da ilgili şubeye giriş yapabilirsiniz.",

    logoutButtonTitle: "Çıkış Yap",
    loginBranchButtonTitle: "Şubeye Giriş",
    editStaffButtonTitle: "Bilgileri Düzenle",
    completeEditingButtonTitle: "Düzenlemeyi Tamamla",
    discardEditingButtonTitle: "Vazgeç",
    createBranchButtonTitle: "Şubeyi Oluştur",
    licenseExpiredToast: "Lisans Süresi Dolmuş Kullanıcı",

    tabBranchListTitle: "Şubeleri Listele",
    tabBranchReportsTitle: "Şubele Raporları",
    tabBranchAddTitle: "+ Yeni Şube Oluştur",

    headings: {
      listTable: {
        id: "İşletme Kodu",
        companyName: "İşletme/Şube Adı",
        staffFullName: "Yetkili Adı Soyadı",
        staffPhoneNumber: "Yetkili Telefonu",
        staffMail: "Yetkili E-Postası",
        licenseEndDate: "Lisans Bitişi",
        refresh: "Yenile",
      },
      reportsTable: {
        companyName: "İşletme/Şube Adı",
        staffCount: "Çalışan Sayısı",
        appointmentCount: "Randevu Sayısı",
        receiptCount: "Adisyon Sayısı",
        serviceIncome: "Hizmet Geliri",
        saleIncome: "Ürün Satış Geliri",
        staffBounty: "Ödenen Prim",
        totalIncome: "Toplam Gelir",
        totalExpense: "Toplam Gider",
        totalCount: "Net Gelir",
      },
    },

    createBranch: {
      companyNameInputLabel: "İşletme Adı",
      staffNameInputLabel: "Yetkili Adı",
      staffSurnameInputLabel: "Yetkili Soyadı",
      commonMailInputLabel: "E-Posta Adresi",
      commonPhoneInputLabel: "Telefon Numarası",
      passInputLabel: "Şifre",
    },

    licenseExpiredLoginToast:
      "Giriş yapmak istenilen hesabın lisans süresi sona ermiş.",
    licenseExpiredEditToast:
      "Düzenlenmek istenilen hesabın lisans süresi sona ermiş.",
    staffUpdateToast: "Çalışan başarıyla güncellendi.",
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/give-reference
   */
  referance: {
    createReferanceButtonTitle: "Referans Ol",
    createReferance: {
      companyNameInputLabel: "İşletme Adı",
      staffNameInputLabel: "Yetkili Adı ve Soyadı",
      commonMailInputLabel: "E-Posta Adresi",
      commonPhoneInputLabel: "Telefon Numarası",
    },
  },

  platformSettings: {
    approve: "Ayarları Güncelle",
    appointmentApprovelCode: {
      title: "Randevu Doğrulama Kodu",
      detail:
        "Platformdan alınan randevular için doğrulama kodu ayarıdır. Bu ayar açık olduğunda platform tarafından randevu alındığında müşteriye doğrulama kodu iletilecektir.",
    },
    appointmentApprovelCodeType: {
      title: "Randevu Doğrulama Kodu Gönderim Tipi",
      detail:
        "Platformdan alınan randevular için gönderim ayarıdır. Bu ayar açık olduğunda paltform tarafından randevu alındığında müşteriye hangi gönderim tipi ile doğrulama kodu iletileceğini gösterir.",
    },
  },

  /**
   * Genel Bakış sayfasının bulunduğu key.
   * https://app.salonrandevu.com/dashboard
   */
  dashboard: {
    favoriteBox: {
      customerManagement: "Müşteri Yönetimi",
      customerList: "Müşteri Listeri",
      lostCustomer: "Kayıp Müşteriler",
      customerComments: "Müşteri Yorumları",
      createNewCustomer: "Yeni Müşteri Oluştur",
      addCustomerFromBulk: "Excel İle Müşteri Ekle",
      serviceManagement: "Hizmet Yönetimi",
      serviceList: "Hizmet Listesi",
      createNewService: "Yeni Hizmet Oluştur",
      createNewServiceRequest: "Hizmet Adı Talebi Oluştur",
      serviceRequest: "Hizmet Adı Talepleri",
      createServicePrime: "Hizmet Primi Oluştur",
      packageManagement: "Paket Yönetimi",
      packageList: "Paket Listesi",
      packageSaleList: "Paket Satışları Listesi",
      packageWithoutAppointment: "Randevusu Oluşturulmamış Paketler",
      createNewPackage: "Yeni Paket Oluştur",
      createNewPackageSale: "Yeni Paket Satışı Oluştur",
      stockManagement: "Stok Yönetimi",
      stockList: "Ürün Listesi",
      createNewStock: "Yeni Ürün Oluştur",
      accountingManagement: "Muhasebe Yönetimi",
      accountingIncome: "Gelir İşlemleri",
      accountingExpense: "Gider İşlemleri",
      expensesTypes: "Gider Kalemleri",
      incomes: "Alacaklandırma",
      expenses: "Borçlandırma",
      expenseProcess: "Borç İşlemleri",
      staffManagement: "Personel Yönetimi",
      staffList: "Personel Listesi",
      primProcess: "Prim İşlemleri",
      createNewPersonel: "Yeni Personel Oluştur",
      advancedReports: "Gelişmiş Raporlar",
      companyReports: "İşletme Raporu",
      staffReports: "Personel Raporu",
      customerReports: "Müşteri Raporu",
      servicesReports: "Hizmer Raporu",
      productReports: "Ürün Raporu",
      messageManagement: "Mesaj Yönetimi",
      smsReports: "Mesaj Raporları",
      selectedSMSSend: "Seçimli Mesaj Gönder",
      filterSMSSend: "Filtreli Mesaj Gönder",
      messageReports: "Mesaj Raporları",
      smsSettings: "SMS Ayarları",
      systemSetting: "Sistem Ayarları",
      profileSettings: "Profile Ayarları",
      compantSettings: "İşletme Ayarları",
      platformSetting: "Platform Ayarları",
      workingHours: "Çalışma Saatleri",
      createNewBranch: "Yeni Şube Oluştur",
      systemLogs: "Sistem Logları",
      giveReferance: "Referance Ol",
      langSetting: "Dil Ayarları",
      walletProcesss: "Bakiye İşlemleri",
      paymentInfo: "Ödeme Bildirimi",
      currentBalance: "Mevcut Bakiyem",
      puchase: "Satın Al",
      smsPackage: "SMS Paketi",
      logOut: "Çıkış",
      galleryManagement: "Galeri Yönetimi",
      showcasePhotos: "Vitrin Fotoğrafı",
      compantGallery: "İşletme Galerisi",
      servicesGallery: "Hizmet Galerisi",
      appointmentTable: "Randevu Takvimi",
      onlineAppointmentRequests: "Online Randevu Talepleri",
      createNewAppointment: "Yeni Randevu Oluştur",
      filterAppointment: "Randevuları Filtrele",
      receiptManagement: "Adisyon Yönetimi",
      receiptList: "Adisyonları Listele",
      createNewReceipt: "Yeni Adisyon Oluştur",
    },
    noWait: "Bekleyen randevu talebiniz bulunmamakta.",
    PlatformLink: {
      btnText: "Randevu Alma Sayfası",
      title: "Randevu Alma Sayfası",
      description:
        "Bu alandan müşterilerinizin işletmenize randevu talebi oluşturması için gerekli olan linke ulaşabilirsiniz. Bu linkten müşterileriniz çalışan, hizmet, gün ve saat bilgilerini girerek randevu talebi oluşturabilir.",
      copyLink: "Linki Kopyala",
      goToLink: "Sayfaya Git",
      linkCopied: "Link Kopyalandı.",
      Qr: "QR Kodu Gör",
    },
    accountInstallCard: {
      heading:
        "Hesap kurulumunuzu aşağıdaki basamaklardan takip edebilirsiniz!",
      description:
        "İlgili adımın üzerine tıklayarak işlem sayfasına gidebilirsiniz.",

      setShift: "Mesai saatlerinizi ayarlayın",
      createService: "Hizmetlerinizi oluşturun",
      setServicePicked: "Çalışanların sunduğu hizmetleri seçin",
      createCustomer: "Müşterilerinizi oluşturun",
    },

    tutorialModal: {
      startHeading: "Hesap Kurulumuna Başla",
      startDescription:
        "Sadece üç temel adımda sistem gereksinimlerini tamamlayarak hesap kurulumunuzu tamamlayın.",

      startTutorialButton: "Kurulumu Başlat",
      dontShowAgainButton: "Bir Daha Gösterme",
    },

    descriptionTutorial: {
      prevButtonTitle: "Önceki",
      nextButtonTitle: "Sonraki",
      finishButtonTitle: "Bitir",
    },

    businessURLCard: {
      heading:
        "İşletme adresinizi müşterilerinizle paylaşarak randevularınızı kolayca yönetin!",
      inputLabel: "İşletme Randevu URL'si",
      openBrowserButton: "Tarayıcıda Aç",
      copyLinkButton: "Linki Kopyala",
      copySuccessToast: "Link başarıyla kopyalandı!",
    },

    usageCard: {
      heading: "Kalan kullanımlarınızı aşağıdan kolayca takip edebilirsiniz.",

      licenseDetail: "Lisans Detayı",
      packageText: "PAKET",
      licenseRemaining: "gün kaldı",

      smsDetail: "Kalan SMS Kredisi",

      avaible_appointment_credit: "Kalan Randevu Kredisi",
    },

    stats: {
      appointment: {
        heading: "Randevu İstatistikleri",
        createdAppointments: "Oluşturulmuş Randevular",
        successAppointments: "Sonuçlanan Randevular",
        closedAppointments: "Sonuçlanmayan Randevular",
      },

      customer: {
        heading: "Müşteri İstatistikleri",
        newCustomers: "Gelen Yeni Müşteriler",
        oldCustomers: "Gelen Eski Müşteriler",
        appointmentCustomers: "Randevulu Müşteriler",
      },

      accounting: {
        heading: "Kasa İstatistikleri",
        income: "Gelir Toplamı ",
        expense: "Gider Toplamı ",
        debt: "Borç Toplamı ",

        licenseUpgradeText: "Hesabınızı Yükseltin!",
      },

      meta: {
        total: "Toplam Randevu",
        arrived: "Geldi",
        notArrived: "Gelmedi",
        waiting: "Yeni",
        canceled: "İptal Edildi",
      },
    },

    cardTitle: {
      receipt_income_card_title: "Kasa İstatistikleri",
      appointment_calender_card_title: "Randevu Takvimi",
      appointments_card_title: "Randevu Ayrıntıları",
    },

    cardDescription: {
      favorites_description:
        "En sık kullandığınız sekmeleri yıldızlayarak kendinize özel kısayollar oluşturabilirsiniz. Ayrıca sürükle bırak ile cardların yerlerini değiştirebilirsiniz.",
      receipt_income_card_description:
        "Günlük, 7 günlük ya da 30 günlük kasa istatiğinizi gelir grafiği üzerinden takip edebilirsiniz.",
      appointment_calender_card_description:
        "Randevu ayrıntılarını görüntülemek istediğiniz günün üzerine tıklayınız.",
      appointment_statistic_card_description:
        "Platform, işletme linki ve panel üzerinden oluşturulan randevu sayısını günlük, 7 günlük ya da 30 gün arayla istatistiksel veriler aracılığıyla raporlayabilirsiniz.",
      appointments_card_description:
        "İşletmenizin günlük, 7 günlük ya da 30 gün arayla aldığı randevu sayısını ve müşterilerinizin gelip/gelmediği bilgisini takip edebilirsiniz.",
      online_appointment_request_card_description:
        "İşletme linkiniz üzerinden müşterilerinizden gelen online randevu taleplerini takip ederek randevuyu onaylayabilir ya da reddedebilirsiniz.",
    },

    favoritePage: {
      sectionHeading: "Favoriler",
    },

    report: {
      company: {
        typeCASH: "Nakit",
        typeCARD: "Kart",
        typeEFT: "Havale/EFT",
        totalIncome: "Toplam Gelir",
        noDataText:
          "Nakit, Kredi/Banka Kartı ve Havale/EFT gelirleriniz mevcut olmadığı için grafik görüntülenemiyor.",
      },
    },

    appointmentAnalys: {
      platform: "SalonRandevu.com",
      company: "İşletme Websitesi",
      panel: "İşletme Tarafından",
      packageWarningText:
        "Randevu İstatistikleri' ni, sadece Premium pakete sahip hesaplar görüntüleyebilir.",
      upgradePackage: "Paket Yükselt",
    },

    waitingAppointments: {
      sectionHeading: "Online Randevu Talepleri",
      headings: {
        appointmentStartDate: "Randevu Saati",
        customerFullName: "İlgilenilecek Müşteri",
        staffFullName: "İlgilenecek Çalışan",
        serviceName: "Yapılacak İşlem",
      },

      buttons: {
        openReceiptTitle: "Adisyonu Aç",
      },
    },

    onlineAppointments: {
      headings: {
        appointmentStartDate: "Tarih - Saat",
        customerFullName: "İlgili Müşteri",
        customerPhone: "Telefon",
        staffFullName: "Çalışan",
        serviceName: "Hizmet",
        note: "Not",
        customerType: "Müşteri Tipi",
        statistics: "İstatistik",
      },

      approveButtonTitle: "Onayla",
      rejectButtonTitle: "Reddet",

      rejectConfirm: {
        title: "Randevu iptalini onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Bu işlem geri alınamaz!",
        alertText:
          "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
      },

      approveToast: "Randevu isteği başarıyla onaylandı!",
      rejectToast: "Randevu isteği başarıyla reddedildi!",
    },
    openAppointments: {
      headings: {
        date: "Tarih - Saat",
        customerFullName: "İlgili Müşteri",
        products: "Ürünler",
        serviceName: "Hizmet",
        totalPayment: "Toplam Tutar",
      },
      title: "Açık Adisyonlar",
      approveButtonTitle: "Onayla",
      rejectButtonTitle: "Reddet",

      rejectConfirm: {
        title: "Randevu iptalini onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Bu işlem geri alınamaz!",
        alertText:
          "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
      },

      approveToast: "Randevu isteği başarıyla onaylandı!",
      rejectToast: "Randevu isteği başarıyla reddedildi!",
    },
    creditors: {
      headings: {
        customerFullName: "İlgili Müşteri",
        type: "Tip",
        paymentDate: "Planlanan Ödeme Tarihi",
        totalPayment: "Toplam Tutar",
      },

      title: "Alacaklılar",
      approveButtonTitle: "Onayla",
      rejectButtonTitle: "Reddet",

      rejectConfirm: {
        title: "Randevu iptalini onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Bu işlem geri alınamaz!",
        alertText:
          "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
      },

      approveToast: "Randevu isteği başarıyla onaylandı!",
      rejectToast: "Randevu isteği başarıyla reddedildi!",
    },
    birthDay: {
      headings: {
        customerFullName: "İlgili Müşteri",
        customerPhone: "Telefon Numarası",
        birthDate: "Doğum Tarihi",
      },
      title: "Yaklaşan Doğum Günleri",
    },
    openReceiptOnSuccessToast: "Randevuya ait adisyon başarıyla oluşturuldu!",
  },

  /**
   * Randevu tablosun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments
   */
  appointments: {
    customerAppointment: {
      title: "Randevuyu İptal Etmeyi Onaylıyor musunuz?",
      yes: "Evet",
      close: "Cancel",
      expTitle: "Bu işlem geri alınamaz!",
      expDetail:
        "Randevu, randevu takviminden silinecektir. Onaylıyor musunuz?",
    },
    appointmentsComp: {
      green:
        "Yeşil renk ile gösterilen saatler randevu alımına açık olan saatlerdir.",
      red: "Kırmızı renk ile gösterilen saatler daha önce randevu oluşturulmuş saatlerdir.",
      purple:
        "Mor ile gösterilen saatlere randevu alınması durumunda mevcut bir randevu veya kapalı olan bir saatle çakışma ihtimali olabilir.",
      gray: "Gri renk ile gösterilen saatler çalışan detayı veya randevu takvimi üzerinden kapatılan saatlerdir.",
    },
    comunicateWithWhatsApp: "WhatsApp ile iletişime Geç",
    tooltipExplanations: {
      selectStaff:
        "Takvimde görmek istediğiniz personelleri buradan seçebilirsiniz",
      staffCount:
        "Sayfada görünmesini istediğiniz kişi sayısını buradan düzenleyebilirsiniz",
      appointmentRequest: "Online Randevu Talepleriniz",
      appointmentList: "Randevu Listeniz",
      appointmentTypes:
        "Randevu takvimini günlük, haftalık veya aylık görüntülemek için buradan değiştirebilirsiniz",
    },
    staffSelect: {
      all: "Tümü",
    },
    staffViewCount: {
      person3: "3 kişi",
      person5: "5 kişi",
      person7: "7 kişi",
      person9: "9 kişi",
      all: "Tümü",
    },
    appointmentTableTypes: {
      day: "Bugun",
      week: "Bu Hafta",
      month: "Bu Ay",
    },
    dayInputLabel: "Gösterilecek Randevuların Tarihi",
    beforeDayButtonTitle: "Önceki Gün",
    afterDayButtonTitle: "Sonraki Gün",
    today: "Bugün",
    allStaff: "Bütün Çalışanlar",
    dayWeekSelectLabel: "Gün-Hafta-Aylık",

    // Salon Randevu
    definitionGreenText:
      "Telefon ya da link üzerinden katılım onayı alınmış randevular.",
    definitionRedText:
      "Randevu saati geçmiş ve gelinmemiş randevuları temsil etmektedir.",
    definitionYellowText:
      "Randevu saati henüz geçmemiş ve bekleyen randevuları temsil etmektedir.",
    definitionLightText:
      "Gelen ve adisyonu açılmış randevuları temsil etmektedir.",
    definitionPurpleText: "Gelindi ve işlemi yapılıyor.",
    // En Randevu
    definitionGreenText_en:
      '"Geldi" olarak işaretlenen randevuları temsil eder.',
    definitionRedText_en:
      '"Gelmedi" olarak işaretlenen randevuları temsil eder.',
    definitionYellowText_en:
      'Henüz işlem yapılmamış , "Bekleme" durumunda olan randevuları temsil eder.',

    create: {
      title: "Seçili Tarihe Randevu Oluştur",
      completeButtonTitle: "Tamamla",
      completePaymentButtonTitle: "Ödemeyi Tamamla",
      discardButtonTitle: "Vazgeç",

      selectedTimeInputLabel: "Randevu Günü ve Saati",
      selectedStaffInputLabel: "Çalışan Seçimi",
      selectedServiceInputLabel: "Çalışanın Sunduğu Hizmetler",
      selectedPackageInputLabel: "Çalışanın Sunduğu Paketler",
      selectedCustomerInputLabel: "Randevu Verilecek Müşteri",
      selectedCustomerInputPlaceholder:
        "Müşteri ismini veya telefon numarasını bu alana giriniz.",
      createNewCustomerButtonTitle: "Yeni Müşteri Oluştur",
      createNewWalkinCustomerButtonTitle: "Kayıtsız Müşteri Oluştur",

      closeAllDayTitle: "Tüm Günü Kapat",

      selecting_service: "Hizmet Seçimi",
      selecting_package: "Paket Seçimi",

      // sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
      sendSmsInputLabel: "Bilgilendirme SMS' i Gönder",
      sendSmsTrue: "Gönderilsin",
      sendSmsFalse: "Gönderilmesin",
      description: "Açıklama",
      defaultValue: "Müşteri Seçiniz",

      groupAppointment: "Toplu Randevu Oluşturma",
      clockOff: "Saat Kapatma",
      addEmployeeButtonTitle: "Çalışan + Hizmet Ekle",

      noteInputLabel: "Randevu Notu (İsteğe Bağlı)",

      onWarningToast:
        "Müşteri seçimi ve servis seçimi alanlarını eksiksiz giriniz!",

      noDataText:
        "Toplu Randevu oluşturmak için en az 2 çalışan ve hizmet seçmelisiniz.",
    },

    detail: {
      title: "Randevu Detayı",
      deleteButtonTitle: "Randevuyu İptal Et",
      closeButtonTitle: "Kapat",

      deleteButton: "Sil",
      editButton: "Düzenle",
      clockOffDetail: "Saat Kapatma Detayı",
      clockOffDateInputLabel: "Kapanan Tarih/Saati",
      clockOffStaffInputLabel: "İlgili Personel",
      clockOffNoteInputLabel: "Açıklama",

      appointmentTime: "Randevu Süresi (DK)",
      appointmentTimeExtension: "Randevu Süresini Uzatın",
      appointmentalAlertMessage:
        "Randevu saatiniz uzatılmıştır. Güncel randevu saatini Randevu Takviminden görüntüleyebilirsiniz.",
      appointmentalWarningMessage: "Lütfen randevu süresini giriniz.",

      dateInputLabel: "Randevu Tarih/Saati",
      serviceInputLabel: "Uygulanacak Hizmet",
      packageInputLabel: "Uygulanacak Paket",
      staffInputLabel: "İlgilenecek Personel",
      customerInputLabel: "Müşteri Adı-Soyadı",
      customerPhoneInputLabel: "Müşteri Telefon Numarası",
      noteInputLabel: "Randevu Notu",
      customerNote: "Müşteri Notu",
      appointmentStatus: {
        title: "Randevu Durumu",
        arrived: "Geldi",
        notArrived: "Gelmedi",
        waiting: "Bekleniyor",

        infoText: "Paketteki seanslar bu şekilde düzenlenecek :",

        changeStatusSuccessToast: "Randevu durumu başarıyla değiştirilmiştir.",
      },

      types: {
        come: "Geldi",
        notCome: "Gelmedi",
        inProcess: "İşlemde",
        new: "Yeni",
        approved: "Onaylı",
      },

      createReceipt: "Adisyon Aç",
      goToReceipt: "Adisyona Git",
    },

    deleteConfirm: {
      title: "Randevu İptal Onayı",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "SMS gönderim ayarlarınızın açık olması ve SMS bakiyenizin olması durumunda müşteriye randevunun iptali konusunda bilgi SMS'i gönderilecektir.",
    },

    pastCreateErrorToast:
      "Geçmiş bir tarih-saate randevu oluşturmayı denediniz.",
    onMouseOverCardToast:
      "Randevu detayını görmek istediğiniz kartın üzerine tıklayabilirsiniz.",
    insertToast: "Randevu başarıyla oluşturuldu.",
    deleteToast: "Randevu başarıyla silindi.",

    weeklyAppointmentsStaffErrorToast:
      "Haftalık randevular için lütfen bir çalışan seçiniz.",
    weeklyAppointmentsDisplayErrorToast:
      "Randevu alabilmek için günlük randevuları görüntüleyiniz.",

    checkButton: "Anladım",
    firstLoginAppointmentsTitle: "BİLGİLENDİRME",
    firstLoginAppointmentsDescription:
      "Sizin için örnek personel bilgisi ve randevu kartı oluşturulmuştur. Personel bilgilerinizi ve randevularınızı mevcut sekmelerin üzerine tıklayarak güncelleyebilirsiniz.",

    updateAppointment: {
      title: "Randevu çalışanını ve/veya saatini değiştirmek istiyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Mevcut randevuyu güncellemeniz halinde çalışan ve/veya saat bilgisi değişecektir.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      service: "Hizmet Adı",
      staff: "Çalışan",
      time: "Saat",
      customer: "Müşteri",

      updateAppointmentSuccessToast: "Randevu başarıyla güncellenmiştir.",
    },

    orderStaffsButtonTitle: "Çalışanları Sırala",

    rightButtonTooltip: "Tabloyu sağa sürükle",
    leftButtonTooltip: "Tabloyu sola sürükle",

    createAppointmentButtonTitle: "Randevu Oluştur",
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/filter
   */
  "appointments/filter": {
    alertBoldText:
      "Aşağıdaki alanları kullanarak randevularınızı filtreleyebilirsiniz.",
    alertText:
      "Alanlarda değişiklik yapmanız durumunda otomatik olarak filtreleme işlemi yapılmaktadır.",

    startInputLabel: "Başlangıç Tarihi",
    endInputLabel: "Bitiş Tarihi",
    staffInputLabel: "Çalışan Seçimi",
    allStaffsText: "Tüm Çalışanlar",
    serviceInputLabel: "Hizmet Adı",
    customerInputLabel: "Müşteri Adı Soyadı",

    tabMenuLeft: {
      customerBill: "Adisyonlar",
      customerAppointment: "Randevular",
      customerDetails: "Müşteri Bilgileri",
      customerPhotos: "Fotoğraflar",
      customerContract: "Sözleşmeler",
      updateCustomerDetailsTrue: "Müşteri bilgileri başarıyla güncellendi!",
      updateCustomerDetailsFalse: "Müşteri bilgileri güncellenemedi!",
    },

    statusInputLabel: "Randevu Durumu Seçimi",
    statusInputItems: [
      "Tüm Randevular",
      "Gelinen Randevular",
      "Gelinmeyen Randevular",
      "Bekleyen Randevular",
      "İptal Edilen Randevular",
    ],

    filterTitle: "Tarih Filtresi",

    canceled: "İptal Edilmiş",
    situations: "Durumlar",

    headings: {
      appointmentStartDate: "Randevu Tarihi",
      customerFullName: "Müşteri",
      serviceName: "Hizmet",
      appointmentNote: "Randevu Notu",
      staffFullName: "İlgilenecek Çalışan",
      customerPhone: "Müşteri Telefonu",
      createdAt: "Randevu Oluşturulma Tarihi",
    },
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/online
   */
  "appointments/online": {
    alertText:
      "Aşağıdaki alanda onaylanması durumunda işlemi sizin uygulayacağınız online randevu talepleri listeletilmektedir. Bu randevuları yalnızca salon yetkilisi onaylayıp/reddedebilir.",
    headings: {
      appointmentStartDate: "Tarih - Saat",
      customerFullName: "İlgili Müşteri",
      customerPhone: "Müşteri Telefonu",
      staffFullName: "İlgilenecek Çalışan",
      serviceName: "Hizmet",
      note: "Müşterinin Randevu Notu",
      customerType: "Müşteri Tipi",
    },

    approveButtonTitle: "Onayla",
    rejectButtonTitle: "Reddet",

    rejectConfirm: {
      title: "Randevu iptalini onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu isteği reddetmeniz durumunda bir daha bu randevu isteğine erişemezsiniz.",
    },

    approveToast: "Randevu isteği başarıyla onaylandı!",
    rejectToast: "İşlem başarıyla gerçekleştirildi!",
    blackListWarning:
      " Numara kara listeye eklendikten sonra bu numara ile randevu talebinde bulunulamaz.",
    addToNumberBlackList: " Bu numarayı kara listeye eklemek istiyor musunuz?",
  },

  /**
   * Randevu Oluştur sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/add
   */
  "appointments/add": {
    staffInputLabel: "Çalışan Seçimi",
    serviceInputLabel: "Çalışanın Sunduğu Hizmetler",
    customerInputLabel: "Randevu Verilecek Müşteri",
    customerInputPlaceholder: "Müşteri ismini bu alana giriniz",
    //sendSmsInputLabel: "Bilgilendirme SMS Gönderimi",
    sendSmsInputLabel: "Bilgilendirme SMS' i Gönder",
    sendSmsTrue: "Gönderilsin",
    sendSmsFalse: "Gönderilmesin",
    noteInputLabel: "Randevu Notu (İsteğe Bağlı)",

    availableHoursButtonTitle: "Uygun Randevu Saatlerini Getir",
    confirmButtonTitle: "Randevu Seçimini Onayla",
    confirmButtonHoursTitle: "Saat Seçimini Onayla",
    createNewCustomerButtonTitle: "Yeni Müşteri Oluştur",
    createNewWalkinCustomerButtonTitle: "Yeni Müşteri Oluştur",

    insertToast: "Randevu başarıyla oluşturuldu.",
  },

  /**
   * Adisyonların listeletildiği sayfaların bulunduğu key.
   * https://app.salonrandevu.com/receipts
   * https://app.salonrandevu.com/packages/sales
   */
  receipts: {
    allDelete: "Adisyonlar ve ödemeler başarıyla silindi!",
    serviceTransactions: {
      // Section
      print: "Yazdır",
      soldTitle: "Yeni Hizmet Satışı",
      title: "Hizmet Satışları",
      packageTitle: "Paket Satışları",
      packageTotalAmount: "Paket Toplamı",
      dateInputLabel: "İşlem Tarihi",
      packageDateInputLabel: "Paket Başlangıcı",
      nameInputLabel: "İşlem Adı",
      transactionStatus: "Durum",
      staffInputLabel: "Personel",
      amountInputLabel: "Ücret ",
      totalAmount: "Hizmet Toplamı",
      noDataText: "Adisyona henüz bir hizmet satışı eklenmemiş!",
      noDataPackageText: "Adisyona henüz bir paket satışı eklenmemiş!",
    },
    filter: {
      title: "Tarih ve Müşteri Bazlı Filtreleme",
      startInputLabel: "Başlangıç Tarihi",
      endInputLabel: "Bitiş Tarihi",
      typeOfSales: "Satış Türü",

      allSales: "Tüm Satışlar",
      openTitle: "Hepsi",
      open: "Açık Satışlar",
      close: "Kapalı Satışlar",
    },

    previous: "Önceki Sayfa",
    next: "Sonraki Sayfa",

    headings: {
      id: "Adisyon No",
      type: "Satış Türü",
      billType: "Adisyon Türü",
      createdAt: "Açılma Tarihi",
      customerFullName: "Müşteri Adı",
      info: "Adisyon İçeriği",
      allAmount: "Toplam",
      paid: "Ödenen",
      debtTotal: "Kalan",
      processDate: "İşlem Tarihi",
    },

    replacements: {
      undefinedCustomer: "Tanımlanmamış Müşteri",
      allSales: "Tüm Satışlar",
      packageSale: "Paket",
      standartSale: "Standart",
    },

    add: {
      foreingPhoneNumberPlaceholder: "Yabancı Telefon Numarasını Giriniz.",
    },

    showButtonTitle: "Görüntüle",
    deleteButtonTitle: "Sil",

    detailButtons: {
      confirmButton: "Onayla",
      closeButton: "Kapat",
    },
    alert_receipt_installment:
      "Adisyonda, ödeme beklenen tarihi geçmiş taksit bulunmaktadır.",
  },

  packages: {
    filter: {
      title: "Paket Bazlı Filtreleme",
      packageInputLabel: "Paket Adı",
    },
  },

  /**
   * Adisyonların oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/receipts/add
   */
  "receipts/add": {
    searchCustomerInputLabel: "Müşteri Ara",
    searchCustomerInputPlaceholder: "Müşteri isim soyismini bu alana giriniz",
    receiptNoteInputLabel: "Adisyon Notu (İsteğe Bağlı)",

    openReceiptBySelectedButtonTitle: "Adisyonu Oluştur",
    openReceiptByNewCustomerButtonTitle:
      "Yeni Müşteri Üzerinden Adisyon Oluştur",
    openReceiptByRandomCustomerButtonTitle:
      "Kayıtsız Müşteri Üzerinden Adisyon Oluştur",
    openReceiptWithCreatedNewCustomerButtonTitle: `Müşteri ve Adisyonu Oluştur`,

    insertToast: "Adisyon başarıyla oluşturuldu!",
  },

  /**
   * Tekli adisyonun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/receipts/detail/:id
   */
  "receipt/detail/id": {
    installmentPayment: "Taksitli Ödemeler",
    packageEdit: {
      dateEditTitle: "Paket Tarih Güncelleme",
      dateEdit:
        "Paketteki bütün seansların tarihleri, tarih aralığına göre güncellenecektir. Onaylıyor musunuz?",
      dateEditNote:
        "Not: Bu güncellemeden sonra değişiklikleri kaydetmeyi unutmayın.",
      approve: "Onayla",
      abort: "Vazgeç",
      delete: "Paket Silme",
      deleteInfo:
        "Paket ve içerisinde bulunan hizmetler silinecektir.Onaylıyor musunuz?",
      deleteNote:
        "Not: Bu güncellemeden sonra değişiklikleri kaydetmeyi unutmayın.",
    },
    paymentStatus: {
      paid: "Ödendi",
      noPaid: "Ödenmedi",
      waiting: "Bekliyor",
    },
    agreementPaper: {
      buttons: {
        close: "Kapat",
        cancel: "İptal",
        preview: "Önizleme",
        approve: "Onayla",
        abort: "Vazgeç",
        save: "Kaydet",
      },
      selectAggrementTemplate: "Sözleşme şablonu seçiniz",
      createNewAgreement: "Yeni Sözleşme Oluştur",
      uploadAgreement: "Sözleşme Yükle",
      savedAgreement: "Kayıtlı Sözleşmeler",
      personalInfo: "Kişisel Bilgiler",
      nameSurname: "Ad Soyad",
      phoneNumber: "Telefon Numarası",
      idNo: "T.C No",
      taxNo: "Vergi No",
      address: "Adres",
      staffName: "Çalışan Adı",
      service: "Hizmet",
      netAmount: "Net Toplam",
      yes: "Evet",
      no: "Hayır",
      print: "Yazdır",
      sendToSigniture: "İmzaya Gönder",
      approvalTitle:
        "Düzenlediğiniz sözleşmeyi mobil imza için göndermeyi onaylıyor musunuz?",
      aggExp:
        "Gönderilen sözleşmeyi mobil uygulamanızdan Listeler Sözleşme Listesi kısmından görüntüleyebilir, düzenleyebilir ve imzalayabilirsiniz.",
      uploadExp: "Bu adisyona ait sözleşme yüklemek için tıklayınız.",
      uploadLabel: "Ad Soyad",
      aggrementName: "Sözleşme Adı",
      warningInfo: "Lütfen eksik alanları doldurunuz: Sözleşme Adı",
      savedAggrement: "Kayıtlı Sözleşmeler",
      table: {
        date: "Tarih",
        name: "Ad Soyad",
        aggName: "Sözleşme Adı",
        size: "Boyutu",
        actions: "İşlemler",
      },
      sendAggrement: {
        smsTitle: "Kayıtlı sözleşmeyi SMS ile iletmek ister misiniz?",
        wpTitle: "Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?",
        send: "Gönder",
        cancel: "İptal",
        approval: "Emin misiniz?",
      },
      deleteAggrement: {
        title: "Sözleşmeyi Silmeyi Onaylıyor Musunuz?",
        approval: "Onayla ve Sil",
        abort: "Vazgeç",
        warningExp: "Sözleşme kalıcı olarak silinecektir.",
        warningDetailExp:
          "Bu işlemin geri dönüşü olmayacaktır. Sözleşme kalıcı olarak silinecektir.",
      },
    },

    printPaper: {
      receiptNo: "Adisyon No",
      services: "Hizmetler",
      staff: "Personel",
      service: "Hizmet",
      price: "Ücret",
      products: "Ürünler",
      product: "Ürün",
      amount: "Adet",
      receiptSummary: "Adisyon Toplamı",
      discount: "İndirim",
      netSum: "Net Toplam",
      remainingAmount: "Kalan Tutar",
    },

    saveYourChanges: "Yapmış olduğunuz değişiklikleri lütfen kaydedin.",
    customerPoint: "Müşteri Puanı",
    remainingDebt: "Toplam Borç",
    saveChangesButtonTitle: "Değişiklikleri Kaydet",
    print: "Yazdır",
    contract: "Sözleşme",
    installSingle: "Taksit",
    installments: "Taksitler",
    paymentAmount: "Ödeme Tutarı",
    packageSessionUpdate: "Paket Seans Güncelleme",
    getAvailableServices: "Uygun Hizmetleri Getir",
    selectCustomerPayment: "Personel Seçimi (Opsiyonel)",

    applyDiscountButtonTitle: "İndirim Uygula",
    addPackageSaleButtonTitle: "Paket Satışı Ekle",
    addServiceTransactionButtonTitle: "Hizmet Satışı Ekle",
    addStockSaleButtonTitle: "Yeni Ürün Satışı Ekle",
    addPaymentButtonTitle: "Yeni Ödeme Ekle",

    installmentButtonTitle: "Taksitli Ödeme Ekle",
    installmentCountInputLabel: "Taksit Sayısı",
    installmentProcess: "Taksitlendirme işlemi",
    addPaymentTitle: "Peşin Ödeme",

    deleteReceiptButtonTitle: "Adisyonu Sil",
    alertText:
      "Mesaj kaydedilmiştir fakat mesajın iletilmesi için öncelikle adisyonu kaydetmelisiniz.",

    sms: {
      sendButtonTitle: "SMS'i Kaydet",
      discardButtonTitle: "İptal",
      addSmsButtonTitle: "Tahsilat Mesajı Gönder",
      smsButtonTitle: "Borç Bilgilendirme / Tahsilat Mesajı Gönder",
    },

    creationdate: "Oluşturulma Tarihi",

    point: "puan",
    packageInner: {
      title: "Paket İçeriği",
      headings: {
        // Table Headings
        name: "Hizmet Adı",
        count: "Toplam Seans",
        completed: "Tamamlanan Seans",
        pending: "Bekleyen Seans",
      },
    },

    serviceTransactions: {
      // Section
      print: "Yazdır",
      soldTitle: "Yeni Hizmet Satışı",
      title: "Hizmet Satışları",
      packageTitle: "Paket Satışları",
      packageTotalAmount: "Paket Toplamı",
      dateInputLabel: "İşlem Tarihi",
      packageDateInputLabel: "Paket Başlangıcı",
      nameInputLabel: "İşlem Adı",
      transactionStatus: "Durum",
      staffInputLabel: "Personel",
      amountInputLabel: "Ücret ",
      totalAmount: "Hizmet Toplamı",
      noDataText: "Adisyona henüz bir hizmet satışı eklenmemiş!",
      noDataPackageText: "Adisyona henüz bir paket satışı eklenmemiş!",
    },

    stockSales: {
      // Section
      title: "Ürün Satışları",
      nameInputLabel: "Ürün Adı",
      deletedName: "Silinmiş Ürün",
      staffInputLabel: "Satışı Yapan",
      amountInputLabel: "Birim Fiyat ",
      countInputLabel: "Adet",
      totalAmount: "Ürün Toplamı",
      noDataText: "Adisyona henüz bir ürün satışı eklenmemiş",
      barcodeCheckAlert:
        "Ücretli içerik aktif değil. Aktivasyon için temsilci ile iletişime geçiniz.",
      selectWithBarcode: "Barkod İle Ürün Seç",
    },
    barcodeAlert: {
      pleaseEnterACode: "Lütfen Bir Ürün Kodunu Girin",
    },

    productTransactions: {
      productSaleTitle: "Yeni Ürün Satışı",
      successAdd: "Ürün Başarıyla Eklendi",
    },

    payments: {
      // Section
      title: "Ödemeler",
      dateInputLabel: "Ödeme Tarihi",
      typeInputLabel: "Ödeme Yöntemi",
      amountInputLabel: "Tutar ",
      pointInputLabel: "Kullanılacak Puan",

      typeCASH: "Nakit",
      typeCARD: "Kredi / Banka Kartı",
      typeEFT: "Havale/EFT",
      typePOINT: "Müşteri Puanı",

      totalAmount: "Adisyon Toplamı",
      totalPaidAmount: "Ödenen Tutar",
      totalDebtAmount: "Borç Tutarı",

      noDataText: "Adisyona henüz bir ödeme yöntemi eklenmemiş!",
    },

    installment: {
      title: "Taksitli Ödemeler",
    },

    notes: {
      // Section
      title: "Notlar",
      receipt: "Adisyon Notu",
      customer: "Müşteri Açıklaması",
      appointment: "Randevu Notu",
    },

    applyDiscount: {
      // Modal
      title: "İndirim Uygula",
      typeInputLabel: "İndirim Tipi",
      typeCASH: "TL Bazında İndirim",
      typePERCENT: "Yüzde Bazında İndirim",
      amountInputLabel: "İndirim Tutarı",
      discardButtonTitle: "Vazgeç",
    },

    packageSale: {
      // Modal
      title: "Yeni Paket Satışı",
      completeButtonTitle: "Satışı Tamamla",

      packageNameInputLabel: "Paket Seçimi",
      serviceInputLabel: "Hizmet Seçimi",
      staffInputLabel: "Çalışan Seçimi",
      startInputLabel: "Başlangıç Tarihi",
      periodInputLabel: "Seans Sayısı",
      periodBetweenInputLabel: "Seans Aralığı",
      periodAmountInputLabel: "Seans Fiyatı",

      noDataText: "Paket satışına henüz bir hizmet-seans eklenmemiş!",
      totalAmount: "Paket Toplamı ",

      servicePickToast: "Hizmet seçimlerinizi eksiksiz tamamlayın!",
      customerPickToast: "Müşteri seçimini eksiksiz tamamlayın!",

      packageSaleSuccess: "Seçtiğiniz paket başarıyla adisyona eklendi.",
    },

    serviceTransaction: {
      // Modal
      title: "Yeni Hizmet Satışı",
      addButtonTitle: "Ekle",
      clearButton: "Temizle",
      discardButtonTitle: "Vazgeç",

      staffInputLabel: "Personel Seçimi",
      serviceInputLabel: "Hizmet Seçimi",
      customerStateInputLanel: "Müşteri Durumu",
      successAdd: "Hizmet Başarıyla Eklendi",
    },

    stockSale: {
      // Modal
      title: "Yeni Ürün Satışı",
      addButtonTitle: "Ekle",
      discardButtonTitle: "Vazgeç",

      staffInputLabel: "Satışı Yapan Personel",
      stockInputLabel: "Ürün Seçimi",
    },

    delete: {
      // Modal
      title: "Silmeyi onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertText:
        "Bu işlemin geri dönüşü yoktur! Bu adisyonu silmeniz durumunda içerisinde yer alan işlemler de beraber silincektir. Muhasebe kayıtlarınıza etki edebilir!",
    },

    discountBeforePaymentToast:
      "İndirim uygulayabilmek için ödemeleri kaldırmanız gerekmektedir.",
    saveChangesToast: "Değişiklikler başarıyla kaydedildi.",
  },

  "packages/sales": {
    filter: {
      title: "Tarih Bazlı Filtreleme",
    },
  },

  /**
   * Paket satışının yapıldığı sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/sales/add
   */
  "packages/sales/add": {
    searchPackegeInputLabel: "Paket Ara",
    customerInputLabel: "Satış Yapılacak Müşteri",
    customerInputPlaceholder: "Müşteri ismini bu alana giriniz",

    importPackageButtonTitle: "Paketi İçe Aktar",
    addServiceButtonTitle: "Pakete Hizmet Ekle",
    completeButtonTitle: "Satışı Tamamla",

    enterPackageName: "Paket Adını Giriniz",

    serviceInputLabel: "Hizmet Seçimi",
    staffInputLabel: "Çalışan Seçimi",
    startInputLabel: "Başlangıç Tarihi",
    periodInputLabel: "Seans Sayısı",
    periodBetweenInputLabel: "Seans Aralığı",
    periodAmountInputLabel: "Seans Fiyatı",
    totalInputLabel: "Paket Toplamı ",

    noDataText: "Paket satışına henüz bir hizmet-seans eklenmemiş!",

    modal: {
      title: "Hazırlanmış Paketi İçe Aktar",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      packageInputLabel: "Paket Seçimi",
    },
  },

  /**
   * Paketlerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/list
   */
  "packages/list": {
    headings: {
      name: "Paket Adı",
      amount: "Paket Tutarı",
    },
  },

  /**
   * Paketlerin oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/add
   */
  "packages/add": {
    name: "Paket Adı",
    givenName: "Yeni Paket...",
    completeButtonTitle: "Paketi Oluştur",
    addServiceButtonTitle: "Pakete Yeni Hizmet Ekle",
    pickCostumerAndPackageSale: "Müşteri Seç ve Paket Satışı Yap",
    CompletePackageSaleButtonTitle: "Paket Satışı Yap",
    discardButtonTitle: "Vazgeç",

    serviceInputLabel: "Hizmet Seçimi",
    periodInputLabel: "Seans Sayısı",
    periodAmountInputLabel: "Seans Birim Fiyatı ",
    totalInputLabel: "Toplam Paket Fiyatı ",

    noDataText: "Pakete henüz bir hizmet-seans eklenmemiş!",

    validateServiceToast: "Hizmet seçimlerinizi eksiksiz tamamlayın!",
    validateCustomersToast: "Müşteri seçimlerinizi eksiksiz tamamlayın!",
    insertToast: "Paket başarıyla oluşturuldu.",
  },

  /**
   * Paket detayı sayfasının bulunduğu key.
   * https://app.salonrandevu.com/packages/detail/:id
   */
  "packages/detail/id": {
    updatePackageButtonTitle: "Paketi Güncelle",
    deletePackageButtonTitle: "Paketi Sil",
    addServiceButtonTitle: "Pakete Yeni Hizmet Ekle",

    nameInputLabel: "Paket Adı",
    serviceInputLabel: "Hizmet Seçimi",
    countInputLabel: "Seans Sayısı",
    periodAmountInputLabel: "Seans Birim Fiyatı",

    totalInputLabel: "Toplam Paket Fiyatı ",
    noDataText: "Pakete henüz bir hizmet-seans eklenmemiş!",

    delete: {
      title: "Paketi Silmeyi Onayla",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Vazgeç",
      alertText:
        "Paketi silmeyi onaylıyor musunuz? Daha önceden yapmış olduğunuz satışlar, adisyonlar ve muhasebe kayıtları etkilenmeyecektir.",
    },

    validateServiceToast: "Hizmet seçimlerinizi eksiksi tamamlayın!",
    deleteToast: "Paket başarıyla silindi.",
    updateToast: "Paket başarıyla güncellendi",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  products: {
    deleteButtonTitle: "Ürünü Sil",

    headings: {
      name: "Ürün Adı",
      amount: "Ürün Ücreti",
      limit: "Stok Adeti",
      bounty: "Prim Yüzdesi",
    },
    delete: {
      // Modal
      title: "Ürün Silme Onayı",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Silmeniz durumunda bir daha bu ürün üzerinden satış gerçekleştiremezsiniz!",
    },

    deleteToast: "Ürün başarıyla silindi.",
    stockName: "Stok Adı",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  "products/add": {
    searchProductInputLabel: "Ürün Ara",
    searchProductInputPlaceholder: "Ürün adını giriniz",

    createButtonTitle: "Ürünü Oluştur",

    nameInputLabel: "Ürün Adı",
    nameInputPlaceholder:
      "Yönetim uygulamanızda listelenecek olan ürünün adını giriniz.",
    amountInputLabel: "Ürün Ücreti ",
    amountInputHint:
      "Belirlediğiniz tutar varsayılan tutardır. Adisyona eklenme esnasında işlem fiyatını değiştirebilirsiniz.",
    bountyInputLabel: "Prim Yüzdesi (%)",

    bountyInputHint:
      "Çalışanlarınızın sabit prim yüzdesi tanımlı değilse belirtilecek yüzde tutarınca prim kazanacaklar.",
    pointInputLabel: "Kazanılacak Puan",
    pointInputHint: "100 müşteri puanı 1TL'ye denk gelmektedir.",
    limitInputLabel: "Stok Adeti",
    limitInputHint:
      "Ürünün stok adetini giriniz. Bu değeri daha sonra değiştirebilirsiniz.",

    insertToast: "Ürün başarıyla oluşturuldu.",
    addBarcode: "Barkode Kodu İle Ürün Oluştur",
    addBarcodeExplain: "Ürüne barkod oluşturmak için kod giriniz.",
    barcodeCheckTitle:
      "Ücretli içerik aktif değil. Aktivasyon için temsilci ile iletişime geçiniz.",
  },

  /**
   * Ürün detay sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products/detail/:id
   */
  "products/detail/id": {
    updateButtonTitle: "Ürünü Güncelle",

    nameInputLabel: "Ürün Adı",
    nameInputPlaceholder:
      "Yönetim uygulamanızda listelenecek olan ürünün adını giriniz.",
    amountInputLabel: "Ürün Ücreti ",
    amountInputHint:
      "Belirlediğiniz tutar varsayılan tutardır. Adisyona eklenme esnasında işlem fiyatını değiştirebilirsiniz.",
    bountyInputLabel: "Prim Yüzdesi (%)",
    bountyInputHint:
      "Çalışanlarınızın sabit prim yüzdesi tanımlı değilse belirtilecek yüzde tutarınca prim kazanacaklar.",
    pointInputLabel: "Kazanılacak Puan",
    pointInputHint: "100 müşteri puanı 1TL'ye denk gelmektedir.",
    limitInputLabel: "Stok Adeti",
    limitInputHint:
      "Ürünün stok adetini giriniz. Bu değeri daha sonra değiştirebilirsiniz.",

    updateToast: "Ürün başarıyla güncellendi.",
  },

  /**
   * Aktif müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers
   */
  customers: {
    phone: { placeHolder: "Telefon Numarası Giriniz" },
    authMessage: {
      noAuth:
        "Yetkileriniz gereğince müşteri bilgilerinde değişiklik yapamazsınız!",
      updateAuth:
        "Müşteri bilgilerini düzenleme ve müşteriyi silme işlemlerini yalnızca işletme yetkilileri gerçekleştirebilir.",
    },
    detail: {
      goToAppointment: "Randevuya Git",
      cancel: "İptal Et",
      canceled: "İptal Edildi",
      birthday: "Doğum Tarihi",
      sPhone: "İkinci Telefon Numarası",
      contactViaWP: "Whatsapp ile iletişime geç",
    },
    alertTExt:
      "Müşteri listenizde ekli olan müşterilerinizin sms gönderimlerini açıp-kapama ayarıdır. Bu işlemi gerçekleştirdiğinizde ekli olan tüm müşterilerinizin sms gönderim ayarı güncellenecektir.",
    filter: {
      title: "Müşteri Arama Filtresi",
      inputLabel: "İsim Soyisim ya da Telefon Numarası",
    },
    amount: {
      openSmsSendingStatus: "SMS Gönderim Durumu Açın",
      closeSmsSendingStatus: "SMS Gönderim Durumu Kapatın",
      title: "Tutarları Görüntüle",
      updateSmsStatus: "E-Mail Durumunu Güncelle",
      allCustomerSmsUpdate: "Bütün Müşterilerin SMS Gönderim Durumu Değişecek!",
    },
    headings: {
      code: "Müşteri Kodu",
      fullName: "İsim Soyisim",
      phone: "Telefon Numarası",
      colorSelect: "Renk Seçimi",
      sex: "Cinsiyet",
      incomeAll: "Kazandırdığı Tutar",
      debtAll: "Borç Tutarı",
    },
    replacements: {
      totalCustomerNumber: "Toplam Müşteri Sayısı",
      sex: {
        undef: "Belirtilmemiş",
        male: "Erkek",
        female: "Kadın",
      },
    },
    passiveAll: {
      passiveAllButtonTitle: "Hepsini Pasif Yap",
      title: "Bütün müşterilerizi pasif yapmayı onaylıyor musunuz?",
      alertText:
        "Bütün müşterileri pasif hale getirmeniz durumunda bir daha randevu/adisyon gibi alanlarda müşterileri göremeyeceksiniz.İstediğiniz her zaman Pasif Müşteriler sekmesinden müşterilerin bilgilerine erişebilirsiniz.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      successToast: "Bütün müşteriler başarıyla pasif hale getirildi.",
    },
    passiveSelected: {
      passiveSelectedButtonTitle: "Seçilenleri Pasif Yap",
      title: "Seçilen müşterileri pasif yapmayı onaylıyor musunuz?",
      alertText:
        "Seçilen müşterileri pasif hale getirmeniz durumunda bir daha randevu/adisyon gibi alanlarda müşterileri göremeyeceksiniz.İstediğiniz her zaman Pasif Müşteriler sekmesinden müşterilerin bilgilerine erişebilirsiniz.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",

      successToast: "Seçilen müşteriler başarıyla pasif hale getirildi.",
    },
  },

  "logs/index": {
    filter: {
      title: "Gelişmiş Arama Filtresi",
      form: {
        description: "İşlem Detayı / Müşteri Adı / Personel Adı",
        startDate: "Başlangıç Tarihi",
        endDate: "Bitiş Tarihi",
        allDate: "Tüm Zamanlar",
        findCustomer: {
          label: "Müşteri adı",
          placeholder: "Müşteri arayın",
          helper: "Yalnızca belirli müşteriye göre filtremele",
        },
      },
    },
  },

  /**
   * Riskli müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/risky
   */
  "customers/risky": {
    offsetInputLabel: "Gün Aralığı Seçimi",
    offsetItem15: "15 günden fazladır işlemde bulunmayanlar",
    offsetItem30: "30 günden fazladır işlemde bulunmayanlar",
    offsetItem45: "45 günden fazladır işlemde bulunmayanlar",
    offsetItem60: "60 günden fazladır işlemde bulunmayanlar",

    headings: {
      code: "Müşteri Kodu",
      fullName: "İsim Soyisim",
      phone: "Telefon Numarası",
      sex: "Cinsiyet",
      incomeAll: "Kazandırdığı Tutar",
      debtAll: "Borç Tutarı",
      lastTransaction: "Son İşlemin Üstünden Geçen Süre",
    },

    replacements: {
      sex: {
        undef: "Belirtilmemiş",
        male: "Erkek",
        female: "Kadın",
      },
    },

    warning: {
      allCustomersDeletedSuccess: "Bütün müşteriyler başarıyla silindi.",
      selectedCustomersDeletedSuccess: "Seçilen müşteriler başarıyla silindi.",
    },

    sendSMSButtonTitle: "Kampanya SMS' i Gönder",
    selectedAllButtonTitle: "Hepsini Seç",
    cancelAllButtonTitle: "Hepsini Kaldır",
    deleteSelectedCustomers: "Seçilen Müşteriyi Sil",
    deleteAllCustomer: "Tüm Müşterileri Sil",
  },

  /**
   * Değerlendirme anketlerinin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/rating
   */
  "customers/rating": {
    headings: {
      createdAt: "Değerlendirme Tarihi",
      staffFullName: "İlgilenen Çalışan",
      serviceName: "Uygulanan Hizmet",
      companyStar: "İşletme Puanı",
      staffStar: "Çalışan Puanı",
      description: "Açıklama",
    },

    deleteButtonTitle: "Değerlendirmeyi Sil",
    replyToComment: "Yanıtla",
    viewToComment: "Görüntüle",
    delete: {
      title: "Silmeyi onaylıyor musunuz?",
      alertText:
        "Bu işlemin geri dönüşü yoktur! Bu değerlendirme anketini silmeniz durumunda bir daha erişemeyeceksiniz.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    message: {
      title: "Yorumu Yanıtla",
      comment: "Yapılan Yorum",
      alertText: "Yorumunuzu yazınız.",
      confirmButtonTitle: "Yayınla",
      discardButtonTitle: "İptal",
    },

    deleteToast: "Belirtilen değerlendirme başarıyla silindi!",
    deleteBeforePlanUpgradeToast:
      "Müşteri yorumunu silmek için paketinizi premium paket olarak yükseltmeniz gerekmektedir.",
  },

  /**
   * Müşterilerin eklendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/add
   */
  "customers/add": {
    validate: {
      phoneNumber: "Telefon Numarası",
      name: "İsim",
      surname: "Soyisim",
      birthDate: "Doğum Tarihi",
    },
    nameInputLabel: "Müşteri Adı",
    nameInputPlaceholder: "Müşteri adını giriniz",

    surnameInputLabel: "Müşteri Soyadı",
    surnameInputPlaceholder: "Müşteri soyadı giriniz",

    phoneInputLabel: "Cep Telefonu",

    genderInputLabel: "Cinsiyet",
    genderMale: "Erkek",
    genderFemale: "Kadın",

    birthdayInputLabel: "Doğum Tarihi",
    birthdayInputInvalidMessage:
      "Lütfen istenilen formatta giriş yapınız. Gün/Ay/Yıl",

    maritalStatusInputLabel: "Medeni Hali",
    maritalStatusMarried: "Evli",
    maritalStatusSingle: "Bekar",

    secondPhoneInputLabel: "İkinci Telefon Numarası",
    secondPhoneInputPlaceholder: "Varsa ikinci telefon numarasını giriniz.",

    mailInputLabel: "E-Posta Adresi",
    mailInputPlaceholder: "Varsa E-Posta adresini giriniz.",

    descriptionInputLabel: "Ek Açıklamalar",

    workStatusInputLabel: "Çalışma Durumu",
    workStatusWorking: "Çalışıyor",
    workStatusUnemployed: "Çalışmıyor",
    workStatusEmployer: "İşveren",
    workStatusStudent: "Öğrenci",
    workStatusHouseWife: "Ev Hanımı",

    relatedBusinessInputLabel: "İlgili Kurum",
    relatedBusinessInputPlaceholder:
      "Müşterinin hangi kurum üzerinden geldiğini varsa belirtiniz",

    sendSmsInputLabel: "SMS Gönderim Durumu",
    sendSmsTrue: "Gönderilsin",
    sendSmsFalse: "Gönderilmesin",

    selectPhoneNumberForMessage: "Mesajların gönderileceği numarayı seç",
    firstPhoneNumber: "Birinci telefon numarası",
    secondPhoneNumber: "İkinci telefon numarası",
    allPhoneNumber: "Her ikisi de",

    createNewCustomerButtonTitle: "Müşteriyi Oluştur",
  },

  "customers/passive": {
    makeActiveButtons: {
      selectedButtonTitle: "Seçilenleri Aktif Yap",
      allButtonTitle: "Hepsini Aktif Yap",
    },

    selectedCustomersSuccessToast:
      "Seçilen müşteriler başarıyla aktif edilmiştir",
    allCustomersSuccessToast: "Tüm müşteriler başarıyla aktif edilmiştir",
  },

  /**
   * Toplu müşteri ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/customers/add/bulk
   */
  "customers/add/bulk": {
    uploadAreaText: "Excel dosyasını yüklemek için bu alana tıklayın",
    stepsHeading: "Excel İle Toplu Müşteri Yükleme Adımları",
    steps: [
      "Excel üzerinden sisteme müşterilerinizi eklemeniz için hazırladığımız excel formatında verilerinizi yüklemeniz gerekmektedir.",
      "Sonraki adımdaki button'a tıklayarak Excel şablonunu indirin.",
      "Excel Formatını İndir",
      "Müşteri verilerinizi bir önceki adımda indirdiğiniz excel formatına uygun hale getirin ve sisteme yükleyin.",
    ],
    boldText:
      "ÖNEMLİ: Kayıpsız ve hatasız veri aktarımı için Excel şablonundaki kolonların yerlerinin, isimlerinin değiştirilmemesi gerekmektedir!",

    preview: {
      heading: "Excel Yükleme Önizlemesi",
      completeButtonTitle: "Toplu Müşteri Kaydını Tamamla",

      nameInputLabel: "İsim",
      surnameInputLabel: "Soyisim",
      phoneInputLabel: "Cep Telefonu",
      sendSmsInputLabel: "SMS Gönderimi",
      sendSmsTrue: "Açık",
      sendSmsFalse: "Kapalı",

      genderInputLabel: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      mailInputLabel: "E-Posta Adresi",
      birthdayInputLabel: "Doğum Tarihi",

      maritalStatusInputLabel: "Medeni Hali",
      maritalStatusMarried: "Evli",
      maritalStatusSingle: "Bekar",
    },

    duplicateRecords: "%s adet mükerrer kayıt silindi.",

    insertToast:
      "Toplu yükleme işleminiz başarıyla sıraya alındı! Liste sayfasına yönlendiriliyorsunuz.",
  },

  /**
   * Hizmet listesinin bulunduğu key.
   * https://app.salonrandevu.com/services
   */
  services: {
    validations: {
      servicesCategory: "Hizmet Kategorisi",
      servicesName: "Hizmet Adı",
      servicesAmount: "Hizmet Ücreti",
      servicesBounty: "Prim Yüzdesi",
      servicesTime: "İşlem Süresi",
    },
    buttons: {
      allServices: "Bütün Hizmetler",
      categories: "Kategoriler",
      selectAll: "Hepsini Seç",
      selectedAll: "Hepsi Seçildi",
      removeAll: "Tümünü Sil",
    },
    filter: {
      title: "Hizmet Arama Filtresi",
      inputLabel: "Hizmet Adı",
    },

    headings: {
      name: "Hizmet Adı",
      processTime: "İşlem Süresi",
      bounty: "Prim Yüzdesi",
      bountyMoney: "Prim Tutarı",
      amount: "Hizmet Ücreti",
      showAtUrl: "Online Randevu Ayarı",
    },

    replacements: {
      showAtUrlTrue: "Açık",
      showAtUrlFalse: "Kapalı",
    },

    deleteButtonTitle: "Hizmeti Sil",
    delete: {
      title: "Hizmet silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu hizmeti silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmet üzerinden randevu alamayacaksınız. Bu hizmete ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    dataNotFound: "Veri Bulunamadı!",

    addAllService: {
      warningTitle: "UYARI!",
      addButtonTitle: "Toplu Hizmet Ekle",
      informationDialog: {
        addServiceDescription:
          "Aktarmış olduğunuz hizmetlerin süreleri ve fiyat bilgisi sistem tarafından otomatik olarak atanmıştır. Lütfen işletmenizde vermiş olduğunuz hizmet süresi ve fiyat bilgilerinizi güncelleyiniz.",
        confirmButtonTitle: "Anladım",
      },
    },

    deleteAll: {
      title: "Hizmetleri silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bütün hizmetleri silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmetler üzerinden randevu alamayacaksınız. Bu hizmetlere ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      deleteAllServicesToast: "Hizmetler başarıyla silindi",
      deleteAllButtonTitle: "Hepsini Sil",
    },

    deleteSelectedServices: {
      title: "Seçilen hizmetleri silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Seçilen hizmetleri silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmetler üzerinden randevu alamayacaksınız. Bu hizmetlere ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      deleteSelectedButtonTitle: "Seçilen Hizmetleri Sil",
    },

    deleteToast: "Hizmet başarıyla silindi.",
  },

  /**
   * Hizmet detay/ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/services/add
   * https://app.salonrandevu.com/services/detail/id
   */
  "services/detail": {
    placeholder: {
      search: "Aramak istediğiniz hizmeti giriniz",
    },
    validate: {
      serviceCategory: "Hizmet Kategorisi",
      servicesName: "Hizmet Adı",
      servicePrice: "Hizmet Ücreti",
      servicesProcessTime: "İşlem Süresi",
    },
    selcetAllEmployees: "Tüm Çalışanları Seç",

    serviceName: "Aradığınız",
    lookingFor: " Hizmet adı",
    ourList: "listemizde yok mu?",
    createRequest: "Talep Oluşturun.",
    createNewRequest: "Yeni Talep Oluşturun.",

    nameInputLabel: "Hizmet Adı",
    nameInputHint:
      "Yönetim uygulamanızda ve online randevu sayfanızda listelenecek olan hizmetin adını giriniz.",

    amountInputLabel: "Hizmet Ücreti ",
    amountInputHint:
      "Belirlediğiniz tutar varsayılan tutardır. Adisyona eklenme esnasında işlem fiyatını değiştirebilirsiniz.",

    bountyInputLabel: "Prim Yüzdesi (%)",
    bountyMoneyBased: "Para Tabanlı Bonus",
    bountySelectMoney: "Para Bazlı",
    bountySelectPer: "Bonus Yüzdesi",
    bountyInputHint:
      "Çalışanlarınızın sabit prim yüzdesi tanımlı değilse bu hizmetten belirtilen yüzde oranında prim kazanacaklar.",

    pointInputLabel: "Kazanılacak Puan",
    pointInputHint: "100 müşteri puanı 1'e karşılık gelmektedir.",

    timeInputLabel: "Ortalama İşlem Süresi (dk)",
    timeInputHint:
      "İşlem süresi müşteri randevu alırken gösterilecek olan bir bilgidir. Bu hizmetin işlemini yapan çalışan işlem süresi boyunca yeni bir randevu alamayacaktır.",

    repeatInputLabel: "Tekrar Süresi (gün)",
    repeatInputHint:
      "Bu hizmet tekrar gerektiren bir işlemse, müşterilerinize tekrar edilmesi gereken süre geldiğinde SMS/E-Posta olarak bilgilendirme yapmanızı sağlayacaktır.",

    urlInputLabel: "Online Randevu Ayarı",
    urlInputHint:
      "İşlemin işletme URL'sinden alınacak online randevularda gösterilip/gösterilmeyeceği ayarıdır.",

    urlInputTrue: "Açık",
    urlInputFalse: "Kapalı",

    staffSectionTitle: "Hizmeti Uygulayacak Çalışanlar",
    timeWarnAlertText:
      "Yapacağınız değişiklik yeni oluşturulacak randevulardan itibaren geçerli olacaktır, oluşturulmuş olan randevulara etki etmeyecektir.",

    createButtonTitle: "Hizmeti Oluştur",
    updateButtonTitle: "Hizmeti Güncelle",
    deleteButtonTitle: "Hizmeti Sil",

    delete: {
      title: "Hizmet silmeyi onaylıyor musunuz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu hizmeti silmeniz durumunda adisyon yönetiminde işlem gerçekleştiremeyecek, randevu alırken/tanımlarken bir daha bu hizmet üzerinden randevu alamayacaksınız. Bu hizmete ait mevcut randevularınız korunacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    lastTransactions: {
      headings: {
        createdAt: "İşlem Tarihi",
        staffFullName: "İlgilenen Çalışan",
        amount: "İşlem Tutarı",
      },
      showOnReceiptButton: "Adisyonda Göster",
    },

    serviceCategoryInput: "Hizmet Kategorisi",
    serviceNameInput: "Hizmet Adı",

    serviceCategoryHint:
      "Seçeceğiniz hizmet kategorisi ve hizmet adı, Salon Randevu platformunda kullanılacaktır.",

    serviceNameHint:
      "Hizmet adını seçebilmek için öncelikle Hizmet Kategorisi bölümünden bir kategori seçmeniz gerekmektedir.",

    deleteToast: "Hizmet başarıyla silindi.",
    insertToast: "Hizmet başarıyla oluşturuldu.",
    updateToast: "Hizmet başarıyla güncellendi.",

    newServiceInputTitle: "Yeni Hizmet Adı",
    newServiceRequestToast: "Yeni Hizmet talebiniz başarıyla oluşturuldu.",
    newServiceRequestErrorToast: "Yeni Hizmet Adı boş bırakılamaz.",
    newServiceRequestDialogTitle: "Yeni Hizmet Talebi",
    newServiceCategoryHint: "Yeni hizmetin bağlı olacağı Hizmet Kategorisi.",
    createNewServiceRequest: "Talebi Oluştur",
    cancelNewServiceRequest: "İptal",

    setCampaignTitle: "Kampanya Olarak Ayarla",
    campaignNameTitle: "Kampanya Adı",
    campaignHint:
      "Kampanya olarak işaretlenen ürünler, işletme sayfanızdaki Kampanya alanında gözükecektir.",

    serviceDescriptionLabel: "Hizmet Açıklaması",
    serviceDescriptionHint:
      "Hizmet açıklaması, müşterilerinizin sunduğunuz hizmeti daha yakından tanımasını sağlar.",
  },

  "payment-notify": {
    copyIban: "IBAN'ı Kopyala",
    ibanSuccess: "IBAN başarıyla kopyalandı.",
    depositBank: "Havale Yapılacak Banka",
    depositAmonut: "Havale Miktarı",
    depositDate: "Havale Tarihi",
    makePaymentNotice: "Ödeme Bildirimi Yap",
  },

  "services/requests": {
    headings: {
      time: "İşlem Tarihi",
      name: "Hizmet Adı",
      state: "Durum",
      category: "Kategori",
      actionHeadingText: "İşlemler",
      stateCompleted: "Talep Onaylandı",
      stateIncompleted: "Talep Onaylanamadı",
      stateWaiting: "Talep Onayı Bekleniyor",
    },

    deletedRequestToast: "Talep isteği silinmiştir",

    cancel: {
      cancelButton: "İptal Et",
      cancelTitle: "Yeni Hizmet Talebini Silmek İstiyor Musunuz?",
      cancelConfirmButtonTitle: "Evet",
      cancelDiscardButtonTitle: "Hayır",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu talebi iptal etmeniz durumunda işlem tarafımızca gerçekleştirelemeyecek ve yeni hizmet kontrolü yapılmayacaktır.",
    },
  },

  "services/bounty": {
    addBountyTitle: "Prim Ekle",

    bountyDescription:
      "Özel prim tanımlama seçeneği ile sabit prim yüzdesi olmayan hizmetleriniz ve çalışanlarınız için buradan özel prim ekleyebilirsiniz.",

    staffInputLabel: "Prim Eklenecek Çalışan",
    categoryInputLabel: "Hizmet Kategorisi",
    serviceInputLabel: "Hizmet Adı",
    bountyAmountInputLabel: "Prim Yüzdesi",

    headings: {
      bounty: "Prim Yüzdesi(%)",
      serviceName: "Hizmetin İsmi",
      staffName: "Çalışanın İsmi",
      deleteBountyButtonTitle: "Primi Sil",
    },

    deleteBountyDialog: {
      title: "Primi Silmek İstediğinizden Emin Misiniz?",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu primi silmeniz durumunda çalışınınız için bu hizmete bağlı prim hesabı olmayacaktır.",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
    },

    addBountySuccessToast: "Hizmete özel çalışan primi başarıyla eklenmiştir.",
    deleteBountySuccessToast:
      "Hizmete bağlı çalışan primi başarıyla silinmiştir.",
  },

  /**
   * Çalışanların listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees
   */
  employees: {
    editButtonTitle: "Çalışanı Düzenle",

    createNewEmployee: "Yeni Çalışan Oluştur",
    orderStaffs: "Çalışanları Listele",
  },

  /**
   * Çalışan profil sayfalarının listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees/detail/id
   * https://app.salonrandevu.com/employees/add
   * https://app.salonrandevu.com/profile
   */
  profile: {
    inputPhone: "Telefon *",
    detail: {
      //Section
      title: "Çalışan Bilgileri",
      profileTitle: "Profil Bilgileri",

      nameInputLabel: "İsim",
      surnameInputLabel: "Soyisim",

      genderInputLabel: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      birthdayInputLabel: "Doğum Tarihi",

      jobTitleInputLabel: "Ünvan",
      bountyInputLabel: "Sabit Prim Yüzdesi",

      permissionInputLabel: "Panel Yetki Durumu",
      permissionOwner: "Yetkili",
      permissionStaff: "Çalışan",
      permissionLimited: "Sınırlı Yetkili",

      sendSmsInputLabel: "SMS Gönderimi",
      sendSmsTrue: "Gönderilsin",
      sendSmsFalse: "Gönderilmesin",

      urlInputLabel: "İşletme URL'sinde Gösterim",
      showAtUrlTrue: "Gösterilsin",
      showAtUrlFalse: "Gösterilmesin",

      branch_list: "Şube Listesi",
      branch_change: "Şube Değiştir",
      branch_enter: "Şubeye Geç",
      branch_change_alert: "Şube değişikliği yapıldı.",
    },

    contact: {
      //Section
      title: "İletişim Bilgileri",

      mailInputLabel: "E-Posta Adresi",
      phoneInputLabel: "Cep Telefonu",
      passwordInputLabel: "Panel Şifresi",
      newPasswordInputLabel: "Yeni Panel Şifresi",
    },

    services: {
      //Section
      title: "Sunduğu Hizmetler",

      alertBoldText:
        "Çalışanın sunduğu hizmetler aşağıdaki servislerin üzerine tıklayarak seçilmedilir!",
      alertText:
        "Aksi halde işletmenin randevu adresinde çalışanın sunduğu hizmetler boş gelecektir.",
    },
    packages: {
      title: "Sunduğu Paketler",

      alertBoldText:
        "Çalışanın sunduğu paketler aşağıdaki kutucukların üzerine tıklanarak seçilmedilir!",
      alertText:
        "Aksi halde işletmenin randevu adresinde çalışanın sunduğu paketler boş gelecektir.",
    },

    booking: {
      //Section
      title: "Randevu Saatleri",
      alertBoldText: "Randevu sıklığınızı düzenleyin!",
      week: [
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi",
        "Pazar",
      ],
      dayOpenText: "Günü randevu alımına aç",
      dayCloseText: "Günü randevu alımına kapat",
    },

    photo: {
      //Section
      uploadButtonTitle: "Fotoğraf Yükle",
      deleteButtonTitle: "Fotoğrafı Kaldır",
    },

    createButtonTitle: "Çalışanı Oluştur",
    updateButtonTitle: "Değişiklikleri Kaydet",
    deleteButtonTitle: "Çalışanı Sil",

    logoutButtonTitle: "Çıkış Yap",

    delete: {
      //Modal
      title: "Silmeyi onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertBoldText: "Bu işlemin geri dönüşü yoktur!",
      alertText:
        "Bu çalışanı silmeniz durumunda çalışan profiline ve diğer bilgilerine bir daha erişemeyebilirsiniz.",
    },

    photoUploadToast: "Fotoğraf başarıyla yüklendi.",
    photoDeleteToast: "Fotoğraf başarıyla kaldırıldı.",
    insertToast: "Çalışan başarıyla oluşturuldu.",
    updateToast: "Çalışan başarıyla güncellendi.",
    deleteToast: "Çalışan başarıyla silindi.",

    limited_permission_label: {
      no_permission: "Erişemez",
      display: "Görüntülüyebilir",
      full_permission: "Tam Yetkili",
    },
  },

  /**
   * SMS Yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms
   */
  sms: {
    smsCode: {
      name: "Ad",
      surname: "Soyad",
    },
    wpSettings: {
      addDevice: "Cihaz Ekle",
      removeDevice: "Cihaz Kaldı",
      whatsappNumber: "WhatsApp Numarası",
      whatsappCredit: "WhatsApp Kredisi",
      status: {
        title: "Durumu",
        active: "Aktif",
        passive: "Pasif",
        quited: "Çıkış Yapılmış",
      },
    },
    qRCode: {
      searchCode: "Qr kodu okutun",
      close: "Kapat",
      again: "Tekrar Gönder",
    },
    index: {
      whatsAppMessage: "WhatsApp Mesajı",
    },
    foundWpDevice: "Hesabınıza kayıtlı whatsapp cihazı bulundu.",
    noFoundWpDevice:
      "Hesabınıza kayıtlı herhangi bi whatsapp cihazı bulunamadı.",
    creditSMSLabel: "Kullanılabilir/Kalan SMS Kredisi",
    creditWPLabel: "Kullanılabilir/Kalan WP Kredisi",
    senderLabel: "Gönderici Adı",

    sendType: {
      label: "Gönderim yöntemi",
      sendSMS: "SMS gönder",
      sendWP: "Whatsapp mesajı gönder",
      both: "Her ikisini de gönder",
    },

    credit: {
      sms: "Sms Kredisi",
      wp: "WhatsApp Kredisi",
    },
    loginWithQr: "QR Kod Tara",
    loginWithNo: "Telefon No Gir",

    addDevice: "Cihaz Ekle",
    removeDevice: "Cihazı Kaldır",

    deviceStatus: "Durumu",
    wpPhone: "WhatsAPP Numarası",
    wpCredits: "WhatsAPP Kredisi",

    phoneNotConnected: "Numaranız Bağlı Değil",

    deviceConnected: "Cihaz Bağlı",
    deviceNotConnected: "Cihaz Bağlı Değil",

    wpQrTutorial1: "WhatsAPP uygulamasını açın, ardından ︙  tıklayın.",
    wpQrTutorial2: `"Cihaz Bağla" butonuna tıklayın`,
    wpQrTutorial3: "QR kodunuzu kameranıza okutun.",

    qrPopupTitle: "Qr kodu okut.",
    qrPopupContext:
      "Uygulamaya bağlanmak için cihazınızın kamerasını kullanarak QR kodunu okutun.",

    withPhoneNumberTitle: "WhatsApp Kodu Almak İstediğiniz Numarayı Giriniz.",
    codeForThisPhone: "numarası için WhatsApp onay kodu",

    alertBoldText: "SMS gönderici bilgileriniz alınıyor...",
    alertText: "Bu işlem biraz sürebilir, lütfen bekleyiniz.",

    undefinedAlerts: {
      wp_heading: "Hesabınızda WhatsApp mesaj gönderimi için cihaz bulunamadı",
      wp_content:
        " İşletme adınızla gönderim yapabileceğiniz bir WhatsApp başlığı bulunmamaktadır. Platformun sabit bilgilendirme mesajları haricinde müşterilerinize özel WhatsApp gönderimleri yapabilmek istiyorsanız",
      wp_span: "  hesabınıza cihaz tanımlayınız.",
      sms_heading:
        "Özelleştirilmiş gönderimler için SMS başlığı tanımlanmamış!",
      sms_content:
        "   İşletme adınızla gönderim yapabileceğiniz bir SMS başlığı bulunmamaktadır. Platformun sabit bilgilendirme mesajları haricinde müşterilerinize özel SMS gönderimleri yapabilmek istiyorsanız SMS hizmeti satın almanız gerekmektedir.",
    },

    sendButtonText: "Görüntüle",
    dialogTitle: "Sms Görüntüleme",
    buttonApprove: "Kapat",

    lastSendingsTitle: "SMS Gönderimleri",
    smsReports: "SMS Raporları",

    groupSMSReports: "Grup SMS Raporları",
    singleSMSReports: "Otomatik SMS Raporları",
    groupWPReports: "Grup WhatsApp Raporları",
    singleWPReports: "Otomatik WhatsApp Raporları",

    creditPaybackButtonTitle: "Kredi İadesi Al",
    headings: {
      date: "Tarih",
      sender: "Gönderici Adı",
      phone: "Telefon Numarası",
      status: "Gönderim Durumu",
      content: "Mesaj İçeriği",
    },
    table_titles: {
      date: "Tarih",
      total: "Toplam",
      successful: "Başarılı",
      unsuccessful: "Başarısız",
      post_postCredit: "Gönderim Öncesi Kredi",
      content: "İçerik",
    },
    bottom_table_titles: {
      phone: "Telefon Numarası",
      status: "Durumu",
      message_content: "Mesaj içeriği",
      details: "Ayrıntılar",
      successful: "Başarılı",
      unsuccessful: "Başarısız",
      data_not_found: "Veri Bulunamadı!",
    },
    replacements: {
      status: [
        "Gönderildi",
        "İletilmedi",
        "Geçersiz Numara",
        "İade Yapıldı",
        "Beklemede",
        "Süresi Geçmiş",
      ],
    },

    send: {
      // Section
      messageInputLabel: "Mesaj İçeriği",
      charType: "Karakter Tipi",
      charTR: "Türkçe",
      charNormal: "Normal",
      buttonTitle: "SMS Gönder",
      discardButtonTitle: "Kapat",
      sendDate: "Gönderim Tarihi",
      futureDate: "İleri Tarihli SMS",

      autoText: {
        nameSurname: "Ad Soyad",
        amountPaid: "Ödenen Tutar",
        remainingDebt: "Kalan Borç",
        businessUrl: "İşletme URL",
        shortCode: "Kısa kod",
        forSmsSelection: "SMS iptali için",
        writeIt: "yazın 4607'a gönderin.",
      },

      check: {
        // Modal
        title: "Bu işlemi onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",
        alertText:
          "Onayınızın ardından kredinizin yeterli olması durumunda gönderim işlemlerine başlanacaktır. Bu işlemin geri dönüşü yoktur!",
      },

      template: "Şablon Seçimi",

      nameSurnameShortcut: "Ad Soyad",
      successToast: "SMS gönderimi başarıyla başlatıldı!",
    },
  },

  /**
   * Seçimli SMS Gönder sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/selective
   */
  "sms/selective": {
    continueButtonTitle: "Devam Et",
    backToSelectButtonTitle: "Seçimlere Geri Dön",

    warningText:
      "Gönderilecek Listesi Harici Tutulacak Listesi ile aynı olamaz!",

    customer: {
      // Section
      title: "Müşteri Listesi",
      headings: {
        fullName: "İsim Soyisim",
        phone: "Telefon Numarası",
      },

      searchInputLabel: "Müşteri Ara",
      selectAll: "Tüm Müşterileri Seç",
      unselectAll: "Tümünü Seçimi Kaldır",
    },

    approved: {
      // Section
      title: "Gönderilecekler Listesi",
      notFound: {
        alertBoldText: "Gönderilecek müşteriler içerisinden seçim yapılmamış!",
        alertText:
          "Müşteri listesi içerisinden tik button'una tıklayarak müşterilerinizi seçmeye başlayabilirsiniz.",
      },

      alertBoldText: "Tüm müşterileriniz başarıyla seçildi!",
    },

    excepted: {
      // Section
      title: "Hariç Tutulacaklar Listesi",
      notFound: {
        alertBoldText: "Gönderimden hariç tutulacak müşteriler seçilmemiş!",
        alertText:
          "Müşterileriniz içerisinden gönderilmeyecek istisna müşterileriniz varsa tablodaki blok button'una tıklayarak müşteriyi gönderimden hariç tutabilirsiniz.",
      },
    },
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/filter": {
    applyFilterButtonTitle: "Filtreyi Uygula",
    continueButtonTitle: "Devam Et",
    backToFilterButtonTitle: "Filtrelemeye Geri Dön",

    tabs: {
      // Section
      debt: "Borç Durumu",
      debtTrue: "Borçlu",
      debtFalse: "Borçsuz",

      risk: "Risk Durumu",
      risk15: "Son 15 günde işlemi olmayanlar",
      risk30: "Son 30 günde işlemi olmayanlar",
      risk45: "Son 45 günde işlemi olmayanlar",
      risk60: "Son 60 günde işlemi olmayanlar",

      work: "Çalışma Durumu",
      workWorking: "Çalışıyor",
      workUnemployed: "Çalışmıyor",
      workEmployer: "İşveren",
      workStudent: "Öğrenci",
      workHouseWife: "Ev Hanımı",

      gender: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      marital: "Medeni Hal",
      maritalMarried: "Evli",
      maritalSingle: "Bekar",

      service: "Alınan Hizmet",
      staff: "İşlem Uygulamış Personel",
      age: "Yaş Aralığı",
    },

    customer: {
      // Section
      title: "Müşteri Listesi",
      headings: {
        fullName: "İsim Soyisim",
        phone: "Cep Telefonu",
        gender: "Cinsiyet",
      },
      replacements: {
        genderNull: "Belirtilmemiş",
        genderFemale: "Kadın",
        genderMale: "Erkek",
      },
    },

    except: {
      title: "Filtrelemeden Hariç Tutulacaklar Listesi",
      alertBoldText: "Gönderimden hariç tutulacak müşteriler seçilmemiş!",
      alertText:
        "Filtreleme sonrası müşteri listeniz içerisinden gönderilmeyecek istisna müşterileriniz varsa tablodaki blok button'una tıklayarak müşteriyi gönderimden hariç tutabilirsiniz.",
    },
  },

  "sms/group": {
    continueButtonTitle: "Devam Et",
    backToSelectButtonTitle: "Seçimlere Geri Dön",

    newGroupButtonTitle: "Grup Oluştur",

    group: {
      title: "Grup Listesi",

      headings: {
        groupName: "Grup Adı",
        customerCount: "Müşteri Sayısı",
      },

      searchInputLabel: "Grup Ara",
      searchInputPlaceholder: "Grup adını giriniz ",
      selectAll: "Tüm Grupları Seç",
      unselectAll: "Tüm Seçimi Kaldır",
    },

    approved: {
      title: "Gönderilecekler Listesi",
      notFound: {
        alertBoldText: "Gönderilecek gruplar içerisinden seçim yapılmamış!",
        alertText:
          "Grup listesi içerisinden tik button'una tıklayarak gruplarınızı seçmeye başlayabilirsiniz.",
      },

      alertBoldText: "Tüm gruplarınız başarıyla seçildi!",
    },

    excepted: {
      title: "Hariç Tutulacaklar Listesi",
      notFound: {
        alertBoldText: "Gönderimden hariç tutulacak gruplar seçilmemiş!",
        alertText:
          "Gruplarınız içerisinden gönderilmeyecek istisna gruplarınız varsa tablodaki blok button'una tıklayarak grubu gönderimden hariç tutabilirsiniz.",
      },
    },

    newGroup: {
      title: "Yeni Grup",

      groupName: "Grup Adı",
      groupEditButtonTitle: "Düzenle",

      customersTitle: "Müşterileriniz",
      selectedCustomersTitle: "Seçili Müşteriler",

      searchCustomer: "Müşteri Ara",
      searchCustomerDescription: "Müşteri adını veya numarasını yazınız",
      selectionDescription:
        "İstediğiniz müşteriyi seçerek veya sürükle bırak metodu ile aktarabilirsiniz.",
      notFoundCustomer: "Aradığınız müşteri(ler) bulunamadı.",

      selectAllButtonTitle: "Bütün Müşterileri Aktar",
      unselectAllButtonTitle: "Bütün Müşterileri Kaldır",
      deleteAllButtonTitle: "Hepsini Sil",

      alertBoldText: "Tüm müşterileriniz başarıyla seçildi!",

      createButtonTitle: "Oluştur",
      cancelButtonTitle: "İptal",

      successToast: "Yeni grup başarıyla oluşturuldu.",
      emptyGroupName: "Grup adı boş olamaz.",

      edit: {
        editGroupTitle: "Grup Güncelle",
        editButtonTitle: "Güncelle",
        deleteGroupButtonTitle: "Grubu Sil",

        updateSuccessToast: "Grup başarıyla güncellenmiştir.",
        deleteSuccessToast: "Grup başarıyla silinmiştir.",
      },
    },

    successToast: "Mesajınız başarıyla gönderildi.",
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/settings": {
    updateButtonTitle: "Ayarları Güncelle",
    customerSwitchTitle: "Müşteri",
    staffSwitchTitle: "Çalışan",
    onOffSwitchTitle: "Açık/Kapalı",

    padiContent:
      "Ücretli içerik aktif değil. Lütfen müşteri temsilciniz ile iletişime geçin.",

    reminder: {
      title: "Randevu Hatırlatma",
      description:
        "Randevu hatırlatmalarına dair SMS gönderimlerinin gitsin/gitmesin ayarıdır.",
    },

    longTermReminder: {
      title: "Uzun Süreli Randevu Hatırlatma",
      description:
        "Randevu tarihine bir günden fazla gün varsa, randevu tarihinden bir gün öncesinden SMS gitsin/gitmesin ayarıdır.",
    },

    requestInfo: {
      title: "Randevu Talebi Bilgilendirme",
      description:
        "Gelen yeni bir online randevu talebi/isteği geldiğinde SMS gitsin/gitmesin ayarıdır.",
    },

    whenApproved: {
      title: "Randevu Talebi Onaylandığında",
      description:
        "Gelen online randevu talebi/isteği onaylandığında SMS gitsin/gitmesin ayarıdır.",
    },

    whenRejected: {
      title: "Randevu Talebi Reddedildiğinde",
      description:
        "Gelen online randevu talebi/isteği reddedildiğinde SMS gitsin/gitmesin ayarıdır.",
    },

    whenCreated: {
      title: "Randevu Oluşturulduğunda",
      description: "Randevu oluşturulduğu esnada SMS gitsin/gitmesin ayarıdır.",
    },

    whenCancelled: {
      title: "Aktif Randevu İptalinde",
      description:
        "Oluşturulan randevu iptal edildiğinde SMS gitsin/gitmesin ayarıdır.",
    },

    whenBirthday: {
      title: "Doğum Günü Gönderimi",
      description:
        "Doğum günü olan müşterilerinize kutlama SMS'i gitsin/gitmesin ayarıdır. Bu ayar işletmenize/kendinize özel gönderici adınızın olması durumunda çalışmaktadır.",
    },

    whenCustomerCreate: {
      title: "Müşteri Eklendiğinde",
      description:
        "Müşteri kaydı sonrasında müşterinize işletmenizin müşteri listesine kaydedildiğine dair bilgilendirme SMS'i gitsin/gitmesin ayarıdır.",
    },

    dragAndDrop: {
      title: "Randevu Sürükle Ve Bırak",
      description:
        "Randevu sürükle ve bırakıldığında müsteriye SMS gitsin/gitmesin ayarıdır.",
    },

    smsShortcode: {
      title: "Sms Kısa Kod",
      description:
        "Müsteri numarası kara listeye eklendiginde SMS gitsin/gitmesin ayarıdır.",
    },

    reminderNoncomerCustomer: {
      title: "Randevuya Gelmeyen Müşteriye Hatırlatma",
      description: [
        "60 gün boyunca işletmenizi ziyaret etmemiş müşterilerinize otomatik hatırlatma",
        "SMS'i gönderilir.",
        "Örnek Mesaj İçeriği : Sayın Figen Çelik , sizi çok özledik !",
        "60 gündür işlem yapmadığınızı farkettik. Tekrar görüşmek dileğiyle, Mutlu günler dileriz..",
        "Randevu almak için : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "Randevu Güncelleme",
      description:
        "Güncellenen randevu saati ve tarihini SMS ile gönder/gönderme ayarıdır.",
    },

    smsAttendedApproval: {
      title: "SMS'den Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    afterAppointment: {
      title: "Randevu Sonrası Değerlendirme",
      description:
        "Randevu sonrasında değerlendirme SMS'i gitsin/gitmesin ayarıdır.",
    },

    reminderHour: {
      title: "Randevu Hatırlatma Gönderim Saati",
      description:
        "Randevu öncesi gönderilecek SMS kaç saat önce gönderileceği ayarıdır.",

      inputLabel: "Kaç saat önce gönderilecek?",
      options: [
        "Kapalı",
        "1 saat",
        "2 saat",
        "3 saat",
        "4 saat",
        "5 saat",
        "6 saat",
        "7 saat",
        "8 saat",
        "9 saat",
        "10 saat",
        "11 saat",
        "12 saat",
        "13 saat",
        "14 saat",
        "15 saat",
        "16 saat",
        "17 saat",
        "18 saat",
        "19 saat",
        "20 saat",
        "21 saat",
        "22 saat",
        "23 saat",
        "24 saat",
      ],
    },

    updateToast: "SMS ayarları başarıyla güncellendi.",
  },
  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/wp/settings": {
    updateButtonTitle: "Ayarları Güncelle",
    customerSwitchTitle: "Müşteri",
    staffSwitchTitle: "Çalışan",
    onOffSwitchTitle: "Açık/Kapalı",

    padiContent:
      "Ücretli içerik aktif değil. Lütfen müşteri temsilciniz ile iletişime geçin.",

    reminder: {
      title: "Randevu Hatırlatma",
      description:
        "Randevu hatırlatmalarına dair WhatsApp gönderimlerinin gitsin/gitmesin ayarıdır.",
    },

    longTermReminder: {
      title: "Uzun Süreli Randevu Hatırlatma",
      description:
        "Randevu tarihine bir günden fazla gün varsa, randevu tarihinden bir gün öncesinden WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    requestInfo: {
      title: "Randevu Talebi Bilgilendirme",
      description:
        "Gelen yeni bir online randevu talebi/isteği geldiğinde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenApproved: {
      title: "Randevu Talebi Onaylandığında",
      description:
        "Gelen online randevu talebi/isteği onaylandığında WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenRejected: {
      title: "Randevu Talebi Reddedildiğinde",
      description:
        "Gelen online randevu talebi/isteği reddedildiğinde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenCreated: {
      title: "Randevu Oluşturulduğunda",
      description:
        "Randevu oluşturulduğu esnada WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenCancelled: {
      title: "Aktif Randevu İptalinde",
      description:
        "Oluşturulan randevu iptal edildiğinde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    whenBirthday: {
      title: "Doğum Günü Gönderimi",
      description:
        "Doğum günü olan müşterilerinize kutlama WhatsApp mesajı gitsin/gitmesin ayarıdır. Bu ayar işletmenize/kendinize özel gönderici adınızın olması durumunda çalışmaktadır.",
    },

    whenCustomerCreate: {
      title: "Müşteri Eklendiğinde",
      description:
        "Müşteri kaydı sonrasında müşterinize işletmenizin müşteri listesine kaydedildiğine dair bilgilendirme WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    dragAndDrop: {
      title: "Randevu Sürükle Ve Bırak",
      description:
        "Randevu sürükle ve bırakıldığında müsteriye WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    smsShortcode: {
      title: "WhatsApp Kısa Kod",
      description:
        "Müsteri numarası kara listeye eklendiginde WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Randevuya Gelmeyen Müşteriye Hatırlatma",
      description: [
        "60 gün boyunca işletmenizi ziyaret etmemiş müşterilerinize otomatik hatırlatma",
        "WhatsApp mesajı gönderilir.",
        "Örnek Mesaj İçeriği : Sayın Figen Çelik , sizi çok özledik !",
        "60 gündür işlem yapmadığınızı farkettik. Tekrar görüşmek dileğiyle, Mutlu günler dileriz..",
        "Randevu almak için : https://app.salonrandevu.com/isletme/2037",
      ],
    },

    appointmentUpdate: {
      title: "Randevu Güncelleme",
      description:
        "Güncellenen randevu saati ve tarihini WhatsApp ile gönder/gönderme ayarıdır.",
    },

    smsAttendedApproval: {
      title: "SMS'den Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    afterAppointment: {
      title: "Randevu Sonrası Değerlendirme",
      description:
        "Randevu sonrasında değerlendirme WhatsApp mesajı gitsin/gitmesin ayarıdır.",
    },

    reminderHour: {
      title: "Randevu Hatırlatma Gönderim Saati",
      description:
        "Randevu öncesi gönderilecek WhatsApp mesajı kaç saat önce gönderileceği ayarıdır.",

      inputLabel: "Kaç saat önce gönderilecek?",
      options: [
        "Kapalı",
        "1 saat",
        "2 saat",
        "3 saat",
        "4 saat",
        "5 saat",
        "6 saat",
        "7 saat",
        "8 saat",
        "9 saat",
        "10 saat",
        "11 saat",
        "12 saat",
        "13 saat",
        "14 saat",
        "15 saat",
        "16 saat",
        "17 saat",
        "18 saat",
        "19 saat",
        "20 saat",
        "21 saat",
        "22 saat",
        "23 saat",
        "24 saat",
      ],
    },

    updateToast: "WhatsApp mesaj ayarları başarıyla güncellendi.",
  },

  "sms/template": {
    notEmpty: "Şablon boş olamaz",
    createTemplateButtonTitle: "Yeni Şablon Oluştur",

    fillAll: "Gerekli Alanları doldurunuz",

    templatesHeadings: {
      name: "İsim",
      content: "İçerik",
    },

    deleteButtonTitle: "Şablonu Sil",

    templateDialog: {
      addTitle: "Yeni Şablon",
      updateTitle: "Şablon Detay/Düzenle",

      templateTitleInputLabel: "Şablon Adı",
      templateTitleInputPlaceholder: "Şablon adını giriniz",

      templateContentInputLabel: "Şablon İçeriği",

      createButtonTitle: "Oluştur",
      updateButtonTitle: "Güncelle",
      discardButtonTitle: "İptal",
    },

    deleteConfirm: {
      title: "Şablonu silmeyi onaylıyor musunuz?",
      confirmButtonTitle: "Evet",
      discardButtonTitle: "Hayır",
      alertBoldText: "Bu işlem geri alınamaz!",
      alertText:
        "Bu telefon numarasını kara listeden kaldırdığınızda Toplu mesaj gönderimlerinde kişiye SMS gönderimi sağlanacaktır.",
    },

    addSuccessToast: "Şablon başarıyla oluşturuldu.",
    updateSuccessToast: "Şablon başarıyla güncellendi.",
    deleteSuccessToast: "Şablon başarıyla silindi.",
  },

  "sms/blacklist": {
    totalNumber: "Toplam Numara sayısı: ",
    blacklistHeadings: {
      number: "Numara",
      addDate: "Eklenme Tarihi",
    },
    deleteUserBlacklist: "Numarayı Listeden Kaldır",
    numberInputLabel: "Engellemek Istediğiniz Numaraları Girin",
    addToBlacklist: "Kara Listeye Ekle",
    addToBlacklistError:
      "Lütfen geçerli numara giriniz birden fazla numara eklemek için ',' kullanın",
    addSuccessToast: "Numara başarıyla eklendi.",

    deleteConfirm: {
      title: "Numarayı listeden silmeyi onaylıyor musunuz?",
      // alertText:"Bu numarayi silmeniz durumunda bir daha erişiminiz olmayacaktır.",
    },
  },

  /**
   * Muhasebe Yönetimindeki ortak kullanımda olan key.
   * https://app.salonrandevu.com/accounting/income
   * https://app.salonrandevu.com/accounting/debt
   */
  accounting: {
    showOnReceipt: "Adisyonda Göster",

    startInputLabel: "Başlangıç Tarihi",
    endInputLabel: "Bitiş Tarihi",
    typeInputLabel: "Ödeme Yöntemi",
    customerName: "Müşteri Adı",

    typeCustomer: "Tüm Müşteriler",
    typeALL: "Tüm Yöntemler",
    typeCASH: "Nakit",
    typeCARD: "Kredi / Banka Kartı",
    typeEFT: "Havale/EFT",

    totalIncome: "Toplam Gelir",
    totalDebt: "Toplam Borç",

    alertBoldText: "Aşağıdaki alanları kullanarak {desc} filtreleyebilirsiniz.",
    alertText:
      "Alanlarda değişiklik yapmanız durumunda otomatik olarak filtreleme işlemi yapılmaktadır.",

    today: "Bugün",
    thisWeek: "Bu Hafta",
    thisMonth: "Bu Ay",

    incomeMethods: "Gelir işlemleri",

    filterArea: {
      header: "Zaman Aralığını Seçiniz",
      yesterday: "Dün",
      lastSevenDays: "Son 7 gün",
      lastFifteenDays: "Son 15 gün",
      lastThirtyDays: "Son 30 gün",
      approve: "Onayla",
      cancel: "İptal",
    },

    noRecords: "Seçtiğiniz tarih aralığında veriler sıfıra eşittir.",
  },

  /**
   * Gelir işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/income
   */
  "accounting/income": {
    headings: {
      createdAt: "Belirtilen Ödeme Tarihi",
      customerFullName: "Müşteri Adı",
      amount: "İşlem Tutarı",
      type: "Ödeme Yöntemi",
    },
  },

  /**
   * Borç işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debt
   */
  "accounting/debt": {
    headings: {
      createdAt: "İşlem Tarihi",
      customerFullName: "Müşteri Adı",
      amount: "Toplam",
      paid: "Ödenen",
      remaining: "Kalan",
    },
  },

  /**
   * Prim işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/bounty
   */
  "accounting/bounty": {
    payButtonTitle: "Prim Öde",
    allPayButtonTitle: "Hepsini Öde",

    headings: {
      staffFullName: "Çalışan Adı Soyadı",
      bounty: "Prim Tutarı",
    },

    check: {
      title: "Prim Ödemesi",
      confirmButtonTitle: "Ödemeyi Yap",
      discardButtonTitle: "Vazgeç",
      alertBoldText:
        "{selected_employee} çalışanı için birikmiş {bounty} prim bulunmaktadır. İşlem tarihi ve ödeme yöntemi seçeneklerini doldurararak prim ödemesini gerçekleştirebilirsiniz.",
    },
  },

  /**
   * Gider işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense
   */
  "accounting/expense": {
    newFields: {
      expenseType: "Gider Tipi",
      expenseAmount: "Gider Tutarı",
      deletingReversable: "Bu işlem geri alınamaz",
      approveDeleting: "Gider Tablodan Silinecektir",
      selectPaymentType: "Lüften ödeme türü seçiniz.",
    },
    add: {
      head: "Yeni Gider",
      close: "Vazgeç",
      title: "Yeni Gider Ekle",
      typeInputLabel: "Gider Kalemi",
      amountInputLabel: "Tutar",
      amountInputPlaceholder: "Bu alana ilgili gider tutarı giriniz.",
      descriptionInputLabel: "Açıklama",
      descriptionInputHint:
        "Bu alana ilgili işlem hakkında eklemek istediğiniz notları girebilirsiniz.",

      buttonTitle: "Gider Ekle",
      paymentType: "Ödeme Yöntemi",

      processDate: "İşlem Tarihi",

      spending: "Harcayan",
      spendingPlaceholder: "Harcayanınızı giriniz.",
    },

    table: {
      title: "Gider İşlemleri",
      headings: {
        createdAt: "Oluşturulma Tarihi",
        transactionDate: "İşlem Tarihi",
        type: "Gider Kalemi",
        description: "Gider Açıklaması",
        amount: "Tutar",
      },
      deleteButtonTitle: "Gideri Sil",
    },

    totalExpenses: "Toplam Gider",

    deleteToast: "Gider kaydı başarıyla silindi.",
    insertToast: "Gider başarıyla oluşturuldu.",
    today: "Bugün",
    thisWeek: "Bu Hafta",
    thisMonth: "Bu Ay",

    incomeMethods: "Gelir işlemleri",

    filterArea: {
      header: "Zaman Aralığını Seçiniz",
      yesterday: "Dün",
      lastSevenDays: "Son 7 gün",
      lastFifteenDays: "Son 15 gün",
      lastThirtyDays: "Son 30 gün",
      approve: "Onayla",
      cancel: "İptal",
    },

    cash: "Nakit",
    creditBankCard: "Kredi / Banka Kartı",
    transfer: "Havale/EFT",
    customerPoint: "Müşreri Puanı",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense/types
   */
  "accounting/expense/types": {
    alertBoldText: "Gider Kalemleri Hakkında",
    alertText:
      "Bu alanda işletmenize özgü gider kalemlerini tanımlayarak gider işlemlerinizi oluşturduğunuz kalemler üzerinden gerçekleştirebilirsiniz.",

    saveChangesButtonTitle: "Değişiklikleri Kaydet",
    typeInputLabel: "{i}. Özel Gider Kalemi",

    insertToast: "İşlem başarıyla gerçekleştirildi.",

    moreButtonTitle: "Daha Fazla",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/credit
   */
  "accounting/credit": {
    filter: {
      title: "Tarih ve Müşteri Bazlı Filtreleme",
      satrtDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      name: "Müşteri Adı",
    },

    add: {
      customerSelection: "Müşteri Seçimi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      enterAmount: "Tutarı Giriniz",
      explanation: "Açıklama",
      saveReceivable: "Alacağı Kaydet",
      updateReceivable: "Alacağı Güncelle",
      collectPayment: "Ödemeyi Tahsil Et",
      paymentDialogTitle: "Ödeme Bilgileri",
      savePaymnet: "Ödemeyi Kaydet",
      closePayment: "Ödemeyi Kapat",

      date: "Tarih",
      amount: "Tutar",
      paymentType: "Ödeme Yöntemi",
      actions: "İşlemler",
      delete: "Sil",
      update: "Güncelle",

      start_amount: "Başlangıç Tutarı",
      end_amount: "Bitiş Tutarı",

      missingInfo: "Eksik Bilgi Girdiniz",
    },

    payments: {
      typeInputLabel: "Ödeme Yöntemi",
      typeInputPlaceholder: "Ödeme Yöntemini Seçiniz",

      amountInputLabel: "Tutar Giriniz",

      typeCASH: "Nakit",
      typeCARD: "Kredi / Banka Kartı",
      typeEFT: "Havale/EFT",
      typePOINT: "Müşteri Puanı",
    },

    addCredit: "Alacak Ekle",
    paid: "Ödendi",
    notPaid: "Ödenmedi",

    headings: {
      createdAt: "Oluşturulma Tarihi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      nameSurname: "İsim-Soyisim",
      all_amount: "Toplam",
      paid: "Ödenen",
      debt_total: "Kalan",
      view: "Detay & Görüntüle",
      delete: "Sil",
      transactions: "İşlemler",
      is_paid: "Ödeme Durumu",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debit
   */
  "accounting/debit": {
    filter: {
      title: "Tarih ve Müşteri Bazlı Filtreleme",
      satrtDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      name: "Müşteri Adı",
    },

    add: {
      customerSelection: "Müşteri İsmi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      enterAmount: "Tutarı Giriniz",
      explanation: "Açıklama",
      saveReceivable: "Borcu Kaydet",
      updateReceivable: "Borcu Güncelle",
      collectPayment: "Ödemeyi Tahsil Et",
      paymentDialogTitle: "Ödeme Bilgileri",
      savePaymnet: "Ödemeyi Kaydet",
      closePayment: "Ödemeyi Kapat",

      date: "Tarih",
      amount: "Tutar",
      paymentType: "Ödeme Yöntemi",
      actions: "İşlemler",
      delete: "Sil",
      update: "Güncelle",

      start_amount: "Başlangıç Tutarı",
      end_amount: "Bitiş Tutarı",

      debitAdded: "Borç Eklendi",
      missingInfo: "Eksik Bilgi Girdiniz",
      errorAccur: "Bir Hata Oluştu.",
    },

    payments: {
      typeInputLabel: "Ödeme Yöntemi",
      typeInputPlaceholder: "Ödeme Yöntemini Seçiniz",

      typeCASH: "Nakit",
      typeCARD: "Kredi / Banka Kartı",
      typeEFT: "Havale/EFT",
      typePOINT: "Müşteri Puanı",
    },

    addCredit: "Borç Ekle",
    paid: "Ödendi",
    notPaid: "Ödenmedi",

    headings: {
      createdAt: "Oluşturulma Tarihi",
      scheduledPaymentDate: "Planlanan Ödeme Tarihi",
      nameSurname: "İsim-Soyisim",
      all_amount: "Toplam",
      paid: "Ödenen",
      debt_total: "Kalan",
      view: "Detay & Görüntüle",
      delete: "Sil",
      transactions: "İşlemler",
      is_paid: "Ödeme Durumu",
    },
  },

  /**
   * Raporlar modülünün genel kullanımlarının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   * https://app.salonrandevu.com/reports/services
   * https://app.salonrandevu.com/reports/stock
   * https://app.salonrandevu.com/reports/staff
   * https://app.salonrandevu.com/reports/customer
   */
  reports: {
    startInputLabel: "Başlangıç Tarihi",
    endInputLabel: "Bitiş Tarihi",

    headings: {
      stockName: "Ürün Adı",
      serviceName: "Ürün Adı",
      transactionCount: "İşlem Sayısı",
      soldCount: "Satış Sayısı",
      income: "Elde Edilen Gelir",
    },

    selectedStaffInputLabel: "Çalışan Seçimi",
    selectedPageCountInputLabel: "Sayfa Sayısı",
  },

  /**
   * İşletme raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   */
  "reports/company": {
    soldItem: "Satılan Ürün",
    soldService: "Uygulanan Hizmet",

    serviceIncome: "Hizmet Geliri",
    itemIncome: "Ürün Geliri",

    generalIncome: "Ödenen Gelir",
    maxExpense: "Toplam Gider",
    netIncome: "Net Gelir",
    totalIncome: "Toplam Gelir",

    typeCASH: "Nakit",
    typeCARD: "Kredi / Banka Kartı",
    typeEFT: "Havale/EFT",

    _maxAppointment: "Toplam Randevu Sayısı",
    _maxReceipt: "Toplam Adisyon Sayısı",
    _serviceIncome: "Hizmetlerden Elde Edilen Gelir",
    _itemIncome: "Ürünlerden Elde Edilen Gelir",
    _paidBounty: "Personellere Ödenen Prim",

    alertText:
      "Hizmet geliri ve ürün geliri olarak belirtilen değerler adisyonu tamamiyle ödenmiş, borçsuz adisyonlar üzerinden hesaplanmıştır.",

    incomeReports: "Gelir Raporları",
    expensesReports: "Gider Raporları",
    summary: "Özet",

    goToDetail: "Detaya Git",
  },

  /**
   * Çalışan raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/staff
   */
  "reports/staff": {
    name: "Çalışan Adı",
    serviceCount: "Sunduğu Hizmet Sayısı",
    soldItemCount: "Sattığı Ürün Sayısı",

    onlyService: "Hizmet Cirosu",
    bountyService: "Hizmet Primi",
    productSum: "Ürün Cirosu",
    bountyProduct: "Ürün Primi",
    packageSum: "Paket Cirosu",
    bountyPackage: "Paket Primi",

    endorsement: "Toplam Cirosu",
    bounty: "Toplam Primi",
    survey: "Değerlendirmesi",
  },

  /**
   * Müşteri raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/customer
   */
  "reports/customer": {
    name: "Müşteri Adı",
    appointmentCount: "Randevu Sayısı",
    appointmentStatus: "Geldi / Bekleniyor / Gelmedi",

    transactionCount: "Yapılan İşlem Sayısı",
    productCount: "Satılan Ürün Sayısı",
    totalEarned: "Toplam Kazandırdığı",
  },

  /**
   * Yapılacaklar listesi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/todos
   */
  todos: {
    toastSuccess: "Görev başarıyla eklendi!",
    toastWarningMessage: "Görev içeriği boş olamaz",
    toastWarningProcess: "Görev ekleme işlemi başarısız.",

    addButtonTitle: "Görevi Oluştur",

    addTodoTitle: "Görev Ekle",
    todoInputLabel: "Görev İçeriği",
    todoInputPlaceholder:
      "Bu alana yapılacak işlerinize dair notlarınızı, görevlerinizi girebilirsiniz.",

    dateTitle: "Tarihli Görevler",
    notFoundText: "Tarihi için kaydedilmiş bir görev bulunmuyor.",

    statusWaiting: "BEKLENİYOR",
    statusCompleted: "TAMAMLANDI",

    updateToast: "Görev durumu başarıyla güncellendi.",
    insertToast: "Görev başarıyla eklendi.",
    deleteToast: "Görev başarıyla silindi.",

    deleteTaskQuestion: "Görevi Silmek İstiyor musunuz?",
    deleteTaskDescription: "Görev listenizden silinecek.",

    emptyWarning: "Boş görev oluşturamazsınız.",
  },

  /**
   * Cüzdan sayfasının bulunduğu key.
   * https://app.salonrandevu.com/wallet
   */
  wallet: {
    cardTitle: "Toplam Bakiyeniz",
    amountInputLabel: "Yüklenecek Tutar ($)",
    amountButtonTitle: "Bakiye Yükle",

    remainingSMS: "Kalan SMS Kredisi",
    licenseDetail: "Kullanılan Paket",
    licenseRemaining: "Lisans Bitimine Kalan",
    appointmentRemaining: "Kalan Randevu Kredisi",
    paymentForm: "Banka Hesapları / Ödeme Bildirim Formu",

    licenseHeading: "Lisans Satın Al",
    smsHeading: "SMS Paketi Satın Al",
    appointmentHeading: "Randevu Kredisi Al",

    withEFT: "HAVALE İLE",
    buyButtonTitle: "Satın Al",

    licenses: "Lisanslar",
    message_packages: "Mesaj Paketleri",
    extra_license: "Ek Paketler",

    bardcode_extra_package: "Barkod Okuyucu",
    whatsapp_extra_package: "WhatsApp Anlık Mesaj",
    online_link_extra_package: "Randevu Alma Sayfası",

    bardcode_extra_package_tooltip:
      "Stok takibi yaparken ürünleri barkodunu okutarak ekleyebilir ve uygulamamız sayesinde cep telefonu kamerasını kullanarak barkodlarını kolayca okutup satışlarını yapabilirsiniz.",
    whatsapp_extra_package_tooltip:
      "WhatsApp hesabınızla anlık bağlantı kurulur ve üye özelinde iletmek istediğiniz mesajları WhatsApp ikonuna tıklayarak web tarafından kullanıyorsanız Whatsapp masaüstü uygulaması ile mesaj yollarsınız mobil tarafta kullanıyorsanız WhatsApp uygulaması ile hızlıca iletişime geçebilirsiniz.",
    online_link_extra_package_tooltip:
      "Bu alandan müşterilerinizin işletmenize randevu talebi oluşturması için gerekli olan link aktive edilir. Bu linkten müşterileriniz günün 24 saati çalışan, hizmet, gün ve saat bilgilerini girerek randevu talebi oluşturabilirler.",

    package_prices: "Paket Fiyatlarına",
    kdv: "Kdv Dahil Değildir",

    allLicenceFeatures: "Lisans Özellikleri",

    sms_price: "SMS + WhatsApp Paket Fiyatlarımıza",
    sms_package: "SMS Paketleri",
    wp_package: "WhatsApp Paketleri",

    see_more: "Daha fazla göster",
    see_less: "Daha az göster",

    includesAll: "Bütün Lisanslarda Bulunanlar",

    buy: "Satın Al",

    load_balance: "Bakiye Yükle",

    monthly_payment: "Aylık Ödeme",
    yearly_payment: "12 Aylık Öde",
    monthly: "Aylık",
    check: {
      title: "Satın alımı onaylıyor musunuz?",
      confirmButtonTitle: "Onayla",
      discardButtonTitle: "Vazgeç",

      alertText:
        "Mevcut bakiyenizle {credit_card_amount}₺ tutarındaki {package_name} paketini satın almayı onaylıyor musunuz?",
      packageChangeAlert:
        "{active_package} paketinizden kalan {remaining_day} gününüz, birim fiyatına göre hesaplanıp {formula} gün olarak {new_package} paketinize eklenecektir.",
    },

    paymentType: {
      title: "Lütfen Ödeme Yöntemini Seçiniz",
      creditCard: "Kredi Kartı",
      eft: "Havale/EFT",
    },

    balanceErrorToast: "Lütfen yüklenecek tutarı giriniz!",
  },

  "platform/photo": {
    photoUploadToast: "Fotoğraf yükleme işleminiz başladı.",
    insertToast: "Fotoğraf yükleme başarıyla tamamlandı.",
    deleteToast: "Fotoğraf başarıyla silindi.",
    anexample: "Örnek Bir Gösterim",

    photoUploadErrorToast: "Fotoğraf ekleme işlemi başarısız oldu.",
    deletePhotoErrorToast: "Fotoğraf silinemedi.",

    photoInfo:
      "Arama ve listeleme ekranlarında gösterilecek fotoğrafı temsil eder.",
  },

  "platform/settings": {
    offOn: "Kapalı / Açık",
    chooseColor: "Renk Seç",
    platformVisibleTitle: "İşletme Görünürlüğü",
    platformVisibleDescription:
      "İşletmenizin Salon Randevu Platformu’nda müşteri aramalarında görünüp/görünmemesi ayarıdır.Bu ayar kapalı olunca Salon Randevu Platformu’nda işletmeniz aramalarda çıkmayacaktır.",

    platformPriceTitle: "Hizmet Ücretleri Görünürlüğü",
    platformPriceDescription:
      "Salon Randevu Platformu’nda hizmet ücretlerinizin gizlenmesi ayarıdır.Bu ayar kapalı olduğunda Salon Randevu Platformu’nda hizmetlerinizin ücreti görünmeyecektir.",

    platformCommentTitle: "Müşteri Yorumları Görünürlüğü",
    platformCommentDescription:
      "Salon Randevu Platformu’nda işletmenize yapılan değerlendirmelerin gizlenmesi ayarıdır.Bu ayar kapalı olduğunda Salon Randevu Platformu’nda işletmenize dair herhangi bir müşteri yorumu görünmeyecektir.",

    platfromAppointmentTitle: "Otomatik Randevu Onayı",
    platfromAppointmentDescription:
      "Randevu taleplerinizin otomatik onay ayarıdır. Bu ayar açık olduğunda size gelen randevu talepleri otomatik olarak onaylanır.",

    platformColorSelectionTitle: "Platform Renk Ayarı",
    platformColorSelectionDescription:
      "Bu ayardan seçilen renge göre Salon Randevu Platormu' nda sizin sayfanız için seçmiş olduğunuz renk ayarı kullanılacaktır.",

    platformVideoURLTitle: "Platform Video URL",
    platformVideoURLDescription:
      "Girdiğiniz video URL' i platform sayfanızda kullanıcılara gösterilecektir.",
    platformVideoURLInputLabel: "Video URL",
    platformVideoURLInputPlaceholder: "Video URL' sini giriniz",

    colorDialogTitle: "Renk Seçimi",
    colorDialogCancelTitle: "Rengi İptal Et",
    colorDialogSelectTitle: "Tamam",
  },

  "platform/categories": {
    howto: {
      title: "Nasıl Yapılır?",
      content: [
        "Sol tarafta oluşturduğunuz hizmetler(mevcut hizmetleriniz) , sağ tarafta ise platformda görünecek olan hizmetler bulunmaktadır.",
        "Platforma eklemek istediğiniz hizmetlerinizi seçin.",
        "Seçtiğiniz hizmetler Platformda hangi kategori altında görünecekse o kategoriyi genişletin.",
        "Seçtiğiniz hizmetler , genişlettiğiniz kategorinin altındakihizmetlerden hangisi ile eşleşiyorsa o hizmeti seçip, Aktar butonuna tıklayın.",
      ],
    },
    knowrequired: {
      title: "Bilmeniz Gerekenler",
      content: [
        "Birden fazla hizmeti aynı anda seçip taşıyabilirsiniz.",
        "Platforma taşıdığınız hizmetleriniz artık Mevcut Hizmetleriniz sekmesinde görünmeyecektir.",
        "Bir hizmeti birden fazla kategori-hizmet adıyla eşleştiremezsiniz.",
        "Seçtiğiniz hizmetler , genişlettiğiniz kategorinin altındaki hizmetlerden hangisi ile eşleşiyorsa o hizmeti seçip, Aktar butonuna tıklayın.",
        "Yanlış eşleştirdiğiniz hizmetleri, hizmet adının yanında bulunan çarpıyı kullanarak o hizmeti tekrar Mevcut Hizmetleriniz sekmesine kaldırabilirsiniz.",
        "Yeni hizmet eklerken platform için kategori-hizmet seçimi “Yeni Hizmet Ekle” sayfasına ayrıca eklendi.",
      ],
    },
  },

  /**
   * İşletme Ayarları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings
   */
  settings: {
    employees: {
      name: "İsim",
      surName: "Soyisim",
      sex: "Cinsiyet",
      color: "Renk",
      phoneNumber: "Cep Telefonu",
      email: "E-Posta Adresi",
      password: "Şifre",
    },
    platformSettingSuccess: "Platform ayarları başarıyla güncellendi.",
    validation: {
      companyName: "İşleme Adı",
      eMail: "E-Posta Adresi",
      phoneNumber: "Telefon Numarası",
      city: "Şehir",
      district: "İlçe",
      companyAddress: "İşletme Adresi",
      ownerName: "Yetkili Adı",
      ownerSurname: "Yetkili Soyadı",
    },
    createStaffSuccess: "Çalışan başarıyla oluşturuldu!",
    img: {
      imgNotFound: "Bu işletmeye ait logo bulunamadı.",
      uploadPhoto: "Logoyu güncelle.",
      clickToUpload: "Bu işletmeye ait logo yüklemek için tıklayınız.",
      changeStaffHours: "Çalışan Saat Değişikliği",
      approve: "Onayla",
      clear: "Vazgeç",
      expHours:
        "Randevu Saati / Başlangıç / Bitiş / Sıkılığında değişiklik yapmak üzeresiniz!! Tüm çalışanlarınızın profilindeki kapalı saatleri varsayılana dönecek ve yeniden düzenlemeniz gerekecek. Onaylıyor musunuz?",
    },
    idInputLabel: "Salon Kodu",
    nameInputLabel: "Salon Adı",
    mailInputLabel: "Salon E-Posta Adresi",
    phoneInputLabel: "Salon Telefon Numarası",
    aboutCompanyLabel: "İşletme Hakkında",

    cityInputLabel: "İl Seçimi",
    districtInputLabel: "İlçe Seçimi",
    addressInputLabel: "Açık Adres",
    mapsInputLabel: "Google Maps İşletme Koordinatları",
    mapsInputHint: "Örneğin: 41.009336, 28.660129",
    currentLocationButtonTitle: "Anlık Konumumu Kullan",

    instagramInputLabel: "Instagram Kullanıcı Adı",
    facebookInputLabel: "Facebook Sayfası Kullanıcı Adı",
    websiteInputLabel: "İnternet Sayfası Url'i",
    withoutAtHint: "Başında @ işareti olmadan giriniz",

    priceInputLabel: "Fiyat Bilgisi Ayarı",
    priceInputHint:
      "İşletme URL'niz üzerinden randevu talebinde bulunacak müşterilerinize seçtiği işlemin fiyat bilgisinin gösterilip/gösterilmeyeceği ayarıdır.",

    commentsInputLabel: "Müşteri Yorumları Ayarı",
    commentsInputHint:
      "İşletme URL'nizde müşterilerinizin anketlerde yaptığı yorumların gösterilip/gösterilmeyeceği ayarıdır.",
    systemLanguage:
      "Sistem Dili Değişikliği İngilize/Türkçe olarak ayarlanabilir.",
    inputOn: "Açık",
    inputOff: "Kapalı",

    urlInputLabel: "İşletme Randevu URL'si",
    openOnBrowserButtonTitle: "Tarayıcıda Aç",
    copyLinkButtonTitle: "Linki Kopyala",
    copyLinkToast: "Link başarıyla kopyalandı.",
    updateButtonTitle: "Bilgileri Güncelle",

    geolocationErrorToast: "Tarayıcınızın Geolocation desteği bulunmuyor.",
    locationErrorToast:
      "Konumunuz alınamadı, lütfen daha sonra tekrar deneyin.",
    updateToast: "Bilgiler başarıyla güncellendi.",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/agreement/index
   */

  "settings/agreements": {
    agreementTemplates: "Sözleşme Şablonları",
    createNewAgreement: "Yeni Sözleşme Oluştur",
    editAgreement: "Sözleşmeyi Düzenle",
    agreementClauses: "Sözleşme Maddeleri",
    agreements: "Sözleşmeler",

    delete: "Sil",
    edit: "Düzenle",
    close: "Kapat",

    yesOrNo: "Evet / Hayır",

    createTamplateArea: {
      personalInformation: "Kişisel Bilgiler",
      paymentInformation: "Ödeme Bilgileri",
      clausesInformation: "Madde Bilgileri",
      label: "Yeni Şablon",
      placeHolder: "Yeni Sözleşme Şablonu",
      saveTamplate: "Şablonu Kaydet",
      editTamplate: "Şablonu Düzenle",
      cancel: "İptal",
      placeHolders: "İşletme Bilgisi",
    },

    personal_info: {
      name_and_surname: "Ad Soyad",
      phone: "Telefon Numarası",
      birth_date: "Doğum Tarihi",
      job: "Meslek",
      id_number: "T.C No",
      tax_number: "Vergi No.",
      address: "Adres",
    },
    payment_info: {
      staff_name: "Çalışan Adı",
      service: "Hizmet",
      total_amount: "Toplam Tutar",
    },

    createClausesArea: {
      createNewClauses: "Yeni Madde Oluştur",
      title: "Madde Başlığı",
      context: "Madde İçeriği",
      yesOrNo: "Evet / Hayır Sorgusu Eklensin",
      update: "Güncelle",
      save: "Kaydet",
      cancel: "İptal",
      fillAreaComplatelty: "Lütfen alanları eksiksiz doldurun.",
    },

    createPdf: {
      companyNamePlaceHolder: "İşetme Adı",
      agreementTitle: "Sözleşme Başlığı",
    },

    agreementsList: {
      date: "Tarih",
      nameSurname: "Ad Soyad",
      agreementName: "Sözleşme Adı",
      size: "Boyutu",
      actions: "İşlemler",
      startDate: "Başlangıç Tarihi",
      endDate: "Bitiş Tarihi",
      searchCustomer: "Müşteri Ara",
    },

    agreementRequstFail:
      "Beklenmeyen bir hata oluştu. Lütfen müşteri temsilciniz ile bağlantıya geçiniz.",

    agreementClausesSavedSucces: "Sözleşme maddesi başarı ile kaydedilmiştir.",
    agreementClausesEditSucces: "Sözleşme maddesi başarı ile güncellenmiştir.",
    agreementClausesDeleteSucces: "Sözleşme maddesi başarı ile silinmiştir.",

    agreementSaveSucces: "Sözleşme şablonu başarı ile kaydedildi.",
    agreementEditSucces: "Sözleşme şablonu başarı ile güncellendi.",
    agreementDeleteSucces: "Sözleşme şablonu başarı ile silindi.",

    deleteClausesModal: {
      title: "Bu Sözleşme Maddesini Silmeyi Onaylıyor musunuz?",
      delete: "Sil",
      cancel: "İptal",
      expTitle: "Bu işlem geri alınamaz!",
      expDetail: "Sözleşme maddesi kalıcı olarak silinecektir.",
    },

    deleteAgreementModal: {
      title: "Bu Sözleşme Şablonunu Silmeyi Onaylıyor musunuz?",
      delete: "Sil",
      cancel: "İptal",
      expTitle: "Bu işlem geri alınamaz!",
      expDetail: "Sözleşme şablonu kalıcı olarak silinecektir.",
    },

    agreement: {
      yes: "Evet",
      no: "Hayır",
      signiture: "İmza",
    },

    smsAproove: {
      title: "Kayıtlı sözleşmeyi SMS ile iletmek ister misiniz?",
      send: "Gönder",
      cancel: "İptal",
      textFirst: "isimli müşteriye SMS ile",
      textSecond: "göndermek istediğinize emin misiniz?",
    },

    wpAproove: {
      title: "Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?",
      send: "Gönder",
      cancel: "İptal",
      textFirst: "isimli müşteriye",
      textSecond: "Whatsapp ile göndermek istediğinize emin misiniz?",
    },

    deleteAgreement: {
      title: "Sözleşmesi Silmeyi Onaylıyor Musunuz?",
      aproove: "Onayla ve Sil",
      cancel: "Vazgeç",
      infoTitle: "Sözleşme kalıcı olarak silinecektir.",
      infoContext:
        "Bu işlemin geri dönüşü olmayacaktır. Sözleşme kalıcı olarak silinecektir.",
    },
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/hours
   */
  "settings/hours": {
    businessCategory: "Firma Kategorisi",
    startInputLabel: "Mesai Başlangıç Saati",
    endInputLabel: "Mesai Bitiş Saati",
    periodBetweenInputLabel: "Randevu Sıklığı (Dakika)",
    startInputLabelHour: "Başlangıç Saat",
    startInputLabelMinute: "Başlangıç Dakika",

    startHour: "Başlangıç Saati",
    endHour: "Bitiş Saati",

    endInputLabelHour: "Bitiş Saat",
    endInputLabelMinute: "Bitiş Dakika",

    updateButtonTitle: "Bilgileri Güncelle",

    updateToast: "Bilgiler başarıyla güncellendi.",
    compareErrorToast:
      "Bitiş Saati alanı Başlangıç Saati alanından küçük olamaz.",
  },

  /**
   * İşletme Galerisi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/gallery
   */
  "settings/gallery": {
    numberPhoto: "Toplam Yükleyebileceğiniz Fotoğraf Sayısı",
    uploadButtonTitle: "Fotoğraf yüklemek için bu alana tıklayın.",
    anexample: "Örnek Bir gösterim",
    nextPhoto: "Sonraki Fotoğraf",

    checkAllButtonTitle: "Tüm Fotoğrafları Seç",
    deleteSelectedButtonTitle: "Seçilen Fotoğrafları Sil ({count})",

    delete: {
      // Modal
      title: "Silme işlemini onaylıyor musunuz?",
      confirmButtonTitle: "Onayla",
      discardButtonTitle: "Vazgeç",

      alertBoldText: "Bu işlemi onaylıyor musunuz?",
      alertText:
        "Silme işlemini gerçekleştirmeniz durumunda silinen fotoğraflar ilişkili bütün sayfalardan kaldırılacaktır.",
      selectAll: "Tümünü Seç",
      before: "Önceki",
      after: "Sonraki",
    },

    photoUploadToast: "Fotoğraf yükleme işleminiz başladı.",
    insertToast: "Fotoğraf yükleme başarıyla tamamlandı.",
    deleteToast: "Fotoğraf başarıyla silindi.",

    photoMorePictureError: "8 fotoğraftan fazla yükleme yapılamaz.",

    enterprise_gallery_info:
      "Bu alandan işletmenize ait mekan fotoğrafları ekleyebilirsiniz.Bu görseller Salon Randevu Platformu ve işletme sayfanızda görüntülenecektir.",

    dialog: {
      title: "Vitrin Fotoğrafı Yükle",
      close: "Kapat",
      selectFoto: "Fotoğraf Seç",
      upload: "Yükle",
    },
  },

  "settings/service-gallery": {
    numberPhoto: "Toplam Yükleyebileceğiniz Fotoğraf Sayısı",
    uploadButtonTitle: "Fotoğraf yüklemek için bu alana tıklayın.",
    anexample: "Örnek Bir gösterim",

    photoDelete: "Silinecek Fotoğrafı Seçiniz",

    checkAllButtonTitle: "Tüm Fotoğrafları Seç",
    deleteSelectedButtonTitle: "Seçili Fotoğrafları Sil ({count})",

    delete: {
      // Modal
      title: "Silme işlemini onaylıyor musunuz?",
      confirmButtonTitle: "Onayla",
      discardButtonTitle: "Vazgeç",

      alertBoldText: "Bu işlemi onaylıyor musunuz?",
      alertText:
        "Silme işlemini gerçekleştirmeniz durumunda silinen fotoğraflar ilişkili bütün sayfalardan kaldırılacaktır.",
    },

    photoUploadToast: "Fotoğraf yükleme işleminiz başladı.",
    insertToast: "Fotoğraf yükleme başarıyla tamamlandı.",
    deleteToast: "Fotoğraf başarıyla silindi.",

    photoMoreUploadError: "8 fotoğraftan fazla yükleme yapılamaz.",

    service_photo_info:
      "Bu alandan verdiğiniz hizmetlerle ilgili görseller ekleyebilirsiniz.Bu görseller Salon Randevu Platformu ve işletme sayfanızda görüntülenecektir.",
  },

  /**
   * Sistem Logları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/logs
   */
  "settings/logs": {
    headings: {
      createdAt: "İşlem Tarihi",
      message: "İşlem Açıklaması",
      detail: "İşlem Detayı",
      ip: "IP Adresi",
    },
  },

  /**
   * 404 sayfasının bulunduğu key.
   * https://app.salonrandevu.com/404
   */
  notFound: {
    heroHeadingText: "Maalesef, bulamadık!",
    boldText:
      "Maalesef aradığınız ya da gitmeye çalıştığınız sayfayı bulamadık.",
    text: "Silinmiş, taşınmış ya da oluşturulmamış olabilir.",
  },

  /**
   * Paket yükselt uyarısının bulunduğu key.
   */
  planUpgrade: {
    heroHeadingText: "Paketinizi yükseltin!",
    boldText: " Bu modülü kullanmak için paket yükseltmeniz gerekmektedir.",
    text: "Bir problem olduğunu düşünüyorsanız bize iletişim kanallarımız üzerinden ulaşablirsiniz.",
    showPackagesButtonTitle: "Paketleri Görüntüle",
    callUsButtonTitle: "Bizi Arayın - 0(212) 706 0510",
  },

  /**
   * İşletme URL'si sayfasının bulunduğu key.
   * https://app.salonrandevu.com/isletme/22
   */
  businessURL: {
    callButtonTitle: "Hemen Ara (0{phone})",
    instagramHeading: "İnstagram'da Takip Edin!",
    instagramText: "Instagram'da @{username}",

    facebookHeading: "Facebook'ta Takip Edin!",
    facebookText: "Facebook'ta @{username}",

    aboutHeading: "İşletme Bilgileri",
    phoneText: "Telefon",
    mailText: "E-Posta",
    addressText: "Adres",

    appointment: {
      // Tab
      tab: "Randevu Oluştur",
      title: "Randevu Talebi Oluştur",

      nameInputLabel: "Adınız",
      surnameInputLabel: "Soyadınız",
      phoneInputLabel: "Cep Telefonu",
      serviceInputLabel: "Hizmet Seçimi",
      serviceInputHint: "Hizmetler arasından seçim yapınız",
      staffInputLabel: "Çalışan Seçimi",
      staffInputHint: "Randevunuzda sizinle ilgilenecek olan çalışanı seçiniz",
      staffInputCompanyHint:
        "Randevunuzda sizinle ilgilenecek olan çalışanı seçiniz. (Çalışan listesinde , seçmiş olduğunuz hizmeti sağlayan çalışanlar listelenir.)",
      dateInputLabel: "Randevu Tarihi",
      dateInputHint:
        "Randevu tarihini ister elle yazabilir, ister takvim ikonuna tıklayarak seçebilirsiniz",

      availableHoursButtonTitle: "Uygun Saatleri Ara",

      check: {
        // Modal
        title: "Randevu Talebini Gönder",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",

        alertBoldText: "Sayın {name} {surname}",
        alertText:
          "{date} günü, saat {time} için randevunuzun oluşturulmasını onaylıyor musunuz?",

        priceText:
          "Seçmiş olduğunuz {service} işleminin genel ücreti işletme tarafından {amount} olarak belirlenmiştir.",
        priceBoldText:
          "Bu fiyat bilgilendirme amacıyla verilmekte olup, kesin bir işlem tutarı niteliği taşımamaktadır.",

        noteInputLabel: "Randevu Notu (İsteğe Bağlı)",
        noteInputHint: "%s/255 Karakter",
      },

      insertToast: "Randevu talebiniz başarıyla oluşturuldu.",

      appointment_button: "Randevu Al",
    },

    servicesGallery: {
      title: "Hizmet Galerisi",
    },

    services: {
      // Tab
      tab: "Hizmet Listesi",
      title: "Hizmet Listesi",
      processTime: "İşlem Süresi: {time} dakika",
    },

    staffs: {
      //Tab
      tab: "Çalışan Listesi",
      title: "Çalışan Listesi",
    },

    comments: {
      // Tab
      tab: "Müşteri Yorumları",
      title: "Yorumlar",
    },
  },

  "support/live_support": {
    liveSupportRequestTitle: "Canlı Destek Talebi",
    appointmentDate: "Randevu Tarihi ve Saati",
    appointmentRequestTitle: "Destek Talepleri",
    createAppointmentButtonTitle: "Randevu Al",

    name: "Adınız",
    surname: "Soyadınız",
    supportrequestsubject: "Destek Talebi Konusu",
    supportrequestdetail: "Destek Talebi Detayı",
    hours: "Saat",

    nameInputLabel: "İsim",
    nameInputHint: "Lütfen isminizi giriniz",

    surnameInputLabel: "Soyisim",
    surnameInputHint: "Lütfen soyisminizi giriniz",

    phoneNumberInputLabel: "Telefon Numarası",

    supportRequestAboutLabel: "Destek Talebi Konusu",
    supportRequestAboutHint: "Destek talebi konusunu yazınız",

    dateInputLabel: "Randevu Tarihi",
    noAvailableHours: "Seçebileceğiniz mevcut bir saat bulunamadı.",

    supportRequestDetailLabel: "Destek Talebi Detayı",
    supportRequestDetailHint:
      "Destek talebinde bulunduğunuz konu ile ilgili lütfen ayrıntılı açıklama yapınız.",
    supportRequestDetailHelperText:
      "Konu ile ilgili yaptığınız işlemleri ve yaşadığınız sorunu lütfen ayrıntıları ile açıklayınız. Vereceğiniz detaylar size en hızlı ve doğru şekilde yardımcı olmamızı sağlayacaktır.",

    appointmentCancelDescription:
      "Önemli: Destek saatinden önce sorununuz çözüldüyse randevunuzu iptal etmeyi unutmayın lütfen.",

    headings: {
      appointmentDate: "Randevu Tarihi",
      appointmentAbout: "Konu",
      appointmentDetail: "Detay",
      name: "İsim Soyisim",
      phoneNumber: "Telefon",
      appointmentStatus: "Rnadevu Durumu",
      cancelButtonTitle: "İptal Et",

      replacements: {
        status: {
          waiting: "Bekleniyor",
          done: "Görüşüldü",
          cancel: "İptal Edildi",
        },
      },
    },

    alertInfo: {
      text1:
        " Seçtiğiniz zamanda 02121060510 numaralı telefonumuzdan sizi arayacağız. Lütfen uzak bağlantı ihtiyacına karşın",
      text2: "ALPEMIX uzaktan bağlantı programını",
      text3: "indirip çalışır vaziyete getiriniz. İstediğiniz her zaman",
      text4: "Pasif Müşteriler",
      text5: "sekmesinden müşteri bilgilerine erişebilirsiniz.",
    },

    createLiveSupportRequestToast:
      "Randevu talebiniz başarıyla oluşturulmuştur",
    cancelLiveSupportRequestToast: "Randevu talebi başarıyla iptal edilmiştir.",
  },

  /**
   * Birden fazla yerde kullanılan sabit tanımlar bu alanda yazılmaktadır.
   */
  component: {
    actionHeadingText: "İşlemler",
    actionHeadingTextRating: "Yorum İşlemleri",
    detailEditButtonTitle: "Detay & Düzenle",
    whatsapp: "Whatsapp ile iletişime geç",
    validationErrorText: "Alanları istenilen formatta düzenleyin",
    loadingText: "Yükleniyor...",

    "customer/customer_detail": {},

    smartBanner: {
      // Loginde mobil uygulama yayında banner'ı
      onAirText: "Uygulamamız Yayında!",
    },

    timerButton: {
      waitText: "Bekleyin",
    },

    excelButton: {
      title: "Excel Olarak İndir",
    },

    datePickerInput: {
      // Datepicker Input
      cancelLabel: "İPTAL",
      clearLabel: "TEMİZLE",
      okLabel: "TAMAMLA",
    },

    dateRangeStats: {
      // Dashboard'daki istatistik kartları
      yesterday: "Dün",
      today: "Bugün",
      week: "Bu Hafta",
      month: "Bu Ay",
      lastWeek: "Son 7 gün",
      last_15_days: "Son 15 gün",
      lastMonth: "Son 30 gün",
    },

    CADialog: {
      // Customer Add Dialog
      title: "Hızlı Müşteri Oluştur",
      createButtonTitle: "Müşteriyi Oluştur",
      discardButtonTitle: "Vazgeç",

      nameInputLabel: "Müşteri Adı",
      nameInputPlaceholder: "Müşteri adını giriniz",

      surnameInputLabel: "Müşteri Soyadı",
      surnameInputPlaceholder: "Müşteri soyadı giriniz",

      phoneInputLabel: "Cep Telefonu",

      updateCustomerDetails: "Müşteriyi Güncelle",
      changeCutomertoActive: "Müşteriyi Aktif Hale Getir",
      changeCutomertoPassive: "Müşteriyi Pasif Hale Getir",

      genderInputLabel: "Cinsiyet",
      genderMale: "Erkek",
      genderFemale: "Kadın",

      birthdayInputLabel: "Doğum Tarihi",
      birthdayInputInvalidMessage:
        "Lütfen istenilen formatta giriş yapınız. Gün/Ay/Yıl",

      maritalStatusInputLabel: "Medeni Hali",
      maritalStatusMarried: "Evli",
      maritalStatusSingle: "Bekar",

      delete: {
        title: "Silmeyi onaylıyor musunuz?",
        confirmButtonTitle: "Evet",
        discardButtonTitle: "Hayır",
        alertText: "Bu işlemin geri dönüşü yoktur!",
        alertTitleText:
          "Bu müşteriyi silmeniz durumunda müşterinin bilgilerine bir daha erişemeyebilirsiniz.",
      },

      checkingForeingNumber: "Yabancı Numara Olarak İşaretle",

      confirmChangeCustomerTitle: "Pasif ayarlamayı onaylıyor musunuz?",
      confirmCustomerTitle:
        "Bu müşteriyi pasif hale getirmeniz durumunda bir daha randevu/adisyon gibi alanlarda müşteriyi göremeyeceksiniz.",
      confirmTitle1: "İstediğiniz her zaman",
      confirmTitle2: "Pasif Müşteriler",
      confirmTitle3: "sekmesindenmüşteri bilgilerine erişebilirsiniz.",

      secondPhoneInputLabel: "İkinci Telefon Numarası",
      secondPhoneInputPlaceholder: "Varsa ikinci telefon numarasını giriniz.",

      mailInputLabel: "E-Posta Adresi",
      mailInputPlaceholder: "Varsa E-Posta adresini giriniz.",

      descriptionInputLabel: "Ek Açıklamalar",

      workStatusInputLabel: "Çalışma Durumu",
      workStatusWorking: "Çalışıyor",
      workStatusUnemployed: "Çalışmıyor",
      workStatusEmployer: "İşveren",
      workStatusStudent: "Öğrenci",
      workStatusHouseWife: "Ev Hanımı",

      relatedBusinessInputLabel: "İlgili Kurum",
      relatedBusinessInputPlaceholder:
        "Müşterinin hangi kurum üzerinden geldiğini varsa belirtiniz",

      sendSmsInputLabel: "SMS Gönderim Durumu",
      sendSmsTrue: "Gönderilsin",
      sendSmsFalse: "Gönderilmesin",

      advancedOptions: "Gelişmiş Seçenekler",
      advancedOptionsHide: "Gizle",
      advancedOptionsShow: "Göster",

      insertToast: "Müşteri kaydı başarıyla oluşturuldu!",
    },

    appointment_notification: {
      description: "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz.",
      closeButtonTitle: "Tamam",
      appointmentsButtonTitle: "Randevular",
    },

    allServicesDialog: {
      serviceCategoryTitle: "Hizmet Kategorileri",
      selectedServicesTitle: "Seçtiğiniz Hizmetler",
      loading: "Yükleniyor...",
      noSelectedService: "Seçilen hizmet bulunmamaktadır.",
      notFoundService: "Aradığınız hizmet bulunamamıştır.",
      transferButtonTitle: "Seçilen Hizmetleri Aktar",

      successToast: "Hizmetleriniz başarıyla aktarılmıştır.",
    },

    sms_review: {
      smsReviewTitle: "Mesaj Önizleme",
      totalNumberTitle: "Toplam Gönderilecek Kişi Sayısı:",
      totalWPCreditTitle: "Toplam WhatsApp Kredisi:",
      totalSMSCreditTitle: "Toplam SMS Kredisi:",
      smsContentTitle: "Mesaj İçeriği:",

      inUseSMSCredit: "Kullanılacak Toplam SMS Kredisi:",
      inUseWPCredit: "Kullanılacak Toplam WhatsApp Kredisi:",

      sendButtonTitle: "Gönder",
      discardButtonTitle: "İptal",
    },

    staffOrderDialog: {
      staffOrderTitle: "Çalışanları Sırala",
      closeButtonTitle: "Kapat",
    },

    NADDialog: {
      createAppointment: "Randevu Oluştur",
    },

    OARDialog: {
      title: "Online Randevu Talepleri",
    },

    filterAppointmentDialog: {
      title: "Randevuları Filtrele",
    },

    dayWeekButton: {
      day: "Gün",
      week: "Hafta",
    },

    semiCirclePie: {
      noDisplayChartDescription:
        "Değerler sıfıra eşit olduğu için grafik gösterilmiyor.",
    },
  },

  panel: {
    licenseRemaining: "gün kaldı",
    sms_credit: "SMS",
    wp_credit: "WhatsApp",
    remainingAppointment: "randevu kaldı",
    changeLanguage: "Dil Değiştir",
  },

  panelTabs: {
    moreFavoriteErrorToast: "En fazla 7 tane favori ekleyebilirsiniz.",
  },

  licenseDetails: {
    currentPackage: "(mevcut paket)",
    features: "Özellikler",
    silver: [
      "3 Personele Kadar ",
      'Yeni Üyelere Özel "50 Randevu Hediye"',
      'Yeni Üyelere Özel "100 SMS Hediye"',
      "100 Yeni Randevu Sadece 79₺",
    ],
    gold: [
      "5 Personele Kadar",
      "Sınırsız Randevu",
      "Çoklu Şube Yönetimi",
      "Özel Müşteri Temsilcisi",
      'Yeni Üyelere Özel "250 SMS Hediye"',
    ],
    platinum: [
      "7 Personele Kadar",
      "Adisyon İşlemleri",
      "Paket Yönetimi",
      "Stok Yönetimi",
      "Prim İşlemleri",
      'Yeni üyelere özel "500 SMS hediye"',
      "Gelişmiş Raporlar",
      "Sınırsız Randevu",
      "Online Randevu Sitesi",
      "Çoklu Şube Yönetimi",
      "Özel Müşteri Temsilcisi",
    ],
    diamond: [
      "10 Personele Kadar",
      "E-İmza Eklentisi",
      "Sözleşme Yönetimi",
      "Adisyon İşlemleri",
      "Paket Yönetimi",
      'Yeni üyelere özel "1000 SMS Hediye"',
      "Stok Yönetimi",
      "Prim İşlemleri",
      "Gelişmiş Raporlar",
      "Sınırsız Randevu",
      "Online Randevu Sitesi",
      "Çoklu Şube Yönetimi",
      "Özel Müşteri Temsilcisi",
    ],
    includesAllLicence: [
      "İşletme Yönetimi",
      "Müşteri Yönetimi",
      "Personel Yönetimi",
      "SMS Gönderimi",
      "Randevu Hatırlatma",
      "Randevu Yönetimi",
      "İşletme Galerisi",
      "Yapılacaklar Listesi",
    ],
  },
};

export default langTR;
