import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function ReCaptcha({ reCaptchaCodeHandler }) {
  return (
    <div>
      <ReCAPTCHA
        sitekey="6Lf1y1QpAAAAACbmlmUr-IxZ1krzrx0uUeTjnSc1"
        onChange={(value) =>
          setTimeout(() => {
            reCaptchaCodeHandler(value);
          }, 1000)
        }
      />
    </div>
  );
}

export default ReCaptcha;
