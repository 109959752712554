import React, { useEffect } from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { RxLapTimer } from "react-icons/rx";
import { MuiThemeProvider } from "@material-ui/core/styles";

const theme = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        color: "primary",
        backgroundColor: "white",
        textAlign: "center",
      },
    },
  },
};

function TimeLeftRenewal({ timeLeft, url, setTimeLeft }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  function formatTime(seconds) {
    const days = Math.floor(seconds / 86400); // 1 gün = 86400 saniye
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Sayıları gerekli durumlarda önde sıfır olacak şekilde pad etme
    const formattedDays = days.toString();
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    console.log("formattedDays: ", typeof formattedDays);

    return (
      <MuiThemeProvider theme={theme}>
        <Tooltip
          arrow
          color="red"
          title={
            <div>
              Your license is about to expire! Discounted license just for you
              Click now to benefit from the prices!
            </div>
          }
        >
          <ButtonContainer
            className="campaigns_timeleft_btn"
            onClick={() => window.open(url)}
          >
            <RxLapTimer style={{ color: "white" }} size={22} />
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                <div style={{ width: "100%", textAlign: "center" }}>
                  {Number(formattedDays) >= 1 ? (
                    <>
                      Last{" "}
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {formattedDays}
                      </span>{" "}
                      Day
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: "bold" }}>{hours}</span>:
                      <span style={{ fontWeight: "bold" }}>
                        {formattedMinutes}
                      </span>
                      :
                      <span style={{ fontWeight: "bold" }}>
                        {formattedSeconds}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ButtonContainer>
        </Tooltip>
      </MuiThemeProvider>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        marginTop: "5px",
      }}
    >
      {formatTime(timeLeft)}
    </div>
  );
}

export default TimeLeftRenewal;

const ButtonContainer = styled.div`
  position: fixed;
  top: -10px;
  right: 48%;
  width: 160px;
  font-family: Arial, sans-serif;
  padding: 5px;
  z-index: 999;
  text-align: center;
  background-color: #ff0000;
  border-radius: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  margin-top: 20px;
  @media (max-width: 1800px) {
    position: fixed;
    top: -15px;
    right: 46%;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }

  @media (max-width: 1500px) {
    position: fixed;
    top: -10px;
    right: 47%;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }
  @media (max-width: 900px) {
    position: fixed;
    top: -10px;
    right: 30%;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: -10px;
    right: 30%;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }
  @media (max-width: 480px) {
    position: fixed;
    top: -10px;
    right: 30%;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 5px;
    border-radius: 20px;
  }
`;
